import React, { useEffect, useState } from 'react';
import style from '../../../assets/styles/questionListModal.module.css';
import { CallWithAuth } from '../../../action/apiActions';
import { GET_QUESTIONBANK } from '../../../action/apiPath';
import polygon from '../../../assets/images/polygon.svg';

// Interface for QuestionBank data
interface QuestionBankData {
  id: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

// Props interface
interface QuestionListModalProps {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  setQuestionBankIdModal: (id: number) => void;
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onConfirm: (selectedQuestionBankId: number, questionId: number) => void;
  data?: {
    questionId: number;
    questionBankId: number;
  };
  isDuplicatePreview: boolean;
}

// API response interface
interface ApiResponse {
  status: boolean;
  res?: {
    data: QuestionBankData[];
  };
}

const QuestionListModal: React.FC<QuestionListModalProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setQuestionBankIdModal = () => {},
  isOpen,
  onClose,
  onConfirm,
  data,
  isDuplicatePreview,
}) => {
  const [questionBank, setQuestionBank] = useState<QuestionBankData[] | null>(null);
  const [selectedQuestionBankId, setSelectedQuestionBankId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Fetch question banks
  const getQuestionBankinmodal = async (): Promise<void> => {
    const url = GET_QUESTIONBANK + '?permission=true';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const GetData: ApiResponse | null = await CallWithAuth('GET', url);

    if (GetData?.status && Array.isArray(GetData?.res?.data)) {
      setQuestionBank(GetData.res.data);
      if (setQuestionBankIdModal !== null) {
        setQuestionBankIdModal(GetData.res.data[0].id);
      }
    }
  };

  useEffect(() => {
    getQuestionBankinmodal();
    if (data?.questionBankId) {
      setSelectedQuestionBankId(data.questionBankId);
    }
  }, [data]);

  // Handle dropdown selection
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(e.target.value);
    setSelectedQuestionBankId(selectedId);
    setQuestionBankIdModal(selectedId);
    setError(null); // Clear error on valid selection
  };

  // Confirm selection and trigger callback
  const handleConfirm = () => {
    if (!selectedQuestionBankId) {
      setError('Please select a question bank.'); // Set error if no selection
      return;
    }

    if (selectedQuestionBankId && data?.questionId) {
      onConfirm(selectedQuestionBankId, data.questionId);
    }
  };

  return (
    <>
      {isOpen && (
        <div
          className={`${style.modalBackdrop} ${isOpen ? style.show : ''} ${isDuplicatePreview ? style.previewBackdrop : ''}`}
        >
          <div className={`${style.modalContainer} ${isOpen ? style.show : ''}`}>
            {isDuplicatePreview && <img src={polygon} alt="polygon" className={style.polyImg} />}
            {!isDuplicatePreview && (
              <div className={style.modalHeader}>
                <span className={style.modalTitle}>Duplicate Question</span>
                <button
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') onClose();
                  }}
                  className={style.modalClose}
                  onClick={onClose}
                >
                  &#10005;
                </button>
              </div>
            )}
            <div className={style.modalContent}>
              <label htmlFor="selectQBBank">Select Target Question Bank</label>
              <select
                id="selectQBBank"
                className={style.modalSelect}
                onChange={handleChange}
                value={selectedQuestionBankId ?? ''}
              >
                <option value="" disabled>
                  Select a question bank
                </option>
                {questionBank?.map((items, i) => (
                  <option key={items.name} value={items.id}>
                    {items.name}
                  </option>
                ))}
              </select>
              {/* Display error message */}
              {error && <div className={style.errorText}>{error}</div>}
            </div>
            <div className={style.modalButtons}>
              <button className={style.modalCancelBtn} onClick={onClose}>
                Cancel
              </button>
              <button className={style.modalDuplicateBtn} onClick={handleConfirm}>
                Duplicate
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionListModal;
