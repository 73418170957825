/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types"
import { React, useEffect, useState } from 'react';

import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Product_Type, Publish_Test } from '../../../../../redux/action';

// * const TestLevel = ({ setlevelSetting }) => {
//     const { testDetails } = useSelector((state) => state.GetAllData);

//  *   const { sectionBody } = useSelector((state) => state.GetAllData);

//  *   const [settings, setSettings] = useState([]);

//     useEffect(() => {
//         // First, map over testDetails.groups to create an array of settings
//         const existingSettings =
//             testDetails?.groups?.map((group) => ({
//                 switching_sections: group.switching_sections_allowed,
//             })) || [];

//  *       // Then, map over sectionBody.groups to create an array of new settings
//         const newSettings =
//             sectionBody?.groups?.map((group) => ({
//                 switching_sections: group.switching_sections_allowed,
//             })) || [];

//   *      // Concatenate existingSettings and newSettings to create the final settings array
//         const finalSettings = [...existingSettings, ...newSettings];

//         setSettings(finalSettings);
//     }, [testDetails, sectionBody]);

//   *  useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//  *   // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

//     return (
//         <div className={style.groupSettingsBox}>
//             {settings?.map((items, i) => (
//                 <div
//                     className={style.groupSettings}
//                     style={{ marginBottom: i == settings.length - 1 ? "8em" : "" }}
//                 >
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div
//                                 className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                     }`}
//                                 onClick={() => updateSwitchingSections(i, false)}
//                             >
//                                 No
//                             </div>

//                             <div
//                                 className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                     }`}
//                                 onClick={() => updateSwitchingSections(i, true)}
//                             >
//                                 Yes
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             ))}

//             {/* <div className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 2 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(1, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(1, true)}>Yes</div>
//                     </div>
//                 </div>
//             </div>

//             <div style={{ marginBottom: "8em" }} className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 3 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(2, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(2, true)}>Yes</div>
//                     </div>
//                 </div>
//             </div> */}
//         </div>
//     );
// };

// * const GroupLevel = ({ setlevelSetting }) => {
//     const { testDetails } = useSelector((state) => state.GetAllData);

//*     const { sectionBody } = useSelector((state) => state.GetAllData);
// *    const [settings, setSettings] = useState([]);

//     useEffect(() => {
//         // First, map over testDetails.groups to create an array of settings
//         const existingSettings =
//             testDetails?.groups?.map((group) => ({
//                 switching_sections: group.switching_sections_allowed,
//                 group_duration: {
//                     hours: group.duration?.hours || null,
//                     minutes: group.duration?.minutes || null,
//                     seconds: group.duration?.seconds || null,
//                 },
//             })) || [];

//  *       // Then, map over sectionBody.groups to create an array of new settings
//         const newSettings =
//             sectionBody?.groups?.map((group) => ({
//                 switching_sections: group.switching_sections_allowed,
//                 group_duration: {
//                     hours: group.duration?.hours || null,
//                     minutes: group.duration?.minutes || null,
//                     seconds: group.duration?.seconds || null,
//                 },
//             })) || [];

// *        // Concatenate existingSettings and newSettings to create the final settings array
//         const finalSettings = [...existingSettings, ...newSettings];

//         setSettings(finalSettings);
//     }, [testDetails, sectionBody]);

//  *   useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//  *   // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

//  *   const updateGroupDuration = (index, e, type) => {
//         if (type == "hours") {
//             setSettings((prevSettings) => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) {
//                     // Check if there are elements in the array
//                     updatedSettings[index] = {
//                         // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             hours: Number(e.target.value), // Update hours
//                         },
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "minutes") {
//             setSettings((prevSettings) => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) {
//                     // Check if there are elements in the array
//                     updatedSettings[index] = {
//                         // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             minutes: Number(e.target.value), // Update hours
//                         },
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings((prevSettings) => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) {
//                     // Check if there are elements in the array
//                     updatedSettings[index] = {
//                         // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             seconds: Number(e.target.value), // Update hours
//                         },
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//     };

//     return (
//         <div className={style.groupSettingsBox}>
//             {settings?.map((items, i) => (
//                 <div
//                     className={style.groupSettings}
//                     style={{ marginBottom: i == items.length - 1 ? "8em" : "" }}
//                 >
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div
//                                 className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                     }`}
//                                 onClick={() => updateSwitchingSections(i, false)}
//                             >
//                                 No
//                             </div>

//                             <div
//                                 className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                     }`}
//                                 onClick={() => updateSwitchingSections(i, true)}
//                             >
//                                 Yes
//                             </div>
//                         </div>
//                     </div>
//                     <div className={style.screenNum}>
//                         <p style={{ fontWeight: 500 }}>Group {i + 1} Duration</p>

//                         <div className={style.timer}>
//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "hours")}
//                                 placeholder={items.group_duration.hours}
//                             />
//                             <label htmlFor="minutes">Hours</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "minutes")}
//                                 placeholder={items.group_duration.minutes}
//                             />
//                             <label htmlFor="minutes">Minutes</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "seconds")}
//                                 placeholder={items.group_duration.seconds}
//                             />
//                             <label htmlFor="second">Seconds</label>
//                         </div>
//                     </div>
//                 </div>
//             ))}

//             {/* <div className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 2 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(1, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(1, true)}>Yes</div>
//                     </div>
//                 </div>
//                 <div className={style.screenNum}>
//                     <p style={{ fontWeight: 500 }}>Group 2 Duration</p>

//                     <div className={style.timer}>
//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "hours")}
//                         />
//                         <label htmlFor="minutes">Hours</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "minutes")}
//                         />
//                         <label htmlFor="minutes">Minutes</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "seconds")}
//                         />
//                         <label htmlFor="second">Seconds</label>
//                     </div>
//                 </div>
//             </div>

//             <div style={{ marginBottom: "8em" }} className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 3 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(2, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(2, true)}>Yes</div>
//                     </div>
//                 </div>
//                 <div className={style.screenNum}>
//                     <p style={{ fontWeight: 500 }}>Group 3 Duration</p>

//                     <div className={style.timer}>
//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "hours")}
//                         />
//                         <label htmlFor="minutes">Hours</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "minutes")}
//                         />
//                         <label htmlFor="minutes">Minutes</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "seconds")}
//                         />
//                         <label htmlFor="second">Seconds</label>
//                     </div>
//                 </div>
//             </div> */}
//         </div>
//     );
// };

//* const SectionLevel = ({ setlevelSetting }) => {
//     const { testDetails } = useSelector((state) => state.GetAllData);

// *    const { sectionBody } = useSelector((state) => state.GetAllData);

//*     const [settings, setSettings] = useState([]);

//     useEffect(() => {
//         // Initialize settings with existing values from testDetails
//         const existingSettings =
//             testDetails?.groups?.map((group) => ({
//                 switching_sections: group.switching_sections_allowed,
//                 group_duration: {
//                     hours: group.duration?.hours || null,
//                     minutes: group.duration?.minutes || null,
//                     seconds: group.duration?.seconds || null,
//                 },
//                 sections_durations:
//                     group.sections?.map((section) => ({
//                         hours: section.duration?.hours || null,
//                         minutes: section.duration?.minutes || null,
//                         seconds: section.duration?.seconds || null,
//                     })) || [],
//             })) || [];

//  *       // Create new settings based on sectionBody
//         const newSettings =
//             sectionBody?.groups?.flatMap((group) => {
//                 return group.sections.map((section) => ({
//                     switching_sections: false,
//                     group_duration: {
//                         hours: null,
//                         minutes: null,
//                         seconds: null,
//                     },
//                     sections_durations: [
//                         {
//                             hours: section.duration?.hours || null,
//                             minutes: section.duration?.minutes || null,
//                             seconds: section.duration?.seconds || null,
//                         },
//                     ],
//                 }));
//             }) || [];

//         // Combine existing settings with new settings
//         const finalSettings = [...existingSettings, ...newSettings];
//         setSettings(finalSettings);
//     }, [testDetails, sectionBody]);

// *    useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//     // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             // Create a new array for the updated settings
//             const updatedSettings = prevSettings.map((setting, i) => {
//                 // If this is the setting we want to update, return a new object with the updated value
//                 if (i === index) {
//                     return {
//                         ...setting,
//                         switching_sections: Boolean(value),
//                     };
//                 }
//                 // Otherwise, return the setting unchanged
//                 return setting;
//             });

//             return updatedSettings;
//         });
//     };

//     const updateGroupDuration = (index, e, type) => {
//         if (type == "hours") {
//             setSettings((prevSettings) => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) {
//                     // Check if there are elements in the array
//                     updatedSettings[index] = {
//                         // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             hours: Number(e.target.value), // Update hours
//                         },
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "minutes") {
//             setSettings((prevSettings) => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) {
//                     // Check if there are elements in the array
//                     updatedSettings[index] = {
//                         // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             minutes: Number(e.target.value), // Update hours
//                         },
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings((prevSettings) => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) {
//                     updatedSettings[index] = {
//                         // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             seconds: Number(e.target.value), // Update hours
//                         },
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//     };
//     const updateSectionDuration = (index, e, type, index2) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = prevSettings.map((setting, i) => {
//                 if (i === index) {
//                     const updatedSectionsDurations = setting.sections_durations.map(
//                         (duration, j) => {
//                             if (j === index2) {
//                                 return {
//                                     ...duration,
//                                     [type]: Number(e.target.value),
//                                 };
//                             }

//                             return duration;
//                         }
//                     );

//  *                   return {
//                         ...setting,
//                         sections_durations: updatedSectionsDurations,
//                     };
//                 }

//                 return setting;
//             });

//             return updatedSettings;
//         });
//     };

//     return (
//         <div className={style.groupSettingsBox}>
//             {settings?.map((items, i) => (
//                 <div
//                     className={style.groupSettings}
//                     style={{ marginBottom: i == items.length - 1 ? "8em" : "" }}
//                 >
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Sections Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div
//                                 className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                     }`}
//                                 onClick={() => updateSwitchingSections(i, false)}
//                             >
//                                 No
//                             </div>

//                             <div
//                                 className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                     }`}
//                                 onClick={() => updateSwitchingSections(i, true)}
//                             >
//                                 Yes
//                             </div>
//                         </div>
//                     </div>
//                     <div className={style.screenNum}>
//                         <p style={{ fontWeight: 500 }}>Group {i + 1} Duration</p>

//                         <div className={style.timer}>
//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 value={items.group_duration.hours}
//                                 onChange={(e) => updateGroupDuration(i, e, "hours")}
//                             />
//                             <label htmlFor="minutes">Hours</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 value={items.group_duration.minutes}
//                                 onChange={(e) => updateGroupDuration(i, e, "minutes")}
//                             />
//                             <label htmlFor="minutes">Minutes</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 value={items.group_duration.seconds}
//                                 onChange={(e) => updateGroupDuration(i, e, "seconds")}
//                             />
//                             <label htmlFor="second">Seconds</label>
//                         </div>
//                     </div>
//                     <div
//                         style={{ margin: "2em 0em 10em 3em" }}
//                         className={style.screenNum}
//                     >
//                         {items.sections_durations.map((item, key) => (
//                             <>
//                                 <p style={{ fontWeight: 500 }}>Section {key + 1} Duration</p>

//                                 <div className={style.timer}>
//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         value={item.hours}
//                                         onChange={(e) => updateSectionDuration(i, e, "hours", key)}
//                                     />
//                                     <label htmlFor="minutes">Hours</label>

//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         value={item.minutes}
//                                         onChange={(e) =>
//                                             updateSectionDuration(i, e, "minutes", key)
//                                         }
//                                     />
//                                     <label htmlFor="minutes">Minutes</label>

//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         value={item.seconds}
//                                         onChange={(e) =>
//                                             updateSectionDuration(i, e, "seconds", key)
//                                         }
//                                     />
//                                     <label htmlFor="second">Seconds</label>
//                                 </div>
//                             </>
//                         ))}
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };

const AdvanceSettingsEdit = ({ publish, setButtonStatus }) => {
  const { testDetails } = useSelector((state) => state.GetAllData);
  const testId = testDetails?.id;
  const dispatch = useDispatch();
  const { testCreationId } = useSelector((state) => state.GetAllData);
  const { getProductType } = useSelector((state) => state.GetAllData);
  // call test rendering template
  useEffect(() => {
    dispatch(Get_Product_Type());
  }, []);

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  const dateTime = getCurrentDateTime();

  const [settings, setSettings] = useState({
    calculator: testDetails?.calculator,
    result_date_time: testDetails?.result_date_time || dateTime,
    test_duration_at: testDetails?.test_duration_at,
    overall_test_duration: {
      hours: testDetails?.duration?.hours || 0,
      minutes: testDetails?.duration?.minutes || 0,
      seconds: testDetails?.duration?.seconds || 0,
    },
  });

  // section setting
  const [levelSetting, setlevelSetting] = useState(null);

  useEffect(() => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...levelSetting,
    }));
  }, [levelSetting]);

  useEffect(() => {
    if (publish) {
      dispatch(Publish_Test(settings, testId));
    }
  }, [publish]);

  const [dateTimeValue, setDateTimeValue] = useState('');

  useEffect(() => {
    // Convert your value to the correct format for datetime-local input
    const utcDateTime = testDetails?.result_date_time;
    const localDateTime = new Date(utcDateTime).toISOString().slice(0, 16);
    setDateTimeValue(localDateTime);
  }, [testDetails]);

  // function to add
  // const handleRenderingTemplate = (e) => {
  //     setSettings((prevState) => ({
  //         ...prevState,
  //         rendering_template: Number(e.target.value),
  //     }));
  // };

  const handleCalculator = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      calculator: Number(e.target.value),
    }));
  };

  const handleResultDateTime = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      result_date_time: e.target.value,
    }));
  };

  const handleOverAllDuration = (e, type) => {
    if (type === 'hours') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          hours: Number(e.target.value),
        },
      }));
    }
    if (type === 'minutes') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          minutes: Number(e.target.value),
        },
      }));
    }
    if (type === 'seconds') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          seconds: Number(e.target.value),
        },
      }));
    }
  };

  //* const handleSwitchGroup = (e) => {
  //     setSettings((prevState) => ({
  //         ...prevState,
  //         switching_groups_allowed: Boolean(e),
  //     }));
  // };

  const handleLevel = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      test_duration_at: Number(e.target.value),
    }));
  };

  useEffect(() => {
    if (
      settings.rendering_template !== null &&
      settings.calculator !== null &&
      settings.overall_test_duration.hours !== null &&
      settings.overall_test_duration.minutes !== null &&
      settings.overall_test_duration.seconds !== null
    ) {
      setButtonStatus(true);
    }
  }, [settings]);

  // * const template = settings.rendering_template;

  return (
    <div className={style.masterTestCreation}>
      <form className={` ${style.testCreationArea} ${style.AdvanceBox}`}>
        <div className={style.AdvanceSettingsContainer}>
          <h5 style={{ fontSize: '18px' }} className={style.testMarklabel}>
            Advance Settings
          </h5>

          <div className={style.bound}>
            {/* <div className={style.templateDrop}>
                            <div className={style.templateDrop_item}>
                                <div className={style.templateGroup}>
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                        Test Paper Rendering Template
                                    </span>
                                    <select
                                        name="question"
                                        id="question"
                                        className={style.template_drop_filter}
                                        onChange={handleRenderingTemplate}
                                        value={settings.rendering_template}
                                    >
                                        {getProductType?.map((items, i) => (
                                            <option value={items.id}>{items.product_type}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div> */}

            {/* Test type */}
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Calculator To Use</span>
                  <select
                    name="question"
                    id="question"
                    className={style.template_drop_filter}
                    onChange={handleCalculator}
                    value={settings.calculator}
                  >
                    <option value={0}>None</option>
                    <option value={1}>Basic</option>
                    <option value={2}>Scientific</option>
                  </select>
                </div>
              </div>
            </div>

            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Result Date/ Time</span>
                  <input
                    type="datetime-local"
                    name="question"
                    id="question"
                    value={settings?.result_date_time}
                    className={style.template_drop_filter}
                    onChange={handleResultDateTime}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={style.switching}>
                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                            Switching Groups Allowed
                        </span>
                        <div className={style.wrapperIcon}>
                            <div
                                className={` ${style.iconBoxs} ${settings.switching_groups_allowed ? "" : style.editOption
                                    }`}
                                onClick={() => handleSwitchGroup(false)}
                            >
                                No
                            </div>

                            <div
                                className={` ${style.iconBoxs} ${settings.switching_groups_allowed ? style.editOption : ""
                                    }`}
                                onClick={() => handleSwitchGroup(true)}
                            >
                                Yes
                            </div>
                        </div>
                    </div> */}

          <p style={{ fontWeight: '500', marginTop: '30px' }} className={style.marketLogic}>
            Test Duration At
          </p>
          <div style={{ fontSize: '13px' }} className={style.levels}>
            <input
              type="radio"
              name="markingLogic"
              checked={settings.test_duration_at === 0}
              value={0}
              onChange={handleLevel}
            />
            <label htmlFor="test level">Test Level</label>
            <input
              style={{ marginLeft: '20px' }}
              name="markingLogic"
              type="radio"
              checked={settings.test_duration_at === 1}
              value={1}
              onChange={handleLevel}
            />
            <label htmlFor="section level">Group Level</label>
            <input
              style={{ marginLeft: '20px' }}
              name="markingLogic"
              type="radio"
              checked={settings.test_duration_at === 2}
              onChange={handleLevel}
              value={2}
            />
            <label htmlFor="section level">Section Level</label>
          </div>

          <div className={style.screenNum}>
            <p style={{ fontWeight: 500 }}>Overall Test Duration</p>

            <div className={style.timer}>
              <input
                className={` ${style.timer_input} ${style.fullbg_section}`}
                type="number"
                name="number"
                min="-100"
                max="100"
                step="1"
                value={settings?.overall_test_duration?.hours}
                onChange={(e) => handleOverAllDuration(e, 'hours')}
              />
              <label htmlFor="minutes">Hours</label>

              <input
                className={` ${style.timer_input} ${style.fullbg_section}`}
                type="number"
                name="number"
                min="-100"
                max="100"
                step="1"
                value={settings?.overall_test_duration?.minutes}
                onChange={(e) => handleOverAllDuration(e, 'minutes')}
              />
              <label htmlFor="minutes">Minutes</label>

              <input
                className={` ${style.timer_input} ${style.fullbg_section}`}
                type="number"
                name="number"
                min="-100"
                max="100"
                step="1"
                value={settings?.overall_test_duration?.seconds}
                onChange={(e) => handleOverAllDuration(e, 'seconds')}
              />
              <label htmlFor="second">Seconds</label>
            </div>
          </div>

          {/* * {settings.test_duration_at == 0 ? (
                        <TestLevel setlevelSetting={setlevelSetting} />
                    ) : (
                        ""
                    )}
                    {settings.test_duration_at == 1 ? (
                        <GroupLevel setlevelSetting={setlevelSetting} />
                    ) : (
                        ""
                    )}
                    {settings.test_duration_at == 2 ? (
                        <SectionLevel setlevelSetting={setlevelSetting} />
                    ) : (
                        ""
                    )} */}
        </div>
      </form>
    </div>
  );
};

AdvanceSettingsEdit.propTypes = {
  publish: PropTypes.any,
  setButtonStatus: PropTypes.func
}

export default AdvanceSettingsEdit;
