import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
  const accessToken = userInfo.jwt;
  if (!accessToken || accessToken === null || accessToken === undefined) {
    return <Navigate to="/sign-in" replace />;
  }
  return children;
};

export default ProtectedRoute;
