import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
  const accessToken = userInfo.jwt;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    if (accessToken && accessToken !== null && accessToken !== undefined) {
      setShouldRedirect(true);
    }
  }, [accessToken]);

  if (shouldRedirect) {
    return <Navigate to="/question-bank" replace />;
  }

  return children;
};

export default PublicRoute;
