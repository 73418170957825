import { CallWithAuth } from '../../../action/apiActions';

const BASE_URL = process.env.REACT_APP_API_KEY;

export const openPreviewModal = async (questionData, setSelectedQuestion, setIsPreviewopen) => {
  if (typeof questionData === 'number') {
    const data = `${BASE_URL}question/${questionData}/get-details/`;
    const questionlistingData = await CallWithAuth('GET', data);

    if (questionlistingData?.res?.data) {
      setSelectedQuestion(questionlistingData.res.data);
    } else {
      throw new Error('Error: Data not found in response');
    }
  } else {
    setSelectedQuestion(questionData);
  }
  setIsPreviewopen(true);
};

export const closePreviewModal = (setIsPreviewopen, setIsDuplicatePreview, setIsModalOpen) => {
  setIsPreviewopen(false);
  setIsDuplicatePreview(false);
  setIsModalOpen(false);
};
