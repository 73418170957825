import React, { useEffect, useState } from 'react';
import AuthLayout from '../layout/AuthLayout';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import Category from '../component/molecule/specific/MastersManagementScreens/Category';
import Identifier from '../component/molecule/specific/MastersManagementScreens/Identifier';
import LodComponent from '../component/molecule/specific/MastersManagementScreens/LOD';
import QuestionBank from '../component/molecule/specific/MastersManagementScreens/QuestionBank';
import SubjectPipeline from '../component/molecule/specific/MastersManagementScreens/SubjectPipeline';
import style from '../assets/styles/masterTemplate.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '../component/molecule/common/Pagination';
import { Get_All_Category } from '../redux/action';
import { useNavigate } from 'react-router-dom';
const Masters = () => {
  // _________question bank id which is top dropdown _________
  const [inputPage, setInputPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [last, setLast] = useState(15);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const { masterScreen } = useSelector((state) => state.GetAllData);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
  const Roles = userInfo.roles || [];
  const isOrganizationUser = Roles.some((role) => role.id === 10);
  useEffect(() => {
    setCurrentPage(1);
    setInputPage(1);
    setLimit(15);
    setOffset(0);
    return () => {
      dispatch(Get_All_Category('', '', '', ''));
    };
  }, [masterScreen]);
  const navigate = useNavigate();
  useEffect(() => {
    if (isOrganizationUser) {
      alert('Access denied, only super admin or organization admin can access');
      navigate('/question-bank');
    }
  }, [isOrganizationUser]);
  return (
    <AuthLayout>
      {/* rightpannel top left menu according to different screen  */}
      <RightPanelTopMenu title={'Masters'} />

      {/* different screens according to the selection of masters  */}
      <div className={style.Master_div}>
        {masterScreen === 1 ? (
          <Category
            totalCount={totalCount}
            offset={offset}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            setInputPage={setInputPage}
            setLast={setLast}
            setNextUrl={setNextUrl}
            setPrevUrl={setPrevUrl}
            setTotalCount={setTotalCount}
            currentPage={currentPage}
          />
        ) : (
          ''
        )}
        {masterScreen === 2 ? (
          <Identifier
            totalCount={totalCount}
            offset={offset}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            setInputPage={setInputPage}
            setLast={setLast}
            setNextUrl={setNextUrl}
            setPrevUrl={setPrevUrl}
            setTotalCount={setTotalCount}
            currentPage={currentPage}
          />
        ) : (
          ''
        )}
        {masterScreen === 3 ? (
          <LodComponent
            totalCount={totalCount}
            offset={offset}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            setInputPage={setInputPage}
            setLast={setLast}
            setNextUrl={setNextUrl}
            setPrevUrl={setPrevUrl}
            setTotalCount={setTotalCount}
            currentPage={currentPage}
          />
        ) : (
          ''
        )}
        {masterScreen === 4 ? (
          <QuestionBank
            totalCount={totalCount}
            offset={offset}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            setInputPage={setInputPage}
            setLast={setLast}
            setNextUrl={setNextUrl}
            setPrevUrl={setPrevUrl}
            setTotalCount={setTotalCount}
            currentPage={currentPage}
          />
        ) : (
          ''
        )}
        {masterScreen === 5 ? (
          <SubjectPipeline
            totalCount={totalCount}
            offset={offset}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            setInputPage={setInputPage}
            setLast={setLast}
            setNextUrl={setNextUrl}
            setPrevUrl={setPrevUrl}
            setTotalCount={setTotalCount}
            currentPage={currentPage}
          />
        ) : (
          ''
        )}
      </div>
      {/*______________________________ PAGINATION ______________________________  */}
      <div className={style.MasterpaginationWrap}>
        <Pagination
          totalCount={totalCount}
          limit={limit}
          currentPage={currentPage}
          inputPage={inputPage}
          offset={offset}
          last={last}
          nextUrl={nextUrl}
          prevUrl={prevUrl}
          setOffset={setOffset}
          setCurrentPage={setCurrentPage}
          setInputPage={setInputPage}
          setLast={setLast}
        />
      </div>
    </AuthLayout>
  );
};

export default Masters;
