/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import MastersEditModal from '../../../atoms/Modal/MastersEditModal';
import MastersAddModal from '../../../atoms/Modal/MastersAddModal';
import style from '../../../../assets/styles/masterTemplate.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Get_All_Question_bank } from '../../../../redux/action';
import NativeButton from '../../../atoms/Button';

const QuestionBank = ({
  currentPage,
  offset,
  setOffset,
  setCurrentPage,
  setInputPage,
  setLast,
  setNextUrl,
  setPrevUrl,
  setTotalCount,
}) => {
  // _______________ fetch questionBank Data from the API with the keyword for searchBar_______________
  const [questionBankKey, setQuestionBankKey] = useState('');
  const [sort, setSort] = useState('');
  const [sortName, setSortName] = useState(true);
  const limit = 15;
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true); // Set loading to true before fetching
    dispatch(Get_All_Question_bank(questionBankKey, sort, limit, currentPage));
  }, [questionBankKey, sortName, limit, currentPage]);
  const { questionBankList } = useSelector((state) => state.GetAllData);

  const sortNameFunction = () => {
    ActionPerform(null);
    if (sortName) {
      setSort('name');
      setSortName(!sortName);
    } else {
      setSort('-name');
      setSortName(!sortName);
    }
  };

  // ________________Add modal ________________
  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  // ________________ EDIT modal ________________
  const [editmodal, setEditmodal] = useState(false);
  const [editdata, setEditdata] = useState(null);

  const openEditModal = (items) => {
    setActionId(null);
    setStatusOfaction(false);
    setEditmodal(true);
    setEditdata(items);
  };
  const closeEditModal = () => {
    setEditmodal(false);
  };

  // ________________ ACTION OF EDIT ________________
  const [statusOfaction, setStatusOfaction] = useState(false);
  const [actionId, setActionId] = useState(null);
  const lastClickedButtonRef = useRef(null);

  const ActionPerform = (i, event) => {
    if (event) {
      event.stopPropagation();
      lastClickedButtonRef.current = event.currentTarget;
    }

    if (statusOfaction && actionId === i) {
      setActionId(null);
      setStatusOfaction(false);
    } else {
      setActionId(i);
      setStatusOfaction(true);
    }
  };
  useEffect(() => {
    if (questionBankList) {
      setIsLoading(false);
      const totalResults = questionBankList.count;

      // If the current offset is greater than the total results,
      // reset to first page
      if (offset >= totalResults && totalResults > 0) {
        setOffset(0);
        setCurrentPage(1);
        setInputPage(1);
        setLast(Math.min(15, questionBankList?.count));
        setTotalCount(offset);
      } else {
        if (questionBankList?.count) {
          setTotalCount(questionBankList?.count);
          setLast(Math.min(offset + 15, questionBankList?.count));
        }
        if (!questionBankList?.count) {
          setTotalCount(1);
          setLast(1);
          setOffset(0);
        }
      }
      setNextUrl(questionBankList?.next);
      setPrevUrl(questionBankList?.previous);
    }
  }, [questionBankList]);
  // __________________ Outside clickable close modal _____________________
  const rowRef = useRef();

  const handleRowClick = (event) => {
    // Don't close if clicking the action button or its children
    if (event.target.id === 'edit' || lastClickedButtonRef.current?.contains(event.target)) {
      return;
    }
    setActionId(null);
    setStatusOfaction(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleRowClick);
    return () => {
      document.removeEventListener('click', handleRowClick);
    };
  }, [handleRowClick]);

  return (
    <>
      <div className={style.master_category}>
        <div className={style.question_bank}>
          {/* _______________________ TABLE SECTION OF THIS PAGE _______________________ */}
          <div className={style.table_content_report}>
            {/* _______________________Top content of table_______________________  */}
            <div className={style.top_content_table}>
              {/*_______________________  Search bar  _______________________ */}
              <div className={` ${style.search_listing} ${style.user_management_search_listing}`}>
                <div className={style.search_icon}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.2598 19.2592L16.1406 16.8594"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className={style.input_search_master}>
                  <input
                    type="search"
                    id="searchInput"
                    placeholder="Search by Question Bank and Tags"
                    onChange={(e) => {
                      setQuestionBankKey(e.target.value);
                      setCurrentPage(1);
                      setInputPage(1);
                      setSortName(true);
                      setSort('');
                    }}
                  />
                </div>
              </div>
              {/*_______________________  filter  _______________________ */}
              <div className={` ${style.filter} ${style.user_management_filter}`}></div>
              {/*_______________________  Add Question Button  _______________________ */}
              <div className={style.add_question}>
                <button className={` ${style.add_new_question} ${style.pointer}`} onClick={() => openModal()}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      fill="white"
                    />
                    <path
                      d="M7.5 12H16.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 16.5V7.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>Add New Question Bank</span>
                </button>
              </div>
            </div>

            {/* TABLE LISTING OFquestionbank  */}
            <div className={` ${style.sub_head_content_table} ${style.questionBank_table}`}>
              <table>
                <thead>
                  <tr>
                    <th className={` ${style.first} ${style.pointer}`} onClick={() => sortNameFunction()}>
                      <span>Question Bank</span>
                      <svg
                        style={
                          sort === 'name' || sort === ''
                            ? { marginLeft: '6px', transform: 'rotate(0deg)', transition: 'transform 0.3s' }
                            : { marginLeft: '6px', transform: 'rotate(180deg)', transition: 'transform 0.3s' }
                        }
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill={sort === '' ? '#999090' : '#00ABFB'}
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill={sort === '' ? '#999090' : '#00ABFB'}
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill={sort === '' ? '#999090' : '#00ABFB'}
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill={sort === '' ? '#999090' : '#00ABFB'}
                        />
                      </svg>
                    </th>
                    <th className={` ${style.second} ${style.pointer}`}>
                      {' '}
                      <span>Question Bank Description</span>
                    </th>
                    <th className={` ${style.third} ${style.pointer}`}>Tags</th>
                    <th className={` ${style.fifth} ${style.pointer}`}></th>
                    <th></th>
                  </tr>
                </thead>

                {/* ____________ questionbank Data Listing _____________ */}
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          textAlign: 'center',
                          padding: '20px',
                          fontSize: '14px',
                        }}
                      >
                        Loading...
                      </td>
                    </tr>
                  </tbody>
                ) : Array.isArray(questionBankList?.results) ? (
                  <tbody>
                    {questionBankList?.results?.map((items, i) => (
                      <tr key={i + items.name} className={style.table_body_content} ref={rowRef}>
                        <td>
                          {' '}
                          <span className={style.questionbankName}>{items.name}</span>
                        </td>
                        <td className={style.questionBank_description}>
                          <span className={style.questionbankDescription}>
                            {items?.discription?.split('\n').map((line, index) => (
                              <React.Fragment key={index + line}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </span>
                        </td>
                        <td className={style.questionBank_tags} style={{ textAlign: 'center' }}>
                          <span
                            className={style.questionbankTag}
                            style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}
                          >
                            {items.free_tags?.map((item, i) => (
                              <>
                                <span key={i + item.name} className={style.test_type}>
                                  {item.name}
                                </span>
                                &nbsp;
                              </>
                            ))}
                          </span>
                        </td>
                        <td id="edit">
                          <div className={style.action} id="edit">
                            <svg
                              className={`${style.pointer} ${style.actionIconwrap}`}
                              id="edit"
                              onClick={() => ActionPerform(i)}
                              width="4"
                              height="17"
                              viewBox="0 0 4 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 12.5C2.53043 12.5 3.03914 12.7107 3.41421 13.0858C3.78929 13.4609 4 13.9696 4 14.5C4 15.0304 3.78929 15.5391 3.41421 15.9142C3.03914 16.2893 2.53043 16.5 2 16.5C1.46957 16.5 0.96086 16.2893 0.585787 15.9142C0.210714 15.5391 0 15.0304 0 14.5C0 13.9696 0.210714 13.4609 0.585787 13.0858C0.96086 12.7107 1.46957 12.5 2 12.5ZM2 6.5C2.53043 6.5 3.03914 6.71071 3.41421 7.08579C3.78929 7.46086 4 7.96957 4 8.5C4 9.03043 3.78929 9.53914 3.41421 9.91421C3.03914 10.2893 2.53043 10.5 2 10.5C1.46957 10.5 0.96086 10.2893 0.585787 9.91421C0.210714 9.53914 0 9.03043 0 8.5C0 7.96957 0.210714 7.46086 0.585787 7.08579C0.96086 6.71071 1.46957 6.5 2 6.5ZM2 0.5C2.53043 0.5 3.03914 0.710714 3.41421 1.08579C3.78929 1.46086 4 1.96957 4 2.5C4 3.03043 3.78929 3.53914 3.41421 3.91421C3.03914 4.28929 2.53043 4.5 2 4.5C1.46957 4.5 0.96086 4.28929 0.585787 3.91421C0.210714 3.53914 0 3.03043 0 2.5C0 1.96957 0.210714 1.46086 0.585787 1.08579C0.96086 0.710714 1.46957 0.5 2 0.5Z"
                                fill="#272727"
                              />
                            </svg>
                            <div
                              className={style.action_buttons}
                              id="edit"
                              style={{ display: actionId === i ? 'block' : 'none' }}
                            >
                              <NativeButton id="edit" onClick={() => openEditModal(items)}>
                                <div
                                  className={` ${style.edit} ${style.pointer}`}
                                  style={{ marginBottom: '10px' }}
                                  id="edit"
                                >
                                  {' '}
                                  <svg
                                    id="edit"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 14.668H14"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{' '}
                                  <span className={style.action_Editbuttons} id="edit">
                                    Edit
                                  </span>
                                </div>
                              </NativeButton>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td
                        colSpan="3"
                        className={style.second}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                          fontSize: '14px',
                        }}
                      >
                        {questionBankList && questionBankList.status === 400
                          ? questionBankList.error
                          : "No Results Found. We couldn't find anything that matches your search. Please try using different keywords"}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* ____________________ All Modals _______________________ */}
      <MastersAddModal
        categoryKey={questionBankKey}
        modal={modal}
        closeModal={closeModal}
        type={'QuestionBank'}
        {...{ sort, limit, currentPage, setSort }}
      />
      <MastersEditModal
        categoryKey={questionBankKey}
        modal={editmodal}
        data={editdata}
        closeModal={closeEditModal}
        type={'QuestionBank'}
        {...{ sort, limit, currentPage, setSort }}
      />
    </>
  );
};

QuestionBank.propTypes = {
  currentPage: PropTypes.any,
  offset: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setInputPage: PropTypes.func,
  setLast: PropTypes.func,
  setNextUrl: PropTypes.func,
  setOffset: PropTypes.func,
  setPrevUrl: PropTypes.func,
  setTotalCount: PropTypes.func,
};

export default QuestionBank;
