import React, { useEffect, useRef, useState } from 'react';
import AuthLayout from '../layout/AuthLayout';
import { useNavigate } from 'react-router-dom';
import { CallWithAuth } from '../action/apiActions';
import { GET_QUESTION_BANK, GET_USERS } from '../action/apiPath';
import moment from 'moment/moment';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import FilterModal from '../component/atoms/Modal/FilterModal';
import QuestionListModal from '../component/atoms/Modal/QuestionListModal';
import style from '../assets/styles/createQuestion.module.css';
import PreviewModal from '../component/atoms/Modal/PreviewModal';
import LockModal from '../component/atoms/Modal/LockModel';
import { useToast } from '../component/molecule/common/Toast';
import { Pagination } from '../component/molecule/common/Pagination';
import { openPreviewModal, closePreviewModal } from '../component/atoms/Modal/modalHandlers';
import { editQuestionHandler } from '../component/atoms/Modal/editHandlers';
import { handleDuplicate, openModalHelper, closeModalHelper } from '../component/atoms/Modal/duplicateHelper';
import { handleConfirmDuplicate } from '../component/atoms/Modal/duplicateInPreviewHelper';

const QuestionBank = () => {
  const toast = useToast();
  const [modalMedium, setModalMedium] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [modalModalData] = useState({
    title: 'This Question is Linked to Existing Tests',
    description:
      'The question you are about to edit has been used in one or more tests. Please be sure to click the ‘Update Exam’ button to update the changes across all tests that include this question.',
    cancelButton: 'Cancel',
    saveButton: 'Continue',
    substitle: 'Are you sure you want to proceed with editing this question?',
  });
  const getRotation = (column) => {
    if (sort === column) {
      return 'rotate(180deg)';
    }
    return 'rotate(0deg)';
  };
  const openModalMedium = () => {
    setModalMedium(true);
  };
  const closeModalMedium = () => {
    setModalMedium(false);
  };
  // ________ initialization :start ____________
  const navigate = useNavigate();
  // ________ initialization :end ____________

  // ________ button for navigating to create question ________
  const CreateQuestion = () => {
    navigate('/create-question');
  };
  // ________ button for navigating to create question ________

  // ________ PAGINATION ________

  const [totalCount, setTotalCount] = useState(0);
  const [last, setLast] = useState(5);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(currentPage);
  const [isPreviewopen, setIsPreviewopen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null); // State to store dataToPass
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [groupedQuestions, setGroupedQuestions] = useState([]);
  const maxSiblingsToShow = 5; // Limit to show only 5 siblings
  const [showMoreState, setShowMoreState] = React.useState({}); // Object to hold the state of each row
  const [isDuplicatePreview, setIsDuplicatePreview] = useState(false); // State to store dataToPass

  const toggleShowMore = (rowId) => {
    // eslint-disable-next-line no-console
    console.log('rowId', groupedQuestions);
    setShowMoreState((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId], // Toggle the specific row's state
    }));
  };
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('isDuplicatePreview', isDuplicatePreview);
  }, [isModalOpen, modalData, isDuplicatePreview]);

  useEffect(() => {
    // alert(totalCount)
    if (totalCount < 5) {
      setLast(totalCount);
    } else {
      setLast(5);
    }
  }, [totalCount]);
  // _________question bank id which is top dropdown _________
  const [questionBankId, setQuestionBankId] = useState(4);

  // _________which one to show my or all _________
  const [questionCategory, setQuestionCategory] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const ChangeQuestionCategory = (e) => {
    setQuestionCategory(e);
  };

  // _________ALL question created listing data _________
  const [questionResults, setQuestionResults] = useState([]);

  const groupQuestionsByParent = (questions) => {
    const grouped = {};

    questions.forEach((question) => {
      // Extract question_id and siblings
      const { question_id, siblings } = question;

      // Group by question_id and include siblings
      grouped[question_id] = siblings;
    });
    return grouped;
  };

  const QuestionListing = async () => {
    const url =
      GET_QUESTION_BANK +
      questionBankId +
      '/question-listing/?draft=0' +
      '&category=' +
      multiselectedCategoryTag.map((item) => item.id).join(',') +
      '&subject_tags=' +
      multiselectedSubjectTag.map((item) => item.id).join(',') +
      '&free_tags=' +
      multiselectedFreeTag.map((item) => item.id).join(',') +
      '&question_authors=' +
      multiselectedAuthorTag.map((item) => item.id).join(',') +
      '&page=' +
      currentPage +
      '&limit=' +
      limit +
      '&last_used_in=' +
      selectedYear +
      '&sort=' +
      sort +
      '&exclude_questions_used_since=' +
      selectedYearExclude +
      `${'&question_id=' + questionId > 0 ? questionId : ''}`;

    try {
      const response = await CallWithAuth('GET', url);

      if (response.status && response.res.status === 200) {
        // Extract siblings from the response
        const questions = response.res.data.results;
        // Group questions by their parent
        const groupedQuestions = groupQuestionsByParent(questions);
        const totalResults = response.res.data.count;
        // Set the grouped questions in the state
        setGroupedQuestions(groupedQuestions || []); // Use empty array as fallback
        setQuestionResults(response.res.data.results);
        setTotalCount(totalResults);
        setNextUrl(response.res.data.next);
        setPrevUrl(response.res.data.previous);
      } else {
        setQuestionResults([]);
        setTotalCount(0);
        setCurrentPage(1);
        setInputPage(1);
        setLast(5);
        setOffset(0);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during fetching question listing:', error);
    }
  };

  useEffect(() => {
    QuestionListing(); // Fetch data on component mount
  }, []);

  // _________ MY question created listing data __________
  const [myquestionResults, setMyquestionResults] = useState([]);

  const MyQuestionListing = async () => {
    const url =
      GET_QUESTION_BANK +
      questionBankId +
      '/question-listing/?draft=1' +
      '&category=' +
      multiselectedCategoryTag.map((item) => item.id).join(',') +
      '&subject_tags=' +
      multiselectedSubjectTag.map((item) => item.id).join(',') +
      '&free_tags=' +
      multiselectedFreeTag.map((item) => item.id).join(',') +
      '&question_authors=' +
      multiselectedAuthorTag.map((item) => item.id).join(',') +
      '&page=' +
      currentPage +
      '&limit=' +
      limit +
      '&last_used_in=' +
      selectedYear +
      '&sort=' +
      sort +
      '&exclude_questions_used_since=' +
      selectedYearExclude +
      `&my_questions=1` +
      `${'&question_id=' + questionId > 0 ? questionId : ''}`;

    const response = await CallWithAuth('GET', url);
    if (response.status && response.res.status === 200) {
      // Extract siblings from the questionlistingData
      const questions = response.res.data.results;
      // Group questions by their parent
      const groupedQuestions = groupQuestionsByParent(questions);

      // Set the grouped questions in the state
      setGroupedQuestions(groupedQuestions || []); // Use empty array as fallback
      setMyquestionResults(response.res.data.results);
      setTotalCount(response.res.data.count);
      setNextUrl(response.res.data.next);
      setPrevUrl(response.res.data.previous);
    } else if (!response.status && response.res.status === 400) {
      setMyquestionResults([]);
      setTotalCount(0);
      setCurrentPage(1);
      setInputPage(1);
      setLast(5);
      setOffset(0);
    }
  };

  // ________ filter modal open  ________
  const [modalFilter, setModalFilter] = useState(false);

  const openModalFilter = () => {
    setModalFilter(true);
  };

  const closeModalFilter = () => {
    setModalFilter(false);
  };

  // Get the current year
  const currentYear = new Date().getFullYear();
  // _________ PRIMARY TAG STATE INITIALIZATION _________
  const [questionId, setquestionId] = useState(0);
  const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([]);
  const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([]);
  const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([]);
  const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([]);
  const [sort, setSort] = useState('');
  // Initialize state for the selected year
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearExclude, setSelectedYearExclude] = useState(null);
  const [questionAccess, setQuestionAccess] = useState({});
  const [questionItem, setQuestionItem] = useState({});
  // _________ YEAR USED-> STATE INITIALIZATION _________

  const userId = JSON.parse(localStorage.getItem('userInfo')).id;
  const handleSort = (e) => {
    if (sort === '') {
      setSort(e);
    } else if (sort === e) {
      setSort(`-${e}`);
    } else {
      setSort(e);
    }
  };
  const getPermission = async () => {
    const Permission = await CallWithAuth('GET', `${GET_USERS}${userId}/user-details/`);
    if (Permission.status && Permission.res.status === 200) {
      const data = Permission.res.data;
      sessionStorage.setItem('permissionForQuestions', JSON.stringify(data));
    } else if (Permission.status && Permission.res.status === 400) {
      // eslint-disable-next-line no-console
      console.log('Access denied, you do not have permission to access source question bank');
    }
  };
  useEffect(() => {
    getPermission();
  }, []);
  useEffect(() => {
    const Permission = JSON.parse(sessionStorage.getItem('permissionForQuestions') || '{}');
    const newQuestionAccess = Permission?.question_bank_management?.filter((item) => item.id === questionBankId)[0];
    if (Permission && newQuestionAccess) {
      sessionStorage.setItem('questionAccess', JSON.stringify(newQuestionAccess));
      setQuestionAccess(newQuestionAccess);
    } else {
      setQuestionAccess({});
      const payload = { draft: false, edit: false, review: false, view: false, id: questionBankId };
      sessionStorage.setItem('questionAccess', JSON.stringify(payload));
    }
    setCurrentPage(1);
    setInputPage(1);
    setOffset(0);
  }, [questionBankId]);

  useEffect(() => {
    if (questionCategory) {
      QuestionListing();
    } else {
      MyQuestionListing();
    }
  }, [questionBankId, questionId, offset, sort, questionCategory, currentPage]);

  // QUESTION maTRIX
  const [questionMatrix, setQuestionMatrix] = useState(null);

  const QuestionMatric = async () => {
    const QuestionMatricData = await CallWithAuth('GET', GET_QUESTION_BANK + questionBankId + '/question-matrics/');
    if (QuestionMatricData.status && QuestionMatricData.res.status === 200) {
      setQuestionMatrix(QuestionMatricData.res.data);
    }
  };

  useEffect(() => {
    QuestionMatric();
  }, [questionBankId]);

  const [statusOfaction, setstatusOfaction] = useState(false);
  const [actionId, setactionId] = useState(null);
  const lastClickedButtonRef = useRef(null);

  // ACTION OF EDIT
  const ActionPerform = (i, event) => {
    if (event) {
      event.stopPropagation();
      lastClickedButtonRef.current = event.currentTarget;
    }
    if (statusOfaction && actionId === i) {
      setactionId(null);
      setstatusOfaction(false);
    } else {
      setactionId(i);
      setstatusOfaction(true);
    }
  };

  const handleEdit = async (items) => {
    await editQuestionHandler(items, setIsPreviewopen, openModalMedium, setQuestionItem, navigate);
  };

  // ____________ outside click close option ____________
  const rowRef = useRef();
  const statusCssHandler = (status) => {
    if (status === 'Approved') {
      return {
        backgroundColor: '#E9FCF1',
        color: '#26AB5B',
        borderColor: '#26AB5B',
      };
    }
    if (status === 'Rejected') {
      return {
        backgroundColor: '#FFF2F1',
        color: '#FF5757',
        borderColor: '#FF5757',
      };
    } else {
      return {
        backgroundColor: '#FFF9E8',
        color: '#FFCF00',
        borderColor: '#FFCF00',
      };
    }
  };

  const handleRowClick = (event) => {
    // Don't close if clicking the action button or its children
    if (event.target.id === 'edit' || lastClickedButtonRef.current?.contains(event.target)) {
      return;
    }
    setactionId(null);
    setstatusOfaction(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleRowClick);
    return () => {
      document.removeEventListener('click', handleRowClick);
    };
  }, [handleRowClick]);

  // Function to handle opening the preview modal
  const handleOpenModal = (items) => {
    openPreviewModal(items, setSelectedQuestion, setIsPreviewopen);
  };

  const handleCloseModal = () => {
    closePreviewModal(setIsPreviewopen, setIsDuplicatePreview, setIsModalOpen);
  };

  const onConfirmDuplicate = (questionBankId, questionId) => {
    handleConfirmDuplicate(
      questionBankId,
      questionId,
      setIsModalOpen,
      setIsPreviewopen,
      setIsDuplicatePreview,
      setactionId,
      toast,
      questionCategory,
      QuestionListing,
      MyQuestionListing
    );
  };
  const handleDuplicateClick = (items) => {
    handleDuplicate(items, (dataToPass) => openModalHelper(dataToPass, setModalData, setIsModalOpen));
  };

  const closeModal = () => {
    closeModalHelper(setIsModalOpen, setModalData, setIsDuplicatePreview);
  };

  useEffect(() => {
    setCurrentPage(1);
    setOffset(0);
    setLast(5);
    setInputPage(1);
  }, [questionCategory]);
  return (
    <>
      <AuthLayout>
        {/* rightpannel top left menu according to different screen  */}
        <RightPanelTopMenu setQuestionBankId={setQuestionBankId} title={'Question Bank'} />

        {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
        <div className={style.question_bank_table_top}>
          <div className={style.question_bank}>
            {/* TOP section of the page where reports result and add new question button  */}
            <div className={style.question_bank_report}>
              <div className={style.results}>
                <div className={style.approved}>
                  <div className={style.box}>
                    <div className={style.icons}>
                      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.8985 29.3346C24.2053 29.3346 30.1837 23.3346 30.1837 16.0013C30.1837 8.66797 24.2053 2.66797 16.8985 2.66797C9.59162 2.66797 3.61328 8.66797 3.61328 16.0013C3.61328 23.3346 9.59162 29.3346 16.8985 29.3346Z"
                          fill="url(#paint0_linear_33_20396)"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M23.2498 11.5162C23.6386 11.907 23.6381 12.5402 23.2486 12.9304L15.7159 20.4771C15.3267 20.867 14.6963 20.8667 14.3074 20.4764L10.5477 16.7031C10.1586 16.3126 10.1586 15.6794 10.5477 15.2889C10.9368 14.8984 11.5677 14.8984 11.9568 15.2889L15.0126 18.3557L21.8407 11.5149C22.2302 11.1247 22.8611 11.1253 23.2498 11.5162Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_33_20396"
                            x1="30.1837"
                            y1="2.66797"
                            x2="11.3708"
                            y2="24.5912"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#9BF763" />
                            <stop offset="1" stopColor="#25AB5B" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.score}>
                      <p>Approved Question</p>
                      <span>{questionMatrix?.approved}</span>
                    </div>
                  </div>
                </div>

                <div className={style.Rejected}>
                  <div className={style.box}>
                    <div className={style.icons}>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.9993 29.3346C23.3327 29.3346 29.3327 23.3346 29.3327 16.0013C29.3327 8.66797 23.3327 2.66797 15.9993 2.66797C8.66602 2.66797 2.66602 8.66797 2.66602 16.0013C2.66602 23.3346 8.66602 29.3346 15.9993 29.3346Z"
                          fill="url(#paint0_linear_33_20413)"
                        />
                        <path
                          d="M12.2285 12.2266L19.771 19.769"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.229 19.769L19.7715 12.2266"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_33_20413"
                            x1="29.3327"
                            y1="2.66797"
                            x2="10.5302"
                            y2="24.6584"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FFA78F" />
                            <stop offset="1" stopColor="#F23E2C" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.score}>
                      <p>Rejected Questions</p>
                      <span>{questionMatrix?.rejected}</span>
                    </div>
                  </div>
                </div>

                <div className={style.Review}>
                  <div className={style.box}>
                    <div className={style.icons}>
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle
                          cx="13.0007"
                          cy="12.9974"
                          r="10.6667"
                          transform="rotate(-90 13.0007 12.9974)"
                          stroke="url(#paint0_linear_33_20429)"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                        <path
                          d="M13.0006 2.33073C7.10961 2.33073 2.33398 7.10636 2.33398 12.9974C2.33398 18.8884 7.10961 23.6641 13.0007 23.6641"
                          stroke="url(#paint1_linear_33_20429)"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_33_20429"
                            x1="2.33398"
                            y1="23.6641"
                            x2="26.334"
                            y2="20.3307"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FF8800" />
                            <stop offset="0.380208" stopColor="#FFCF00" />
                            <stop offset="1" stopColor="#FFE8CE" stopOpacity="0" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_33_20429"
                            x1="13.2019"
                            y1="24.2678"
                            x2="13.2019"
                            y2="2.33073"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FF8800" />
                            <stop offset="1" stopColor="#FFCF00" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.score}>
                      <p>Review Questions</p>
                      <span>{questionMatrix?.in_review}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.add_question}>
                <button className={` ${style.add_new_question} ${style.pointer}`} onClick={() => CreateQuestion()}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      fill="white"
                    />
                    <path
                      d="M7.5 12H16.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 16.5V7.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span> Add New Questions</span>
                </button>
              </div>
            </div>

            {/* TABLE SECTION OF THIS PAGE  */}
            <div className={style.table_content_report}>
              <div className={style.top_content_table}>
                <div className={style.table_title}>
                  <span
                    className={questionCategory ? ` ${style.pointer}` : ` ${style.underline_active} ${style.pointer}`}
                    onClick={() => ChangeQuestionCategory(false)}
                  >
                    My Questions
                  </span>
                  <span
                    className={questionCategory ? ` ${style.underline_active} ${style.pointer}` : ` ${style.pointer}`}
                    onClick={() => ChangeQuestionCategory(true)}
                  >
                    All Questions
                  </span>
                </div>

                <div className={style.search_listing}>
                  <div className={style.tag_icon}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                        stroke="#212529"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.2598 19.2592L16.1406 16.8594"
                        stroke="#212529"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Tags</span>
                  </div>

                  <div className={style.input_search}>
                    <svg
                      style={{ position: 'relative', top: '10px', left: '14px' }}
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="0.5" width="20" height="20" rx="4" fill="#272727" />
                      <path
                        d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                        fill="white"
                      />
                    </svg>
                    <input
                      type="search"
                      style={{ marginLeft: '15px' }}
                      id="searchInput"
                      placeholder="Search for subject, area topic, sub topic, question ID tags to refine list"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setOffset(0);
                        setquestionId(e.target.value);
                        setInputPage(1);
                      }}
                    />
                  </div>
                </div>

                <div className={style.filter}>
                  <div className={style.pointer} onClick={() => openModalFilter()}>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill={modalFilter ? '#00ABFB' : 'none'}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                        stroke={modalFilter ? '#00ABFB' : '#212529'}
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span style={{ color: modalFilter ? '#00ABFB' : '#212529' }}>Filter</span>
                  </div>
                </div>
              </div>

              {/* TABLE LISTING OF ALL AND MY QUESTION  */}
              <div className={style.sub_head_content_table}>
                <table>
                  <thead>
                    <tr>
                      <th className={` ${style.very_first} ${style.pointer}`}>Question ID and Tags</th>
                      <th className={` ${style.first} ${style.pointer}`}>
                        <span>General Tags</span>
                        {/* <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z" fill="#00ABFB" />
                          <path d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z" fill="#00ABFB" />
                          <path d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z" fill="#00ABFB" />
                          <path d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z" fill="#00ABFB" />
                        </svg> */}
                      </th>
                      <th className={` ${style.second} ${style.pointer}`}>
                        {' '}
                        <span>Author</span>
                        <svg
                          onClick={() => handleSort('created_by')}
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '2px',
                            transform: getRotation('created_by'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.third} ${style.pointer}`}>
                        <span>Created On</span>
                        <svg
                          onClick={() => handleSort('created_at')}
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '2px',
                            transform: getRotation('created_at'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.fourth} ${style.pointer}`}>
                        <span>LOD</span>
                        <svg
                          onClick={() => handleSort('difficulty')}
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '2px',
                            transform: getRotation('difficulty'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.fifth} ${style.pointer}`}>
                        <span>Status</span>
                        <svg
                          onClick={() => handleSort('status')}
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginLeft: '2px',
                            transform: getRotation('status'),
                            transition: 'transform 0.3s ease',
                          }}
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  {/* ALL AND MY QUESTION according to ternary operator */}
                  {questionCategory ? (
                    <tbody>
                      {questionResults?.map((items, i) => (
                        <tr key={i} className={style.table_body_content} ref={rowRef}>
                          <td>
                            <div className={`${style.question_id_tag} questionIdTags`}>
                              <div
                                className={`${style.id} ${questionCategory ? style.idCursor : ''}`}
                                // onClick={() => openPreviewModal(items)}
                                onClick={() => handleOpenModal(items)}
                              >
                                {items.question_id}
                                {/* {items.question_id?.map((items, i) => items)} */}
                              </div>
                              <div className={style.tag}>
                                <p>{items.question_short_identifier}</p>
                                <span>{items.primary_tag.slug}</span>
                                <br />
                                {/* Render siblings array with underlined text */}
                                <div>
                                  <div className={style.sibling_div}>
                                    {items.siblings.length > 0 && (
                                      <>
                                        <span>Siblings:</span>
                                        {items.siblings.slice(0, maxSiblingsToShow).map((sibling, idx) => (
                                          <React.Fragment key={sibling}>
                                            <span
                                              className={style.underlined}
                                              onClick={() => {
                                                if (questionAccess?.draft) {
                                                  toast.add(
                                                    'You do not have permission to view this question. Please contact the content management admin to request access rights.',
                                                    'error'
                                                  );
                                                } else {
                                                  openPreviewModal(sibling);
                                                }
                                              }}
                                            >
                                              {sibling}
                                            </span>
                                            {idx < items.siblings.slice(0, maxSiblingsToShow + 1).length - 1 && ' | '}
                                          </React.Fragment>
                                        ))}

                                        {items.siblings.length > maxSiblingsToShow && (
                                          <>
                                            {showMoreState[items.question_id] &&
                                              items.siblings.slice(maxSiblingsToShow).map((sibling, idx) => (
                                                <React.Fragment key={sibling}>
                                                  <span
                                                    className={style.underlined}
                                                    onClick={() => {
                                                      if (questionAccess?.draft) {
                                                        toast.add(
                                                          'You do not have permission to view this question. Please contact the content management admin to request access rights.',
                                                          'error'
                                                        );
                                                      } else {
                                                        openPreviewModal(sibling);
                                                      }
                                                    }}
                                                  >
                                                    {sibling}
                                                  </span>
                                                  {idx < items.siblings.slice(maxSiblingsToShow).length - 1 && ' | '}
                                                </React.Fragment>
                                              ))}
                                            <span
                                              onClick={() => toggleShowMore(items.question_id)} // Toggle specific row's state
                                              className={style.moreLink}
                                            >
                                              {showMoreState[items.question_id]
                                                ? 'Show less'
                                                : `${items.siblings.length - maxSiblingsToShow} More`}
                                            </span>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              {items.free_tags.map((item, i) => (
                                <button key={i} className={`${style.general_button} ${style.generalButtonWrap}`}>
                                  {item.name}
                                </button>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by {items.created_by}</div>
                          </td>
                          <td>
                            <div className={style.created_date}>{moment(items.created_at).format('Do MMM YYYY')}</div>
                          </td>
                          <td>
                            <div className={style.lod}>{items.difficulty}</div>
                          </td>
                          <td>
                            <div className={style.status}>
                              {' '}
                              <span style={statusCssHandler(items.status)}>{items.status}</span>
                            </div>
                          </td>
                          <td id="edit">
                            {questionAccess?.edit ||
                            questionAccess?.review ||
                            (questionAccess?.draft && items.status !== 'Approved') ? (
                              <div className={` ${style.action} ${style.pointer}`} id="edit">
                                <svg
                                  onClick={() => ActionPerform(i)}
                                  id="edit"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                    fill="#272727"
                                  />
                                </svg>
                                <div
                                  className={style.action_button}
                                  id="edit"
                                  style={{ display: actionId === i ? 'block' : 'none' }}
                                >
                                  {questionAccess?.edit ||
                                  questionAccess?.review ||
                                  (questionAccess?.draft && items.status !== 'Approved') ? (
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      id="edit"
                                      className={style.edit}
                                      // onClick={() => Edit(items)}
                                      onClick={() => handleEdit(items)}
                                    >
                                      {' '}
                                      <svg
                                        id="edit"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M2 14.668H14"
                                          stroke="#212529"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                          stroke="#212529"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                          stroke="#212529"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>{' '}
                                      <span id="edit">Edit</span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {questionAccess?.edit || questionAccess?.review ? (
                                    <div className={style.duplicate} onClick={() => handleDuplicateClick(items)}>
                                      <svg
                                        width="13"
                                        height="16"
                                        viewBox="0 0 13 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9.00065 0.667969H1.66732C0.933984 0.667969 0.333984 1.26797 0.333984 2.0013V10.668C0.333984 11.0346 0.633984 11.3346 1.00065 11.3346C1.36732 11.3346 1.66732 11.0346 1.66732 10.668V2.66797C1.66732 2.3013 1.96732 2.0013 2.33398 2.0013H9.00065C9.36732 2.0013 9.66732 1.7013 9.66732 1.33464C9.66732 0.967969 9.36732 0.667969 9.00065 0.667969ZM11.6673 3.33464H4.33398C3.60065 3.33464 3.00065 3.93464 3.00065 4.66797V14.0013C3.00065 14.7346 3.60065 15.3346 4.33398 15.3346H11.6673C12.4007 15.3346 13.0007 14.7346 13.0007 14.0013V4.66797C13.0007 3.93464 12.4007 3.33464 11.6673 3.33464ZM11.0007 14.0013H5.00065C4.63398 14.0013 4.33398 13.7013 4.33398 13.3346V5.33464C4.33398 4.96797 4.63398 4.66797 5.00065 4.66797H11.0007C11.3673 4.66797 11.6673 4.96797 11.6673 5.33464V13.3346C11.6673 13.7013 11.3673 14.0013 11.0007 14.0013Z"
                                          fill="#212529"
                                        />
                                      </svg>
                                      <span> Duplicate</span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      {myquestionResults?.map((items, i) => (
                        <tr key={i} className={style.table_body_content} ref={rowRef}>
                          <td>
                            <div className={`${style.question_id_tag} questionIdTags`}>
                              <div className={style.id}>
                                {/* {items.question_id?.map((items, i) => items)} */}
                                {items.question_draft_id}
                              </div>
                              <div className={style.tag}>
                                <p>{items?.question_short_identifier}</p>
                                <span>{items?.primary_tag?.slug}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={style.general_tag}>
                              {items.free_tags.map((item, i) => (
                                <button key={i} className={`${style.general_button} ${style.generalButtonWrap}`}>
                                  {item.name}
                                </button>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className={style.author_tag}>Author by {items.created_by}</div>
                          </td>
                          <td>
                            <div className={style.created_date}>{moment(items.created_at).format('Do MMM YYYY')}</div>
                          </td>
                          <td>
                            <div className={style.lod}>{items.difficulty}</div>
                          </td>
                          <td>
                            <div className={style.status}>
                              {' '}
                              <span style={statusCssHandler(items.status)}>{items.status}</span>
                            </div>
                          </td>
                          <td id="edit">
                            {questionAccess?.edit || questionAccess?.review || questionAccess?.draft ? (
                              <div id="edit" style={{ cursor: 'pointer' }} className={style.action}>
                                <svg
                                  id="edit"
                                  onClick={() => ActionPerform(i)}
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    id="edit"
                                    d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z"
                                    fill="#272727"
                                  />
                                </svg>
                                <div
                                  id="edit"
                                  className={style.action_button}
                                  style={{ display: actionId === i ? 'block' : 'none' }}
                                >
                                  {
                                    <div
                                      id="edit"
                                      style={{ cursor: 'pointer' }}
                                      className={style.edit}
                                      // onClick={() => Edit(items)}
                                      onClick={() => handleEdit(items)}
                                    >
                                      {' '}
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M2 14.668H14"
                                          stroke="#212529"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                                          stroke="#212529"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.92578 3.36719C8.21245 5.20719 9.70578 6.61385 11.5591 6.80052"
                                          stroke="#212529"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>{' '}
                                      <span>Edit</span>
                                    </div>
                                  }
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            {/*______________________________ PAGINATION ______________________________  */}
            <div className={style.listpaginationWrap}>
              <Pagination
                totalCount={totalCount}
                limit={limit}
                currentPage={currentPage}
                inputPage={inputPage}
                offset={offset}
                last={last}
                nextUrl={nextUrl}
                prevUrl={prevUrl}
                setOffset={setOffset}
                setCurrentPage={setCurrentPage}
                setInputPage={setInputPage}
                setLast={setLast}
              />
            </div>
            {/*______________________________ PAGINATION ______________________________  */}
          </div>
        </div>
      </AuthLayout>
      {/* FILTER MODAL CALL for filteration on searching  */}
      <FilterModal
        usedFor={0}
        questionCategory={questionCategory}
        QuestionListing={QuestionListing}
        MyQuestionListing={MyQuestionListing}
        modalFilter={modalFilter}
        closeModalFilter={closeModalFilter}
        multiselectedSubjectTag={multiselectedSubjectTag}
        setmultiSelectedSubjectTag={setmultiSelectedSubjectTag}
        multiselectedFreeTag={multiselectedFreeTag}
        setmultiSelectedFreeTag={setmultiSelectedFreeTag}
        multiselectedCategoryTag={multiselectedCategoryTag}
        setmultiSelectedCategoryTag={setmultiSelectedCategoryTag}
        multiselectedAuthorTag={multiselectedAuthorTag}
        setmultiSelectedAuthorTag={setmultiSelectedAuthorTag}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedYearExclude={selectedYearExclude}
        setSelectedYearExclude={setSelectedYearExclude}
        currentYear={currentYear}
      />
      <PreviewModal
        onEdit={handleEdit}
        questionAccess={questionAccess}
        selectedQuestion={selectedQuestion}
        isOpen={isPreviewopen}
        onClose={handleCloseModal}
        setIsDuplicateModalOpen={setIsModalOpen}
        setModalData={setModalData}
        modalData={modalData}
        setIsDuplicatePreview={setIsDuplicatePreview}
        iframeSrc="/question-content-url"
      />
      <QuestionListModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={onConfirmDuplicate}
        data={modalData} // Pass modalData to the modal
        isDuplicatePreview={isDuplicatePreview}
      />
      <LockModal
        modalData={modalModalData}
        forwardButton={() => navigate('/edit-question', { state: questionItem })}
        modalMedium={modalMedium}
        closeModalMedium={closeModalMedium}
      />
    </>
  );
};

export default QuestionBank;
