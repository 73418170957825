/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from '../../../assets/styles/modalStyle.module.css';
import style1 from '../../../assets/styles/reviewStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Change_Review_Status, ToggleOpen_InRevie } from '../../../redux/action';
import content from '../../../assets/images/content-question.png';
import calculator from '../../../assets/images/calculator.png';
import moment from 'moment';
import NativeButton from '../Button';
const ReviewDetailModal = ({ modalreviewDetail, detailData, closeModalreviewDetail }) => {
  const { isOpenInReview } = useSelector((state) => state.GetAllData);
  const { reviewdata } = useSelector((state) => state.GetAllData);
  const dispatch = useDispatch();
  const [openID, setOpenID] = useState(null);
  const toggleDropdown = (e) => {
    setOpenID(e);
    dispatch(ToggleOpen_InRevie(!isOpenInReview));
  };
  const status = reviewdata?.filter((items) => items?.question_id === detailData?.question_id);
  return (
    <>
      {modalreviewDetail ? (
        <div className={style.filterModal}>
          <div className={style.overlay}></div>
          <div className={style.center_div_review}>
            <div className={style.review_page}>
              <div className={style.user_question}>
                <header className={style.question_header}>
                  <span className={style.question_id}>Question ID :</span>{' '}
                  <b className="bold-text">{detailData.question_id}</b>
                  <div className={style.bw_line}></div>
                  <span className={style.question_id}>Authored By :</span> <b>{detailData.created_by}</b>
                  <div className={style.bw_line}></div>
                  <span className={style.question_id}>Created On :</span>{' '}
                  <b>{moment(detailData.created_at).format('Do MMM YYYY')}</b>
                  <div
                    className={` ${style1.dropdown} ${
                      detailData.question_id === openID && isOpenInReview ? `${style1.open}` : ''
                    }`}
                  >
                    <NativeButton onClick={() => toggleDropdown(detailData.question_id)}>
                      <div className={style1.dropdown_input}>
                        <span>{status[0]?.status}</span>
                        <span>
                          {detailData.question_id === openID && isOpenInReview ? (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.7193 10.0352L7.06596 5.68849C7.5793 5.17515 8.4193 5.17515 8.93263 5.68849L13.2793 10.0352"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.2797 5.96484L8.93306 10.3115C8.41973 10.8248 7.57973 10.8248 7.06639 10.3115L2.71973 5.96484"
                                stroke="#212529"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </NativeButton>

                    <ul className={style1.dropdown_options}>
                      <li className={style1.dropdown_option}>
                        <input
                          type="radio"
                          name="options"
                          value={1}
                          onChange={(e) => dispatch(Change_Review_Status(e, detailData.question_id))}
                        />{' '}
                        Approve
                      </li>
                      <li className={style1.dropdown_option}>
                        <input
                          type="radio"
                          name="options"
                          value={0}
                          onChange={(e) => dispatch(Change_Review_Status(e, detailData.question_id))}
                        />{' '}
                        Reject
                      </li>
                      <li className={style1.dropdown_option}>
                        <input
                          type="radio"
                          name="options"
                          value={2}
                          onChange={(e) => dispatch(Change_Review_Status(e, detailData.question_id))}
                        />{' '}
                        In Review
                      </li>
                    </ul>
                  </div>
                  <button className={style.closeModalreviewDetail} onClick={() => closeModalreviewDetail()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.34375 6.34375L17.6575 17.6575"
                        stroke="#212529"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.34254 17.6575L17.6562 6.34375"
                        stroke="#212529"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </header>

                <div className={style.user_info}>
                  <div className={style.user_topics}>{detailData.primary_tag.slug}</div>

                  <div className={style.rest}>
                    <span className={style.user_activity}>Last activity 1 day ago</span>
                    <button className={style.edit_button}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M2.5 18.332H17.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.0504 3.00002L4.20878 10.2417C3.95045 10.5167 3.70045 11.0584 3.65045 11.4334L3.34211 14.1334C3.23378 15.1084 3.93378 15.775 4.90045 15.6084L7.58378 15.15C7.95878 15.0834 8.48378 14.8084 8.74211 14.525L15.5838 7.28335C16.7671 6.03335 17.3004 4.60835 15.4588 2.86668C13.6254 1.14168 12.2338 1.75002 11.0504 3.00002Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.9082 4.20703C10.2665 6.50703 12.1332 8.26536 14.4499 8.4987"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Edit Question
                    </button>
                  </div>
                </div>

                <div className={style.main_content}>
                  <div className={style.container}>
                    <div className={style.section_one}>
                      <img src={content} alt="Question" />
                    </div>
                    <div className={style.section_two}>
                      <img src={calculator} alt="question-calculator" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

ReviewDetailModal.propTypes = {
  closeModalreviewDetail: PropTypes.func,
  detailData: PropTypes.shape({
    created_at: PropTypes.any,
    created_by: PropTypes.any,
    primary_tag: PropTypes.shape({
      slug: PropTypes.any,
    }),
    question_id: PropTypes.any,
  }),
  modalreviewDetail: PropTypes.any,
};

export default ReviewDetailModal;
