import React, { useEffect } from 'react';
import SelectType from './SelectType';
import { useSelector } from 'react-redux';
import CreatingQuestionSingle from './CreatingQuestionSingle';
import CreatingQuestionGrouped from './CreationQuestionGrouped';
import style from '../../../../../assets/styles/createQuestion.module.css';

interface QuestionCreationProps {
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData: any;
}

const QuestionCreation: React.FC<QuestionCreationProps> = ({ id, getData }) => {
  // _______ selector for question type screen _______
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selector = useSelector((state: any) => state.QuestionScreenReducer);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const basicInfoForType = useSelector((state: any) => state.BasicInformationReducer);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleQuestionType = () => {};
  useEffect(() => {
    window.scrollTo(0, 0);
    const element = document.getElementById('scroll');
    element?.scrollTo(0, 0);
  }, [selector.screen]);
  return (
    <div id="scroll" className={`${style.select_question} ${style.CreateQuestionWrap}`}>
      {/*_______ type selection of question bank  _______*/}
      {selector.screen === 2.1 ? (
        <SelectType
          getData={getData}
          id={id}
          data={selector}
          handleQuestionType={handleQuestionType}
          questiontype={getData?.question_type_id}
        />
      ) : (
        ''
      )}
      {/* _______ different question bank according to user selection -> Single Type _______ */}
      {selector?.screen === 2.2 && basicInfoForType?.format === 0 ? (
        <CreatingQuestionSingle getData={getData} id={id} />
      ) : (
        ''
      )}
      {/* _______ different question bank according to user selection -> Grouped Type _______ */}
      {selector?.screen === 2.2 && basicInfoForType?.format === 1 ? (
        <CreatingQuestionGrouped getData={getData} id={id} />
      ) : (
        ''
      )}
    </div>
  );
};

export default QuestionCreation;
