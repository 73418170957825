/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { GET_ORGANISATION, GET_ROLES, GET_USERS } from '../../../../action/apiPath';
import { CallWithAuth } from '../../../../action/apiActions';
import MediumModal from '../../../atoms/Modal/MediumModal';

import style from '../../../../assets/styles/organisationManagement.module.css';
import { ChangeOrganisation } from '../../../../redux/action';
import { useDispatch } from 'react-redux';
import NativeButton from '../../../atoms/Button';

const OrganisationDetails = ({ setScreen, id }) => {
  const [userData, setUserData] = useState(null);
  const UserDetailFunction = async () => {
    const data = await CallWithAuth('GET', GET_USERS + id + '/user-details/');

    if (data.status && data.res.status === 200) {
      setUserData(data.res.data);
    }
  };

  useEffect(() => {
    UserDetailFunction();
  }, []);

  // _______________ roles get ______________
  const [roles, setRoles] = useState([]);

  const GetRoles = async () => {
    console.log(roles);
    const GetData = await CallWithAuth('GET', GET_ROLES);
    if (GetData.status && Array.isArray(GetData.res.data)) {
      setRoles(GetData.res.data);
    }
  };

  useEffect(() => {
    GetRoles();
  }, []);

  // oganisation
  const [organisation, setOrganisation] = useState([]);

  const getOrganisation = async () => {
    console.log(organisation);
    const GetData = await CallWithAuth('GET', GET_ORGANISATION);
    if (GetData.status && Array.isArray(GetData.res.data)) {
      setOrganisation(GetData.res.data);
    }
  };

  useEffect(() => {
    getOrganisation();
  }, []);

  // _____________ MODAL OPEN AND CLOSE _____________
  const [modalMedium, setModalMedium] = useState(false);
  const [modalData] = useState({
    title: 'Are you sure you want to Inactivate Lollypop Studio ?',
    cancelButton: 'No, View User List',
    saveButton: 'Yes, Inactive',
  });

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  const dispatch = useDispatch();

  return (
    <>
      <div>
        <div className={style.top_section}>
          <NativeButton onClick={() => dispatch(ChangeOrganisation(0))}>
            <span className={style.left}>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.5488 19.422L9.02883 12.902C8.25883 12.132 8.25883 10.872 9.02883 10.102L15.5488 3.58203"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </span>
          </NativeButton>
          <NativeButton onClick={() => dispatch(ChangeOrganisation(3))}>
            <span className={style.right}>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 22H21" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M13.2603 3.59924L5.05034 12.2892C4.74034 12.6192 4.44034 13.2692 4.38034 13.7192L4.01034 16.9592C3.88034 18.1292 4.72034 18.9292 5.88034 18.7292L9.10034 18.1792C9.55034 18.0992 10.1803 17.7692 10.4903 17.4292L18.7003 8.73924C20.1203 7.23924 20.7603 5.52924 18.5503 3.43924C16.3503 1.36924 14.6803 2.09924 13.2603 3.59924Z"
                  stroke="#00ABFB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.8896 5.04883C12.3196 7.80883 14.5596 9.91883 17.3396 10.1988"
                  stroke="#00ABFB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              &nbsp; <u>Edit</u>
            </span>
          </NativeButton>
        </div>
        <div className={` ${style.table_content_report}  ${style.organisation_detail_head}`}>
          <div className={style.profile_detail_div}>
            Lollypop Studio
            <div className={style.status_Organisation}>
              <input
                type="radio"
                className={style.pointer}
                onClick={() => openModalMedium()}
                id="question"
                name="choose_question"
                value="Question"
              />
              <label htmlFor="html">Active</label>
              &nbsp; &nbsp; &nbsp;
              <input type="radio" className={style.pointer} id="question" name="choose_question" value="Question" />
              <label htmlFor="html">Inactive</label>
            </div>
          </div>

          <div className={` ${style.select_question} ${style.profile_status}`}>
            <div className={` ${style.question_bank_table} ${style.shadow} ${style.organisation_table}`}>
              <div className={` ${style.tbody_question_bank} ${style.profile_detail_info}`}>
                <div className={style.first}>
                  <span className={style.title_detail}>
                    {' '}
                    Active Users &nbsp; <b>80</b>
                  </span>
                  <br />
                  <span className={style.description_detail}>{userData?.name}</span>
                </div>
                <div className={style.third}>
                  <span className={style.title_detail}>
                    {' '}
                    Subscription &nbsp; <b>Basic Plan</b>
                  </span>
                  <br />
                  <span className={style.description_detail}>{userData?.email_id}</span>
                </div>
                <div className={style.second}>
                  <span className={style.title_detail}>
                    {' '}
                    Plan Expiry &nbsp; <b>01 Jan 2023</b>
                  </span>
                  <br />
                  <span className={style.description_detail}>{userData?.email_id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TABLE SECTION OF THIS PAGE  */}
        <div className={style.table_content_report}>
          <div className={style.top_content_table}>
            <div className={` ${style.search_listing} ${style.user_management_search_listing}`}>
              <div className={style.tag_icon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.2598 19.2592L16.1406 16.8594"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className={style.input_search}>
                <input
                  type="search"
                  id="searchInput"
                  placeholder="Search for subject, area topic, sub topic, question ID tags to refine list"
                />
              </div>
            </div>

            {/* <div className='filter user_management_filter'> */}
            {/* <div className='pointer'>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill={modalFilter ? "#00ABFB" : "none"} xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z" stroke={modalFilter ? "#00ABFB" : "#212529"} strokeWidth="1.5"strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span style={{ color: modalFilter ? "#00ABFB" : "#212529" }}>Filter</span>
                            </div> */}
            {/* </div> */}

            {/* <div className='add-question'>
                            <button className='add_new_question pointer'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" fill="white" />
                                    <path d="M7.5 12H16.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 16.5V7.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span>Add User</span>
                            </button>
                        </div> */}

            <div className={style.add_question}>
              <div className={style.filter}>
                <div className={style.pointer}>
                  <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Filter By</span>
                </div>
              </div>
              <div>
                <input type="checkbox" />
                <span>Question Author</span> &nbsp;
              </div>
              <div>
                <input type="checkbox" />
                <span>Test Author</span> &nbsp;
              </div>
              <div>
                <input type="checkbox" />
                <span>Admin</span> &nbsp;
              </div>
            </div>
          </div>

          {/* TABLE LISTING OF ALL AND MY QUESTION  */}
          <div className={style.sub_head_content_table}>
            <table>
              <thead>
                <tr>
                  <th className={style.pointer}>
                    <span>User Name</span>
                    {/* <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z" fill="#00ABFB" />
                            <path d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z" fill="#00ABFB" />
                            <path d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z" fill="#00ABFB" />
                            <path d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z" fill="#00ABFB" />
                        </svg> */}
                  </th>
                  {/* <th className='first pointer'>
                                        <span>Organisation</span>
                                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z" fill="#00ABFB" />
                                            <path d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z" fill="#00ABFB" />
                                            <path d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z" fill="#00ABFB" />
                                            <path d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z" fill="#00ABFB" />
                                        </svg>
                                    </th> */}
                  <th className={` ${style.second} ${style.pointer}`}>
                    {' '}
                    <span>User Role</span>
                    {/* <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z" fill="#00ABFB" />
                            <path d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z" fill="#00ABFB" />
                            <path d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z" fill="#00ABFB" />
                            <path d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z" fill="#00ABFB" />
                        </svg> */}
                  </th>
                  <th className={` ${style.third} ${style.pointer}`}>
                    <span>Question Banks</span>
                    {/* <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z" fill="#00ABFB" />
                            <path d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z" fill="#00ABFB" />
                            <path d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z" fill="#00ABFB" />
                            <path d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z" fill="#00ABFB" />
                        </svg> */}
                  </th>
                  <th className={` ${style.fourth} ${style.pointer}`}>
                    <span>Subjects</span>
                    {/* <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z" fill="#00ABFB" />
                            <path d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z" fill="#00ABFB" />
                            <path d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z" fill="#00ABFB" />
                            <path d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z" fill="#00ABFB" />
                        </svg> */}
                  </th>
                  <th className={` ${style.fifth} ${style.pointer}`}>
                    <span>Status</span>
                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                        fill="#00ABFB"
                      />
                    </svg>
                  </th>
                  <th></th>
                </tr>
              </thead>

              {/* ALL AND MY QUESTION according to ternary operator */}
              <tbody>
                <tr className={style.table_body_content}>
                  <td>
                    <div className={style.user_management_table_body_content}>Rachael Roul</div>
                  </td>
                  {/* <td><div className={style.user_management_table_body_content}>
                                           organisation

                                        </div>
                                        </td> */}
                  <td>
                    <div className={style.user_management_table_body_content}>Test Author</div>
                  </td>
                  <td>
                    <div className={style.user_management_table_body_content}>CAT (EDIT)</div>
                  </td>
                  <td>
                    <div className={style.user_management_table_body_content}>Quanitive Apptitude</div>
                  </td>

                  <td>
                    <div className={style.activate}>
                      {' '}
                      <span>Active</span>
                    </div>
                  </td>
                  <td>
                    <div className={` ${style.action} ${style.pointer}`}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr className={style.table_body_content}>
                  <td>
                    <div className={style.user_management_table_body_content}>Rachael Roul</div>
                  </td>
                  {/* <td><div className={style.user_management_table_body_content}>
                                           organisation

                                        </div>
                                        </td> */}
                  <td>
                    <div className={style.user_management_table_body_content}>Test Author</div>
                  </td>
                  <td>
                    <div className={style.user_management_table_body_content}>CAT (EDIT)</div>
                  </td>
                  <td>
                    <div className={style.user_management_table_body_content}>Quanitive Apptitude</div>
                  </td>

                  <td>
                    <div className={style.activate}>
                      {' '}
                      <span>Active</span>
                    </div>
                  </td>
                  <td>
                    <div className={` ${style.action} ${style.pointer}`}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr className={style.table_body_content}>
                  <td>
                    <div className={style.user_management_table_body_content}>Rachael Roul</div>
                  </td>
                  {/* <td><div className={style.user_management_table_body_content}>
                                           organisation

                                        </div>
                                        </td> */}
                  <td>
                    <div className={style.user_management_table_body_content}>Test Author</div>
                  </td>
                  <td>
                    <div className={style.user_management_table_body_content}>CAT (EDIT)</div>
                  </td>
                  <td>
                    <div className={style.user_management_table_body_content}>Quanitive Apptitude</div>
                  </td>

                  <td>
                    <div className={style.activate}>
                      {' '}
                      <span>Active</span>
                    </div>
                  </td>
                  <td>
                    <div className={` ${style.action} ${style.pointer}`}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <MediumModal
        modalMedium={modalMedium}
        openModalMedium={openModalMedium}
        closeModalMedium={closeModalMedium}
        modalData={modalData}
      />
    </>
  );
};

OrganisationDetails.propTypes = {
  id: PropTypes.any,
  setScreen: PropTypes.any,
};

export default OrganisationDetails;
