import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import style from '../../../../assets/styles/signin.module.css';
import PublicLayout from '../../../../layout/PublicLayout';
import { useToast } from '../../common/Toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { CallWithAuth } from '../../../../action/apiActions';
import { CHANGE_PASSWORD_API, VALIDATE_TOKEN } from '../../../../action/apiPath';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Validation Schema using Yup
const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Password must contain a minimum of 8 characters')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one symbol e.g. @, !')
    .matches(/^(?=.*[A-Za-z])(?=.*\d).+$/, 'Password must be alphanumeric.')
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'The new password & confirm password do not match!')
    .required('Confirm Password is required'),
});
const specialCharRegex = /^(?=.*[!@#$%^&*()_+{}[\]:;"'<>?,./~`-]).+$/;
const alphanumericRegex = /^(?=.*[A-Za-z])(?=.*\d).+$/;

const CreateNewPassword: React.FC = () => {
  const toast = useToast();
  const query = useQuery();
  const token = query.get('token');

  const showToast = (text: string) => {
    if (toast) {
      toast.add(text, 'error');
    }
  };
  const showSuccessToast = (text: string) => {
    if (toast) {
      toast.add(text, 'success');
    }
  };
  const navigate = useNavigate();
  const [apiError, setApiError] = React.useState('');
  const valid_token = async () => {
    try {
      const payload = { token: '', removeHeader: true };
      if (token) {
        payload.token = token.substring(0, token?.length - 1);
      }
      setApiError('');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await CallWithAuth('POST', VALIDATE_TOKEN, payload);
      if (response?.status && response?.res?.data) {
        console.log('Token is valid');
      } else {
        // Handle different error responses
        const errorData = response?.res?.data;
        if (errorData?.error) {
          setApiError(errorData?.error);
          showToast(errorData?.error);
        } else {
          setApiError('Failed to change password. Please try again.');
          showToast('Failed to change password. Please try again.');
        }
        navigate('/sign-in');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  useEffect(() => {
    valid_token();
  }, [token]);
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const payload = {
          password: values.newPassword,
          confirm_password: values.confirmPassword,
          token: '',
          removeToken: true,
        };

        if (token) {
          payload.token = token.substring(0, token?.length - 1);
        }
        setApiError('');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = await CallWithAuth('POST', CHANGE_PASSWORD_API, payload);
        if (response?.status && response?.res?.data.message) {
          showSuccessToast(response?.res?.data.message);

          navigate('/sign-in', { replace: true });
        } else {
          // Handle different error responses
          const errorData = response?.res?.data;

          if (errorData?.error) {
            setApiError(errorData?.error);
            showToast(errorData?.error);
          } else {
            setApiError('Failed to change password. Please try again.');
            showToast('Failed to change password. Please try again.');
          }
        }
      } catch (error) {
        setApiError('An unexpected error occurred. Please try again.');
        showToast('An error occurred while changing password.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <PublicLayout>
      <form className={style.user_form} onSubmit={formik.handleSubmit}>
        <h2 className={style.create_new_pass}>Create New Password</h2>
        <div className={style.error_wrap} style={{ visibility: apiError ? 'visible' : 'hidden' }}>
          <span className={style.warningIcon}></span>
          <div className={style.validation_error}>{apiError}</div>
        </div>
        <div className={style.user_info}>
          <div className={style.new_pass}>
            <label htmlFor="newPassword" style={{ fontWeight: 'bold' }}>
              New Password <span className={style.required_field}> *</span>
            </label>
            <input
              id="newPassword"
              name="newPassword"
              type="password"
              placeholder="Enter new password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.newPassword && formik.errors.newPassword ? style.error_input : ''}
              disabled={formik.isSubmitting}
            />
            <ul className={style.custom_list}>
              <li className={formik.values.newPassword.length >= 8 ? style.tick : style.bullet}>
                Password must contain a minimum of 8 characters
              </li>
              <li className={specialCharRegex.test(formik.values.newPassword) ? style.tick : style.bullet}>
                Password must contain at least one symbol e.g. @, !
              </li>
              <li className={alphanumericRegex.test(formik.values.newPassword) ? style.tick : style.bullet}>
                Password must be alphanumeric.
              </li>
            </ul>

            <label htmlFor="confirmPassword" style={{ fontWeight: 'bold' }}>
              Confirm Password <span className={style.required_field}> *</span>
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Re-enter your password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.confirmPassword && formik.errors.confirmPassword ? style.error_input : ''}
              disabled={formik.isSubmitting}
            />
            <div
              className={style.validationError}
              style={{
                visibility: formik.touched.confirmPassword && formik.errors.confirmPassword ? 'visible' : 'hidden',
              }}
            >
              {formik.errors.confirmPassword}
            </div>
          </div>

          <div className={style.password_options}>
            <span
              className={style.hidden_password_matchbox}
              style={{
                visibility:
                  formik.values.confirmPassword === formik.values.newPassword && formik.values.newPassword
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <svg
                className={style.tick}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  fill="url(#paint0_linear_2393_5568)"
                />
                <path
                  d="M7.75 12.0019L10.58 14.8319L16.25 9.17188"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2393_5568"
                    x1="22"
                    y1="2"
                    x2="7.89816"
                    y2="18.4928"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9BF763" />
                    <stop offset="1" stopColor="#25AB5B" />
                  </linearGradient>
                </defs>
              </svg>
              Passwords Match
            </span>
          </div>
        </div>

        <div className={style.setting_new_pass_signin_button}>
          <button
            disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
            style={{
              cursor: !(formik.isValid && formik.dirty) || formik.isSubmitting ? 'no-drop' : 'pointer',
              background: !(formik.isValid && formik.dirty) || formik.isSubmitting ? 'lightgray' : '#00ABFB',
            }}
            className={`${style.bt_setting_new_pass_signup} ${style.dynamic_button}`}
            type="submit"
          >
            {formik.isSubmitting ? 'Creating Password...' : 'Create Password'}
          </button>
        </div>
      </form>
    </PublicLayout>
  );
};

export default CreateNewPassword;