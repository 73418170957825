/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { CallWithAuth } from '../../../action/apiActions';
import { GET_AUTHOR, GET_CATEGORY, GET_FREE_TAG, GET_SUBJECT_TAG } from '../../../action/apiPath';
import style from '../../../assets/styles/modalStyle.module.css';
import NativeButton from '../Button';

const FilterModal = ({
  usedFor,
  Filter,
  setFilter,
  questionCategory,
  QuestionListing,
  MyQuestionListing,
  closeModalFilter,
  modalFilter,
  currentYear,
  setSelectedYearExclude,
  selectedYearExclude,
  multiselectedAuthorTag,
  selectedYear,
  setSelectedYear,
  setmultiSelectedAuthorTag,
  multiselectedSubjectTag,
  setmultiSelectedSubjectTag,
  multiselectedFreeTag,
  setmultiSelectedFreeTag,
  multiselectedCategoryTag,
  setmultiSelectedCategoryTag,
}) => {
  // _________ PRIMARY TAG STATE INITIALIZATION _________
  const [multisubjectTaginput, setmultiSubjectTaginput] = useState('');
  const [multisubjectTag, setmultiSubjectTag] = useState([]);
  const [multisubjectTagStatus, setmultiSubjectTagStatus] = useState(false);
  // const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([])

  // CALL GET API FOR getting TAGS
  const TagSubjectMultiple = async (e) => {
    setmultiSubjectTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + '?keyword=' + e);
      if (data.status && data.res.status === 200) {
        setmultiSubjectTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiSubjectTagStatus(true);
        } else {
          setmultiSubjectTagStatus(false);
        }
      }
    } else {
      setmultiSubjectTagStatus(false);
    }
  };

  // select tags from options
  const selectSubjectTag = (e) => {
    setmultiSelectedSubjectTag((current) => [...current, e]);
    setmultiSubjectTagStatus(false);
    setmultiSubjectTaginput('');
  };

  // _________ Free TAG STATE INITIALIZATION _________
  const [multiFreeTaginput, setmultiFreeTaginput] = useState('');
  const [multiFreeTag, setmultiFreeTag] = useState([]);
  const [multiFreeTagStatus, setmultiFreeTagStatus] = useState(false);
  // const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([])

  // CALL GET API FOR getting TAGS
  const TagFreeMultiple = async (e) => {
    setmultiFreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_FREE_TAG + '?keyword=' + e);
      if (data.status && data.res.status === 200) {
        setmultiFreeTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiFreeTagStatus(true);
        } else {
          setmultiFreeTagStatus(false);
        }
      }
    } else {
      setmultiFreeTagStatus(false);
    }
  };

  // select tags from options
  const selectFreeTag = (e) => {
    setmultiSelectedFreeTag((current) => [...current, e]);
    setmultiFreeTagStatus(false);
    setmultiFreeTaginput('');
  };

  // _________ Category TAG STATE INITIALIZATION _________
  const [multiCategoryTaginput, setmultiCategoryTaginput] = useState('');
  const [multiCategoryTag, setmultiCategoryTag] = useState([]);
  const [multiCategoryTagStatus, setmultiCategoryTagStatus] = useState(false);
  // const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([])

  // CALL GET API FOR getting TAGS
  const TagCategoryMultiple = async (e) => {
    setmultiCategoryTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_CATEGORY + '?keyword=' + e);
      if (data.status && data.res.status === 200) {
        setmultiCategoryTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiCategoryTagStatus(true);
        } else {
          setmultiCategoryTagStatus(false);
        }
      }
    } else {
      setmultiCategoryTagStatus(false);
    }
  };

  // select tags from options
  const selectCategoryTag = (e) => {
    setmultiSelectedCategoryTag((current) => [...current, e]);
    setmultiCategoryTagStatus(false);
    setmultiCategoryTaginput('');
  };

  // _________ AUTHIR TAG STATE INITIALIZATION _________
  const [multiAuthorTaginput, setmultiAuthorTaginput] = useState('');
  const [multiAuthorTag, setmultiAuthorTag] = useState([]);
  const [multiAuthorTagStatus, setmultiAuthorTagStatus] = useState(false);

  // CALL GET API FOR getting TAGS
  const TagAuthorMultiple = async (e) => {
    setmultiAuthorTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_AUTHOR + '?keyword=' + e);
      if (data.status && data.res.status === 200) {
        setmultiAuthorTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiAuthorTagStatus(true);
        } else {
          setmultiAuthorTagStatus(false);
        }
      }
    } else {
      setmultiAuthorTagStatus(false);
    }
  };

  // select tags from options
  const selectAuthorTag = (e) => {
    setmultiSelectedAuthorTag((current) => [...current, e]);
    setmultiAuthorTagStatus(false);
    setmultiAuthorTaginput('');
  };

  // _________ YEAR USED-> STATE INITIALIZATION _________

  // Get the current year
  // Create an array of years from 2000 to 20 years after the current year
  const yearOptions = [];
  for (let year = 2000; year <= currentYear + 20; year++) {
    yearOptions.push(year);
  }

  // Event handler for changing the selected year
  const handleYearChangeUsed = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };

  // Event handler for changing the selected year
  const handleYearChangeExclude = (e) => {
    setSelectedYearExclude(parseInt(e.target.value, 10));
  };

  const SaveFilter = () => {
    questionCategory ? QuestionListing() : MyQuestionListing();

    closeModalFilter();
  };

  const SaveFilterReview = () => {
    setFilter(!Filter);
    closeModalFilter();
  };

  const modalRef = useRef();

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModalFilter();
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOverlayClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOverlayClick);
    };
  }, [handleOverlayClick]);

  // __________________ outside clickable close modal _____________________

  const rowRef = useRef();
  return (
    <>
      {usedFor === 0 ? (
        <>
          {' '}
          {modalFilter ? (
            <div className={style.filterModal}>
              <div className={style.overlay}></div>
              <div className={style.center_div} ref={modalRef}>
                {/* __________ modal top header __________ */}
                <div className={style.header_top}>
                  {/* __________ title __________ */}
                  <div className={style.title}>
                    <div>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span> &nbsp; Filter</span>
                    </div>
                  </div>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button onClick={() => closeModalFilter()}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* __________ modal bottom FIELD __________ */}
                <div className={style.bottom_fields}>
                  {/* subject tag  */}
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Subject | Area | Topic | Subtopic </p>
                      <div className={style.search_listing}>
                        <div className={style.tag_icons}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#272727" />
                            <path
                              d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        <div className={style.input_search} ref={rowRef}>
                          {multiselectedSubjectTag?.map((items, i) => (
                            <span key={i} className={style.selected_tags}>
                              {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                            </span>
                          ))}

                          <input
                            type="search"
                            id="searchInput"
                            placeholder=""
                            value={multisubjectTaginput}
                            onChange={(e) => TagSubjectMultiple(e.target.value)}
                          />

                          {multisubjectTagStatus ? (
                            <div className={style.list_input_primary}>
                              {multisubjectTag
                                ?.filter(
                                  (item) => !multiselectedSubjectTag.some((filterItem) => item.id === filterItem.id)
                                )
                                .map((items, i) => (
                                  <NativeButton key={i + items.area} onClick={() => selectSubjectTag(items)}>
                                    <div>
                                      <span>
                                        {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                                      </span>
                                    </div>
                                  </NativeButton>
                                ))}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className={style.cancel_button}>
                          <button onClick={() => setmultiSelectedSubjectTag([])}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.75781 7.75781L16.2431 16.2431"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.75691 16.2431L16.2422 7.75781"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* used id  */}
                  <div className={style.drop}>
                    <div className={style.drop_item}>
                      <div className={style.subject}>
                        <p>Last Used In </p>
                        <select
                          name="question"
                          id="question"
                          className={style.header_drop_filter}
                          value={selectedYear}
                          onChange={handleYearChangeUsed}
                        >
                          {yearOptions.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className={style.drop_item}>
                      <div className={style.subject}>
                        <p>Exclude Questions Used Since </p>
                        <select
                          name="question"
                          id="question"
                          className={style.header_drop_filter}
                          value={selectedYearExclude}
                          onChange={handleYearChangeExclude}
                        >
                          {yearOptions.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* category and tag  */}
                  <div className={style.drop}>
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Category </p>
                        <div className={style.search_listing}>
                          <div className={style.tag_icons}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="20" height="20" rx="4" fill="#272727" />
                              <path
                                d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                fill="white"
                              />
                            </svg>
                          </div>

                          <div className={style.input_search}>
                            {multiselectedCategoryTag?.map((items, i) => (
                              <span key={i} className={style.selected_tags}>
                                {items.name}{' '}
                              </span>
                            ))}

                            <input
                              type="search"
                              id="searchInput"
                              placeholder=""
                              value={multiCategoryTaginput}
                              onChange={(e) => TagCategoryMultiple(e.target.value)}
                            />

                            {multiCategoryTagStatus ? (
                              <div className={style.list_input_primary}>
                                {multiCategoryTag
                                  ?.filter(
                                    (item) => !multiselectedCategoryTag.some((filterItem) => item.id === filterItem.id)
                                  )
                                  .map((items, i) => (
                                    <NativeButton key={i} onClick={() => selectCategoryTag(items)}>
                                      <div>
                                        <span>{items.name} </span>
                                      </div>
                                    </NativeButton>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className={style.cancel_button}>
                            <button onClick={() => setmultiSelectedCategoryTag([])}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.75781 7.75781L16.2431 16.2431"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.75691 16.2431L16.2422 7.75781"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Tags </p>
                        <div className={style.search_listing}>
                          <div className={style.tag_icons}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="20" height="20" rx="4" fill="#272727" />
                              <path
                                d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                fill="white"
                              />
                            </svg>
                          </div>

                          <div className={style.input_search}>
                            {multiselectedFreeTag?.map((items, i) => (
                              <>
                                <span key={i} className={style.selected_tags}>
                                  {items.name}{' '}
                                </span>
                              </>
                            ))}

                            <input
                              type="search"
                              id="searchInput"
                              placeholder=""
                              value={multiFreeTaginput}
                              onChange={(e) => TagFreeMultiple(e.target.value)}
                            />

                            {multiFreeTagStatus ? (
                              <div className={style.list_input_primary}>
                                {multiFreeTag
                                  ?.filter(
                                    (item) => !multiselectedFreeTag.some((filterItem) => item.id === filterItem.id)
                                  )
                                  .map((items, i) => (
                                    <>
                                      <div key={i} onClick={() => selectFreeTag(items)}>
                                        <span>{items.name} </span>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className={style.cancel_button}>
                            <button onClick={() => setmultiSelectedFreeTag([])}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.75781 7.75781L16.2431 16.2431"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.75691 16.2431L16.2422 7.75781"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* question author  */}
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Question Author(s) </p>
                      <div className={style.search_listing}>
                        <div className={style.tag_icons}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#272727" />
                            <path
                              d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        <div className={style.input_search}>
                          {multiselectedAuthorTag?.map((items, i) => (
                            <>
                              <span key={i} className={style.selected_tags}>
                                {items.first_name}-{items.last_name}{' '}
                              </span>
                            </>
                          ))}

                          <input
                            type="search"
                            id="searchInput"
                            placeholder=""
                            value={multiAuthorTaginput}
                            onChange={(e) => TagAuthorMultiple(e.target.value)}
                          />

                          {multiAuthorTagStatus ? (
                            <div className={style.list_input_primary}>
                              {multiAuthorTag
                                ?.filter(
                                  (item) => !multiselectedAuthorTag.some((filterItem) => item.id === filterItem.id)
                                )
                                .map((items, i) => (
                                  <>
                                    <div key={i} onClick={() => selectAuthorTag(items)}>
                                      <span>
                                        {items.first_name}-{items.last_name}{' '}
                                      </span>
                                    </div>
                                  </>
                                ))}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className={style.cancel_button}>
                          <button onClick={() => setmultiSelectedAuthorTag([])}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.75781 7.75781L16.2431 16.2431"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.75691 16.2431L16.2422 7.75781"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* __________ apply and save button __________ */}
                  <div>
                    <button className={style.blue_button} onClick={() => SaveFilter()}>
                      Apply Selected Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}{' '}
        </>
      ) : (
        ''
      )}

      {usedFor === 1 ? (
        <>
          {' '}
          {modalFilter ? (
            <div className={style.filterModal}>
              <div className={style.overlay}></div>
              <div className={style.center_div}>
                {/* __________ modal top header __________ */}
                <div className={style.header_top}>
                  {/* __________ title __________ */}
                  <div className={style.title}>
                    <div>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span> &nbsp; Filter</span>
                    </div>
                  </div>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button onClick={() => closeModalFilter()}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* __________ modal bottom FIELD __________ */}
                <div className={style.bottom_fields}>
                  {/* subject tag  */}
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Subject | Area | Topic | Subtopic </p>
                      <div className={style.search_listing}>
                        <div className={style.tag_icons}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#272727" />
                            <path
                              d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        <div className={style.input_search} ref={rowRef}>
                          {multiselectedSubjectTag?.map((items, i) => (
                            <>
                              <span key={i} className={style.selected_tags}>
                                {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                              </span>
                            </>
                          ))}

                          <input
                            type="search"
                            id="searchInput"
                            placeholder=""
                            value={multisubjectTaginput}
                            onChange={(e) => TagSubjectMultiple(e.target.value)}
                          />

                          {multisubjectTagStatus ? (
                            <div className={style.list_input_primary}>
                              {multisubjectTag
                                ?.filter(
                                  (item) => !multiselectedSubjectTag.some((filterItem) => item.id === filterItem.id)
                                )
                                .map((items, i) => (
                                  <>
                                    <div key={i} onClick={() => selectSubjectTag(items)}>
                                      <span>
                                        {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                                      </span>
                                    </div>
                                  </>
                                ))}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className={style.cancel_button}>
                          <button onClick={() => setmultiSelectedSubjectTag([])}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.75781 7.75781L16.2431 16.2431"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.75691 16.2431L16.2422 7.75781"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* free tag  */}
                  <div className={style.drop}>
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Tags </p>
                        <div className={style.search_listing}>
                          <div className={style.tag_icons}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="20" height="20" rx="4" fill="#272727" />
                              <path
                                d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                fill="white"
                              />
                            </svg>
                          </div>

                          <div className={style.input_search}>
                            {multiselectedFreeTag?.map((items, i) => (
                              <>
                                <span key={i} className={style.selected_tags}>
                                  {items.name}{' '}
                                </span>
                              </>
                            ))}

                            <input
                              type="search"
                              id="searchInput"
                              placeholder=""
                              value={multiFreeTaginput}
                              onChange={(e) => TagFreeMultiple(e.target.value)}
                            />

                            {multiFreeTagStatus ? (
                              <div className={style.list_input_primary}>
                                {multiFreeTag
                                  ?.filter(
                                    (item) => !multiselectedFreeTag.some((filterItem) => item.id === filterItem.id)
                                  )
                                  .map((items, i) => (
                                    <>
                                      <div key={i} onClick={() => selectFreeTag(items)}>
                                        <span>{items.name} </span>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className={style.cancel_button}>
                            <button onClick={() => setmultiSelectedFreeTag([])}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.75781 7.75781L16.2431 16.2431"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.75691 16.2431L16.2422 7.75781"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* question author  */}
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Question Author(s) </p>
                      <div className={style.search_listing}>
                        <div className={style.tag_icons}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#272727" />
                            <path
                              d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        <div className={style.input_search}>
                          {multiselectedAuthorTag?.map((items, i) => (
                            <>
                              <span key={i} className={style.selected_tags}>
                                {items.first_name}-{items.last_name}{' '}
                              </span>
                            </>
                          ))}

                          <input
                            type="search"
                            id="searchInput"
                            placeholder=""
                            value={multiAuthorTaginput}
                            onChange={(e) => TagAuthorMultiple(e.target.value)}
                          />

                          {multiAuthorTagStatus ? (
                            <div className={style.list_input_primary}>
                              {multiAuthorTag
                                ?.filter(
                                  (item) => !multiselectedAuthorTag.some((filterItem) => item.id === filterItem.id)
                                )
                                .map((items, i) => (
                                  <>
                                    <div key={i} onClick={() => selectAuthorTag(items)}>
                                      <span>
                                        {items.first_name}-{items.last_name}{' '}
                                      </span>
                                    </div>
                                  </>
                                ))}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className={style.cancel_button}>
                          <button onClick={() => setmultiSelectedAuthorTag([])}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.75781 7.75781L16.2431 16.2431"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.75691 16.2431L16.2422 7.75781"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* __________ apply and save button __________ */}
                  <div>
                    <button className={style.blue_button} onClick={() => SaveFilterReview()}>
                      Apply Selected Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}{' '}
        </>
      ) : (
        ''
      )}

      {usedFor === 2 ? (
        <>
          {' '}
          {modalFilter ? (
            <div className={style.filterModal}>
              <div className={style.overlay}></div>
              <div className={style.center_div} ref={modalRef}>
                {/* __________ modal top header __________ */}
                <div className={style.header_top}>
                  {/* __________ title __________ */}
                  <div className={style.title}>
                    <div>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span> &nbsp; Filter</span>
                    </div>
                  </div>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button onClick={() => closeModalFilter()}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* __________ modal bottom FIELD __________ */}
                <div className={style.bottom_fields}>
                  {/* subject tag  */}
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Subject | Area | Topic | Subtopic </p>
                      <div className={style.search_listing}>
                        <div className={style.tag_icons}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#272727" />
                            <path
                              d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        <div className={style.input_search} ref={rowRef}>
                          {multiselectedSubjectTag?.map((items, i) => (
                            <>
                              <span key={i} className={style.selected_tags}>
                                {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                              </span>
                            </>
                          ))}

                          <input
                            type="search"
                            id="searchInput"
                            placeholder=""
                            value={multisubjectTaginput}
                            onChange={(e) => TagSubjectMultiple(e.target.value)}
                          />

                          {multisubjectTagStatus ? (
                            <div className={style.list_input_primary}>
                              {multisubjectTag
                                ?.filter(
                                  (item) => !multiselectedSubjectTag.some((filterItem) => item.id === filterItem.id)
                                )
                                .map((items, i) => (
                                  <>
                                    <div key={i} onClick={() => selectSubjectTag(items)}>
                                      <span>
                                        {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                                      </span>
                                    </div>
                                  </>
                                ))}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className={style.cancel_button}>
                          <button onClick={() => setmultiSelectedSubjectTag([])}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.75781 7.75781L16.2431 16.2431"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.75691 16.2431L16.2422 7.75781"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* used id  */}
                  <div className={style.drop}>
                    <div className={style.drop_item}>
                      <div className={style.subject}>
                        <p>Last Used In </p>
                        <select
                          name="question"
                          id="question"
                          className={style.header_drop_filter}
                          value={selectedYear}
                          onChange={handleYearChangeUsed}
                        >
                          {yearOptions.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className={style.drop_item}>
                      <div className={style.subject}>
                        <p>Exclude Questions Used Since </p>
                        <select
                          name="question"
                          id="question"
                          className={style.header_drop_filter}
                          value={selectedYearExclude}
                          onChange={handleYearChangeExclude}
                        >
                          {yearOptions.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* category and tag  */}
                  <div className={style.drop}>
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Category </p>
                        <div className={style.search_listing}>
                          <div className={style.tag_icons}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="20" height="20" rx="4" fill="#272727" />
                              <path
                                d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                fill="white"
                              />
                            </svg>
                          </div>

                          <div className={style.input_search}>
                            {multiselectedCategoryTag?.map((items, i) => (
                              <>
                                <span key={i} className={style.selected_tags}>
                                  {items.name}{' '}
                                </span>
                              </>
                            ))}

                            <input
                              type="search"
                              id="searchInput"
                              placeholder=""
                              value={multiCategoryTaginput}
                              onChange={(e) => TagCategoryMultiple(e.target.value)}
                            />

                            {multiCategoryTagStatus ? (
                              <div className={style.list_input_primary}>
                                {multiCategoryTag
                                  ?.filter(
                                    (item) => !multiselectedCategoryTag.some((filterItem) => item.id === filterItem.id)
                                  )
                                  .map((items, i) => (
                                    <>
                                      <div key={i} onClick={() => selectCategoryTag(items)}>
                                        <span>{items.name} </span>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className={style.cancel_button}>
                            <button onClick={() => setmultiSelectedCategoryTag([])}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.75781 7.75781L16.2431 16.2431"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.75691 16.2431L16.2422 7.75781"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Tags </p>
                        <div className={style.search_listing}>
                          <div className={style.tag_icons}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="20" height="20" rx="4" fill="#272727" />
                              <path
                                d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                fill="white"
                              />
                            </svg>
                          </div>

                          <div className={style.input_search}>
                            {multiselectedFreeTag?.map((items, i) => (
                              <>
                                <span key={i} className={style.selected_tags}>
                                  {items.name}{' '}
                                </span>
                              </>
                            ))}

                            <input
                              type="search"
                              id="searchInput"
                              placeholder=""
                              value={multiFreeTaginput}
                              onChange={(e) => TagFreeMultiple(e.target.value)}
                            />

                            {multiFreeTagStatus ? (
                              <div className={style.list_input_primary}>
                                {multiFreeTag
                                  ?.filter(
                                    (item) => !multiselectedFreeTag.some((filterItem) => item.id === filterItem.id)
                                  )
                                  .map((items, i) => (
                                    <>
                                      <div key={i} onClick={() => selectFreeTag(items)}>
                                        <span>{items.name} </span>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className={style.cancel_button}>
                            <button onClick={() => setmultiSelectedFreeTag([])}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.75781 7.75781L16.2431 16.2431"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.75691 16.2431L16.2422 7.75781"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* question author  */}
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Question Author(s) </p>
                      <div className={style.search_listing}>
                        <div className={style.tag_icons}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#272727" />
                            <path
                              d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        <div className={style.input_search}>
                          {multiselectedAuthorTag?.map((items, i) => (
                            <>
                              <span key={i} className={style.selected_tags}>
                                {items.first_name}-{items.last_name}{' '}
                              </span>
                            </>
                          ))}

                          <input
                            type="search"
                            id="searchInput"
                            placeholder=""
                            value={multiAuthorTaginput}
                            onChange={(e) => TagAuthorMultiple(e.target.value)}
                          />

                          {multiAuthorTagStatus ? (
                            <div className={style.list_input_primary}>
                              {multiAuthorTag
                                ?.filter(
                                  (item) => !multiselectedAuthorTag.some((filterItem) => item.id === filterItem.id)
                                )
                                .map((items, i) => (
                                  <>
                                    <div key={i} onClick={() => selectAuthorTag(items)}>
                                      <span>
                                        {items.first_name}-{items.last_name}{' '}
                                      </span>
                                    </div>
                                  </>
                                ))}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className={style.cancel_button}>
                          <button onClick={() => setmultiSelectedAuthorTag([])}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.75781 7.75781L16.2431 16.2431"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.75691 16.2431L16.2422 7.75781"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* __________ apply and save button __________ */}
                  <div>
                    <button className={style.blue_button} onClick={() => SaveFilter()}>
                      Apply Selected Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}{' '}
        </>
      ) : (
        ''
      )}
    </>
  );
};

FilterModal.propTypes = {
  Filter: PropTypes.any,
  MyQuestionListing: PropTypes.func,
  QuestionListing: PropTypes.func,
  closeModalFilter: PropTypes.func,
  currentYear: PropTypes.number,
  modalFilter: PropTypes.any,
  multiselectedAuthorTag: PropTypes.shape({
    map: PropTypes.func,
    some: PropTypes.func,
  }),
  multiselectedCategoryTag: PropTypes.shape({
    map: PropTypes.func,
    some: PropTypes.func,
  }),
  multiselectedFreeTag: PropTypes.shape({
    map: PropTypes.func,
    some: PropTypes.func,
  }),
  multiselectedSubjectTag: PropTypes.shape({
    map: PropTypes.func,
    some: PropTypes.func,
  }),
  questionCategory: PropTypes.any,
  selectedYear: PropTypes.any,
  selectedYearExclude: PropTypes.any,
  setFilter: PropTypes.func,
  setSelectedYear: PropTypes.func,
  setSelectedYearExclude: PropTypes.func,
  setmultiSelectedAuthorTag: PropTypes.func,
  setmultiSelectedCategoryTag: PropTypes.func,
  setmultiSelectedFreeTag: PropTypes.func,
  setmultiSelectedSubjectTag: PropTypes.func,
  usedFor: PropTypes.number,
};

export default FilterModal;
