/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';
import MediumModal from '../../../../../atoms/Modal/MediumModal';

interface Option {
  id: number;
  value: string;
  is_correct: boolean;
}

interface HighlightCorrectSentenceProps {
  body: any[]; // Replace 'any' with specific type for `body` if known
  questionbutton: number;
  data: string;
  // eslint-disable-next-line no-unused-vars
  setbodyformultiquestion: (body: any[]) => void;
  passageText: any;
  // eslint-disable-next-line no-unused-vars
  setShowModal: (item: boolean) => void;
  showModal: boolean;
  editor: any;
}

const HighlightCorrectSentence: React.FC<HighlightCorrectSentenceProps> = ({
  data,
  body,
  questionbutton,
  setbodyformultiquestion,
  passageText,
  setShowModal,
  showModal,
  editor,
}) => {
  const [questionIdentifier, setQuestionIdentifier] = useState<string | undefined>(
    body[questionbutton]?.question_short_identifier
  );
  const [questionDirection, setQuestionDirection] = useState<string | undefined>(
    body[questionbutton]?.question_direction
  );
  const [compose_text, setCompose_text] = useState<string | undefined>(body[questionbutton]?.compose_question);
  const [explanation_text, setExplanation_text] = useState<string | undefined>(body[questionbutton]?.explaination_text);
  const [videoId, setVideoId] = useState<string | undefined>(body[questionbutton]?.video_id);
  const [options, setOptions] = useState<Option[]>(body[questionbutton]?.options ? body[questionbutton]?.options : []);
  const [passageData, setPassageData] = useState(data ?? '');
  const [modalMedium, setModalMedium] = useState(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [modalData] = useState({
    title: 'Changing the passage will modify the answers.',
    description: 'Correct option and alternate options values will be reset. Please confirm your action',
    cancelButton: 'Cancel',
    saveButton: 'Yes, Change',
  });
  const onPassageTextChnage = passageText();
  useEffect(() => {
    if (data) {
      setModalMedium(false);
      handleConfirmDelete(data);
      setPassageData(data);
      setShowModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (
      passageData !== onPassageTextChnage &&
      onPassageTextChnage &&
      !modalMedium &&
      showModal &&
      options.length > 0
      // Check if the length increased
    ) {
      openModalMedium();
    }
  }, [onPassageTextChnage, options.length, modalMedium]);
  function splitParagraph(paragraph: string) {
    if (paragraph) {
      if (typeof paragraph !== 'string') {
        throw new Error('Input must be a string');
      }
      // Match sentences ending with ., !, or ? (including the mark)
      const regex = /[^.!?]{1,1000}[.!?]/g;
      const lines = paragraph.match(regex) || [];
      // Trim spaces and return the lines
      const sentences = lines.map((line) => line.trim());
      const optionsLabels = convertToOptionsArray(sentences);
      setOptions([...optionsLabels]);
    }
  }

  const convertToOptionsArray = (sentences: any[]) => {
    return sentences.map((sentence, i) => ({
      id: i,
      value: sentence,
      is_correct: false,
    }));
  };

  const handleOptionSelect = (id: number) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        is_correct: option.id === id ,
      }))
    );
  };

  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionIdentifier,
      question_direction: questionDirection,
      compose_question: compose_text,
      options: options,
      video_id: videoId ?? null,
      explaination_text: explanation_text ?? null,
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    AddUpdateBody();
  }, [questionIdentifier, questionDirection, compose_text, options, videoId, explanation_text]);

  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  const handleConfirmDelete = (data: string) => {
    // Add your delete logic here
    splitParagraph(data);
  };

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  return (
    <>
      <div className={style.tinymce_text} style={{ marginTop: '15px' }}>
        {/* add Question Short Identifier with tnymce*/}
        <div className={style.Question_Direction}>
          <p>
            Question Short Identifier <b className={style.red_mendantory}>*</b>
          </p>
          <textarea
            placeholder="Enter Question Short Identifier"
            className={`${style.question_identifier} ${style.Shortquestion} ${style.nonResizable}`}
            value={questionIdentifier}
            onChange={(e) => setQuestionIdentifier(e.target.value)}
          />
        </div>

        {/* add Question Direction with tnymce*/}
        <div className={`${style.Question_Direction} ${style.QuestionDirection}`}>
          <p>Enter Question Direction</p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={questionDirection !== null ? questionDirection : ''}
              onChange={(text) => setQuestionDirection(text)}
            />
          </div>
        </div>

        {/* add compose quetion*/}
        <div className={`${style.explanation_text} ${style.explanationtext}`}>
          <div className={style.questionWrapper}>
            <p className="questionContainer">
              Compose question <b className={style.red_mendantory}>*</b>
            </p>
          </div>

          <div className={style.ComposeQuestion}>
            <QuillEditor
              toolbarList={false}
              defaultValue={compose_text ? b64DecodeUnicode(compose_text) : ''}
              onChange={(text) => setCompose_text(btoa(text))}
            />
          </div>
        </div>
        {/*  Select the correct sentence */}
        <div className={`${style.explanation_text} ${style.videoId}`}>
          {options.length > 0 && (
            <p>
              Select the correct sentence <b className={style.red_mendantory}>*</b>
              <br />
              <span className={style.sentenceNote}>
                Note: If you have changed the passage above, make sure you click outside the passage text editor to
                repopulate the list of sentences below.
              </span>
            </p>
          )}

          <div style={{ width: '100%' }} className={style.optionsList}>
            {options.length > 0 &&
              options?.map((option) => (
                <div key={option.id} className={style.optionItem}>
                  <div className={style.sentecnceInputWrapper}>
                    <input
                      type="radio"
                      name={`correctSentences`}
                      checked={option.is_correct}
                      onChange={() => handleOptionSelect(option.id)}
                      className={style.radioInput}
                    />
                    <div style={{ width: '94%' }} className={style.sentecnceInput}>
                      {option.value}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* add Video ID */}
        <div className={`${style.explanation_text} ${style.videoId}`}>
          <p>Video ID</p>
          <div className={style.explanation_video}>
            <input
              type="text"
              value={videoId}
              placeholder="Enter Video ID"
              onChange={(e) => setVideoId(e.target.value)}
            />
          </div>
        </div>

        {/* add explanation_text with tnymce*/}
        <div className={style.explanation_text}>
          <p>Add Explanation Text</p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={explanation_text ? b64DecodeUnicode(explanation_text) : ''}
              onChange={(text) => setExplanation_text(btoa(text))}
            />
          </div>
        </div>
      </div>
      <MediumModal
        modalMedium={modalMedium}
        forwardButton={() => {
          setModalMedium(false);
          setShowModal(false);
          editor?.focus();
        }}
        closeModalMedium={() => {
          closeModalMedium();
          editor?.setContent(`<p>${passageData}</p>`);
          setShowModal(true);
        }}
        modalData={modalData}
      />
    </>
  );
};

export default HighlightCorrectSentence;
