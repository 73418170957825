/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor } from '@tinymce/tinymce-react';

interface TinyMCEProps {
  // eslint-disable-next-line no-unused-vars
  setA?: (a: boolean) => void;
  id?: number;
  toolbarList?: boolean;
  defaultValue?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (content: string) => void;
  // eslint-disable-next-line no-unused-vars
  onRemove?: (id: string) => void;
  disabled?: boolean;
  ref?: React.Ref<any>;
  data?: Record<string, any>;
  // eslint-disable-next-line no-unused-vars
  refSetter?: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  onBlurChange?: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  setShowModal?: (data: any) => void;
}

const TinyMCE: React.FC<TinyMCEProps> = ({
  setA,
  id = 0,
  toolbarList = false,
  defaultValue = '',
  onChange,
  onRemove,
  data = {},
  refSetter,
  onBlurChange,
  setShowModal,
}) => {
  function filterObjectByArray(arr: string[], obj: Record<string, any>) {
    for (const key in obj) {
      if (!arr.includes(key)) {
        if (onRemove) {
          onRemove(key);
        }
      }
    }
  }

  return (
    <Editor
      onInit={(evt, editor) => {
        refSetter && refSetter(editor);
        editor.on('click', (e) => {
          if (e.target.classList.contains('button-getter')) {
            // eslint-disable-next-line no-console
            console.log('Button inside editor clicked!');
          }
        });
      }}
      tinymceScriptSrc="https://manual-upload-assets.s3.ap-south-1.amazonaws.com/tinymce/tinymce.min.js"
      apiKey="43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz"
      value={defaultValue}
      onEditorChange={(_text, editor) => {
        if (onChange) {
          if (id === 16 && setA) {
            setA(true);
          }
          onChange(editor.getContent({ format: 'html' }));
          const editorDoc = editor.getDoc();
          const dynamicButtons = editorDoc.querySelectorAll('.dynamic-button');
          const deleteButtons = editorDoc.querySelectorAll('.dynamic-button .delete-btn');
          const output: string[] = [];
          dynamicButtons.forEach((btn) => {
            output.push(btn.id);
          });
          filterObjectByArray(output, data);
          deleteButtons.forEach((deleteButton) => {
            (deleteButton as HTMLElement).onclick = () => {
              const parentButton = deleteButton.parentElement;
              if (parentButton && onRemove) {
                onRemove(parentButton.id);
                parentButton.remove();
              }
            };
          });
        }
      }}
      onBlur={() => {
        if (onBlurChange && setShowModal && id === 16) {
          onBlurChange(window);
          setShowModal(false);
        }
      }}
      init={{
        plugins: toolbarList
          ? 'ai preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars  image link math media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker editimage help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker advtable footnotes mergetags autocorrect typography advtemplate markdown revisionhistory'
          : 'ai preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars  image link math media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker editimage help formatpainter permanentpen pageembed charmap tinycomments mentions linkchecker advtable footnotes mergetags autocorrect typography advtemplate markdown revisionhistory',
        // menubar: 'file edit view insert tools table help', // Removed 'format' from the menubar
        menu: {
          // Define other menus, exclude the "Format" menu or remove the formats submenu if needed
          format: {
            title: 'Format',
            items: 'bold italic underline strikethrough superscript subscript | removeformat',
          },
        },
        toolbar: toolbarList
          ? 'undo redo | blocks | bold italic align outdent indent| image table math'
          : 'undo redo | blocks | bold italic align outdent indent',
        resize: false,
      }}
    />
  );
};

export default TinyMCE;
