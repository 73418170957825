/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { CallWithAuth } from '../../../../action/apiActions';
import { GET_QUESTIONBANK } from '../../../../action/apiPath';
import { useDispatch, useSelector } from 'react-redux';
import { CreateQuestionAction, QuestionCreationScreenCancel, SetQuestionBank } from '../../../../redux/action';
import { useNavigate } from 'react-router-dom';
import MediumModal from '../../../atoms/Modal/MediumModal';
import style from './../../../../assets/styles/createQuestion.module.css';
import { useToast } from '../../common/Toast';

interface QuestionBankProps {
  id: string;
  Permission: any;
}

const QuestionBank: React.FC<QuestionBankProps> = ({ id, Permission }) => {
  // _________ START : INITILIZATION: REDUX DISPATCH _________
  const dispatch = useDispatch();
  const selector = useSelector((state: any) => state.CreateQuestionReducer);
  const navigate = useNavigate();
  // _________ END : INITILIZATION: REDUX DISPATCH _________

  // GET QUESTION BANK API CALL SAVED THE DATA IN STATE
  const [questionBank, setQuestionBank] = useState<any[]>([]);

  const getQuestionBank = async () => {
    const GetData = await CallWithAuth('GET', GET_QUESTIONBANK);
    if (GetData.status && GetData?.res?.data && Array.isArray(GetData.res.data)) {
      setQuestionBank(GetData?.res?.data);
    }
  };

  useEffect(() => {
    getQuestionBank();
  }, []);

  // SELECT QUESTION BANK SAVED IN QUESTION STATE
  const [question, setQuestion] = useState(null);

  // SELECT QUESTION BANK SAVED  to make status false
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);

  useEffect(() => {
    if (question === null) {
      setSaveButtonStatus(true);
    } else {
      setSaveButtonStatus(false);
    }
  }, [question]);

  // _____________ MODAL OPEN AND CLOSE _____________
  const [modalMedium, setModalMedium] = useState(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [modalData] = useState({
    title: 'Are you sure, Do you want to cancel Question Creation?',
    description: 'Your Current Changes Will Not Be Saved And You Will Be Redirected To Question Listing ',
    cancelButton: 'Continue Editing',
    saveButton: 'Yes, Cancel',
  });

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  // cancel button to send the user to question-bank
  const Cancel = () => {
    navigate('/question-bank');
    dispatch(QuestionCreationScreenCancel());
  };

  useEffect(() => {
    setQuestion(selector.question_bank_id);
  }, [selector]);
  const toast = useToast();

  return (
    <>
      <div className={style.select_question}>
        <div className={style.top_bar}>
          <div className={style.top_bar_title}>
            <span>Select a Question Bank/Exam</span>
          </div>
        </div>
        <div className={style.listandsave}>
          {/* _____________  LIST OF QUESTION BANK  _____________ */}
          <div className={`${style.list} ${style.questionList}`}>
            {questionBank?.map((items: any) => (
              <div
                key={items.id}
                className={
                  items.id === question ? ` ${style.active_box} ${style.pointer}` : `${style.box} ${style.pointer}`
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setQuestion(items.id);
                    dispatch(SetQuestionBank(items.id));
                  }
                }}
                onClick={() => {
                  setQuestion(items.id);
                  dispatch(SetQuestionBank(items.id));
                }}
              >
                <div className={style.title_list_box} style={{ width: '18vw' }}>
                  <p className={style.title_list_name} title={items.name.length > 20 ? items.name : ''}>
                    {items.name.length > 20 ? items.name.slice(0, 20) + '...' : items.name}
                  </p>
                  <div className={style.tags}>
                    {items?.free_tags.map((item: any) => (
                      <>
                        <button key={item.name} className={`${style.general_button} ${style.generalButtonWrap}`}>
                          {item.name}
                        </button>{' '}
                        &nbsp;
                      </>
                    ))}
                  </div>
                </div>

                <div className={style.question_list_box}>
                  <p>
                    Total Questions&nbsp;<span> {items.total_questions}</span>
                  </p>
                </div>

                <div className={style.largeBall}>
                  <svg width="72" height="62" viewBox="0 0 72 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="70.5" cy="-8.5" r="70.5" fill="url(#paint0_linear_1_36277)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1_36277"
                        x1="70"
                        y1="-8"
                        x2="22.5"
                        y2="48.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#03ADFB" />
                        <stop offset="1" stopColor="#2CCDFA" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                <div className={style.smallBall}>
                  <svg width="52" height="18" viewBox="0 0 52 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="26" cy="-8" r="26" fill="url(#paint0_linear_1_36278)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1_36278"
                        x1="25.8156"
                        y1="-7.8156"
                        x2="8.29787"
                        y2="13.0213"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#03ADFB" />
                        <stop offset="1" stopColor="#2CCDFA" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            ))}
          </div>
          {/* _____________  LIST OF QUESTION BANK  _____________ */}

          {/* _____________  SAVE BUTTON  _____________ */}
        </div>
      </div>
      {/* _____________  SAVE BUTTON  _____________ */}
      <div className={`${style.saveButton_questionBank} ${style.questionBankSaveButton}`}>
        <button
          className={`${style.cancel} ${style.pointer} ${style.cancelBtnWrap} `}
          onClick={() => openModalMedium()}
        >
          Cancel
        </button>
        <div>
          <button
            className={saveButtonStatus ? ` ${style.blue_button_disable}` : `${style.blue_button}`}
            disabled={saveButtonStatus}
            onClick={() => {
              if (Permission?.edit | Permission?.review | Permission?.draft) {
                dispatch(CreateQuestionAction(question, id));
              } else {
                toast?.remove('all');
                toast?.add("Sorry, you don't have permission to create questions in this question bank.", 'error');
              }
            }}
          >
            Save & Continue
          </button>
        </div>
      </div>
      <MediumModal
        modalMedium={modalMedium}
        forwardButton={() => Cancel()}
        closeModalMedium={closeModalMedium}
        modalData={modalData}
      />
    </>
  );
};

export default QuestionBank;
