/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import AuthLayout from '../layout/AuthLayout';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import TaskList from '../component/molecule/specific/TaskManagementScreens/TaskList';
import AddTask from '../component/molecule/specific/TaskManagementScreens/AddTask';
import TaskDetail from '../component/molecule/specific/TaskManagementScreens/TaskDetail';
import style from '../assets/styles/taskManagement.module.css';
import { Task_Screen_Change } from '../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import EditTask from '../component/molecule/specific/TaskManagementScreens/EditTask';
import { useNavigate } from 'react-router-dom';

const TaskManagement = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  // ________________ screen to show dynamically ________________
  const [screen, setScreen] = useState(0);

  // ________________ Keyword use in searching, sorting and pagination ________________
  const [keyword, setKeyword] = useState('');
  const [sort, setsort] = useState('');

  const { taskManagementScreens } = useSelector((state) => state.GetAllData);

  const [hasNavigatedAway, setHasNavigatedAway] = useState(false);

  useEffect(() => {
    if (history.pathname !== '/task-management') {
      setHasNavigatedAway(true);
    }
  }, []);

  useEffect(() => {
    if (hasNavigatedAway) {
      dispatch(Task_Screen_Change(0));
      setHasNavigatedAway(false);
    }
  }, [dispatch, hasNavigatedAway]);
  return (
    
      <AuthLayout>
        {/* rightpannel top left menu according to different screen  */}
        <RightPanelTopMenu setQuestionBankId={null} title={'Task Management'} />
        {/* different screens according to the selection of masters  */}
        <div className={style.question_bank_table_top}>
          {taskManagementScreens === 0 ? <TaskList setKeyword={setKeyword} setsort={setsort} /> : ''}
          {taskManagementScreens === 1 ? <AddTask /> : ''}
          {taskManagementScreens === 2 ? <TaskDetail /> : ''}
          {taskManagementScreens === 3 ? <EditTask /> : ''}
        </div>
      </AuthLayout>
    
  );
};

export default TaskManagement;
