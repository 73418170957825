/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import rightArrowSvg from '../../../../assets/images/right_arrow.svg';
import leftArrowSvg from '../../../../assets/images/left_arrow.svg';
import verticalLineSvg from '../../../../assets/images/verticalLineSvg.svg';
import VerticalLine from '../../../../assets/images/vertical-line.svg';
import style from '../../../../assets/styles/userManagement.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserDetail, GetOraganisationList, GetRoles, UserScreenChange } from '../../../../redux/action';
import { useNavigate, useParams } from 'react-router-dom';
import PageLayout from '../../../atoms/PageLayout';

const UserDetail = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { userDataInfo } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(GetUserDetail(userId));
  }, [userId]);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  // Slice the data for pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = userDataInfo?.question_bank_management?.slice(startIndex, endIndex) || [];
  const totalPages = Math.ceil((userDataInfo?.question_bank_management?.length || 0) / itemsPerPage);
  const navigate = useNavigate();
  const totalRows = userDataInfo?.question_bank_management?.length || 0; // Fallback to 0 if undefined
  const rowsPerPage = 10; // Rows per page
  // Function to calculate the range of rows
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, totalRows);

  // Function to change page
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(GetRoles());
    dispatch(GetOraganisationList());
  }, [dispatch]);

  return (
    <PageLayout title={'User Management'}>
      <div>
        <div className={style.top_section}>
          <span
            className={style.left}
            onClick={() => {
              dispatch(UserScreenChange(0));
              navigate('/user-management');
            }}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5488 19.422L9.02883 12.902C8.25883 12.132 8.25883 10.872 9.02883 10.102L15.5488 3.58203"
                stroke="#212529"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </span>
          <span
            className={style.right}
            onClick={() => {
              dispatch(UserScreenChange(3));
              navigate(`/user-management/edit-user/${userDataInfo?.id}`);
            }}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 22H21" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M13.2603 3.59924L5.05034 12.2892C4.74034 12.6192 4.44034 13.2692 4.38034 13.7192L4.01034 16.9592C3.88034 18.1292 4.72034 18.9292 5.88034 18.7292L9.10034 18.1792C9.55034 18.0992 10.1803 17.7692 10.4903 17.4292L18.7003 8.73924C20.1203 7.23924 20.7603 5.52924 18.5503 3.43924C16.3503 1.36924 14.6803 2.09924 13.2603 3.59924Z"
                stroke="#00ABFB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.8896 5.04883C12.3196 7.80883 14.5596 9.91883 17.3396 10.1988"
                stroke="#00ABFB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            &nbsp; <u>Edit</u>
          </span>
          <div className={`${style.table_content_report} ${style.table_height}`}>
            <div className={`${style.profile_detail_div} ${style.profile_detail_div_font}`}>
              User Profile
              <div className={userDataInfo?.is_active ? `${style.activate}` : `${style.inactive}`}>
                {userDataInfo?.is_active ? 'Active' : 'Inactive'}
              </div>
            </div>
            <div className={`${style.select_question} ${style.profile_status}`}>
              <div className={`${style.question_bank_table} ${style.shadow}  ${style.new_shadow}`}>
                <div className={`${style.tbody_question_bank} ${style.profile_detail_info}`}>
                  <div className={`${style.first} ${style.all_column}`}>
                    <div className={style.title_detail}>First Name</div>
                    <div className={`${style.description_detail} ${style.firstName}`}>
                      {' '}
                      {userDataInfo?.first_name || ' '}
                    </div>
                  </div>
                  <img src={VerticalLine} className={style.vertical_line} alt="Vertical Line" />
                  <div className={`${style.last_name} ${style.all_column}`}>
                    <div className={style.title_detail}>Last Name</div>
                    <div className={`${style.description_detail} ${style.lastName}`}>{userDataInfo?.last_name}</div>
                  </div>
                  <img src={VerticalLine} className={style.vertical_line} alt="Vertical Line" />

                  <div className={`${style.second} ${style.all_column}`}>
                    <div className={style.title_detail}>Email ID</div>

                    <div className={`${style.description_detail} ${style.userEmail}`}>{userDataInfo?.email_id}</div>
                  </div>
                  <img src={VerticalLine} className={style.vertical_line} alt="Vertical Line" />

                  <div className={`${style.fourth} ${style.all_column}`}>
                    <div className={style.title_detail}>Mobile Number</div>

                    <div className={`${style.description_detail} ${style.mobileNumber}`}>
                      {userDataInfo?.mobile_number}
                    </div>
                  </div>
                  <img src={VerticalLine} className={style.vertical_line} alt="Vertical Line" />

                  <div className={`${style.last} ${style.all_column}`}>
                    <div className={style.title_detail}>Organization</div>

                    <div className={`${style.description_detail} ${style.organizationName}`}>
                      {userDataInfo?.organizations?.organization_name}
                    </div>
                  </div>
                  <img src={VerticalLine} className={style.vertical_line} alt="Vertical Line" />

                  <div className={`${style.third} ${style.all_column}`}>
                    <div className={style.title_detail}>Role</div>

                    <div className={`${style.description_detail} ${style.roleName}`}>
                      {userDataInfo?.roles?.role_name.replace('_', ' ')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Question bank name */}
            <div className={` ${style.select_question} ${style.profile_status}`}>
              <div className={style.role_permission}>Roles Permission</div>
              <div className={style.table_container}>
                <table className={style.responsive_table}>
                  <thead>
                    <tr>
                      <th className={`${style.first} ${style.second_all_column}`}>Question Bank</th>
                      <th className={`${style.second} ${style.second_all_column}`}>Review</th>
                      <th className={`${style.third} ${style.second_all_column}`}>Edit</th>
                      <th className={`${style.fourth} ${style.second_all_column}`}>View</th>
                      <th className={`${style.last} ${style.second_all_column}`}>Draft</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((item, index) => (
                        <tr key={index + item}>
                          <td className={`${style.first} ${style.first_column}`}>{item.name}</td>
                          <td className={style.second}>
                            <label className={style.custom_radio}>
                              <input type="radio" name={`review_${index}`} checked={item.review} />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                          <td className={style.third}>
                            <label className={style.custom_radio}>
                              <input type="radio" name={`edit_${index}`} checked={item.edit} />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                          <td className={style.fourth}>
                            <label className={style.custom_radio}>
                              <input type="radio" name={`view_${index}`} checked={item.view} />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                          <td className={style.last}>
                            <label className={style.custom_radio}>
                              <input type="radio" name={`draft_${index}`} checked={item.draft} />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className={style.no_data_message}>
                          No Question Bank Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={style.pagination_user_detail}>
              {totalRows > 10 && ( // Render pagination only if totalRows is more than 10
                <div className={style.pagination_container}>
                  <div className={style.pagination_info}>
                    The page you're on&nbsp;
                    <input
                      type="number"
                      min="1"
                      max={totalPages}
                      value={currentPage}
                      onChange={(e) => handlePageChange(Number(e.target.value))}
                      className={style.pagination_input}
                    />
                  </div>
                  <img src={verticalLineSvg} alt="divider" className={style.vertical_line_pagination} />
                  <div className={style.pagination_controls}>
                    <button
                      className={style.pagination_button}
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <img
                        src={leftArrowSvg}
                        className={`${style.pagination_icon} ${currentPage === 1 ? style.disabled : style.active}`}
                        alt="left arrow"
                      />
                    </button>
                    <span>
                      {startRow} - {endRow} of {totalRows}
                    </span>
                    <button
                      className={style.pagination_button}
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <img
                        src={rightArrowSvg}
                        className={`${style.pagination_icon} ${
                          currentPage === totalPages ? style.disabled : style.active
                        }`}
                        alt="right arrow"
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default UserDetail;
