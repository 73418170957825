import { CallWithAuth } from '../../../action/apiActions';
import { GET_QUESTION_DRAFT } from '../../../action/apiPath';

/**
 * Handles editing a question by checking lock permissions and navigating or opening a modal.
 * @param {object} items - The question item data.
 * @param {function} setIsPreviewOpen - Function to set the preview open state.
 * @param {function} openModalMedium - Function to open a medium modal.
 * @param {function} setQuestionItem - Function to set the selected question item.
 * @param {function} navigate - Navigation function to redirect the user.
 */
export const editQuestionHandler = async (items, setIsPreviewOpen, openModalMedium, setQuestionItem, navigate) => {
  const url = `${GET_QUESTION_DRAFT}${items?.question_id}/check-lock-permission/`;
  const lockPermissionCheck = await CallWithAuth('GET', url);

  if (lockPermissionCheck.status && lockPermissionCheck.res?.status === 200) {
    if (lockPermissionCheck.res?.data?.lock > 0) {
      setIsPreviewOpen(false);
      openModalMedium();
      setQuestionItem(items);
    } else {
      navigate('/edit-question', { state: items });
    }
  }
};
