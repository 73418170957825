import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { CallWithAuth } from '../../../../action/apiActions';
import { EDIT_SUBJECT_TAG, GET_SUBJECT_TAG } from '../../../../action/apiPath';
import { Get_All_Subject_pipeline } from '../../../../redux/action';
import style from '../../../../assets/styles/editSubjectPipeline.module.css';
import AuthLayout from '../../../../layout/AuthLayout';
import { SlArrowLeft } from 'react-icons/sl';
import RightPanelTopMenu from '../../common/RightPanelTopMenu';
import { useToast } from '../../common/Toast';

const EditSubjectPipeline = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const rowRef = useRef();
  const [responseData, setResponseData] = useState({});
  const [descriptionSubTopic, setDescriptionSubTopic] = useState('');
  const [subjectTagStatus, setSubjectTagStatus] = useState(false);
  const [areaTagStatus, setAreaTagStatus] = useState(false);
  const [validationErrorSubject, setValidationErrorSubject] = useState('');
  const [validationErrorTopic, setValidationErrorTopic] = useState('');
  const [validationErrorSubTopic, setValidationErrorSubTopic] = useState('');
  const [validationErrorArea, setValidationErrorArea] = useState('');

  const fetchSubjectData = async () => {
    try {
      const data = await CallWithAuth('GET', `${GET_SUBJECT_TAG}${id}/subject-tag-details/`);
      if (data) {
        setResponseData(data.res.data.data);
        setSelectedPrimaryTag(data.res.data.data.subject);
        setSelectedPrimaryTagStatus(true);
        setSelectedTopicTag(data.res.data.data.topic);
        setSelectedTopicTagStatus(true);
        setSelectedAreaTag(data.res.data.data.area);
        setSelectedAreaTagStatus(true);
        setDescriptionSubTopic(data.res.data.data.subtopic_description);
        // Safe way to handle the subtopic array
        if (data.res.data.data.subtopic) {
          let result = data.res.data.data.subtopic;
          let arr = result.split(',');
          setMultiselectedSubTopicTag(arr);
        }
        setMultiSubTopicTagStatus(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch subject data', error);
      // Optionally, set some error state or show a message
    }
  };

  useEffect(() => {
    fetchSubjectData();
  }, [id]);

  useEffect(() => {
    setAreaData(responseData?.area);
    setTopicData(responseData?.topic);
    setSubjectData(responseData?.subject);
  }, [responseData]);

  //  -------------------------- Sub -Topic ---------------------- Sub -Topic --------------------

  const [multiselectedSubTopicTag, setMultiselectedSubTopicTag] = useState([]);
  const [multiSubTopicTagInput, setMultiSubTopicTagInput] = useState('');
  const [multiSubTopicTag, setMultiSubTopicTag] = useState([]);
  const [multiSubTopicTagStatus, setMultiSubTopicTagStatus] = useState(false);

  // CALL GET API FOR getting TAGSuseE
  const TagSubTopicMultiple = async (e) => {
    setMultiSubTopicTagInput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-subtopic/' + '?subtopic=' + e);
      if (data.status && data.res.status === 200) {
        if (data.res.data.length > 0) {
          setMultiSubTopicTag(data.res.data);
          // setnewSubTopicTagStatus(false)
          setMultiSubTopicTagStatus(true);
        } else {
          // setnewSubTopicTags([e])
          setMultiSubTopicTagStatus(false);
          // setnewSubTopicTagStatus(true)
        }
      }
    } else {
      setMultiSubTopicTagStatus(false);
    }
  };

  const selectSubTopicTag = (e) => {
    setMultiselectedSubTopicTag((current) => {
      if (!current.includes(e)) {
        return [...current, e];
      }
      return current;
    });
    setMultiSubTopicTagInput(e);

    setMultiSubTopicTagStatus(false);
    setMultiSubTopicTagInput('');
  };

  //  -------------- For Search Fields --------------
  const selectPrimaryTag = (e) => {
    setSubjectData(e);
    setSelectedPrimaryTag(e);
    setSelectedPrimaryTagStatus(true);
    setSubjectTagStatus(false);
  };

  //------------------subject-----------------------
  const [subjectTag, setSubjectTag] = useState([]);
  const [selectedPrimaryTag, setSelectedPrimaryTag] = useState(null);

  const [selectedPrimaryTagStatus, setSelectedPrimaryTagStatus] = useState(false);
  const [subjectData, setSubjectData] = useState(selectedPrimaryTag);

  const TagSubject = async (e) => {
    setSubjectData(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-subject/' + '?subject=' + e);
      if (data.status && data.res.status === 200) {
        setSubjectTag(data.res.data);
        if (data.res.data.length > 0) {
          setSubjectTagStatus(true);
        } else {
          setSubjectTagStatus(false);
        }
      }
    } else {
      setSubjectTagStatus(false);
    }
  };

  // -------------------- Topic ----------------------------
  const [topicTag, setTopicTag] = useState([]);
  const [selectedTopicTag, setSelectedTopicTag] = useState(null);
  const [selectedTopicTagStatus, setSelectedTopicTagStatus] = useState(false);
  const [topicTagStatus, setTopicTagStatus] = useState(false);
  const [topicData, setTopicData] = useState(null);

  // select tags from options
  const selectTopicTag = (e) => {
    setTopicData(e);
    setSelectedTopicTag(e);
    setTopicTagStatus(false);
  };

  const TagTopic = async (e) => {
    setTopicData(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-topic/' + '?topic=' + e);
      if (data.status && data.res.status === 200) {
        setTopicTag(data.res.data);
        if (data.res.data.length > 0) {
          setTopicTagStatus(true);
        } else {
          setTopicTagStatus(false);
        }
      }
    } else {
      setTopicTagStatus(false);
    }
  };

  //  -------------------- Area -------------------------
  const [areaTag, setAreaTag] = useState([]);
  const [selectedAreaTag, setSelectedAreaTag] = useState(null);
  const [selectedAreaTagStatus, setSelectedAreaTagStatus] = useState(false);
  const [areaData, setAreaData] = useState('');

  // select tags from options
  const selectAreaTag = (e) => {
    setAreaData(e);
    setSelectedAreaTag(e);

    setSelectedAreaTagStatus(true);
    setAreaTagStatus(false);
  };
  const TagArea = async (e) => {
    setAreaData(e);

    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-area/' + '?area=' + e);
      if (data.status && data.res.status === 200) {
        setAreaTag(data.res.data);
        if (data.res.data.length > 0) {
          setAreaTagStatus(true);
        } else {
          setAreaTagStatus(false);
        }
      }
    } else {
      setAreaTagStatus(false);
    }
  };

  const modalRef = useRef();

  // Close modal handler
  const closeModal = () => {
    navigate('/masters');
  };

  const Toast = useToast();
  const AddNew = async (id) => {
    const body = {
      subject: subjectData,
      area: areaData,
      topic: topicData,
      subtopic: multiselectedSubTopicTag.join(', '),
      subtopic_description: descriptionSubTopic,
    };

    try {
      const data = await CallWithAuth('PATCH', `${EDIT_SUBJECT_TAG}${id}/update-subject-tag/`, body);
      if (data.status) {
        if (Toast) {
          Toast.remove('all');
          Toast.add(String(data?.res?.data?.message), 'Subject Tags have been created successfully!');
        }
        closeModal();
        dispatch(Get_All_Subject_pipeline('', '', 15, 1));
        handleBack();
      } else {
        const keys = Object?.keys(data?.res?.data);
        const firstErrorKey = keys[0];
        Toast?.remove('all');
        Toast?.add(String(`${keys[0]} : ${data?.res.data[firstErrorKey][0]}`), 'error');
      }
    } catch (error) {
      alert('An unexpected error occurred. Please try again.');
    }
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/masters');
  };
  const removeTag = (index) => {
    const updatedTags = multiselectedSubTopicTag.filter((_, i) => i !== index);
    setMultiselectedSubTopicTag(updatedTags);
  };

  const handleRowClick = (event) => {
    if (rowRef.current && !rowRef.current.contains(event.target)) {
      // Check if the click is within the action buttons
      const isActionButtonClick = event.target.closest('.action_buttons');

      if (!isActionButtonClick) {
        setAreaTagStatus(false);
        setMultiSubTopicTagStatus(false);
        setTopicTagStatus(false);
        setSubjectTagStatus(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleRowClick);
    return () => {
      document.removeEventListener('mousedown', handleRowClick);
    };
  }, [handleRowClick]);

  return (
    <AuthLayout>
      <RightPanelTopMenu />
      <div className={style.filterModal}>
        <div className={style.center_div_large} ref={modalRef}>
          <div className={style.top_title}>
            <button className={style.back_button} onClick={() => handleBack()}>
              <span className={style.arrow}>
                <SlArrowLeft size={16} />
              </span>
              <span style={{ fontSize: '16px', position: 'relative', bottom: '1px' }}>Back</span>
            </button>
          </div>
          {/* TABLE LISTING OF ALL AND MY QUESTION  */}
          <div className={style.full_container}>
            <div style={{ paddingBottom: '15px' }} className={style.add_title}>
              <p style={{ fontSize: '20px' }} className={style.addtitle_tag}>
                Edit Subject Pipeline
              </p>
            </div>
            <div className={style.identifier_master_modal}>
              <div style={{ paddingBottom: '5px' }} className={style.master_modal}>
                {/* ___________________ subject ___________________ */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>Subject </p>
                    <span className={style.required_field}>*</span>
                  </div>
                </div>
                <div className={style.master_tag_search}>
                  {/* this is input field for searching */}
                  <div className={style.input_search} ref={rowRef}>
                    {selectedPrimaryTagStatus ? (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Subject"
                        value={selectedPrimaryTag}
                        onChange={(e) => {
                          selectPrimaryTag(e.target.value);
                          TagSubject(e.target.value);
                        }}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    ) : (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Subject"
                        onChange={(e) => TagSubject(e.target.value)}
                      />
                    )}
                    {subjectTagStatus ? (
                      <div className={style.list_input_primary}>
                        {subjectTag?.map((items) => (
                          <button key={items} className={style.pointer} onClick={() => selectPrimaryTag(items)}>
                            <span>{items} </span>
                          </button>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                    {/* cancel button  */}
                    {selectedPrimaryTag && (
                      <div className={style.cancel_button}>
                        <button
                          className={style.pointer}
                          onClick={() => {
                            setSelectedPrimaryTag('');
                            TagSubject('');
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {setValidationErrorSubject && (
                  <div className={style.validationError}>
                    <p>{validationErrorSubject}</p>
                  </div>
                )}
                {/* ___________________ Topic ___________________ */}
                <div style={{ paddingTop: '12px' }} className={style.tags}>
                  <div className={style.subject}>
                    <p>Topic </p>
                    <span className={style.required_field}>*</span>
                  </div>
                </div>
                <div className={style.master_tag_search}>
                  {/* this is input field for searching */}
                  <div className={style.input_search} ref={rowRef}>
                    {selectedTopicTagStatus ? (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Topic"
                        value={selectedTopicTag}
                        onChange={(e) => {
                          selectTopicTag(e.target.value);
                          TagTopic(e.target.value);
                        }}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    ) : (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Topic"
                        onChange={(e) => TagTopic(e.target.value)}
                      />
                    )}

                    {topicTagStatus ? (
                      <div className={style.list_input_primary}>
                        {topicTag?.map((items) => (
                          <button key={items} className={style.pointer} onClick={() => selectTopicTag(items)}>
                            <span> {items} </span>
                          </button>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                    {/* cancel button  */}
                    {selectedTopicTag && (
                      <div className={style.cancel_button}>
                        <button
                          className={style.pointer}
                          onClick={() => {
                            setSelectedTopicTag('');
                            TagTopic('');
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {setValidationErrorTopic && (
                  <div className={style.validationError}>
                    <p>{validationErrorTopic}</p>
                  </div>
                )}
              </div>
              <div style={{ paddingLeft: '15px' }} className={style.master_modal}>
                {/* ___________________ area ___________________ */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>Area </p>
                    <span className={style.required_field}>*</span>
                  </div>
                </div>
                <div style={{ paddingBottom: '15px' }} className={style.master_tag_search}>
                  {/* this is input field for searching */}
                  <div className={style.input_search} ref={rowRef}>
                    {selectedAreaTagStatus ? (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Area"
                        value={selectedAreaTag}
                        onChange={(e) => {
                          selectAreaTag(e.target.value);
                          TagArea(e.target.value);
                        }}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    ) : (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Area"
                        onChange={(e) => TagArea(e.target.value)}
                      />
                    )}
                    {areaTagStatus ? (
                      <div className={style.list_input_primary}>
                        {areaTag?.map((items) => (
                          <button key={items} className={style.pointer} onClick={() => selectAreaTag(items)}>
                            <span> {items} </span>
                          </button>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                    {/* cancel button  */}
                    {selectedAreaTag && (
                      <div className={style.cancel_button}>
                        <button
                          className={style.pointer}
                          onClick={() => {
                            setSelectedAreaTag('');
                            TagArea('');
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {setValidationErrorArea && (
                  <div className={style.validationError}>
                    <p>{validationErrorArea}</p>
                  </div>
                )}
                {/* ---------------Descriptive sub topic */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>Sub-Topic</p>
                    <span className={style.required_field}>*</span>
                  </div>
                </div>
                <div className={style.master_tag_search}>
                  <div className={style.input_search} style={{ height: '38px' }} ref={rowRef}>
                    {multiSubTopicTagStatus ? (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Sub-Topic"
                        style={{ marginTop: '0px', marginBottom: '0px', paddingBottom: '0px' }}
                        value={multiSubTopicTagInput}
                        onChange={(e) => {
                          selectSubTopicTag(e.target.value);
                          TagSubTopicMultiple(e.target.value);
                        }}
                      />
                    ) : (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Sub-Topic"
                        value={multiSubTopicTagInput}
                        style={{ marginTop: '0px', marginBottom: '3px', padding: '10px' }}
                        onChange={(e) => TagSubTopicMultiple(e.target.value)}
                      />
                    )}
                    {multiSubTopicTagInput && (
                      <div className={style.cancel_button}>
                        <button
                          className={style.pointer}
                          onClick={() => {
                            setMultiSubTopicTagInput('');
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                  {setValidationErrorSubTopic && (
                    <div className={style.validationErrortag}>
                      <p>{validationErrorSubTopic}</p>
                    </div>
                  )}

                  {multiSubTopicTagStatus && multiSubTopicTagInput && multiSubTopicTag?.length > 0 && (
                    <div className={style.list_input_primary}>
                      {multiSubTopicTag.map((items) => (
                        <button key={items} className={style.pointer} onClick={() => selectSubTopicTag(items)}>
                          <span> {items} </span>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className={style.selected_subtopic_tags}>
                  {multiselectedSubTopicTag?.length > 0 && (
                    <div>
                      <div className={style.selected_tags_list}>
                        {multiselectedSubTopicTag?.map((item, index) => (
                          <span key={item} className={style.selected_tag_item}>
                            <span style={{ width: '260px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                              {item}
                            </span>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={style.remove_icon}
                              onClick={() => removeTag(index)} // Call removeTag function on click
                            >
                              <path
                                d="M18 6L6 18M6 6L18 18"
                                stroke="#272727"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  paddingTop: '20px',
                  display: 'flex',
                  gap: '12px',
                  flexDirection: 'column',
                  paddingLeft: '35px',
                  paddingRight: '10px',
                }}
              >
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>Description Of Subtopic </p>
                  </div>
                </div>
                <div className={style.master_tag_search} style={{ height: '180px', backgroundColor: '#fff' }}>
                  <div
                    className={style.input_search}
                    ref={rowRef}
                    style={{ position: 'relative', backgroundColor: '#fff', width: '85%', height: '80%' }}
                  >
                    <textarea
                      type="text"
                      rows={10}
                      cols={34}
                      id="searchInput"
                      value={descriptionSubTopic}
                      placeholder="Type Description Of Subtopic"
                      className={style.subtopicDescripTextarea}
                      onChange={(e) => setDescriptionSubTopic(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className={style.master_modal}>
                {/* ___________________ tags ___________________ */}
                <div className={style.tags}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} className={style.subject}>
                    <p style={{ margin: '0px' }}>Tags </p>
                    {multiselectedSubTopicTag.length === 0 ? (
                      <div>
                        <span className={style.AddpipelineWaterMark}>
                          No tags have been created yet. Enter the subject, area <br /> topic, and sub-topic to create a
                          tag
                        </span>
                      </div>
                    ) : (
                      <div className={style.tagsContainer}>
                        {multiselectedSubTopicTag?.map((items) => (
                          <span key={items} className={style.badge} style={{ margin: '5px' }}>
                            {selectedPrimaryTag} | {selectedAreaTag} | {selectedTopicTag} | {items}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={` ${style.saveButton_questionBank} ${style.margin_top_20} ${style.identifier_save}`}>
              <button
                className={` ${style.cancel} ${style.pointer}`}
                onClick={() => {
                  fetchSubjectData();
                }}
              >
                Cancel
              </button>
              <div>
                <button className={style.blue_button} onClick={() => AddNew(id)}>
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default EditSubjectPipeline;
