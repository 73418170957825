import PropTypes from "prop-types"
import React from 'react'

export default function NativeButton({ key,children, onClick, style = {}, ...props }) {
  return (
    <button
    key={key} // Prevents React from reusing the same button element
    onClick={onClick}
    style={{
      all: 'unset', // Resets all default button styles
      cursor: 'pointer', // Adds pointer cursor to indicate interactivity
      display: 'block', // Mimics block-level element like a <div>
      ...style, // Allows custom styles to be passed as props
    }}
    {...props}
  >
    {children}
  </button>
  )
}

NativeButton.propTypes = {
  children: PropTypes.any,
  key: PropTypes.any,
  onClick: PropTypes.any,
  style: PropTypes.object
}
