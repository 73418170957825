/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Pagination } from '../../../common/Pagination';
import style from '../../../../../assets/styles/testCreationStyle.module.css';
import style1 from '../../../../../assets/styles/modalStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_AUTHOR,
  GET_BROWSE_QUESTION,
  GET_FREE_TAG,
  GET_QUESTIONBANK,
  GET_SUBJECT_TAG,
} from '../../../../../action/apiPath';
import { CallWithAuth } from '../../../../../action/apiActions';
import moment from 'moment';
import { Get_All_LOD, GetQuestionBankList } from '../../../../../redux/action';
import deleteId from '../../../../../assets/images/deleteId.svg';
import PreviewModal from '../../../../atoms/Modal/PreviewModal';
import QuestionListModal from '../../../../atoms/Modal/QuestionListModal';
import { openPreviewModal, closePreviewModal } from '../../../../atoms/Modal/modalHandlers';
import { editQuestionHandler } from '../../../../atoms/Modal/editHandlers';
import { handleDuplicate, openModalHelper, closeModalHelper } from '../../../../atoms/Modal/duplicateHelper';
import { handleConfirmDuplicate } from '../../../../atoms/Modal/duplicateInPreviewHelper';
import { useToast } from '../../../common/Toast';
// eslint-disable-next-line react/prop-types
const BrowsingQuestion = memo(
  ({
    SetSelectIds,
    setModal,
    totalQuestion,
    selectIds,
    selectedIds,
    AddToTestPaper,
    testType,
    sectionName,
    roundNo,
    setName,
  }) => {
    // _________ PRIMARY TAG STATE INITIALIZATION _________
    const [multisubjectTaginput, setmultiSubjectTaginput] = useState('');
    const [multisubjectTag, setmultiSubjectTag] = useState([]);
    const [multisubjectTagStatus, setmultiSubjectTagStatus] = useState(false);
     const [questionAccess, setQuestionAccess] = useState({});
      const [selectedQuestion, setSelectedQuestion] = useState('');
      const [isPreviewopen, setIsPreviewopen] = useState(false);
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [modalData, setModalData] = useState(null); // State to store dataToPass
      const [isDuplicatePreview, setIsDuplicatePreview] = useState(false); // State to store dataToPass
      const [actionId, setactionId] = useState(null);
      const [questionCategory, setQuestionCategory] = useState(false);
      const toast = useToast();
    // const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([])
    // CALL GET API FOR getting TAGS
    const TagSubjectMultiple = async (e) => {
      setmultiSubjectTaginput(e);
      if (e.length > 2) {
        const data = await CallWithAuth('GET', GET_SUBJECT_TAG + '?keyword=' + e);
        if (data.status && data.res.status === 200) {
          setmultiSubjectTag(data.res.data);
          if (data.res.data.length > 0) {
            setmultiSubjectTagStatus(true);
          } else {
            setmultiSubjectTagStatus(false);
          }
        }
      } else {
        setmultiSubjectTagStatus(false);
      }
    };

    // select tags from options
    const selectSubjectTag = (e) => {
      setmultiSelectedSubjectTag((current) => [...current, e]);
      setmultiSubjectTagStatus(false);
      setmultiSubjectTaginput('');
    };

    // _________ Free TAG STATE INITIALIZATION _________
    const [multiFreeTaginput, setmultiFreeTaginput] = useState('');
    const [multiFreeTag, setmultiFreeTag] = useState([]);
    const [multiFreeTagStatus, setmultiFreeTagStatus] = useState(false);
    // const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([])

    // CALL GET API FOR getting TAGS
    const TagFreeMultiple = async (e) => {
      setmultiFreeTaginput(e);
      if (e.length > 2) {
        const data = await CallWithAuth('GET', GET_FREE_TAG + '?keyword=' + e);
        if (data.status && data.res.status === 200) {
          setmultiFreeTag(data.res.data);
          if (data.res.data.length > 0) {
            setmultiFreeTagStatus(true);
          } else {
            setmultiFreeTagStatus(false);
          }
        }
      } else {
        setmultiFreeTagStatus(false);
      }
    };

    // select tags from options
    const selectFreeTag = (e) => {
      setmultiSelectedFreeTag((current) => [...current, e]);
      setmultiFreeTagStatus(false);
      setmultiFreeTaginput('');
    };

    // _________ Category TAG STATE INITIALIZATION _________
    // const [multiCategoryTaginput, setmultiCategoryTaginput] = useState('');
    // const [multiCategoryTag, setmultiCategoryTag] = useState([]);
    // const [multiCategoryTagStatus, setmultiCategoryTagStatus] = useState(false);
    // const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([])

    // CALL GET API FOR getting TAGS
    // const TagCategoryMultiple = async (e) => {
    //   setmultiCategoryTaginput(e);
    //   if (e.length > 2) {
    //     const data = await CallWithAuth('GET', GET_CATEGORY + '?keyword=' + e);
    //     if (data.status && data.res.status === 200) {
    //       setmultiCategoryTag(data.res.data);
    //       if (data.res.data.length > 0) {
    //         setmultiCategoryTagStatus(true);
    //       } else {
    //         setmultiCategoryTagStatus(false);
    //       }
    //     }
    //   } else {
    //     setmultiCategoryTagStatus(false);
    //   }
    // };

    // select tags from options
    // const selectCategoryTag = (e) => {
    //   setmultiSelectedCategoryTag((current) => [...current, e]);
    //   setmultiCategoryTagStatus(false);
    //   setmultiCategoryTaginput('');
    // };

    // _________ AUTHIR TAG STATE INITIALIZATION _________
    const [multiAuthorTaginput, setmultiAuthorTaginput] = useState('');
    const [multiAuthorTag, setmultiAuthorTag] = useState([]);
    const [multiAuthorTagStatus, setmultiAuthorTagStatus] = useState(false);
    // const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([])

    // CALL GET API FOR getting TAGS
    const TagAuthorMultiple = async (e) => {
      setmultiAuthorTaginput(e);
      if (e.length > 2) {
        const data = await CallWithAuth('GET', GET_AUTHOR + '?keyword=' + e);
        if (data.status && data.res.status === 200) {
          setmultiAuthorTag(data.res.data);
          if (data.res.data.length > 0) {
            setmultiAuthorTagStatus(true);
          } else {
            setmultiAuthorTagStatus(false);
          }
        }
      } else {
        setmultiAuthorTagStatus(false);
      }
    };

    // select tags from options
    const selectAuthorTag = (e) => {
      setmultiSelectedAuthorTag((current) => [...current, e]);
      setmultiAuthorTagStatus(false);
      setmultiAuthorTaginput('');
    };

    // Get the current year
    const currentYear = new Date().getFullYear();
    // _________ YEAR USED-> STATE INITIALIZATION _________

    // Get the current year
    // const currentYear = new Date().getFullYear();

    // Initialize state for the selected year
    // const [selectedYear, setSelectedYear] = useState(currentYear);
    // const [selectedYearExclude, setSelectedYearExclude] = useState(currentYear);

    // Create an array of years from 2000 to 20 years after the current year
    const yearOptions = [];
    for (let year = 2000; year <= currentYear + 20; year++) {
      yearOptions.push(year);
    }

    // Event handler for changing the selected year
    const handleYearChangeUsed = (e) => {
      setSelectedYear(parseInt(e.target.value, 10));
    };

    // Event handler for changing the selected year
    const handleYearChangeExclude = (e) => {
      setSelectedYearExclude(parseInt(e.target.value, 10));
    };

    // _________ PRIMARY TAG STATE INITIALIZATION _________
    const [questionId, setquestionId] = useState('');
    const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([]);
    const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([]);
    const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([]);
    const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([]);
    const [sort, setSort] = useState('-created_at');
    // Initialize state for the selected year
    const [selectedYear, setSelectedYear] = useState(2000);
    const [selectedYearExclude, setSelectedYearExclude] = useState('');
    // _________ YEAR USED-> STATE INITIALIZATION _________

    // const [currentSort, setCurrentSort] = useState("")

    // ________ PAGINATION ________

    const [totalCount, setTotalCount] = useState(0);
    const [last, setLast] = useState(5);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextUrl, setNextUrl] = useState(null);
    const [prevUrl, setPrevUrl] = useState(null);
    const [inputPage, setInputPage] = useState(currentPage);
    // _________question bank id which is top dropdown _________
    const [questionBankId, setQuestionBankId] = useState(null);
    // ________ LOD _________
    const [lod, setLod] = useState('');
    // ________ category ________
    const [category, setCategory] = useState('');

    const [sortConfig, setSortConfig] = useState({
      field: 'created_at',
      direction: 'desc',
      sort: '-created_at',
    });

    const handleSort = (field) => {
      setSortConfig((prevConfig) => {
        let direction, sortValue;

        if (field === 'created_at') {
          // Toggle between ascending and descending for created_at
          if (prevConfig.field === field && prevConfig.direction === 'desc') {
            direction = 'asc';
            sortValue = field;
          } else {
            direction = 'desc';
            sortValue = `-${field}`;
          }
        } else {
          // General sorting logic for other fields
          direction = prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc';
          sortValue = direction === 'desc' ? `-${field}` : field;
        }

        setSort(sortValue);
        setSortConfig({ field, direction, sort: sortValue });

        // Reset pagination
        setCurrentPage(1);
        setInputPage(1);
        setOffset(0);
        setPrevUrl(null);
        setLast(15);

        return { field, direction, sort: sortValue };
      });
    };

    const getRotation = (field) => {
      if (sortConfig.field !== field) return 'rotate(0deg)';
      return sortConfig.direction === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)';
    };

    // Helper function to get icon color
    const getIconColor = (field) => {
      return sortConfig.field === field ? '#00ABFB' : '#999090';
    };
    // ________ PAGINATION ________

    useEffect(() => {
      // alert(totalCount)
      if (totalCount < 5) {
        setLast(totalCount);
      } else {
        setLast(5);
      }
    }, [totalCount]);
    // ________ PAGINATION ________

    // _________ALL question created listing data _________
    const [questionResults, setquestionResults] = useState([]);

    const QuestionListing = async () => {
      const url =
        GET_BROWSE_QUESTION +
        '?question_bank=' +
        questionBankId +
        '&category=' +
        category +
        '&subject_tags=' +
        multiselectedSubjectTag.map((item) => item.id).join(',') +
        '&free_tags=' +
        multiselectedFreeTag.map((item) => item.id).join(',') +
        '&question_authors=' +
        multiselectedAuthorTag.map((item) => item.id).join(',') +
        '&offset=' +
        offset +
        '&limit=' +
        limit +
        '&lod=' +
        lod +
        '&after=' +
        selectedYear +
        '&sort=' +
        sort +
        '&before=' +
        selectedYearExclude +
        '&question_ids=' +
        questionId;

      const response = await CallWithAuth('GET', url);
      if (response.status && response.res.status === 200) {
        setquestionResults(response.res.data.results);
        setTotalCount(response.res.data.count);
        setNextUrl(response.res.data.next);
        setPrevUrl(response.res.data.previous);
      }
    };

    useEffect(() => {
      QuestionListing();
    }, [questionBankId, questionId, offset, sort]);

    const ResetSelection = () => {
      setCategory('');
      setmultiSelectedSubjectTag([]);
      setmultiSelectedFreeTag([]);
      setmultiSelectedAuthorTag([]);
      setOffset(0);
      setLod('');
      setSelectedYear(currentYear);
      setSort('');
      setSelectedYearExclude(currentYear);
      setquestionId(0);
    };

    const [questionBank, setQuestionBank] = useState(null);
    const getQuestionBank = async () => {
      let permissionKey = testType === 1 ? '?test=true' : '';
      const GetData = await CallWithAuth('GET', GET_QUESTIONBANK + permissionKey);
      if (GetData.status && Array.isArray(GetData.res.data)) {
        setQuestionBank(GetData.res.data);
        // setQuestionBankId(GetData.res.data[0].id)
      }
    };

    useEffect(() => {
      getQuestionBank();
    }, []);
    useEffect(() => {
      dispatch(GetQuestionBankList('', ''));
    }, []);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(Get_All_LOD('', ''));
    }, []);
    const { lodList, categoryList } = useSelector((state) => state.GetAllData);

    // Selection

    const SelectQuestion = (e) => {
      if (selectIds?.includes(e)) {
        const newArray = selectIds.filter((item) => item !== e);
        SetSelectIds(newArray);
      } else {
        SetSelectIds((prevState) => [...prevState, e]);
      }
    };

    // useEffect(() => {
    //   if (Array.isArray(selectIds) && selectIds.length > 0) {
    //     alert("check 1")
    //     SetSelectedId(prevState => [...prevState, ...selectIds])
    //   } else {
    //     SetSelectedId([])
    //   }
    // }, [selectIds])

    // const [selectQuestionId, setselectQuestionId]= useState([])
    useEffect(() => {
      let selectQuestionIds = selectedIds.map((items, i) => (testType == 1 ? items.question_id : items.id));
      // setselectQuestionId(selectQuestionIds)
      SetSelectIds(selectQuestionIds);
    }, [selectedIds[0]]);

    // const AddToTestPaper = () => {
    //   const valuesToAdd = selectIds;
    //   SetSelectIds(prevState => [...prevState, ...valuesToAdd])
    //   setModal(false)
    // }

    const rowRef = useRef();

    const handleDeleteId = (id) => {
      const updatedSelectIds = selectIds.filter((item) => item !== id);
      SetSelectIds(updatedSelectIds);
    };

    const getQuestionbankStyle = () => {
      return {
        color: questionBankId ? 'black' : '#808080',
      };
    };
    const getLODStyle = () => {
      return {
        color: lod ? 'black' : '#808080',
      };
    };
    const getCategoryStyle = () => {
      return {
        color: category ? 'black' : '#808080',
      };
    };
    // Function to handle opening the preview modal
      const handleOpenModal = (items) => {
        if(testType === 1) {
        openPreviewModal(items, setSelectedQuestion, setIsPreviewopen);
        }
      };
    
      const handleCloseModal = () => {
        closePreviewModal(setIsPreviewopen, setIsDuplicatePreview, setIsModalOpen);
      };
        const handleDuplicateClick = (items) => {
          handleDuplicate(items, (dataToPass) => openModalHelper(dataToPass, setModalData, setIsModalOpen));
        };

        const onConfirmDuplicate = (
           questionBankId,
           questionId,
           questionCategory = null, // Default to null or any appropriate value
           QuestionListing = () => Promise.resolve(true), // Default to a no-op function
           MyQuestionListing = () => Promise.resolve(true) // Default to a no-op function
         ) => {
           handleConfirmDuplicate(
             questionBankId,
             questionId,
             setIsModalOpen,
             setIsPreviewopen,
             setIsDuplicatePreview,
             setactionId,
             toast,
             questionCategory,
             QuestionListing,
             MyQuestionListing
           );
         };

      const closeModal = () => {
          closeModalHelper(setIsModalOpen, setModalData, setIsDuplicatePreview);
        };

       const handleEdit = async (items) => {
          await editQuestionHandler(items, setIsPreviewopen, openModalMedium, setQuestionItem, navigate);
        };
      
    return (
      <>
      <div className={style1.filterModal}>
        <div className={style1.overlay}></div>
        <div className={style1.center_div_larger} style={{ overflow: 'hidden' }}>
          <div className={style.test_creation_form}>
            <div className={style.filterContainer}>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }} className={style.test_top_content_table}>
                <div className={style.leftItems}>
                  {testType === 0 && (
                    <p style={{ fontWeight: '600', fontSize: '18px' }}>Browsing questions for Group 1 - Section 1</p>
                  )}
                  {testType === 1 && (
                    <p style={{ fontWeight: '600', fontSize: '18px' }}>
                      Browsing questions for {sectionName || ''} {roundNo ? ' | ' + roundNo : ''}{' '}
                      {setName ? ' | ' + setName : ''}
                    </p>
                  )}
                  <div style={{ fontSize: '13px' }} className={style.handleAttempt}>
                    <span>{selectIds.length} </span>
                    <span style={{ color: 'lightgray', paddingRight: '5px' }}>/{totalQuestion}</span>
                    <span>Questions already added</span>
                  </div>
                </div>
                <div className={style.table_filter_right_options}>
                  <svg
                    onClick={() => setModal(false)}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.35352 6.34375L17.684 17.6575"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.35216 17.6575L17.6826 6.34375"
                      stroke="#212529"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div className={style.masterfilterCreation}>
                <div className={style.filterCreationArea}>
                  {/* filters  */}
                  <div className={style.sectionContainer}>
                    <section className={style.workArea1}>
                      <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Question Bank </p>
                          <div className={style.header_drop}>
                            <select
                              name="question"
                              id="question"
                              style={getQuestionbankStyle()}
                              onClick={(e) => setQuestionBankId(Number(e.target.value))}
                            >
                              <option value={null} hidden>
                                Select question bank
                              </option>
                              {questionBank?.map((items, i) => (
                                <option key={i} value={items.id}>
                                  {items.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* subject tag  */}
                      <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Subject | Area | Topic | Subtopic </p>
                          <div className={style.search_listing} style={{ height: 'auto' }}>
                            <div className={style.tag_icons}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="20" height="20" rx="4" fill="#272727" />
                                <path
                                  d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                  fill="white"
                                />
                              </svg>
                            </div>

                            <div className={style.input_search} ref={rowRef}>
                              <div className={style.subjectWrap}>
                                {multiselectedSubjectTag?.map((items, i) => (
                                  <>
                                    <span className={style.selected_tags}>
                                      {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                                    </span>
                                  </>
                                ))}
                              </div>

                              <input
                                type="search"
                                id="searchInput"
                                placeholder="Search tags"
                                value={multisubjectTaginput}
                                onChange={(e) => TagSubjectMultiple(e.target.value)}
                                style={{ margin: '5px', padding: '10px' }}
                              />

                              {multisubjectTagStatus ? (
                                <div className={style.list_input_primary}>
                                  {multisubjectTag
                                    ?.filter(
                                      (item) => !multiselectedSubjectTag.some((filterItem) => item.id === filterItem.id)
                                    )
                                    .map((items, i) => (
                                      <>
                                        <div onClick={() => selectSubjectTag(items)}>
                                          <span>
                                            {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                                          </span>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>

                            <div className={style.cancel_button}>
                              <button onClick={() => setmultiSelectedSubjectTag([])}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.75781 7.75781L16.2431 16.2431"
                                    stroke="#272727"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.75691 16.2431L16.2422 7.75781"
                                    stroke="#272727"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* category and tag  */}
                      <div className={style.drop}>
                        {/* <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Category </p>
                          <div className={style.search_listing}>
                            <div className={style.tag_icons}>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="4" fill="#272727" />
                                <path d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z" fill="white" />
                              </svg>


                            </div>

                            <div className={style.input_search}>

                              {multiselectedCategoryTag?.map((items, i) => (
                                <>
                                  <span className={style.selected_tags}>{items.name}  </span>
                                </>
                              ))}

                              <input type='search' id='searchInput' placeholder='' value={multiCategoryTaginput} onChange={(e) => TagCategoryMultiple(e.target.value)} />


                              {multiCategoryTagStatus ?
                                <div className={style.list_input_primary}>
                                  {multiCategoryTag?.filter(item => !multiselectedCategoryTag.some(filterItem => item.id === filterItem.id)).map((items, i) => (
                                    <>
                                      <div onClick={() => selectCategoryTag(items)}><span>{items.name}  </span></div>
                                    </>
                                  ))}
                                </div>
                                : ""}

                            </div>

                            <div className={style.cancel_button}>
                              <button onClick={() => setmultiSelectedCategoryTag([])}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#272727" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#272727" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </button>
                            </div>

                          </div>

                        </div>
                      </div> */}

                        <div className={style.tags}>
                          <div className={style.subject}>
                            <p>Difficulty Level</p>
                            <div className={style.header_drop}>
                              <select
                                name="question"
                                id="question"
                                style={getLODStyle()}
                                onClick={(e) => setLod(Number(e.target.value))}
                              >
                                <option value={null} hidden>
                                  Select Difficulty
                                </option>
                                {lodList?.map((items, i) => (
                                  <option key={i} value={items.id}>
                                    {items.level}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className={style.tags}>
                          <div className={style.subject}>
                            <p>Category </p>
                            <div className={style.header_drop}>
                              <select
                                name="question"
                                id="question"
                                style={getCategoryStyle()}
                                onClick={(e) => setCategory(Number(e.target.value))}
                              >
                                <option value={null} hidden>
                                  Select Category
                                </option>
                                {categoryList?.map((items, i) => (
                                  <option key={i} value={items.id}>
                                    {items.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={style.drop}>
                        <div className={style.drop_item}>
                          <div className={style.subject}>
                            <p className={style.subjectLabel}>Include questions used in or after </p>
                            <select
                              name="question"
                              id="question"
                              className={style.header_drop_filter}
                              value={selectedYear}
                              onChange={handleYearChangeUsed}
                            >
                              {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className={style.drop_item}>
                          <div className={style.subject}>
                            <p className={style.subjectLabel}>Include question used in or before </p>
                            <select
                              name="question"
                              id="question"
                              className={style.header_drop_filter}
                              value={selectedYearExclude}
                              onChange={handleYearChangeExclude}
                            >
                              {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* free tag  */}
                      <div className={style.drop}>
                        <div className={style.tags} style={{ width: '100%' }}>
                          <div className={style.subject}>
                            <p>Tags </p>
                            <div className={style.search_listing} style={{ height: 'auto' }}>
                              <div className={style.tag_icons}>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect width="20" height="20" rx="4" fill="#272727" />
                                  <path
                                    d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>

                              <div className={style.input_search}>
                                <div className={style.tagsWrap}>
                                  {' '}
                                  {multiselectedFreeTag?.map((items, i) => (
                                    <>
                                      <span className={style.selected_tags}>{items.name} </span>
                                    </>
                                  ))}
                                </div>

                                <input
                                  type="search"
                                  id="searchInput"
                                  placeholder="Search free-form tags"
                                  value={multiFreeTaginput}
                                  style={{ margin: '5px', padding: '10px' }}
                                  onChange={(e) => TagFreeMultiple(e.target.value)}
                                />

                                {multiFreeTagStatus ? (
                                  <div className={style.list_input_primary}>
                                    {multiFreeTag
                                      ?.filter(
                                        (item) => !multiselectedFreeTag.some((filterItem) => item.id === filterItem.id)
                                      )
                                      .map((items, i) => (
                                        <>
                                          <div onClick={() => selectFreeTag(items)}>
                                            <span>{items.name} </span>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>

                              <div className={style.cancel_button}>
                                <button onClick={() => setmultiSelectedFreeTag([])}>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.75781 7.75781L16.2431 16.2431"
                                      stroke="#272727"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.75691 16.2431L16.2422 7.75781"
                                      stroke="#272727"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* question author  */}
                      <div className={style.tags}>
                        <div className={style.subject}>
                          <p>Question Author(s) </p>
                          <div className={style.search_listing} style={{ height: 'auto' }}>
                            <div className={style.tag_icons}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect width="20" height="20" rx="4" fill="#272727" />
                                <path
                                  d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                  fill="white"
                                />
                              </svg>
                            </div>

                            <div className={style.input_search}>
                              <div className={style.QuestionAuthWrap}>
                                {multiselectedAuthorTag?.map((items, i) => (
                                  <>
                                    <span className={style.selected_tags}>
                                      {items.first_name}-{items.last_name}{' '}
                                    </span>
                                  </>
                                ))}
                              </div>

                              <input
                                type="search"
                                id="searchInput"
                                placeholder="Search authors"
                                value={multiAuthorTaginput}
                                style={{ margin: '5px', padding: '10px' }}
                                onChange={(e) => TagAuthorMultiple(e.target.value)}
                              />

                              {multiAuthorTagStatus ? (
                                <div className={style.list_input_primary}>
                                  {multiAuthorTag
                                    ?.filter(
                                      (item) => !multiselectedAuthorTag.some((filterItem) => item.id === filterItem.id)
                                    )
                                    .map((items, i) => (
                                      <>
                                        <div onClick={() => selectAuthorTag(items)}>
                                          <span>
                                            {items.first_name}-{items.last_name}{' '}
                                          </span>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>

                            <div className={style.cancel_button}>
                              <button onClick={() => setmultiSelectedAuthorTag([])}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.75781 7.75781L16.2431 16.2431"
                                    stroke="#272727"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.75691 16.2431L16.2422 7.75781"
                                    stroke="#272727"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className={style.workArea2}>
                      <span style={{ fontWeight: '500', fontSize: '13px' }}>Question ID(s) </span>

                      <div className={` ${style.filter_listing} ${style.id_filter_listing}`}>
                        <div className={style.filterSearch}>
                          <svg
                            style={{ marginTop: '13px', marginLeft: '10px' }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#272727" />
                            <path
                              d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                              fill="white"
                            />
                          </svg>
                          <input
                            type="search"
                            id="searchInput"
                            placeholder="Search Question ID(s)"
                            onChange={(e) => setquestionId(e.target.value)}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                  {/* button  */}
                  <div className={style.performBT}>
                    <button className={` ${style.btOne} ${style.common}`} onClick={() => ResetSelection()}>
                      Reset Selection
                    </button>
                    <button className={` ${style.btTwo} ${style.common}`} onClick={() => QuestionListing()}>
                      Apply Selected Filter
                    </button>
                  </div>
                  {/*  ________________________________ question ________________________________ */}
                  <div className={style.questionBank}>
                    <div className={style.headerBank}>
                      <h4 style={{ fontWeight: '500', margin: '15px', fontSize: '18px' }}>Question List</h4>
                    </div>

                    {/* ________________ question ________________  */}
                    <div className={style.sub_head_content_table}>
                      <table>
                        <thead>
                          <tr>
                            <th className={` ${style.very_first} ${style.pointer}`}>S.No</th>
                            <th className={` ${style.very_first} ${style.pointer}`}>Question ID and Tags</th>

                            <th className={` ${style.second} ${style.pointer}`}>
                              {' '}
                              <span>Author</span>
                              <svg
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSort('created_by__email');
                                }}
                                width="19"
                                height="12"
                                viewBox="0 0 19 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  marginLeft: '6px',
                                  transform: getRotation('created_by__email'),
                                  transition: 'transform 0.3s ease',
                                }}
                              >
                                <path
                                  d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                  fill={getIconColor('created_by__email')}
                                />
                                <path
                                  d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                  fill={getIconColor('created_by__email')}
                                />
                                <path
                                  d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                  fill={getIconColor('created_by__email')}
                                />
                                <path
                                  d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                  fill={getIconColor('created_by__email')}
                                />
                              </svg>
                            </th>
                            {testType === 1 && (
                              <th className={` ${style.third} ${style.pointer}`}>
                                <span>Academic Year</span>
                                <svg
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSort('academic_year');
                                  }}
                                  width="19"
                                  height="12"
                                  viewBox="0 0 19 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    marginLeft: '6px',
                                    transform: getRotation('academic_year'),
                                    transition: 'transform 0.3s ease',
                                  }}
                                >
                                  <path
                                    d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                    fill={getIconColor('academic_year')}
                                  />
                                  <path
                                    d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                    fill={getIconColor('academic_year')}
                                  />
                                  <path
                                    d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                    fill={getIconColor('academic_year')}
                                  />
                                  <path
                                    d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                    fill={getIconColor('academic_year')}
                                  />
                                </svg>
                              </th>
                            )}
                            <th className={` ${style.third} ${style.pointer}`}>
                              <span>Created On</span>
                              <svg
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSort('created_at');
                                }}
                                width="19"
                                height="12"
                                viewBox="0 0 19 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  marginLeft: '6px',
                                  transform: getRotation('created_at'),
                                  transition: 'transform 0.3s ease',
                                }}
                              >
                                <path
                                  d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                  fill={getIconColor('created_at')}
                                />
                                <path
                                  d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                  fill={getIconColor('created_at')}
                                />
                                <path
                                  d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                  fill={getIconColor('created_at')}
                                />
                                <path
                                  d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                  fill={getIconColor('created_at')}
                                />
                              </svg>
                            </th>
                            <th className={` ${style.fourth} ${style.pointer}`}>
                              <span>Type</span>
                              <svg
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSort('question_type__category');
                                }}
                                width="19"
                                height="12"
                                viewBox="0 0 19 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  marginLeft: '6px',
                                  transform: getRotation('question_type__category'),
                                  transition: 'transform 0.3s ease',
                                }}
                              >
                                <path
                                  d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                  fill={getIconColor('question_type__category')}
                                />
                                <path
                                  d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                  fill={getIconColor('question_type__category')}
                                />
                                <path
                                  d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                  fill={getIconColor('question_type__category')}
                                />
                                <path
                                  d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                  fill={getIconColor('question_type__category')}
                                />
                              </svg>
                            </th>
                            <th className={` ${style.fourth} ${style.pointer}`}>
                              <span>LOD</span>
                              <svg
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSort('difficulty__level');
                                }}
                                width="19"
                                height="12"
                                viewBox="0 0 19 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  marginLeft: '6px',
                                  transform: getRotation('difficulty__level'),
                                  transition: 'transform 0.3s ease',
                                }}
                              >
                                <path
                                  d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                                  fill={getIconColor('difficulty__level')}
                                />
                                <path
                                  d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                                  fill={getIconColor('difficulty__level')}
                                />
                                <path
                                  d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                                  fill={getIconColor('difficulty__level')}
                                />
                                <path
                                  d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                                  fill={getIconColor('difficulty__level')}
                                />
                              </svg>
                            </th>

                            <th className={` ${style.fifth} ${style.pointer} ${style.filter_th}`}>
                              {testType !== 1 && <span>Select</span>}
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                  fill="url(#paint0_linear_1445_50173)"
                                />
                                <path
                                  d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_1445_50173"
                                    x1="20.9141"
                                    y1="0"
                                    x2="6.81222"
                                    y2="16.4928"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#9BF763" />
                                    <stop offset="1" stopColor="#25AB5B" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        {/* ALL AND MY QUESTION according to ternary operator */}
                        <tbody>
                          {questionResults?.map((items, i) => (
                            <tr key={i} className={style.table_body_content}>
                              <td className={style.srNum} style={{ paddingLeft: '26px' }}>
                                {i + 1}
                              </td>
                              <td>
                                <div className={`${style.question_id_tag} ${style.browseQuestionTags}`}>
                                  <div className={style.id} onClick={() => handleOpenModal(items)}>{items.question_id}</div>
                                  <div className={style.tag}>
                                    <p
                                      title={
                                        items.question_short_identifier.length > 30
                                          ? items.question_short_identifier
                                          : ''
                                      }
                                      style={{
                                        color: 'black',
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                        lineHeight: '1.2',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {items.question_short_identifier.length > 30
                                        ? items.question_short_identifier.slice(0, 30) + '...'
                                        : items.question_short_identifier}
                                    </p>
                                    <span style={{ padding: '4px 12px', display: 'inline-flex' }}>
                                      {items.primary_tag.slug}
                                    </span>
                                    {testType === 1 && (
                                      <div>
                                        {items?.free_tags?.slice(0, 2).map((item, i) => (
                                          <button key={i} className={style.general_button}>
                                            {item.name}
                                          </button>
                                        ))}
                                        {items?.free_tags?.length > 2 && (
                                          <button className={style.general_button}>
                                            +{items?.free_tags?.length - 2}
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className={`${style.author_tag} ${style.authorTag}`}>{items.author}</div>
                              </td>
                              {testType === 1 && (
                                <td>
                                  <div className={style.academicYear}>{items.academic_year}</div>
                                </td>
                              )}
                              <td>
                                <div className={style.created_date}>
                                  {' '}
                                  {moment(items.created_on).format('Do MMM YYYY')}
                                </div>
                              </td>
                              <td>
                                <div className={style.testType}> {items.type}</div>
                              </td>
                              <td>
                                <div className={style.lod}> {items.lod}</div>
                              </td>
                              <td>
                                {selectIds.length >= totalQuestion ? (
                                  ''
                                ) : (
                                  <div
                                    style={{ border: '1px solid', height: '20px', width: '20px', borderRadius: '6px', cursor: 'pointer' }}
                                    onClick={() => SelectQuestion(items?.question_id)}
                                  >
                                    {selectIds?.includes(items?.question_id) ? (
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.91406 20H13.9141C18.9141 20 20.9141 18 20.9141 13V7C20.9141 2 18.9141 0 13.9141 0H7.91406C2.91406 0 0.914062 2 0.914062 7V13C0.914062 18 2.91406 20 7.91406 20Z"
                                          fill="url(#paint0_linear_1445_50173)"
                                        />
                                        <path
                                          d="M6.66406 10.0019L9.49406 12.8319L15.1641 7.17188"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <defs>
                                          <linearGradient
                                            id="paint0_linear_1445_50173"
                                            x1="20.9141"
                                            y1="0"
                                            x2="6.81222"
                                            y2="16.4928"
                                            gradientUnits="userSpaceOnUse"
                                          >
                                            <stop stopColor="#9BF763" />
                                            <stop offset="1" stopColor="#25AB5B" />
                                          </linearGradient>
                                        </defs>
                                      </svg>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* ________________ footer ________________ */}
                    <footer className={style.sectionFooter} style={{ marginTop: '20px' }}>
                      <div className={style.footer_content}>
                        <span style={{ fontWeight: '500', marginRight: '5px' }}>Selected Questions</span>
                        <span>
                          {selectIds?.length} <b style={{ color: 'lightgray' }}> / {totalQuestion}</b>
                        </span>
                      </div>

                      <div className={`${style.footer_content_mid} ${style.footer_content_last} `}>
                        {selectIds?.map((items, i) => (
                          <span key={i} className={style.closeIds}>
                            {items}
                            {testType === 1 && (
                              <img
                                src={deleteId}
                                className={style.iconInfo}
                                style={{ marginLeft: '3px' }}
                                alt="delete"
                                onClick={() => handleDeleteId(items)}
                              />
                            )}
                          </span>
                        ))}
                      </div>

                      <div className={style.footer_content_right}>
                        <div
                          style={{ marginRight: '20px' }}
                          className={style.drop_score}
                          onClick={() => AddToTestPaper()}
                        >
                          Add To Test Paper
                        </div>
                      </div>
                    </footer>

                    {/*______________________________ PAGINATION ______________________________  */}
                    <div className={style.testPaperPagination}>
                      <Pagination
                        totalCount={totalCount}
                        limit={limit}
                        currentPage={currentPage}
                        inputPage={inputPage}
                        offset={offset}
                        last={last}
                        nextUrl={nextUrl}
                        prevUrl={prevUrl}
                        setOffset={setOffset}
                        setCurrentPage={setCurrentPage}
                        setInputPage={setInputPage}
                        setLast={setLast}
                      />
                    </div>
                    {/*______________________________ PAGINATION ______________________________  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviewModal
        onEdit={handleEdit}
        questionAccess={questionAccess}
        selectedQuestion={selectedQuestion}
        isOpen={isPreviewopen}
        onClose={handleCloseModal}
        setIsDuplicateModalOpen={setIsModalOpen}
        setModalData={setModalData}
        modalData={modalData}
        setIsDuplicatePreview={setIsDuplicatePreview}
        iframeSrc="/question-content-url"
      />
      <QuestionListModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={onConfirmDuplicate}
        data={modalData} // Pass modalData to the modal
        isDuplicatePreview={isDuplicatePreview}
      />
      </>
    );
  }
);

BrowsingQuestion.propTypes = {
  AddToTestPaper: PropTypes.func,
  SetSelectIds: PropTypes.func,
  selectIds: PropTypes.shape({
    filter: PropTypes.func,
    includes: PropTypes.func,
    length: PropTypes.any,
    map: PropTypes.func,
  }),
  selectedIds: PropTypes.shape({
    map: PropTypes.func,
  }),
  setModal: PropTypes.func,
  totalQuestion: PropTypes.any,
  testType: PropTypes.any,
  sectionName: PropTypes.any,
  roundNo: PropTypes.any,
  setName: PropTypes.any,
};

export default BrowsingQuestion;
