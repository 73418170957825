import PropTypes from "prop-types"
import React from 'react';
import style from '../../assets/styles/userManagement.module.css';
import AuthLayout from "../../layout/AuthLayout";
import RightPanelTopMenu from "../molecule/common/RightPanelTopMenu";

// eslint-disable-next-line react/prop-types
const PageLayout = ({ children,title }) => {
  return (
    <AuthLayout>
      {/* rightpannel top left menu according to different screen  */}
      <RightPanelTopMenu setQuestionBankId={null} title={title} />
      {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
      <div className={style.question_bank_table_top}>{children}</div>
    </AuthLayout>
  );
};

PageLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any
}

export default PageLayout;
