/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';
import style from '../../../assets/styles/pagination.module.css';
import { debounce } from '../../atoms/debounce';
interface PaginationProps {
  totalCount: number;
  limit: number;
  currentPage: any;
  inputPage: any;
  offset: number;
  last: number;
  nextUrl: string | null;
  prevUrl: string | null;
  propsStyle?: any;
  // eslint-disable-next-line no-unused-vars
  setOffset: (offset: number) => void;
  // eslint-disable-next-line no-unused-vars
  setCurrentPage: (page: any) => void;
  // eslint-disable-next-line no-unused-vars
  setInputPage: (page: any) => void;
  // eslint-disable-next-line no-unused-vars
  setLast: (last: number) => void;
  setNextUrl?: (last: any) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  limit,
  currentPage,
  inputPage,
  offset,
  last,
  nextUrl,
  prevUrl,
  setOffset,
  setCurrentPage,
  setInputPage,
  setLast,
  propsStyle,
  setNextUrl,
}) => {
  const [error, setError] = useState('');
  const setToLastPage = () => {
    const lastPage = Math.ceil(totalCount / limit);
    setInputPage(lastPage);
    setCurrentPage(lastPage);
    setOffset((lastPage - 1) * limit);
    const newLastCount = totalCount ;
    setLast(newLastCount);
    setError('');
  };
  useEffect(() => {
    if (last > totalCount && totalCount > 0) {
      setLast(totalCount);
    
    }
    // if(offset>totalCount&&totalCount>0){
    //   setNextUrl && setNextUrl(null);
    // }
  }, [totalCount, last,offset]);
  const handlePageInputBlur = () => {
    // If input is empty, set to last page
    if (inputPage === '' || inputPage === null) {
      setToLastPage();
      return;
    }

    const lastPage = Math.ceil(totalCount / limit);
    if (inputPage > lastPage) {
      setError('Page Limit exceed');
      setTimeout(() => {
        setToLastPage();
      }, 1500);
    } else {
      setInputPage(inputPage);
      setCurrentPage(inputPage);
      debouncedHandleChange(inputPage);
      setError('');
    }
  };

  const handleNextPage = () => {
    const newOffset = offset + limit;
    if (nextUrl) {
      setOffset(newOffset);
      if (currentPage !== null) {
        const newPage = currentPage + 1;
        setCurrentPage(newPage);
        setInputPage(newPage);
        // Clear error if new page is valid
        if (newPage <= Math.ceil(totalCount / limit)) {
          setError('');
        }
      }
      const newLastCount = Math.min(newOffset + (limit??20), totalCount);
      setLast(newLastCount);
    }
  };

  const handlePrevPage = () => {
    if (prevUrl) {
      setOffset(offset - limit);
      if (currentPage !== null) {
        const newPage = currentPage - 1;
        setCurrentPage(newPage);
        setInputPage(newPage);
        // Clear error if new page is valid
        if (newPage >= 1) {
          setError('');
        }
      }
      setLast(offset);
    }
  };

  const handleChange = (inputValue: any) => {
    // If input is empty, set to last page
    if (inputValue === '' || inputValue === null) {
      setInputPage('');
      setTimeout(() => {
        setToLastPage();
      }, 1500);
      return;
    }
    if (!isNaN(inputValue) && inputValue > 0) {
      const lastPage = Math.ceil(totalCount / limit);
      if (inputValue > lastPage) {
        setError('Page Limit exceed');
        setTimeout(() => {
          setToLastPage();
        }, 1500);
      } else {
        setCurrentPage(inputValue);
        setOffset((inputValue - 1) * limit);
        setLast(offset);
        const newLastCount = Math.min(inputValue * limit, totalCount);
        setLast(newLastCount);
        setError('');
      }
    } else {
      setError('Page Limit exceed');
    }
  };
  // Use useCallback to memoize the debounced function
  const debouncedHandleChange = useCallback(
    debounce((inputValue) => {
      handleChange(inputValue);
    }, 1000),
    [totalCount, limit]
  );

  const handlePageInputChange = (e: any) => {
    const value = e.target.value;
    const inputValue = parseInt(value);

    // If input is empty or not a valid number, set to empty string
    if (isNaN(inputValue)) {
      setInputPage('');
      return;
    }
    if (inputValue > Math.ceil(totalCount / limit)&&inputValue>0) {
      setError('Page Limit exceed');
    } else {
      setError('');
    }
    // Valid page number
    if(inputValue>0){

      setInputPage(inputValue);
    }else{
      setInputPage(1)
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // Prevent the default action if inside a form
      event.preventDefault();
      handlePageInputBlur();
    }
  };

  return (
    <div style={propsStyle} className={style.pagination}>
      <div className={style.number}>
        {error ? <span style={{ color: 'red' }}>{error}</span> : "The page you're on"}
        &nbsp; &nbsp;
        <input
          type="number"
          value={inputPage}
          onChange={handlePageInputChange}
          onBlur={handlePageInputBlur}
          onKeyDown={handleKeyDown}
          min="1"
          max={Math.ceil(totalCount / limit)}
          className={style.currentpage}
        />
      </div>
      <div className={style.line}>
        <svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line opacity="0.1" x1="0.695312" y1="23.5" x2="0.695311" y2="0.5" stroke="#764FDB" strokeLinecap="round" />
        </svg>
      </div>
      <div className={style.change}>
        <div className={style.changeButton}>
          <button
            className={style.prevButton}
            data-testid="prev-button"
            onClick={handlePrevPage}
            disabled={prevUrl === null}
          />
        </div>
        <div className={style.current}>
          {offset + 1}-{last} of {totalCount}
        </div>
        <div className={style.changeButton}>
          <button
            data-testid="next-button"
            className={style.nextButton}
            onClick={handleNextPage}
            disabled={nextUrl === null}
          />
        </div>
      </div>
    </div>
  );
};
