import { GET_QUESTION_BANK } from '../../../action/apiPath';
import { CallWithAuth } from '../../../action/apiActions';

export const handleConfirmDuplicate = async (
  questionBankId,
  questionId,
  setIsModalOpen,
  setIsPreviewopen,
  setIsDuplicatePreview,
  setactionId,
  toast,
  questionCategory,
  QuestionListing,
  MyQuestionListing
) => {
  // Construct the payload for the API
  const body = {
    question_id: questionId,
    question_bank_id: questionBankId,
  };

  try {
    const duplicte_data = await CallWithAuth('POST', GET_QUESTION_BANK + 'question-duplicate/', body);
    if (duplicte_data.status && duplicte_data.res.data.status === 200) {
      const successMessage = duplicte_data.res.data.message;
      if (toast) {
        toast.remove('all');
        toast.add(successMessage, 'success');
      }

      // Update states and close modal
      setIsModalOpen(false);
      setIsPreviewopen(false);
      setIsDuplicatePreview(false);

      // Call the appropriate listing function based on questionCategory
      if (questionCategory) {
        QuestionListing();
      } else {
        MyQuestionListing();
      }

      setactionId(null);
    } else if (duplicte_data.res.status === 400) {
      const errorMessage =
        duplicte_data.res.data.error || 'Access denied, you do not have permission to access source question bank';
      if (toast) {
        toast.remove('all');
        toast.add(errorMessage, 'error');
      }
    }
  } catch (error) {
    if (toast) {
      toast.remove('all');
      toast.add('An error occurred during duplication. Please try again.', 'error');
    }
  }
};
