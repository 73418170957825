import React, { useCallback, useState, useRef, useEffect } from 'react';
import { CallWithAuth } from '../../../../action/apiActions';
import { CREATE_SUBJECT_TAG, GET_SUBJECT_TAG } from '../../../../action/apiPath';
import style from '../../../../assets/styles/addNewPipelines.module.css';
import { Get_All_Subject_pipeline } from '../../../../redux/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SlArrowLeft } from 'react-icons/sl';
import AuthLayout from '../../../../layout/AuthLayout';
import RightPanelTopMenu from '../../common/RightPanelTopMenu';
import { useToast } from '../../common/Toast';

const AddNewPipeline = () => {
  const rowRef = useRef();
  const dispatch = useDispatch();
  // ------------ Subject ------------
  const [subjectTag, setSubjectTag] = useState([]);
  const [selectedPrimaryTagStatus] = useState(false);
  const [subjectTagStatus, setSubjectTagStatus] = useState(false);
  const [selectedPrimaryTag, setSelectedPrimaryTag] = useState('');
  const [subjectData, setSubjectData] = useState(selectedPrimaryTag);
  const [validationErrorSubject, setValidationErrorSubject] = useState('');
  const [validationErrorTopic, setValidationErrorTopic] = useState('');
  const [validationErrorSubTopic, setValidationErrorSubTopic] = useState('');
  const [validationErrorArea, setValidationErrorArea] = useState('');
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [isButtonDiable] = useState(false);

  //  ----------- Description Sub-Topic -----------
  const [descriptionSubTopic, setDescriptionSubTopic] = useState('');
  const Toast = useToast();
  const closeModal = () => {
    navigate('/masters');
  };
  const ClearFieldModel = () => {
    setSubjectData('');
    setTopicData('');
    setAreaData('');
    setMultiSubTopicTaginput('');
    setDescriptionSubTopic('');
    setMultiselectedSubTopicTag([]);
  };
  const TagSubject = async (e) => {
    setSubjectData(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-subject/' + '?subject=' + e);
      if (data.status && data.res.status === 200) {
        setSubjectTag(data.res.data);
        if (data.res.data.length > 0) {
          setSubjectTagStatus(true);
        } else {
          setSubjectTagStatus(false);
        }
      }
    } else {
      setSubjectTagStatus(false);
    }
  };

  const selectPrimaryTag = (e) => {
    if (!subjectData) {
      setValidationError('Please provide the subject field');
      return;
    }
    setSubjectData(e);
    setSelectedPrimaryTag(e);
    setSubjectTagStatus(false);
  };

  const handleInputChange = (e) => {
    setSubjectData(e.target.value);
    if (e.target.value.length > 2) {
      // Fetch suggestions again
      TagSubject(e.target.value);
    }
  };
  // ----------- Topic --------------
  const [topicTag, setTopicTag] = useState([]);
  const [selectedTopicTagStatus] = useState(false);
  const [topicTagStatus, setTopicTagStatus] = useState(false);
  const [selectedTopicTag, setSelectedTopicTag] = useState('');
  const [topicData, setTopicData] = useState(selectedTopicTag);

  const TagTopic = async (e) => {
    setTopicData(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-topic/' + '?topic=' + e);
      if (data.status && data.res.status === 200) {
        setTopicTag(data.res.data);
        if (data.res.data.length > 0) {
          setTopicTagStatus(true);
        } else {
          setTopicTagStatus(false);
        }
      }
    } else {
      setTopicTagStatus(false);
    }
  };

  // select tags from options
  const selectTopicTag = (e) => {
    setSelectedTopicTag(e);
    setTopicData(e);
    setTopicTagStatus(false);
  };

  const handleTopicInputChange = (e) => {
    const value = e.target.value;
    setTopicData(value); // Update topicData with user input
    TagTopic(value); // Fetch relevant topics based on the input value
  };
  // ------------- Area ----------------
  const [areaTag, setAreaTag] = useState([]);
  const [selectedAreaTagStatus, setSelectedAreaTagStatus] = useState(false);
  const [areaTagStatus, setAreaTagStatus] = useState(false);
  const [selectedAreaTag, setSelectedAreaTag] = useState('');
  const [areaData, setAreaData] = useState('');

  // Fetch area tags based on user input
  const TagArea = async (e) => {
    setAreaData(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-area/' + '?area=' + e);
      if (data.status && data.res.status === 200) {
        setAreaTag(data.res.data);
        setAreaTagStatus(data.res.data.length > 0);
      }
    } else {
      setAreaTagStatus(false);
    }
  };

  // Select an area tag from the dropdown
  const selectAreaTag = (e) => {
    setAreaData(e);
    setSelectedAreaTag(e);
    setAreaTagStatus(false);
  };

  // Handle manual area input changes
  const handleManualAreaInput = (e) => {
    setAreaData(e.target.value);
    setSelectedAreaTagStatus(false);
    TagArea(e.target.value);
  };

  // Handle manual subtopic input changes
  const handleSubTopicInput = (e) => {
    const value = e.target.value;
    setMultiSubTopicTaginput(value);
    // Set new timeout for API call
    setTimeout(() => {
      TagSubTopicMultiple(value);
    }, 300); // 300ms delay
  };
  //  ----------------- Sub-Topic ------------
  const [multiselectedSubTopicTag, setMultiselectedSubTopicTag] = useState([]);
  const [multiSubTopicTag, setMultiSubTopicTag] = useState([]);
  const [multiSubTopicTagStatus, setMultiSubTopicTagStatus] = useState(false);
  const [multiSubTopicTaginput, setMultiSubTopicTaginput] = useState(multiSubTopicTag);

  // CALL GET API FOR getting TAGS
  const TagSubTopicMultiple = useCallback(async (value) => {
    if (value.length > 2) {
      try {
        const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-subtopic/' + '?subtopic=' + value);
        if (data.status && data.res.status === 200) {
          if (data.res.data.length > 0) {
            setMultiSubTopicTag(data.res.data);
            setMultiSubTopicTagStatus(true);
          } else {
            setMultiSubTopicTagStatus(false);
          }
        }
      } catch (error) {
        setMultiSubTopicTagStatus(false);
      }
    } else {
      setMultiSubTopicTagStatus(false);
    }
  }, []);

  const selectSubTopicTag = (e) => {
    setMultiselectedSubTopicTag((current) => {
      if (!current.includes(e)) {
        return [...current, e];
      }
      return current;
    });
    setMultiSubTopicTagStatus(false);
    setMultiSubTopicTaginput('');
  };

  const modalRef = useRef();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/masters');
  };

  const AddNew = async () => {
    try {
      const body = {
        subject: subjectData,
        area: areaData,
        topic: topicData,
        subtopic: multiselectedSubTopicTag ?? [],
        subtopic_description: descriptionSubTopic,
      };

      // Making the API call
      const { res: data } = await CallWithAuth('POST', CREATE_SUBJECT_TAG, body);
      if (data.data.status || (data && data.length > 0)) {
        if (Toast) {
          Toast.remove('all');
          Toast.add(String(data.data.message), 'Subject Tags have been created successfully!');
        }
        closeModal();
        dispatch(Get_All_Subject_pipeline('', '', 15, 1));
        handleBack();
      } else if (data?.data) {
        const keys = Object.keys(data.data);
        const firstErrorKey = keys[0];
        if (Toast) {
          Toast.remove('all');
          Toast.add(String(`${keys[0]} : ${data?.data[firstErrorKey][0]}`), 'error');
        }
      }
    } catch (error) {
      alert('There was an error with the request.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const data = [...multiselectedSubTopicTag, e.target.value]?.filter(
        (value, index, self) => index === self.findIndex((t) => t.toLowerCase() === value.toLowerCase())
      );
      setMultiselectedSubTopicTag(data);
      setMultiSubTopicTaginput('');
    }
  };

  const removeTag = (index) => {
    const updatedTags = multiselectedSubTopicTag.filter((_, i) => i !== index);
    setMultiselectedSubTopicTag(updatedTags);
  };
  const handleRowClick = (event) => {
    if (rowRef.current && !rowRef.current.contains(event.target)) {
      // Check if the click is within the action buttons
      const isActionButtonClick = event.target.closest('.action_buttons');

      if (!isActionButtonClick) {
        setAreaTagStatus(false);
        setMultiSubTopicTagStatus(false);
        setTopicTagStatus(false);
        setSubjectTagStatus(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleRowClick);
    return () => {
      document.removeEventListener('mousedown', handleRowClick);
    };
  }, [handleRowClick]);
  return (
    <AuthLayout>
      <RightPanelTopMenu />
      <div
        className={`${style.filterModel} ${style.addPipelinefilterModel}`}
        style={{ overflowY: 'scroll', height: '85vh' }}
      >
        <div className={style.top_title}>
          {/* __________ close __________  */}
          <div className={style.back}>
            <button className={style.back_button} onClick={() => handleBack()}>
              <span className={style.arrow}>
                <SlArrowLeft style={{ fontWeight: '900' }} />
              </span>{' '}
              Back
            </button>
          </div>
        </div>
        <div style={{ margin: '10px 30px' }}>
          <div className={style.center_div_large} ref={modalRef}>
            <div className={style.add_title}>
              <p className={style.addtitle_tag}>Create New Subject Pipeline</p>
            </div>
            <div className={style.identifier_master_modal}>
              <div className={style.master_modal}>
                {/* ___________________ subject ___________________ */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>
                      Subject <span className={style.required_field}>*</span>{' '}
                    </p>
                  </div>
                </div>
                <div className={style.master_tag_search}>
                  {/* this is input field for searching */}
                  <div className={style.input_search}>
                    {selectedPrimaryTagStatus ? (
                      <span style={{ paddingLeft: '0px' }}>{selectedPrimaryTag} </span>
                    ) : (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Subject"
                        value={subjectData}
                        onChange={handleInputChange}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    )}

                    {subjectTagStatus ? (
                      <div className={style.list_input_primary} ref={rowRef}>
                        {subjectTag?.map((items) => (
                          <button key={items} className={style.pointer} onClick={() => selectPrimaryTag(items)}>
                            <span>{items} </span>
                          </button>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {/* cancel button  */}
                  <div className={style.cancel_button}>
                    {subjectData && (
                      <button className={style.pointer} onClick={() => setSubjectData('')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                {setValidationErrorSubject && (
                  <div className={style.validationError}>
                    <p>{validationErrorSubject}</p>
                  </div>
                )}
                {/* ___________________ Topic ___________________ */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>
                      Topic <span className={style.required_field}>*</span>
                    </p>
                  </div>
                </div>
                <div className={style.master_tag_search}>
                  {/* this is input field for searching */}
                  <div className={style.input_search}>
                    {selectedTopicTagStatus ? (
                      <span style={{ paddingLeft: '0px' }}> {selectedTopicTag} </span>
                    ) : (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Topic"
                        value={topicData}
                        onChange={handleTopicInputChange}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    )}

                    {topicTagStatus ? (
                      <div className={style.list_input_primary} ref={rowRef}>
                        {topicTag?.map((items) => (
                          <button key={items} className={style.pointer} onClick={() => selectTopicTag(items)}>
                            <span> {items} </span>
                          </button>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {/* cancel button  */}
                  <div className={style.cancel_button}>
                    {topicData && (
                      <button className={style.pointer} onClick={() => setTopicData('')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                {setValidationErrorTopic && (
                  <div className={style.validationError}>
                    <p>{validationErrorTopic}</p>
                  </div>
                )}
              </div>
              <div className={style.master_modal}>
                {/* ___________________ area ___________________ */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>
                      Area <span className={style.required_field}>*</span>
                    </p>
                  </div>
                </div>
                <div className={style.master_tag_search}>
                  {/* this is input field for searching */}
                  <div className={style.input_search}>
                    {selectedAreaTagStatus ? (
                      <span style={{ paddingLeft: '0px' }}> {selectedAreaTag} </span>
                    ) : (
                      <input
                        type="search"
                        id="searchInput"
                        placeholder="Type Area"
                        value={areaData}
                        onChange={(e) => handleManualAreaInput(e)}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    )}

                    {areaTagStatus && areaData ? (
                      <div className={style.list_input_primary} ref={rowRef}>
                        {areaTag.length > 0 ? (
                          areaTag.map((items) => (
                            <button key={items} className={style.pointer} onClick={() => selectAreaTag(items)}>
                              <span> {items} </span>
                            </button>
                          ))
                        ) : (
                          <div>No areas found</div> // Display message if no areas are found
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {/* cancel button  */}
                  <div className={style.cancel_button}>
                    {areaData && (
                      <button className={style.pointer} onClick={() => setAreaData('')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                {setValidationErrorArea && (
                  <div className={style.validationError}>
                    <p>{validationErrorArea}</p>
                  </div>
                )}

                {/* ___________________ subtopic ___________________ */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p>
                      Sub-Topic <span className={style.required_field}>*</span>
                    </p>
                  </div>
                </div>
                <div className={style.master_tag_search}>
                  {/* this is input field for searching */}
                  <div className={style.input_search}>
                    <input
                      type="search"
                      id="searchInput"
                      placeholder="Type Sub-Topic"
                      value={multiSubTopicTaginput}
                      onChange={(e) => handleSubTopicInput(e)}
                      onKeyDown={handleKeyDown}
                      autoComplete="off"
                      list="autocompleteoff"
                    />
                    {/* list of items of pre-defined multiSubTopic tag  */}
                    {multiSubTopicTagStatus ? (
                      <div className={style.list_input_primary} ref={rowRef}>
                        {multiSubTopicTag?.map((items) => (
                          <button key={items} className={style.pointer} onClick={() => selectSubTopicTag(items)}>
                            <span>{items} </span>
                          </button>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {/* cancel button  */}
                  <div className={style.cancel_button}>
                    <button className={style.pointer} onClick={() => setMultiSubTopicTagStatus(false)}></button>
                  </div>
                </div>
                {setValidationErrorSubTopic && (
                  <div className={style.validationError}>
                    <p>{validationErrorSubTopic}</p>
                  </div>
                )}

                <div className={style.selected_subtopic_tags}>
                  {multiselectedSubTopicTag?.length > 0 && (
                    <div>
                      <div className={style.selected_tags_list}>
                        {multiselectedSubTopicTag?.map((item, index) => (
                          <span key={index + item} className={style.selected_tag_item}>
                            <span style={{ width: '260px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                              {item}
                            </span>
                            {/* Cross icon to remove the selected tag */}
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={style.remove_icon}
                              onClick={() => removeTag(index)} // Call removeTag function on click
                            >
                              <path
                                d="M18 6L6 18M6 6L18 18"
                                stroke="#272727"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={style.master_modal}>
                <div className={style.master_model_inside}>
                  {/* ___________________ Sub-top-description ___________________ */}
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Description Of Subtopic </p>
                    </div>
                  </div>
                  <div className={style.master_tag_search} style={{ height: '150px', backgroundColor: '#fff' }}>
                    {/* this is input field for searching */}
                    <div className={style.input_search} ref={rowRef} style={{ position: 'relative' }}>
                      <textarea
                        className={`${style.desctextarea} ${style.subtopicDescripTextarea}`}
                        type="text"
                        id="searchInput"
                        placeholder="Type Description Of Subtopic"
                        value={descriptionSubTopic}
                        onChange={(e) => setDescriptionSubTopic(e.target.value)}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className={style.master_modal}>
                {/* ___________________ tags ___________________ */}
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p style={{ margin: '0px' }}>Tags </p>
                  </div>
                </div>

                {multiselectedSubTopicTag.length === 0 ? (
                  <div>
                    <span className={style.AddpipelineWaterMark}>
                      No tags have been created yet. Enter the subject, area,
                      <br /> topic, and sub-topic to create a tag
                    </span>
                  </div>
                ) : (
                  <div className={style.tagsContainer}>
                    {multiselectedSubTopicTag?.map((items, i) => (
                      <span key={i + subjectData} className={style.slugs_Tag}>
                        {subjectData} | {areaData} | {topicData} | {items}
                      </span>
                    ))}
                  </div>
                )}

                <div
                  className={`${style.saveButton_questionBank} ${style.margin_top_20} ${style.identifier_save}`}
                  style={{ justifyContent: 'left', padding: '30px 0px' }}
                >
                  <button className={` ${style.cancel} ${style.pointer}`} onClick={() => ClearFieldModel()}>
                    Cancel
                  </button>
                  <div>
                    <button className={style.blue_button} disabled={isButtonDiable} onClick={() => AddNew()}>
                      Add Tag to List
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default AddNewPipeline;
