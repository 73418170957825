import React, { useEffect } from 'react';
import AuthLayout from '../layout/AuthLayout';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import OrganisationList from '../component/molecule/specific/OrganisationManagementScreens/OrganisationList';
import OrganisationDetails from '../component/molecule/specific/OrganisationManagementScreens/OrganisationDetails';
import style from '../assets/styles/organisationManagement.module.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const OrganisationManagement = () => {
  const navigate = useNavigate();
  const { organisationScreen } = useSelector((state) => state.GetAllData);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
  const Roles = userInfo.roles || [];
  const isOrganizationAdmin = Roles.some((role) => role.id === 9);
  const isOrganizationUser = Roles.some((role) => role.id === 10);

  useEffect(() => {
    if (isOrganizationUser || isOrganizationAdmin) {
      alert('Access denied, only super admin or organization admin can access');
      navigate('/question-bank');
    }
  }, [isOrganizationUser, isOrganizationAdmin]);
  return (
    <AuthLayout>
      <RightPanelTopMenu setQuestionBankId={null} title={'Organisation Management'} />
      {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
      <div className={style.question_bank_table_top}>
        {organisationScreen === 0 ? <OrganisationList /> : ''}
        {organisationScreen === 1 ? <OrganisationDetails /> : ''}
      </div>
    </AuthLayout>
  );
};

export default OrganisationManagement;
