/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { GET_QUESTIOB_TYPE } from '../../../../../action/apiPath';
import { CallWithAuth } from '../../../../../action/apiActions';
import { QuestionCreationScreen, QuestionCreationScreenCancel } from '../../../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MediumModal from '../../../../atoms/Modal/MediumModal';
import clozeDropdown from '../../../../../assets/images/clozewith-dropdown.svg';
import clozeMultichoice from '../../../../../assets/images/clozewith-multichoice.svg';
import selectionPerRow from '../../../../../assets/images/selectionperrow.svg';
import selectionPerLabel from '../../../../../assets/images/selectionPerLabel.svg';
import selectionPerColumn from '../../../../../assets/images/selectionPerColumn.svg';
import highlightPassage from '../../../../../assets/images/Highlight.svg';
import fractionInput from '../../../../../assets/images/fractionInput.svg';
import descriptiveText from '../../../../../assets/images/descriptiveText.svg';
import style from '../../../../../assets/styles/createQuestion.module.css';

interface DataType {
  questiontype?: {
    question_type_id?: number;
  };
  [key: string]: any;
}

const SelectType = ({
  data,
  format,
  handleQuestionType,
  index,
  selectedID,
  getData,
  questiontype,
}: {
  id: any;
  data: DataType;
  format?: any;
  // eslint-disable-next-line no-unused-vars
  handleQuestionType: (id: number) => void;
  index?: number;
  selectedID?: number | null;
  getData?: any;
  questiontype?: any;
}) => {
  // ________ initialize ________
  const selector = useSelector((state: any) => state.QuestionScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ________ ACCORDIAN OPENER ___________
  const [open, setOpen] = useState(true);

  const openAccordian = () => {
    setOpen(true);
  };
  const closeAccordian = () => {
    setOpen(false);
  };

  //  ________ SELECT CATEGORY ________

  // CHOOSECATEGORY
  const SelectCategoryKey = (i: any) => {
    setSelectedQuestionCategory(i);

    setQuestionTypeId(null);
  };
  // CHOOSECATEGORY_ID
  const [questionTypeId, setQuestionTypeId] = useState<any>(null);
  const SelectCategoryId = (key: any, data: any) => {
    setQuestionTypeId(data);
  };

  useEffect(() => {
    setQuestionTypeId(data?.questiontype?.question_type_id ?? null);
  }, [data]);

  // ________ get category from API and grouped in sequence ________
  const [questionCategory, setQuestionCategory] = useState<{ [key: string]: any[] }>({});
  const [selectedQuestionCategory, setSelectedQuestionCategory] = useState(0);
  const itemContentMap: any = {
    14: <img src={clozeDropdown} alt="clozeDropdown" />,
    15: <img src={clozeMultichoice} alt="clozeMultichoice" />,
    16: <img src={highlightPassage} alt="highlight" />,
    17: <img src={selectionPerRow} alt="selectionPerRow" />,
    18: <img src={selectionPerLabel} alt="selectionPerLabel" />,
    19: <img src={selectionPerColumn} alt="selectionPerColumn" />,
    20: <img src={fractionInput} alt="fractionInput" />,
    6: <img src={descriptiveText} alt="descriptiveText" />,
  };
  const removeHighLight = (data: any) => {
    if (selector.screen === 2.1) {
      // Iterate over the keys of the object
      for (const key in data) {
        // Check if the array under the key contains an object with id: 16
        if (data[key].some((item: any) => item.id === 16)) {
          // If found, delete the key from the object
          delete data[key];
        }
      }
    }
    return data;
  };
  const GetQuestionTypeCategory = async () => {
    const category = await CallWithAuth('GET', GET_QUESTIOB_TYPE);
    if (category.status && category.res && category.res.status === 200) {
      const groupedData: { [key: string]: any[] } = {};
      category?.res?.data.forEach((item: any) => {
        const category = item.category;
        if (!groupedData[category]) {
          groupedData[category] = [];
        }
        groupedData[category].push(item);
      });
      const data = removeHighLight(groupedData);
      setQuestionCategory(data);
    }
  };
  useEffect(() => {
    GetQuestionTypeCategory();
  }, []);

  // SELECT questionTypeId SAVED to make status false
  const [saveButtonStatus, setSaveButtonStatus] = useState(true);

  useEffect(() => {
    if (questionTypeId === null) {
      setSaveButtonStatus(true);
    } else {
      setSaveButtonStatus(false);
    }
  }, [questionTypeId]);
  useEffect(() => {
    if (questiontype && questionCategory) {
      const categoryIndex = Object.keys(questionCategory)
        ?.map((items) => items)
        .indexOf(questiontype.category);
      setSelectedQuestionCategory(categoryIndex);
      setQuestionTypeId(questiontype);
    }
  }, [questiontype, questionCategory, screen]);

  // ________ body of the selection type  ________
  const body = {
    question_type_id: questionTypeId,
  };

  // _____________ MODAL OPEN AND CLOSE _____________
  const [modalMedium, setModalMedium] = useState(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [modalData] = useState({
    title: 'Are you sure, Do you want to cancel Question Creation?',
    description: 'Your Current Changes Will Not Be Saved And You Will Be Redirected To Question Listing ',
    cancelButton: 'Continue Editing',
    saveButton: 'Yes, Cancel',
  });

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  // cancel button to send the user to question-bank
  const Cancel = () => {
    navigate('/question-bank');
    dispatch(QuestionCreationScreenCancel());
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    if (selectedID !== null) {
      if (selectedID === 3 || selectedID === 4 || selectedID === 5) {
        setSelectedQuestionCategory(1);
      }
      setQuestionTypeId(selectedID);
      setHoveredItem(null);
    } else {
      !questiontype && setQuestionTypeId(null);
    }
  }, [selectedID]);
  useEffect(() => {
    if (getData) {
      if (
        getData?.question_type_id?.id === 3 ||
        getData?.question_type_id?.id === 4 ||
        getData?.question_type_id?.id === 5
      ) {
        setSelectedQuestionCategory(1);
      }
      setQuestionTypeId(getData?.question_type_id?.id);
    }
  }, [getData]);
  return (
    <>
      <div className={style.question_type_box} style={{ height: open ? 'auto' : '5%' }}>
        {/* ________ top of selection  ________*/}
        <div>
          <div className={style.question_type_title}>
            <span>Select Question Type</span>

            {open ? (
              <svg
                className={style.pointer}
                onClick={closeAccordian}
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 16.7617L10.5999 9.96271C11.3699 9.15976 12.6299 9.15976 13.3999 9.96271L19.9199 16.7617"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                className={style.pointer}
                onClick={openAccordian}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <div></div>
        </div>

        <div className={style.category} style={{ display: open ? 'flex' : 'none' }}>
          {/* ________ sub-category by grouping the API  ________*/}
          <div className={style.sub_category}>
            {Object.keys(questionCategory)?.map((items, i) => {
              return (
                <button
                  key={items}
                  className={
                    selectedQuestionCategory === i
                      ? ` ${style.selectedquestion} ${style.pointer} ${style.selectQuestionWrap} textUnderLine`
                      : `${style.pointer} ${style.selectQuestionWrap}`
                  }
                  onClick={() => {
                    if (!questiontype?.id) SelectCategoryKey(i);
                  }}
                >
                  {items}
                </button>
              );
            })}
          </div>
          {/* ________ name-category by grouping the API  ________*/}
          <div className={style.name_category}>
            <div
              className={style.selection_box}
              style={{ marginBottom: selector.screen === 2.1 ? '0%' : '5%' }}
              key={index}
            >
              {Object.values(questionCategory)
                ?.filter((item, key) => key === selectedQuestionCategory)
                .map((items, i) => (
                  <>
                    {items.map((item, key) => (
                      <button
                        key={item.name}
                        className={` ${style.select_type} ${style.pointer}`}
                        onClick={() => !questiontype?.id && SelectCategoryId(key, item)}
                        style={{ background: 'none', border: 'none', padding: '0px', textAlign: 'left' }}
                      >
                        <p
                          style={{
                            color: questionTypeId?.id === item.id || selectedID === item.id ? '#00ABFB' : '#212529',
                          }}
                        >
                          {item.name}
                        </p>

                        {hoveredItem === item?.id ? (
                          <button
                            className={`${style.select_box_type} ${style.hover}`}
                            onClick={() => {
                              !questiontype?.id && handleQuestionType(item.id);
                            }}
                            onMouseLeave={() => !questiontype?.id && setHoveredItem(null)}
                            style={{
                              borderColor: questionTypeId?.id === item.id ? '#00ABFB' : '#C7CCD1',
                              height: '145px',
                              width: '240px',
                              alignItems: 'center',
                              background: 'none',
                              textAlign: 'left',
                            }}
                          >
                            <div
                              className={`${style.hover_content} ${questionTypeId?.id === item.id ? style.active : ''}`}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="64px" height="64px">
                                <path
                                  fill="#4caf50"
                                  d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                />
                                <path fill="#fff" d="M21,14h6v20h-6V14z" />
                                <path fill="#fff" d="M14,21h20v6H14V21z" />
                              </svg>
                            </div>
                          </button>
                        ) : (
                          <button
                            className={style.select_box_type}
                            onClick={() => !questiontype?.id && handleQuestionType(item.id)}
                            onMouseEnter={() => !questiontype?.id && setHoveredItem(item.id)}
                            onMouseLeave={() => !questiontype?.id && setHoveredItem(null)}
                            style={{
                              borderColor:
                                questionTypeId?.id === item.id || selectedID === item.id ? '#00ABFB' : '#C7CCD1',
                              height: '145px',
                              width: '240px',
                              alignItems: 'center',
                              background: 'none',
                              textAlign: 'left',
                            }}
                          >
                            {itemContentMap[item.id] || (
                              <>
                                <p>Which colour has the smallest wavelength?</p>
                                <div>
                                  <input type="radio" name="single-answer" id="single-answer" />
                                  <label htmlFor="red">Red</label>
                                </div>

                                <div className={style.checked_full}>
                                  <input type="radio" name="single-answer" id="single-answer" checked />
                                  <label htmlFor="Violet">Violet </label>
                                </div>

                                <div>
                                  <input type="radio" name="single-answer" id="single-answer" />
                                  <label htmlFor="green">Green</label>
                                </div>
                              </>
                            )}
                          </button>
                        )}
                      </button>
                    ))}
                  </>
                ))}
            </div>
            {/* _________ SAVE BUTTON _________ */}
            {format === 1 ? (
              ''
            ) : (
              <div className={style.saveButton_questionCreation}>
                <button
                  className={` ${style.cancel} ${style.pointer}`}
                  onClick={() => openModalMedium()}
                  style={{ background: 'none', border: 'none', padding: '0px' }}
                >
                  Cancel
                </button>
                <div>
                  <button
                    className={saveButtonStatus ? ` ${style.blue_button_disable}` : `${style.blue_button}`}
                    disabled={saveButtonStatus}
                    onClick={() => dispatch(QuestionCreationScreen(body))}
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <MediumModal
        modalMedium={modalMedium}
        forwardButton={() => Cancel()}
        closeModalMedium={closeModalMedium}
        modalData={modalData}
      />
    </>
  );
};

export default SelectType;
