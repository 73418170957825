import React, { useEffect, useState } from 'react';
import AuthLayout from '../layout/AuthLayout';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import TestComm from '../component/molecule/specific/ReviewScreens/TestComm.js';
import moment from 'moment';
import style from '../assets/styles/reviewStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  Change_Question_catoegory_InRevie,
  Change_Review_Status,
  Get_Need_Review,
  Get_Review,
  ToggleOpen_InRevie,
} from '../redux/action.tsx';
import FilterModal from '../component/atoms/Modal/FilterModal.js';
import ReviewDetailModal from '../component/atoms/Modal/ReviewDetailModal.js';

const Review = () => {
  // to show the toggle icon and the logic by which only one dropdown is opening because of unique ID
  const { isOpenInReview } = useSelector((state) => state.GetAllData);
  const [openID, setOpenId] = useState(null);

  const toggleDropdown = (e) => {
    setOpenId(e);
    dispatch(ToggleOpen_InRevie(!isOpenInReview));
  };

  // _________which one to show -> my or all _________
  const dispatch = useDispatch();
  const { reviewdata } = useSelector((state) => state.GetAllData);
  const { questionCategoryInReview } = useSelector((state) => state.GetAllData);

  //  FILTERS
  const [Filter, setFilter] = useState(false);
  const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([]);
  const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([]);
  const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([]);
  // sort
  const [sort, setsort] = useState('');
  const [sortStatus, setsortStatus] = useState(true);
  const [sortAuthor, setsortAuthor] = useState(true);
  const [sortCreatedOn, setsortCreatedOn] = useState(true);

  const sortStatusFunction = () => {
    if (sortStatus) {
      setsort('status');
      setsortStatus(!sortStatus);
    } else {
      setsort('-status');
      setsortStatus(!sortStatus);
    }
  };

  const sortAuthorFunction = () => {
    if (sortAuthor) {
      setsort('created_by');
      setsortAuthor(!sortAuthor);
    } else {
      setsort('-created_by');
      setsortAuthor(!sortAuthor);
    }
  };

  const sortCreatedFunction = () => {
    if (sortCreatedOn) {
      setsort('created_at');
      setsortCreatedOn(!sortCreatedOn);
    } else {
      setsort('-created_at');
      setsortCreatedOn(!sortCreatedOn);
    }
  };

  useEffect(() => {
    if (questionCategoryInReview) {
      dispatch(Get_Review(sort, multiselectedAuthorTag, multiselectedSubjectTag, multiselectedFreeTag));
      setOpenId(null);
    } else {
      dispatch(Get_Need_Review(sort, multiselectedAuthorTag, multiselectedSubjectTag, multiselectedFreeTag));
      setOpenId(null);
    }
  }, [questionCategoryInReview, Filter, sortStatus, sortAuthor, sortCreatedOn]);

  // _________ To open/close comments section _________
  const [isCommentVisible, setCommentVisibility] = useState(false);

  const handleCommentToggle = () => {
    setCommentVisibility(!isCommentVisible);
  };

  // ________ filter modal open  ________
  const [modalFilter, setModalFilter] = useState(false);

  const openModalFilter = () => {
    setModalFilter(true);
  };

  const closeModalFilter = () => {
    setModalFilter(false);
  };

  // ________ detail of revieww modal open  ________
  const [modalreviewDetail, setModalreviewDetail] = useState(false);
  const [detailData, setDetailData] = useState(null);

  const openModalreviewDetail = (items) => {
    setDetailData(items);
    setModalreviewDetail(true);
  };

  const closeModalreviewDetail = () => {
    setModalreviewDetail(false);
  };

  return (
    <>
      <AuthLayout>
        <RightPanelTopMenu title={'Review'} />
        {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
        <div className={style.review}>
          <div className={style.question_bank_table_top}>
            <div className={style.question_bank}>
              {/* TABLE SECTION OF THIS PAGE  */}
              <div className={style.table_content_report}>
                <div className={style.top_content_table}>
                  <div className={style.table_title}>
                    <span
                      className={
                        questionCategoryInReview ? ` ${style.pointer}` : `${style.underline_active} ${style.pointer}`
                      }
                      onClick={() => dispatch(Change_Question_catoegory_InRevie(false))}
                    >
                      Need Review
                    </span>
                    <span
                      className={
                        questionCategoryInReview ? `${style.underline_active} ${style.pointer}` : ` ${style.pointer}`
                      }
                      onClick={() => dispatch(Change_Question_catoegory_InRevie(true))}
                    >
                      All Lists
                    </span>
                  </div>

                  <div className={style.filter}>
                    <div className={`${style.pointer} ${style.filters_button}`} onClick={() => openModalFilter()}>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span style={{ color: 'black' }}>Filter</span>
                    </div>
                  </div>
                </div>

                {/* TABLE LISTING OF ALL AND need review  */}
                <div className={style.sub_head_content_table}>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th className={` ${style.very_first} ${style.pointer}`}>Question ID and Tags</th>
                        <th className={` ${style.first} ${style.pointer}`}>
                          <span>General Tags</span>
                        </th>
                        <th className={` ${style.second} ${style.pointer}`} onClick={() => sortAuthorFunction()}>
                          {' '}
                          <span>Author</span>
                          <svg
                            width="19"
                            height="12"
                            viewBox="0 0 19 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                              fill="#00ABFB"
                            />
                          </svg>
                        </th>

                        <th className={` ${style.third} ${style.pointer}`} onClick={() => sortCreatedFunction()}>
                          <span>Created On</span>
                          <svg
                            width="19"
                            height="12"
                            viewBox="0 0 19 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                              fill="#00ABFB"
                            />
                          </svg>
                        </th>

                        <th className={` ${style.fifth} ${style.pointer}`} onClick={() => sortStatusFunction()}>
                          <span>Status</span>
                          <svg
                            width="19"
                            height="12"
                            viewBox="0 0 19 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                              fill="#00ABFB"
                            />
                            <path
                              d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                              fill="#00ABFB"
                            />
                          </svg>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    {/* review AND all according to ternary operator */}

                    {reviewdata?.map((items, i) => (
                      <tr key={i} style={{ borderBottom: '1px solid black' }} className={style.data_tags}>
                        <td style={{ marginTop: '5px' }} className={style.user_data_id}>
                          <b onClick={() => openModalreviewDetail(items)} style={{ textDecoration: 'underline' }}>
                            {items.question_id}
                          </b>
                          <div className={style.some_text_tags}>
                            <span style={{ fontSize: '12px' }} className>
                              {items.question_short_identifier}
                            </span>

                            <div className={style.user_info}>
                              <div className={style.user_topics}>{items.primary_tag.slug}</div>
                            </div>
                          </div>
                        </td>
                        <td className={style.some_tags}>
                          <div style={{ marginTop: '5px' }} className={style.user_genral_tags}>
                            {items.free_tags.map((item, i) => (
                              <span key={i} className={style.tag_num}>
                                {item.name}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className={style.user_author} style={{ fontSize: '10px' }}>
                          <i>Authored by {items.created_by}</i>
                        </td>
                        <td className={style.created_date} style={{ fontSize: '10px' }}>
                          {moment(items.created_at).format('Do MMM YYYY')}
                        </td>
                        <td className={style.user_status}>
                          <div
                            className={` ${style.dropdown} ${
                              items.question_id === openID && isOpenInReview ? `${style.open}` : ''
                            }`}
                          >
                            <div
                              className={`${items.status === 'In-Review' ? style.dropdown_input : ''} ${items.status === 'Approved' ? style.dropdown_input_approved : ''} ${items.status === 'Rejected' ? style.dropdown_input_rejected : ''} `}
                              onClick={() => toggleDropdown(items.question_id)}
                            >
                              <span>{items.status}</span>
                              <span>
                                {items.question_id === openID && isOpenInReview ? (
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.7193 10.0352L7.06596 5.68849C7.5793 5.17515 8.4193 5.17515 8.93263 5.68849L13.2793 10.0352"
                                      stroke="#272727"
                                      strokeWidth="1.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.2797 5.96484L8.93306 10.3115C8.41973 10.8248 7.57973 10.8248 7.06639 10.3115L2.71973 5.96484"
                                      stroke="#212529"
                                      strokeWidth="1.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </span>
                            </div>

                            <ul className={style.dropdown_options}>
                              <li className={style.dropdown_option}>
                                <input
                                  type="radio"
                                  name="options"
                                  value={1}
                                  onChange={(e) => dispatch(Change_Review_Status(e, items.question_id))}
                                />{' '}
                                Approve
                              </li>
                              <li className={style.dropdown_option}>
                                <input
                                  type="radio"
                                  name="options"
                                  value={0}
                                  onChange={(e) => dispatch(Change_Review_Status(e, items.question_id))}
                                />{' '}
                                Reject
                              </li>
                              <li className={style.dropdown_option}>
                                <input
                                  type="radio"
                                  name="options"
                                  value={2}
                                  onChange={(e) => dispatch(Change_Review_Status(e, items.question_id))}
                                />{' '}
                                In Review
                              </li>
                            </ul>
                          </div>
                        </td>
                        <td className={style.todo_comment_button}>
                          <button className={style.comm_bt} onClick={() => handleCommentToggle()}>
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.0004 1.66797C8.90603 1.66797 7.82239 1.88352 6.81135 2.30231C5.8003 2.7211 4.88164 3.33492 4.10782 4.10875C2.54502 5.67155 1.66704 7.79116 1.66704 10.0013C1.65976 11.9256 2.32604 13.7917 3.55038 15.2763L1.88371 16.943C1.76808 17.0602 1.68975 17.209 1.6586 17.3706C1.62746 17.5323 1.64489 17.6995 1.70871 17.8513C1.77792 18.0012 1.89013 18.1272 2.03108 18.2133C2.17203 18.2993 2.33538 18.3416 2.50038 18.3346H10.0004C12.2105 18.3346 14.3301 17.4567 15.8929 15.8939C17.4557 14.3311 18.3337 12.2114 18.3337 10.0013C18.3337 7.79116 17.4557 5.67155 15.8929 4.10875C14.3301 2.54594 12.2105 1.66797 10.0004 1.66797ZM10.0004 16.668H4.50871L5.28371 15.893C5.43892 15.7368 5.52604 15.5256 5.52604 15.3055C5.52604 15.0853 5.43892 14.8741 5.28371 14.718C4.19253 13.628 3.51302 12.1934 3.36095 10.6586C3.20888 9.12384 3.59366 7.58381 4.44973 6.3009C5.3058 5.018 6.5802 4.0716 8.05581 3.62295C9.53141 3.17429 11.1169 3.25114 12.5422 3.8404C13.9675 4.42965 15.1444 5.49486 15.8724 6.85454C16.6004 8.21422 16.8344 9.78426 16.5346 11.2971C16.2349 12.81 15.4198 14.1722 14.2283 15.1515C13.0368 16.1308 11.5427 16.6668 10.0004 16.668ZM12.5004 9.16797H10.8337V7.5013C10.8337 7.28029 10.7459 7.06833 10.5896 6.91205C10.4334 6.75577 10.2214 6.66797 10.0004 6.66797C9.77936 6.66797 9.5674 6.75577 9.41112 6.91205C9.25484 7.06833 9.16704 7.28029 9.16704 7.5013V9.16797H7.50038C7.27936 9.16797 7.0674 9.25577 6.91112 9.41205C6.75484 9.56833 6.66704 9.78029 6.66704 10.0013C6.66704 10.2223 6.75484 10.4343 6.91112 10.5906C7.0674 10.7468 7.27936 10.8346 7.50038 10.8346H9.16704V12.5013C9.16704 12.7223 9.25484 12.9343 9.41112 13.0906C9.5674 13.2468 9.77936 13.3346 10.0004 13.3346C10.2214 13.3346 10.4334 13.2468 10.5896 13.0906C10.7459 12.9343 10.8337 12.7223 10.8337 12.5013V10.8346H12.5004C12.7214 10.8346 12.9334 10.7468 13.0896 10.5906C13.2459 10.4343 13.3337 10.2223 13.3337 10.0013C13.3337 9.78029 13.2459 9.56833 13.0896 9.41205C12.9334 9.25577 12.7214 9.16797 12.5004 9.16797Z"
                                fill="#272727"
                              />
                              <circle cx="16.6663" cy="4.44271" r="3.83333" fill="#00ABFB" stroke="white" />
                            </svg>
                          </button>
                        </td>
                        <div style={{ height: '4vh' }}></div>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>

            {/* Comments section component  */}
            <TestComm isCommentVisible={isCommentVisible} />
          </div>

          <FilterModal
            usedFor={1}
            modalFilter={modalFilter}
            Filter={Filter}
            setFilter={setFilter}
            closeModalFilter={closeModalFilter}
            multiselectedSubjectTag={multiselectedSubjectTag}
            setmultiSelectedSubjectTag={setmultiSelectedSubjectTag}
            multiselectedFreeTag={multiselectedFreeTag}
            setmultiSelectedFreeTag={setmultiSelectedFreeTag}
            multiselectedAuthorTag={multiselectedAuthorTag}
            setmultiSelectedAuthorTag={setmultiSelectedAuthorTag}
          />
          <ReviewDetailModal
            key={detailData}
            detailData={detailData}
            modalreviewDetail={modalreviewDetail}
            closeModalreviewDetail={closeModalreviewDetail}
          />
        </div>
      </AuthLayout>
    </>
  );
};

export default Review;
