/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import style from '../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../atoms/QuillEditor';
import deleteIcon from '../../../../../assets/images/deleteCrossBtn.svg';
import dotIcon from '../../../../../assets/images/vertical-bold.svg';
import selectedDotIcon from '../../../../../assets/images/vertical-bold-white.svg';
import addImg from '../../../../../assets/images/add.svg';
import removeImg from '../../../../../assets/images/remove.svg';
import arrowIcon from '../../../../../assets/images/upArrow.svg';

type SelectOption = {
  value: number;
  label: string;
};

const SelectOptions: SelectOption[] = [
  { value: 1, label: 'Basic' },
  { value: 2, label: 'Multi Source Reasoning' },
  { value: 3, label: 'Table Sorting' },
];

interface SelectOptionsProps {
  passage: any;
  setPassage: React.Dispatch<React.SetStateAction<any[]>>;
  passageButton: number;
  setPassageButton: React.Dispatch<React.SetStateAction<number>>;
  sourceText: string | undefined;
  setSourceText: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedType: any;
  setSelectedType: React.Dispatch<React.SetStateAction<number>>;
  sourceTable: any;
  setSourceTable: React.Dispatch<React.SetStateAction<any>>;
  sourceTableButton: any;
  setSourceTableButton: React.Dispatch<React.SetStateAction<any>>;
  sourceTableText: any;
  setSourceTableText: React.Dispatch<React.SetStateAction<any>>;
  isEdit: boolean;
  queTypeId: number;
}

const MultiSource: React.FC<SelectOptionsProps> = ({
  passage,
  setPassage,
  passageButton,
  setPassageButton,
  sourceText,
  setSourceText,
  selectedType,
  setSelectedType,
  sourceTable,
  setSourceTable,
  sourceTableButton,
  setSourceTableButton,
  sourceTableText,
  setSourceTableText,
  isEdit,
  queTypeId,
}) => {
  const [isAddSourceDisabled, setIsAddSourceDisabled] = useState<boolean>(false);
  const [isAddSourceTableDisabled, setIsAddSourceTableDisabled] = useState<boolean>(false);
  const editorRef = useRef<any>(null);
  const tableEditorRef = useRef<any>(null);
  useEffect(() => {
    if (isEdit && selectedType === 2) {
      setIsAddSourceDisabled(true);
    }
    if (isEdit && selectedType === 3) {
      setIsAddSourceTableDisabled(true);
    }
  }, [selectedType]);
  //css object for the dynamic dropdown button
  const buttonStyles = {
    dropdownButton: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #00ABFB',
      borderRadius: '10px',
      background: 'white',
      width: '138px',
      height: '40px',
      gap: '8px',
      padding: '0 10px',
      cursor: 'pointer',
    },
    buttonText: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '18px',
      color: '#00ABFB',
    },
    deleteBtn: {
      width: '20px',
      height: '20px',
      backgroundImage: `url(${deleteIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      cursor: 'pointer',
    },
  };
  const getStyleString = (styleObj: { [s: string]: unknown } | ArrayLike<unknown>) => {
    return Object.entries(styleObj)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
      .join('; ');
  };

  // for Multisource
  const addSourceBtnClickHandler = () => {
    const buttonSubText = 'Passage Source';
    const buttonHTML = `
      <button class="dynamic-button mceNonEditable" id="${'passagesource'}" style="${getStyleString(buttonStyles.dropdownButton)}">
        <span class='button-getter' style="${getStyleString(buttonStyles.buttonText)}">${buttonSubText}</span>
        <span class="delete-btn" style="${getStyleString(buttonStyles.deleteBtn)}" >
        </span> </button> `;

    editorRef.current.execCommand('mceInsertContent', false, buttonHTML);
    setIsAddSourceDisabled(true);
    setPassage([{ name: '', desc: '' }]);
  };

  const setRef = (data: any) => {
    editorRef.current = data;
    if (data?.on) {
      data.on('click', (e: any) => {
        const target = e.target;
        if (target.classList.contains('delete-btn')) {
          updateOptionsOnButtonRemove('passage');
        }
      });
    }
  };
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const updateOptionsOnButtonRemove = (data: string | number) => {
    editorRef.current.execCommand('mceInsertContent', false, '');
    setPassage([]);
    setPassageButton(0);
    setIsAddSourceDisabled(false);
  };

  const addPassage = (): void => {
    setPassage([...passage, { name: '', desc: '' }]);
  };

  const closePassage = (): void => {
    const updatedPassages = passage.filter((_: any, index: number) => index !== passageButton);
    setPassage(updatedPassages);
    setPassageButton(updatedPassages.length - 1 >= 0 ? updatedPassages.length - 1 : 0);
  };

  // for TableSort
  const addSourceTableBtnClickHandler = () => {
    const buttonSubText = 'Table Source';
    const buttonHTML = `
      <button class="dynamic-button mceNonEditable" id="${'tablesource'}" style="${getStyleString(buttonStyles.dropdownButton)}">
        <span class='button-getter' style="${getStyleString(buttonStyles.buttonText)}">${buttonSubText}</span>
        <span class="delete-btn" style="${getStyleString(buttonStyles.deleteBtn)}">
        </span> </button> `;

    tableEditorRef.current.execCommand('mceInsertContent', false, buttonHTML);
    setIsAddSourceTableDisabled(true);
    setSourceTable([{ name: '', desc: '' }]);
  };

  const setTableRef = (data: any) => {
    tableEditorRef.current = data;
    if (data?.on) {
      data.on('click', (e: any) => {
        const target = e.target;
        if (target.classList.contains('delete-btn')) {
          updateOptionsOnTableButtonRemove('table');
        }
      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const updateOptionsOnTableButtonRemove = (data: string | number) => {
    tableEditorRef.current.execCommand('mceInsertContent', false, '');
    setSourceTable([]);
    setSourceTableButton(0);
    setIsAddSourceTableDisabled(false);
  };

  const addTable = (): void => {
    setSourceTable([...sourceTable, { name: '', desc: '' }]);
  };

  const closeTable = (): void => {
    const updatedPassages = sourceTable.filter((_: any, index: number) => index !== sourceTableButton);
    setSourceTable(updatedPassages);
    setSourceTableButton(updatedPassages.length - 1 >= 0 ? updatedPassages.length - 1 : 0);
  };

  return (
    <div className={`${style.tinymce_text} ${style.multisource_container}`}>
      <div className={style.select_type}>
        <p className={style.selectLabel}>
          Select Type: <b className={style.red_mendantory}>*</b>
        </p>
        <div style={{ position: 'relative', width: '34%' }}>
          <select
            name="selectType"
            id="selectType"
            className={style.source_type_select}
            onChange={(e) => {
              setSelectedType(Number(e.target.value));
            }}
            value={selectedType}
            disabled={isEdit || queTypeId === 16}
          >
            {SelectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <img src={arrowIcon} alt="Custom Arrow" className={style.dropdownArrowSource} />
        </div>
      </div>
      {selectedType === 2 && (
        <div>
          <div className={style.compose_text}>
            <p className="questionContainer">
              Add Source Text <b className={style.red_mendantory}> *</b>
            </p>
            <div style={{ height: 'auto', flexGrow: '1' }} className={style.ComposeQuestion}>
              <QuillEditor
                refSetter={(data: any) => setRef(data)}
                ref={editorRef}
                defaultValue={sourceText !== null ? sourceText : ''}
                onChange={(text) => {
                  setSourceText(text);
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(text, 'text/html');
                  const passageSource = doc.getElementById('passagesource');
                  if (!passageSource) {
                    setIsAddSourceDisabled(false);
                    setPassage([]);
                  }
                }}
                onRemove={updateOptionsOnButtonRemove}
              />

              {/* add source button*/}
              <button
                onClick={addSourceBtnClickHandler}
                disabled={isAddSourceDisabled}
                className={`${style.addResponsebtn} ${isAddSourceDisabled ? style.disabledButton : ''}`}
              >
                <span className={style.addIcon}></span>
                <span className={style.addResponsText}>Add Source</span>
              </button>
            </div>
          </div>
          {passage.length > 0 && (
            <div>
              <p className={style.add_psg_text}>Add Passages</p>
              <div className={`${style.container} ${style.questionContainer} ${style.sourceContainer}`}>
                {passage?.map((items: any, i: number) => (
                  <button
                    key={items}
                    draggable="true"
                    className={
                      i === passageButton
                        ? ` ${style.multiquestionbutton} ${style.draggable}`
                        : ` ${style.multiquestionbuttonBlue} ${style.draggable}`
                    }
                    onClick={() => setPassageButton(i)}
                  >
                    {i === passageButton ? (
                      <img src={selectedDotIcon} alt="dot-icon" />
                    ) : (
                      <img src={dotIcon} alt="dot-icon" />
                    )}{' '}
                    &nbsp; Passage {i + 1}
                  </button>
                ))}
              </div>
              {/* Add passage button: to add more passages  */}
              <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'space-between', height: '50' }}>
                <button
                  className={` ${style.Add_answer} ${style.pointer} ${style.addPassageWrap}`}
                  style={{ marginTop: '0px' }}
                  onClick={() => addPassage()}
                >
                  <img src={addImg} alt="add" />
                  <span>Add Passage</span>
                </button>

                {/* Current QUESTION RUNNING  */}
                <div className={`${style.questions_remove} ${style.passage_remove}`}>
                  {passage.length > 1 ? (
                    <div className={style.remove} style={{ width: '100%' }}>
                      <button className={` ${style.remove_question} ${style.pointer}`} onClick={() => closePassage()}>
                        <img src={removeImg} alt="remove" />
                        &nbsp;
                        <span>Remove Passage</span>
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {/* Passage details section */}
              <div className={style.passage_details}>
                <div className={`${style.Question_Direction} ${style.passage_desc}`}>
                  <p>
                    Passage Name <b className={style.red_mendantory}>*</b>
                  </p>
                  <textarea
                    value={passage[passageButton]?.name || ''}
                    className={`${style.question_identifier} ${style.Shortquestion}`}
                    onChange={(e) => {
                      const updatedPassages = [...passage];
                      updatedPassages[passageButton].name = e.target.value;
                      setPassage(updatedPassages);
                    }}
                    placeholder={`Enter Passage Name`}
                  />
                </div>
                <div className={style.compose_text}>
                  <p>
                    Add Passage <b className={style.red_mendantory}>*</b>
                  </p>
                  <div style={{ height: 'auto', flexGrow: '1' }}>
                    <QuillEditor
                      defaultValue={atob(passage[passageButton].desc || '')}
                      onChange={(text) => {
                        const updatedPassages = [...passage];
                        updatedPassages[passageButton].desc = btoa(text);
                        setPassage(updatedPassages);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {selectedType === 3 && (
        <div>
          <div className={style.compose_text}>
            <p className="questionContainer">
              Add Source Text <b className={style.red_mendantory}> *</b>
            </p>

            <div style={{ height: 'auto', flexGrow: '1' }} className={style.ComposeQuestion}>
              <QuillEditor
                refSetter={(data: any) => setTableRef(data)}
                ref={tableEditorRef}
                defaultValue={sourceTableText !== null ? sourceTableText : ''}
                onChange={(text) => {
                  setSourceTableText(text);
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(text, 'text/html');
                  const tableSource = doc.getElementById('tablesource');
                  if (!tableSource) {
                    setIsAddSourceTableDisabled(false);
                    setSourceTable([]);
                  }
                }}
                onRemove={updateOptionsOnTableButtonRemove}
              />

              {/* add source button*/}
              <button
                onClick={addSourceTableBtnClickHandler}
                disabled={isAddSourceTableDisabled}
                className={`${style.addResponsebtn} ${isAddSourceTableDisabled ? style.disabledButton : ''}`}
              >
                <span className={style.addIcon}></span>
                <span className={style.addResponsText}>Add Source</span>
              </button>
            </div>
          </div>
          {sourceTable.length > 0 && (
            <div>
              <p className={style.add_psg_text}>Add Tables</p>
              <div className={`${style.container} ${style.questionContainer} ${style.sourceContainer}`}>
                {sourceTable?.map((items: any, i: number) => (
                  <button
                    key={items}
                    draggable="true"
                    className={
                      i === sourceTableButton
                        ? ` ${style.multiquestionbutton} ${style.draggable}`
                        : ` ${style.multiquestionbuttonBlue} ${style.draggable}`
                    }
                    onClick={() => setSourceTableButton(i)}
                  >
                    {i === sourceTableButton ? (
                      <img src={selectedDotIcon} alt="dot-icon" />
                    ) : (
                      <img src={dotIcon} alt="dot-icon" />
                    )}{' '}
                    &nbsp; Table {i + 1}
                  </button>
                ))}
              </div>
              {/* Add passage button: to add more passages  */}
              <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between', height: '50' }}>
                <button
                  className={` ${style.Add_answer} ${style.pointer} ${style.addTableWrap}`}
                  style={{ marginTop: '0px' }}
                  onClick={() => addTable()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addTable();
                    }
                  }}
                >
                  <img src={addImg} alt="add" />
                  <span>Add Table</span>
                </button>

                {/* Current QUESTION RUNNING  */}
                <div className={`${style.questions_remove} ${style.passage_remove}`}>
                  {sourceTable.length > 1 ? (
                    <div className={style.remove} style={{ width: '100%' }}>
                      <button className={` ${style.remove_question} ${style.pointer}`} onClick={() => closeTable()}>
                        <img src={removeImg} alt="remove" />
                        &nbsp;
                        <span>Remove Table</span>
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {/* Passage details section */}
              <div className={style.passage_details}>
                <div className={style.Question_Direction}>
                  <p>
                    Table Name <b className={style.red_mendantory}>*</b>
                  </p>
                  <textarea
                    value={sourceTable[sourceTableButton]?.name || ''}
                    className={`${style.question_identifier} ${style.Shortquestion}`}
                    onChange={(e) => {
                      const updatedPassages = [...sourceTable];
                      updatedPassages[sourceTableButton].name = e.target.value;
                      setSourceTable(updatedPassages);
                    }}
                    placeholder={`Enter Table Name`}
                  />
                </div>
                <div className={style.compose_text}>
                  <p>
                    Add Table <b className={style.red_mendantory}>*</b>
                  </p>
                  <div style={{ height: 'auto', flexGrow: '1' }}>
                    <QuillEditor
                      defaultValue={atob(sourceTable[sourceTableButton].desc || '')}
                      onChange={(text) => {
                        const updatedPassages = [...sourceTable];
                        updatedPassages[sourceTableButton].desc = btoa(text);
                        setSourceTable(updatedPassages);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSource;
