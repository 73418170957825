import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';

import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { change_test_screen, Publish_Test } from '../../../../../redux/action';
import { GET_PDF_DATA, GET_TEST_LISTS } from '../../../../../action/apiPath';
import { CallWithAuth } from '../../../../../action/apiActions';
import { useToast } from '../../../common/Toast';
import { CHANGE_TEST_SCREEN } from '../../../../../redux/constant';
import { useNavigate } from 'react-router-dom';

// **const TestLevel = ({ setlevelSetting }) => {

// **    const { sectionBody } = useSelector((state) => state.GetAllData);

//  **   const [settings, setSettings] = useState([
//     ]);

//     useEffect(() => {
//         const numberOfTimesToAdd = sectionBody?.groups?.length

// **        const newSettings = Array.from({ length: numberOfTimesToAdd }, () => ({
//             switching_sections: false,
//         }));

// **        setSettings(prevSettings => [
//             ...prevSettings,
//             ...newSettings
//         ]);

//     }, [sectionBody])

// **    useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

// **    // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

//     return (
//         <div className={style.groupSettingsBox}>
//             {settings?.map((items, i) => (
//                 <div className={style.groupSettings} style={{ marginBottom: i==settings.length-1?"8em":"" }}>
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                 }`} onClick={() => updateSwitchingSections(i, false)}>No</div>

//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                 }`} onClick={() => updateSwitchingSections(i, true)}>Yes</div>
//                         </div>
//                     </div>
//                 </div>
//             ))}

//             {/* <div className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 2 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(1, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(1, true)}>Yes</div>
//                     </div>
//                 </div>
//             </div>

//             <div style={{ marginBottom: "8em" }} className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 3 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(2, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(2, true)}>Yes</div>
//                     </div>
//                 </div>
//             </div> */}
//         </div>
//     );
// };

//** */ const GroupLevel = ({ setlevelSetting }) => {

//  **   const { sectionBody } = useSelector((state) => state.GetAllData);
//     const [settings, setSettings] = useState([
//     ]);

//     useEffect(() => {
//         const numberOfTimesToAdd = sectionBody?.groups?.length

//   **      const newSettings = Array.from({ length: numberOfTimesToAdd }, () => ({
//             switching_sections: false,
//             group_duration: {
//                 hours: null,
//                 minutes: null,
//                 seconds: null,
//             },
//         }));

// **        setSettings(prevSettings => [
//             ...prevSettings,
//             ...newSettings
//         ]);

//     }, [sectionBody])

// **    useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//  **   // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

// **    const updateGroupDuration = (index, e, type) => {
//         if (type == "hours") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             hours: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "minutes") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             minutes: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             seconds: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//     };

//     return (
//         <div className={style.groupSettingsBox} >

//             {settings?.map((items, i) => (
//                 <div className={style.groupSettings} style={{ marginBottom:  i==settings.length-1?"8em":"" }}>
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                 }`} onClick={() => updateSwitchingSections(i, false)}>No</div>

//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                 }`} onClick={() => updateSwitchingSections(i, true)}>Yes</div>
//                         </div>
//                     </div>
//                     <div className={style.screenNum}>
//                         <p style={{ fontWeight: 500 }}>Group {i + 1} Duration</p>

//                         <div className={style.timer}>
//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "hours")}
//                             />
//                             <label htmlFor="minutes">Hours</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "minutes")}
//                             />
//                             <label htmlFor="minutes">Minutes</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "seconds")}
//                             />
//                             <label htmlFor="second">Seconds</label>
//                         </div>
//                     </div>
//                 </div>
//             ))}

//             {/* <div className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 2 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(1, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[1].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(1, true)}>Yes</div>
//                     </div>
//                 </div>
//                 <div className={style.screenNum}>
//                     <p style={{ fontWeight: 500 }}>Group 2 Duration</p>

//                     <div className={style.timer}>
//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "hours")}
//                         />
//                         <label htmlFor="minutes">Hours</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "minutes")}
//                         />
//                         <label htmlFor="minutes">Minutes</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(1, e, "seconds")}
//                         />
//                         <label htmlFor="second">Seconds</label>
//                     </div>
//                 </div>
//             </div>

//             <div style={{ marginBottom: "8em" }} className={style.groupSettings}>
//                 <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                     Group 3 Setting
//                 </h5>
//                 <div className={style.switching}>
//                     <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                         Switching Groups Allowed
//                     </span>
//                     <div className={style.wrapperIcon}>
//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? "" : style.editOption
//                             }`} onClick={() => updateSwitchingSections(2, false)}>No</div>

//                         <div className={` ${style.iconBoxs}  ${settings[2].switching_sections ? style.editOption : ""
//                             }`} onClick={() => updateSwitchingSections(2, true)}>Yes</div>
//                     </div>
//                 </div>
//                 <div className={style.screenNum}>
//                     <p style={{ fontWeight: 500 }}>Group 3 Duration</p>

//                     <div className={style.timer}>
//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "hours")}
//                         />
//                         <label htmlFor="minutes">Hours</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "minutes")}
//                         />
//                         <label htmlFor="minutes">Minutes</label>

//                         <input
//                             className={style.timer_input}
//                             type="number"
//                             name="number"
//                             min="-100"
//                             max="100"
//                             step="1"
//                             onChange={(e) => updateGroupDuration(2, e, "seconds")}
//                         />
//                         <label htmlFor="second">Seconds</label>
//                     </div>
//                 </div>
//             </div> */}
//         </div>
//     );
// };

//** */ const SectionLevel = ({ setlevelSetting }) => {

// **    const { sectionBody } = useSelector((state) => state.GetAllData);

//** */     const [settings, setSettings] = useState([
//     ]);

//     useEffect(() => {

// **        for (let i = 0; i < sectionBody?.groups?.length; i++) {
//             const numberOfTimesToAdd = sectionBody?.groups?.length
//             const sectionTime = sectionBody?.groups[i].sections.length

//  **           var newSettings = Array.from({ length: numberOfTimesToAdd }, () => ({
//                 switching_sections: false,
//                 group_duration: {
//                     hours: null,
//                     minutes: null,
//                     seconds: null,
//                 },
//                 sections_durations: Array.from({ length: sectionTime }, () => ({
//                     hours: null,
//                     minutes: null,
//                     seconds: null,
//                 })),
//             }));
//         }

//   **      setSettings(prevSettings => [
//             ...prevSettings,
//             ...newSettings
//         ]);

//     }, [sectionBody])

// **    useEffect(() => {
//         setlevelSetting({
//             group_settings: settings,
//         });
//     }, [settings]);

//  /   // Function to update the switching_sections status of the first element
//     const updateSwitchingSections = (index, value) => {
//         setSettings((prevSettings) => {
//             const updatedSettings = [...prevSettings];
//             updatedSettings[index] = {
//                 ...updatedSettings[index],
//                 switching_sections: Boolean(value),
//             };
//             return updatedSettings;
//         });
//     };

//  /   const updateGroupDuration = (index, e, type) => {
//         if (type == "hours") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             hours: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "minutes") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             minutes: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     updatedSettings[index] = { // Update the first index
//                         ...updatedSettings[index], // Maintain other properties
//                         group_duration: {
//                             ...updatedSettings[index].group_duration, // Maintain other properties in group_duration
//                             seconds: Number(e.target.value), // Update hours
//                         }
//                     };
//                 }
//                 return updatedSettings;
//             });
//         }
//     };

//  /   const updateSectionDuration = (index, e, type, index2) => {
//         if (type == "hours") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     if (updatedSettings[index].sections_durations && updatedSettings[index].sections_durations.length > 0) { // Check if sections_durations array exists and has elements
//                         updatedSettings[index].sections_durations[index2] = { // Update the first index of sections_durations
//                             ...updatedSettings[index].sections_durations[index2], // Maintain other properties
//                             hours: Number(e.target.value) // Update hours
//                         };
//                     }
//                 }
//                 return updatedSettings;
//             });

// /        }
//         if (type == "minutes") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     if (updatedSettings[index].sections_durations && updatedSettings[index].sections_durations.length > 0) { // Check if sections_durations array exists and has elements
//                         updatedSettings[index].sections_durations[index2] = { // Update the first index of sections_durations
//                             ...updatedSettings[index].sections_durations[index2], // Maintain other properties
//                             minutes: Number(e.target.value) // Update hours
//                         };
//                     }
//                 }
//                 return updatedSettings;
//             });
//         }
//         if (type == "seconds") {
//             setSettings(prevSettings => {
//                 const updatedSettings = [...prevSettings]; // Create a copy of settings array
//                 if (updatedSettings.length > 0) { // Check if there are elements in the array
//                     if (updatedSettings[index].sections_durations && updatedSettings[index].sections_durations.length > 0) { // Check if sections_durations array exists and has elements
//                         updatedSettings[index].sections_durations[index2] = { // Update the first index of sections_durations
//                             ...updatedSettings[index].sections_durations[index2], // Maintain other properties
//                             seconds: Number(e.target.value) // Update hours
//                         };
//                     }
//                 }
//                 return updatedSettings;
//             });
//         }
//     };

//     return (
//         <div className={style.groupSettingsBox}>
//             {settings?.map((items, i) => (
//                 <div className={style.groupSettings} style={{ marginBottom:  i==settings.length-1?"8em":"" }}>
//                     <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
//                         Group {i + 1} Setting
//                     </h5>
//                     <div className={style.switching}>
//                         <span style={{ fontSize: "14px", fontWeight: "500" }}>
//                             Switching Groups Allowed
//                         </span>
//                         <div className={style.wrapperIcon}>
//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? "" : style.editOption
//                                 }`} onClick={() => updateSwitchingSections(i, false)}>No</div>

//                             <div className={` ${style.iconBoxs}  ${items.switching_sections ? style.editOption : ""
//                                 }`} onClick={() => updateSwitchingSections(i, true)}>Yes</div>
//                         </div>
//                     </div>
//                     <div className={style.screenNum}>
//                         <p style={{ fontWeight: 500 }}>Group {i + 1} Duration</p>

//                         <div className={style.timer}>
//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "hours")}
//                             />
//                             <label htmlFor="minutes">Hours</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "minutes")}
//                             />
//                             <label htmlFor="minutes">Minutes</label>

//                             <input
//                                 className={style.timer_input}
//                                 type="number"
//                                 name="number"
//                                 min="-100"
//                                 max="100"
//                                 step="1"
//                                 onChange={(e) => updateGroupDuration(i, e, "seconds")}
//                             />
//                             <label htmlFor="second">Seconds</label>
//                         </div>
//                     </div>
//                     <div style={{ margin: "2em 0em 0em 3em" }} className={style.screenNum}>
//                         {items.sections_durations.map((item, key) => (
//                             <>
//                                 <p style={{ fontWeight: 500 }}>Section {key + 1} Duration</p>

//                                 <div className={style.timer}>
//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         onChange={(e) => updateSectionDuration(i, e, "hours", key)}
//                                     />
//                                     <label htmlFor="minutes">Hours</label>

//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         onChange={(e) => updateSectionDuration(i, e, "minutes", key)}
//                                     />
//                                     <label htmlFor="minutes">Minutes</label>

//                                     <input
//                                         className={style.timer_input}
//                                         type="number"
//                                         name="number"
//                                         min="-100"
//                                         max="100"
//                                         step="1"
//                                         onChange={(e) => updateSectionDuration(i, e, "seconds", key)}
//                                     />
//                                     <label htmlFor="second">Seconds</label>
//                                 </div>
//                             </>
//                         ))}

//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };

// eslint-disable-next-line react/prop-types
const AdvanceSettings = ({ isEdit, publish, setButtonStatus, setpdfResult, pdfresult }) => {
  const dispatch = useDispatch();
  const { testCreationId, sectionBody, getBasicSettingData, testDetails } = useSelector((state) => state.GetAllData);

  const [settings, setSettings] = useState({
    calculator: null,
    result_date_time: null,
    test_duration_at: 0,
    overall_test_duration: {
      hours: null,
      minutes: null,
      seconds: null,
    },
  });

  const initialData = {
    section: sectionBody.groups[0].sections.map((item) => {
      return { name: item.section_name, time: { hours: "", minutes: "", seconds: "" } };
    }),
  };
  const [data, setData] = useState(structuredClone(initialData));

  // Function to calculate overall time
  const calculateOverallTime = (sections) => {
    let totalSeconds = 0;

    sections.forEach(({ time }) => {
      totalSeconds += time.hours * 3600 + time?.minutes * 60 + time?.seconds;
    });
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    setSettings({ ...settings, overall_test_duration: { hours, minutes, seconds } });
  };

  // Handle input change
  const onhandleSectionTime = (index, field, value) => {
    const numericValue = parseInt(value, 10) || 0;
    const updatedSections = data?.section?.map((section, i) =>
      i === index
        ? {
            ...section,
            time: {
              ...section.time,
              [field]: Math.max(0, numericValue),
            },
          }
        : section
    );

    setData((prevData) => ({
      ...prevData,
      section: updatedSections,
    }));
    calculateOverallTime(updatedSections); // Recalculate overall time
  };
  useEffect(() => {
    if (publish) {
      dispatch(Publish_Test(settings, isEdit ? testDetails.id : testCreationId, handleSubmit));
    }
  }, [publish]);
  const toast = useToast();
  const handleSubmit = (data) => {
    if (data.status === 200) {
      toast?.add(data.data.message, 'success');
      const screen=0
      dispatch({ type: CHANGE_TEST_SCREEN, screen });
      navigate('/test-papers-management');
    } else {
      toast?.add(data.data.message, 'error');
    }
  };
  const handleCalculator = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      calculator: Number(e.target.value),
    }));
  };

  const handleResultDateTime = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      result_date_time: e.target.value,
    }));
  };

  const handleOverAllDuration = (e, type) => {
    if (type === 'hours') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          hours: e.target.value,
        },
      }));
    }
    if (type === 'minutes') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          minutes:e.target.value,
        },
      }));
    }
    if (type === 'seconds') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        overall_test_duration: {
          ...prevSettings.overall_test_duration,
          seconds: e.target.value,
        },
      }));
    }
  };

  const handleLevel = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      test_duration_at: Number(e.target.value),
    }));
  };

  useEffect(() => {
    if (
      settings.calculator !== null &&
      settings.result_date_time !== null &&
      settings.overall_test_duration.hours !== null &&
      settings.overall_test_duration.minutes !== null &&
      settings.overall_test_duration.seconds !== null
    ) {
      setButtonStatus(true);
    }
  }, [settings]);
  const PdfCheck = async () => {
    //e.preventDefault();
    const url = GET_PDF_DATA + `${isEdit ? testDetails.id : testCreationId}` + '/get_test_paper_pdf/';
    const PdfChecking = await CallWithAuth('GET', url);
    if (PdfChecking.status && PdfChecking.res.status === 200) {
      setpdfResult(PdfChecking.res.data.test_paper_pdf_url);
    }
  };
  const navigate = useNavigate();
  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
    const body = {
      calculator: settings.calculator,
      result_date_time: settings.result_date_time,
      test_duration_at: 0,
      overall_test_duration: {
        total_test_duration: settings.overall_test_duration,
        sections_duration: data?.section?.map((item, i) => {
          return { section_no: i + 1, hours: item.time.hours, minutes: item.time.minutes, seconds: item.time.seconds };
        }),
      },
    };
    const url = `${GET_TEST_LISTS}${testCreationId}/advanced_settings/`;
    const response = await CallWithAuth('PATCH', url, body);
    if (response.status && response.res.status === 200) {
      toast?.add(response.res.data.message, 'success');
      const screen = 0;
      dispatch({ type: CHANGE_TEST_SCREEN, screen });
      navigate('/test-papers-management');
    } else {
      toast?.add(response.res.data.error, 'error');
    }
  };
  useEffect(() => {
    if (pdfresult === null && getBasicSettingData?.test_type != 1) {
      const intervalId = setInterval(() => {
        // Call your function here
        PdfCheck();
      }, 2000); // 2000 milliseconds = 2 seconds

      // Clear the interval when the component unmounts or when the dependency array changes
      return () => clearInterval(intervalId);
    }
  }, [pdfresult]);
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  useEffect(() => {
    if (testDetails) {
      const newSettings = {
        calculator: testDetails?.calculator,
        result_date_time: formatDate(testDetails?.result_date_time) ?? '',
        test_duration_at: testDetails?.test_duration_at,
        overall_test_duration: {
          hours: testDetails?.duration?.hours || 0,
          minutes: testDetails?.duration?.minutes || 0,
          seconds: testDetails?.duration?.seconds || 0,
        },
      };
      if (getBasicSettingData?.test_type == 1) {
        newSettings.overall_test_duration = {
          hours: testDetails?.duration?.total_test_duration.hours || 0,
          minutes: testDetails?.duration?.total_test_duration.minutes || 0,
          seconds: testDetails?.duration?.total_test_duration.seconds || 0,
        };
        const newData = structuredClone(data?.section)?.map((item, i) => {
          return { ...item, time: structuredClone(testDetails?.duration.sections_duration)[i] };
        });
        setData({ section: structuredClone(newData) });
      }
      setSettings(structuredClone(newSettings));
    }
  }, [testDetails]);

  return (
    <div className={style.masterTestCreation}>
      <form className={` ${style.testCreationArea} ${style.AdvanceBox}`}>
        <div className={style.AdvanceSettingsContainer}>
          <h5 className={`${style.testMarklabel} ${style.AdvanceSettingslabel}`}>Advance Settings</h5>
          <div className={style.bound}>
            {/* Test type */}
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Calculator</span>
                  <b className={style.red_mendantory}> *</b>
                  <select
                    name="question"
                    id="question"
                    value={settings.calculator}
                    className={`${style.template_drop_filter} select-focused`}
                    onChange={handleCalculator}
                  >
                    <option value={0}>None</option>
                    <option value={1}>Basic</option>
                    <option value={2}>Scientific</option>
                  </select>
                </div>
              </div>
            </div>

            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>Result Date/ Time</span>
                  <input
                    type="datetime-local"
                    name="question"
                    id="question"
                    value={settings.result_date_time}
                    className={`${style.template_drop_filter} select-focused`}
                    onChange={handleResultDateTime}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={style.switching}>
                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                            Switching Groups Allowed
                        </span>
                        <div className={style.wrapperIcon}>
                            <div
                                className={` ${style.iconBoxs} ${settings.switching_groups_allowed ? "" : style.editOption
                                    }`}
                                onClick={() => handleSwitchGroup(false)}
                            >
                                No
                            </div>

                            <div
                                className={` ${style.iconBoxs} ${settings.switching_groups_allowed ? style.editOption : ""
                                    }`}
                                onClick={() => handleSwitchGroup(true)}
                            >
                                Yes
                            </div>
                        </div>
                    </div> */}

          {getBasicSettingData?.test_type != 1 && (
            <>
              <p style={{ fontWeight: '500', marginTop: '10px' }} className={style.marketLogic}>
                Test Duration At
              </p>
              <div style={{ fontSize: '13px' }} className={style.levels}>
                <input checked={settings.test_duration_at == 0} type="radio" name="markingLogic" defaultChecked value={0} onClick={handleLevel} />
                <label htmlFor="test level">Test Level</label>
                <input
                checked={settings.test_duration_at == 1}
                  style={{ marginLeft: '20px' }}
                  name="markingLogic"
                  type="radio"
                  value={1}
                  onClick={handleLevel}
                />
                <label htmlFor="section level">Group Level</label>
                <input
                  style={{ marginLeft: '20px' }}
                  name="markingLogic"
                  type="radio"
                  value={2}
                  checked={settings.test_duration_at == 2}
                  onClick={handleLevel}
                />
                <label htmlFor="section level">Section Level Level</label>
              </div>

              <div className={style.screenNum}>
                <p style={{ fontWeight: 500 }}>Overall Test Duration</p>

                <div className={style.timer}>
                  <input
                    value={settings.overall_test_duration.hours ?? 0}
                    className={` ${style.timer_input} ${style.fullbg_section} select-focused`}
                    type="number"
                    name="number"
                    min="-100"
                    max="100"
                    step="1"
                    onChange={(e) => handleOverAllDuration(e, 'hours')}
                  />
                  <label htmlFor="minutes">Hours</label>

                  <input
                    value={settings.overall_test_duration.minutes ?? 0}
                    className={` ${style.timer_input} ${style.fullbg_section} select-focused`}
                    type="number"
                    name="number"
                    min="-100"
                    max="100"
                    step="1"
                    onChange={(e) => handleOverAllDuration(e, 'minutes')}
                  />
                  <label htmlFor="minutes">Minutes</label>

                  <input
                    value={settings.overall_test_duration.seconds ?? 0}
                    className={` ${style.timer_input} ${style.fullbg_section} select-focused`}
                    type="number"
                    name="number"
                    min="-100"
                    max="100"
                    step="1"
                    onChange={(e) => handleOverAllDuration(e, 'seconds')}
                  />
                  <label htmlFor="second">Seconds</label>
                </div>
              </div>
            </>
          )}
          {getBasicSettingData?.test_type == 1 && (
            <>
              <p style={{ fontWeight: '500', marginTop: '10px' }} className={style.marketLogic}>
                Test Paper Duration <b className={style.red_mendantory}> *</b>
              </p>

              <div className={style.screenNum}>
                {/* <p style={{ fontWeight: 500 }}>Overall Test Duration</p> */}

                <div className={style.timer}>
                  <input
                    value={settings.overall_test_duration.hours ?? 0}
                    disabled
                    className={` ${style.timer_input} ${style.fullbg_section} ${style.HandleTestDuration}`}
                    type="number"
                    name="number"
                    min="-100"
                    max="100"
                    step="1"
                    onChange={(e) => handleOverAllDuration(e, 'hours')}
                  />
                  <label htmlFor="minutes" className={style.timerLabel}>
                    Hours
                  </label>

                  <input
                    disabled
                    value={settings.overall_test_duration.minutes ?? 0}
                    className={` ${style.timer_input} ${style.fullbg_section} ${style.HandleTestDuration}`}
                    type="number"
                    name="number"
                    min="-100"
                    max="100"
                    step="1"
                    onChange={(e) => handleOverAllDuration(e, 'minutes')}
                  />
                  <label htmlFor="minutes" className={style.timerLabel}>
                    Minutes
                  </label>

                  <input
                    value={settings.overall_test_duration.seconds ?? 0}
                    disabled
                    className={` ${style.timer_input} ${style.fullbg_section} ${style.HandleTestDuration}`}
                    type="number"
                    name="number"
                    min="-100"
                    max="100"
                    step="1"
                    onChange={(e) => handleOverAllDuration(e, 'seconds')}
                  />
                  <label htmlFor="second" className={style.timerLabel}>
                    Seconds
                  </label>
                </div>
              </div>
              <p
                style={{ fontWeight: '500', marginTop: '18px', fontSize: '16px', marginBottom: '5px' }}
                className={style.marketLogic}
              >
                Section Duration
              </p>
              {data?.section?.map((item, index) => (
                <div key={item.name} className={style.screenNum}>
                  <p style={{ fontWeight: 400 }}>{item?.name}</p>

                  <div className={style.timer}>
                    <input
                      style={{ backgroundColor: 'white' }}
                      className={` ${style.timer_input} ${style.fullbg_section} select-focused`}
                      type="number"
                      name="number"
                      min="-100"
                      max="100"
                      step="1"
                      value={item?.time?.hours}
                      onChange={(e) => onhandleSectionTime(index, 'hours', e.target.value)}
                    />
                    <label htmlFor="minutes" className={style.timerLabel}>
                      Hours
                    </label>

                    <input
                      style={{ backgroundColor: 'white' }}
                      className={` ${style.timer_input} ${style.fullbg_section} select-focused`}
                      type="number"
                      name="number"
                      min="-100"
                      max="100"
                      step="1"
                      value={item?.time?.minutes}
                      onChange={(e) => onhandleSectionTime(index, 'minutes', e.target.value)}
                    />
                    <label htmlFor="minutes" className={style.timerLabel}>
                      Minutes
                    </label>

                    <input
                      style={{ backgroundColor: 'white' }}
                      className={` ${style.timer_input} ${style.fullbg_section} select-focused`}
                      type="number"
                      name="number"
                      min="-100"
                      max="100"
                      step="1"
                      value={item?.time?.seconds}
                      onChange={(e) => onhandleSectionTime(index, 'seconds', e.target.value)}
                    />
                    <label htmlFor="second" className={style.timerLabel}>
                      Seconds
                    </label>
                  </div>
                </div>
              ))}
              <div style={{ marginTop: '40px' }} className={style.performBT}>
                <button className={` ${style.btOne} ${style.common}`} onClick={() => dispatch(change_test_screen(1))}>
                  Back
                </button>
                <button className={` ${style.btTwo} ${style.common}`} onClick={(e) => handleSaveAndContinue(e)}>
                  Create Test
                </button>
              </div>
            </>
          )}
          {/*? {settings.test_duration_at == 0 ? <TestLevel setlevelSetting={setlevelSetting} /> : ""}
                    {settings.test_duration_at == 1 ? <GroupLevel setlevelSetting={setlevelSetting} /> : ""}
                    {settings.test_duration_at == 2 ? <SectionLevel setlevelSetting={setlevelSetting} /> : ""} */}
        </div>
      </form>
    </div>
  );
};

AdvanceSettings.propTypes = {
  pdfresult: PropTypes.any,
  publish: PropTypes.any,
  setButtonStatus: PropTypes.func,
  setpdfResult: PropTypes.func,
};

export default AdvanceSettings;
