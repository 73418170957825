import React, { useEffect } from 'react';
import AuthLayout from '../layout/AuthLayout';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import UserList from '../component/molecule/specific/UserManagementScreens/UserList';
import style from '../assets/styles/userManagement.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_USER_DETAIL_FAIL } from '../redux/constant';

const UserManagement = () => {
  // ________________ screen to show dynamically ________________
  const { userScreen } = useSelector((state) => state.GetAllData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userScreen === 0) {
      const response = {
        data: {},
      };

      dispatch({
        type: EDIT_USER_DETAIL_FAIL,
        response,
      });
    }
  }, [userScreen]);
  return (
    
      <AuthLayout>
        {/* rightpannel top left menu according to different screen  */}
        <RightPanelTopMenu setQuestionBankId={null} title={'User Management'} />
        {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
        <div className={style.question_bank_table_top}>
          <UserList />
        </div>
      </AuthLayout>
    
  );
};

export default UserManagement;
