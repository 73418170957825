/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_SUBJECT_TAG } from '../../../../../action/apiPath';
import style from '../../../../../assets/styles/createQuestion.module.css';

interface PrimaryTagProps {
  selectedPrimaryTag: any;
  // eslint-disable-next-line no-unused-vars
  setSelectedPrimaryTag: (tag: any) => void;
  primary_id: number | null;
  questionbutton?: any;
  RemovePrimaryTag: () => void;
  subjectTags?: [];
}

const PrimaryTag: React.FC<PrimaryTagProps> = ({
  selectedPrimaryTag,
  setSelectedPrimaryTag,
  primary_id,
  questionbutton,
  RemovePrimaryTag,
  subjectTags,
}) => {
  // _________ STATE INITIALIZATION _________
  const [subjectTag, setSubjectTag] = useState(subjectTags ?? []);
  const [selectedPrimaryTagStatus, setSelectedPrimaryTagStatus] = useState(false);
  const [subjectTagStatus, setSubjectTagStatus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  // CALL GET API FOR getting TAGS
  const TagSubject = async (e: any) => {
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + '?keyword=' + e);
      if (data.status && data.res && data.res.status === 200) {
        setSubjectTag(data.res.data);
        if (data.res.data.length > 0) {
          setSubjectTagStatus(true);
        } else {
          setSubjectTagStatus(false);
        }
      }
    } else {
      setSubjectTagStatus(false);
    }
  };

  const TagAllSubject = async () => {
    if (subjectTags?.length) {
      setSubjectTag(subjectTags);
    } else {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG);
      if (data.status && data.res && data.res.status === 200) {
        setSubjectTag(data.res.data);
      }
    }
  };

  // select tags from options
  const selectPrimaryTag = (e: any) => {
    setSelectedPrimaryTag(e);
    setSelectedPrimaryTagStatus(true);
    setSubjectTagStatus(false);
  };

  useEffect(() => {
    if (selectedPrimaryTag === null || selectedPrimaryTag === undefined) {
      setSelectedPrimaryTagStatus(false);
    } else {
      setSelectedPrimaryTagStatus(true);
    }
  }, [selectedPrimaryTag]);

  useEffect(() => {
    if (primary_id === null) {
      setSelectedPrimaryTag(null);
    } else {
      const primaryID = subjectTag.filter((item: any) => item.id === primary_id).map((items: any) => items);
      setSelectedPrimaryTag(primaryID[0]);
    }
  }, [primary_id, questionbutton, subjectTag]);

  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowClick = (event: any) => {
    if (rowRef.current && !rowRef.current.contains(event.target)) {
      // Check if the click is within the action buttons
      const isActionButtonClick = event.target.closest('.action_buttons');
      if (!isActionButtonClick) {
        setSubjectTagStatus(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleRowClick);
    return () => {
      document.removeEventListener('mousedown', handleRowClick);
    };
  }, [handleRowClick]);

  useEffect(() => {
    TagAllSubject();
  }, [questionbutton]);

  const closePrimary = () => {
    setSelectedPrimaryTagStatus(false);
    RemovePrimaryTag();
    // Clear the input field if it exists
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div className={style.primary_tag}>
      <div className={style.tag}>
        <div className={style.tag_title}>
          Primary Tags <b className={style.red_mendantory}>*</b>
        </div>
        <div className={style.tag_desc}>Subject | Area | Topic | Subtopic</div>
        <div className={style.tag_search} style={{ height: 'auto' }}>
          <div className={style.tag_icon}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.2598 19.2592L16.1406 16.8594"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Tags</span>
          </div>

          <div className={style.input_search} ref={rowRef} style={{ padding: '10px 0px' }}>
            {selectedPrimaryTagStatus ? (
              <span className={style.selectedPrimaryTag}>
                {selectedPrimaryTag?.subject} | {selectedPrimaryTag?.area} | {selectedPrimaryTag?.topic} |{' '}
                {selectedPrimaryTag?.subtopic}{' '}
              </span>
            ) : (
              <input
                ref={inputRef}
                type="search"
                id="searchInput"
                placeholder=""
                style={{ padding: '7px' }}
                onChange={(e) => TagSubject(e.target.value)}
              />
            )}

            {subjectTagStatus ? (
              <div className={style.list_input_primary}>
                {subjectTag?.map((items: any, i) => (
                  <button
                    key={items.subject}
                    className={`${style.pointer} ${style.multiTagWrap}`}
                    onClick={() => selectPrimaryTag(items)}
                  >
                    <span>
                      {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                    </span>
                  </button>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>

          <div className={style.cancel_button}>
            <button className={style.pointer} onClick={() => closePrimary()}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.75781 7.75781L16.2431 16.2431"
                  stroke="#272727"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.75691 16.2431L16.2422 7.75781"
                  stroke="#272727"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryTag;
