import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import QuestionBank from './pages/QuestionBank';
import CreateQuestion from './pages/CreateQuestion';
import EditQuestion from './pages/EditQuestion';
import CreateQuestionSame from './pages/CreateQuestionSame';
import UserManagement from './pages/UserManagement';
import Masters from './pages/Masters';
import OrganisationManagement from './pages/OrganisationManagement';
import Review from './pages/Review';
import Report from './pages/Report';
import TaskManagement from './pages/TaskManagement';
import TestCreation from './pages/TestCreation';
import ForgetPassword from './component/molecule/specific/SignInScreens/ForgetPassword';
import Login from './component/molecule/specific/SignInScreens/Login';
import CreateNewPassword from './component/molecule/specific/SignInScreens/CreateNewPassword';
import DocumentLogger from './component/molecule/specific/DocumentLogger/DocumentLog';
import ImportAllFiles from './component/molecule/specific/DocumentLogger/ImportQuestions';
import AddNewPipeline from './component/molecule/specific/MastersManagementScreens/AddNewPipeline';
import EditSubjectPipeline from './component/molecule/specific/MastersManagementScreens/EditSubjectPipeline';
import AddUser from './component/molecule/specific/UserManagementScreens/AddUser';
import UserDetail from './component/molecule/specific/UserManagementScreens/UserDeatil';
import PublicRoute from './layout/PublicRoute';
import ProtectedRoute from './layout/ProtectedRoute';
import AddTest from './component/molecule/specific/TestCreationScreens/AddTest';

// Define the functional component with correct typing
const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/sign-in"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/create-password"
        element={
          <PublicRoute>
            <CreateNewPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/create-question-same/:id"
        element={
          <ProtectedRoute>
            <CreateQuestionSame />
          </ProtectedRoute>
        }
      />
      <Route
        path="/question-bank"
        element={
          <ProtectedRoute>
            <QuestionBank />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-question"
        element={
          <ProtectedRoute>
            <CreateQuestion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-question"
        element={
          <ProtectedRoute>
            <EditQuestion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-management"
        element={
          <ProtectedRoute>
            <UserManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-management/user-details/:userId"
        element={
          <ProtectedRoute>
            <UserDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-management/add-user"
        element={
          <ProtectedRoute>
            <AddUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-management/edit-user/:userId"
        element={
          <ProtectedRoute>
            <AddUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/organisation-management"
        element={
          <ProtectedRoute>
            <OrganisationManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/masters"
        element={
          <ProtectedRoute>
            <Masters />
          </ProtectedRoute>
        }
      />
      <Route
        path="/review"
        element={
          <ProtectedRoute>
            <Review />
          </ProtectedRoute>
        }
      />
      <Route
        path="/report"
        element={
          <ProtectedRoute>
            <Report />
          </ProtectedRoute>
        }
      />
      <Route
        path="/task-management"
        element={
          <ProtectedRoute>
            <TaskManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/test-papers-management"
        element={
          <ProtectedRoute>
            <TestCreation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/test-papers-management/add"
        element={
          <ProtectedRoute>
            <AddTest />
          </ProtectedRoute>
        }
      />
      <Route
        path="/test-papers-management/edit/:id"
        element={
          <ProtectedRoute>
            <AddTest />
          </ProtectedRoute>
        }
      />
      {/*<Route
      path="/test-creation"
      element={
        <ProtectedRoute>
          <TestCreation />
        </ProtectedRoute>
      }
    /> */}
      <Route
        path="/document-logger"
        element={
          <ProtectedRoute>
            <DocumentLogger />
          </ProtectedRoute>
        }
      />
      <Route
        path="/import-questions"
        element={
          <ProtectedRoute>
            <ImportAllFiles />
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-new-pipeline"
        element={
          <ProtectedRoute>
            <AddNewPipeline />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-subject-pipeline/:id"
        element={
          <ProtectedRoute>
            <EditSubjectPipeline />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
