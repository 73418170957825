/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_SUBJECT_TAG } from '../../../../../action/apiPath';
import style from '../../../../../assets/styles/createQuestion.module.css';
import NavPills from './NavPills';

interface SecondaryTagProps {
  multiselectedSecondaryTag: any[];
  setmultiSelectedSecondaryTag: React.Dispatch<React.SetStateAction<any[]>>;
  secondary_id: any[];
  questionbutton?: any;
  RemoveSecondaryTag: () => void;
  subjectTags?: [];
}

const SecondaryTag: React.FC<SecondaryTagProps> = ({
  multiselectedSecondaryTag,
  setmultiSelectedSecondaryTag,
  secondary_id,
  questionbutton,
  RemoveSecondaryTag,
  subjectTags,
}) => {
  // _________ STATE INITIALIZATION _________
  const [multiSubjectTaginput, setMultiSubjectTaginput] = useState('');
  const [multiSubjectTag, setMultiSubjectTag] = useState([]);
  const [allMultiSubjectTag, setAllMultiSubjectTag] = useState([]);
  const [multiSubjectTagStatus, setMultiSubjectTagStatus] = useState(false);
  // CALL GET API FOR getting TAGS
  const TagSubjectMultiple = async (e: any) => {
    setMultiSubjectTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + '?keyword=' + e);
      if (data.status && data.res && data.res.status === 200) {
        setMultiSubjectTag(data.res.data);
        if (data.res.data.length > 0) {
          setMultiSubjectTagStatus(true);
        } else {
          setMultiSubjectTagStatus(false);
        }
      }
    } else {
      setMultiSubjectTagStatus(false);
    }
  };

  // select tags from options
  const selectSecondaryTag = (e: any) => {
    setmultiSelectedSecondaryTag((current) => [...current, e]);
    setMultiSubjectTagStatus(false);
    setMultiSubjectTaginput('');
  };

  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowClick = (event: any) => {
    if (rowRef.current && !rowRef.current.contains(event.target)) {
      // Check if the click is within the action buttons
      const isActionButtonClick = event.target.closest('.action_buttons');

      if (!isActionButtonClick) {
        setMultiSubjectTagStatus(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleRowClick);
    return () => {
      document.removeEventListener('mousedown', handleRowClick);
    };
  }, [handleRowClick]);

  const AllTagSubjectMultiple = async () => {
    if (subjectTags?.length) {
      setAllMultiSubjectTag(subjectTags);
    } else {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG);
      if (data.status && data.res && data.res.status === 200) {
        setAllMultiSubjectTag(data.res.data);
      }
    }
  };

  useEffect(() => {
    AllTagSubjectMultiple();
  }, [questionbutton]);

  useEffect(() => {
    if (secondary_id?.length === 0) {
      setmultiSelectedSecondaryTag([]);
    } else {
      const primaryID = allMultiSubjectTag
        ?.filter((item: any) => secondary_id?.includes(item.id))
        .map((items: any) => items);
      setmultiSelectedSecondaryTag(primaryID);
    }
  }, [JSON.stringify(secondary_id), questionbutton, allMultiSubjectTag]);

  const closePrimary = () => {
    setmultiSelectedSecondaryTag([]);
    RemoveSecondaryTag();
    // Clear the input field if it exists
    setMultiSubjectTaginput('');
  };

  return (
    <div className={style.Secondary_tag}>
      <div className={style.tag}>
        <div className={style.tag_title}>
          Secondary Tags <b className={style.red_mendantory}>*</b>
        </div>
        <div className={style.tag_desc}>Add more Sub Topic</div>
        <div className={style.tag_search}>
          <div className={style.tag_icon}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.2598 19.2592L16.1406 16.8594"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Tags</span>
          </div>
          <div className={style.input_search} ref={rowRef}>
            <div style={{ width: '100%' }}>
              <NavPills data={multiselectedSecondaryTag} style={style} />
              <input
                type="search"
                id="searchInput"
                placeholder=""
                value={multiSubjectTaginput}
                onChange={(e) => TagSubjectMultiple(e.target.value)}
              />
            </div>
            {multiSubjectTagStatus ? (
              <div className={style.list_input_primary}>
                {multiSubjectTag?.map((items: any) => (
                  <button
                    key={items.subject}
                    className={`${style.pointer} ${style.multiTagWrap}`}
                    onClick={() => selectSecondaryTag(items)}
                  >
                    <span>
                      {items.subject} | {items.area} | {items.topic} | {items.subtopic}{' '}
                    </span>
                  </button>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={style.cancel_button}>
            <button className={style.pointer} onClick={() => closePrimary()}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.75781 7.75781L16.2431 16.2431"
                  stroke="#272727"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.75691 16.2431L16.2422 7.75781"
                  stroke="#272727"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryTag;
