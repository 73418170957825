/* eslint-disable no-unused-vars */
import React, { useEffect, ReactNode } from 'react';
import SideBar from '../component/molecule/common/SideBar';
import RightPanel from '../component/molecule/common/RightPanel';
import { CallForAuth, CallWithAuth } from '../action/apiActions';
import { GET_AUTHENTICATION, POST_REFRESH_AUTH } from '../action/apiPath';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

// Define more specific types for API responses
interface ApiResponse {
  status: boolean;
  res: {
    status: number;
    data: {
      jwt?: string;
    };
  };
}

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const navigate = useNavigate();

  //  call refresh API to get JWT and store in cookies
  const CheckAuthwithRefresh = async (): Promise<void> => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
    const refreshToken = userInfo.jwt_refresh[0];
    const response = (await CallForAuth(`jwt ${refreshToken}`, 'POST', POST_REFRESH_AUTH)) as ApiResponse;
    if (response?.status && response?.res?.data?.jwt) {
      Cookies.set('accessToken', response.res.data.jwt);
      const userInfoString = localStorage.getItem('userInfo');
      if (!userInfoString) {
        // eslint-disable-next-line no-console
        console.error('No userInfo found in localStorage.');
      } else {
        try {
          const userInfo = JSON.parse(userInfoString);
          const newJwtValue = response.res.data.jwt;
          userInfo.jwt = newJwtValue;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          // eslint-disable-next-line no-console
          console.log('JWT updated successfully in localStorage.');
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error updating JWT in userInfo:', error);
        }
      }
    } else {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
        if (userInfo.hasOwnProperty('jwt')) {
          delete userInfo.jwt;
          // eslint-disable-next-line no-console
          console.log('jwt property has been removed from userInfo.');
        } else {
          // eslint-disable-next-line no-console
          console.warn('jwt property does not exist in userInfo.');
        }
        const updatedUserInfoString = JSON.stringify(userInfo);
        localStorage.setItem('userInfo', updatedUserInfoString);
        // eslint-disable-next-line no-console
        console.log('userInfo updated successfully in localStorage.');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error processing userInfo:', error);
      }
      navigate('/sign-in');
    }
  };
  //  check that user is authenticated with(  ME API ) or not-> if not the call refresh API
  const CheckAuthwithoutRefresh = async (): Promise<void> => {
    const response = (await CallWithAuth('GET', GET_AUTHENTICATION)) as ApiResponse;
    // Safely check response properties
    if (!response?.status && response?.res?.status === 401) {
      await CheckAuthwithRefresh();
    }
  };
  useEffect(() => {
    CheckAuthwithoutRefresh();
  }, []);

  return (
    <section id="dashboard">
      <SideBar sidebarshow={false} />
      <RightPanel rightbarshow={false}>{children}</RightPanel>
    </section>
  );
};

export default AuthLayout;
