/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { QuestionCreationScreenCancel, QuestionCreationScreenChooseOnlyBack } from '../../../../redux/action';
import style from '../../../../assets/styles/createQuestion.module.css';
import NativeButton from '../../../atoms/Button';

const SendForReview = ({ id, initializeQuestion }) => {
  const location = useLocation();
  const receivedState = location.state;

  // ________ initialization :start ____________
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ________ initialization :end ____________

  // cancel button to send the user to question-bank
  const Cancel = () => {
    navigate('/question-bank');
    dispatch(QuestionCreationScreenCancel());
  };

  // START : initializeQuestion for getting the ID so that backend will know that we are start creating question

  const SameSettingQuestion = async () => {
    initializeQuestion();
    dispatch(QuestionCreationScreenChooseOnlyBack(1));
  };

  const [editSection, setEditSection] = useState(false);
  const Permission = JSON.parse(sessionStorage.getItem('questionAccess'));

  useEffect(() => {
    if (receivedState !== null) {
      if (Object.entries(receivedState).length > 0) {
        setEditSection(true);
      }
    }
  }, [receivedState]);

  return (
    <div style={{ height: '55vh', boxShadow: '0px 8px 24px 0px #7090B026' }} className={style.select_question}>
      {/* send for review  */}
      <div className={style.send_for_review}>
        {/* top section */}
        <div className={style.top}>
          <div className={style.icon}>
            {Permission?.edit || Permission?.review ? (
              <svg width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.2"
                  d="M50.185 7.57951L34.3337 3.33216C28.584 1.79154 24.3343 4.26112 22.7886 10.0297L17.5535 29.5672C15.9825 35.4304 18.4483 39.6183 24.198 41.159L40.0493 45.4063C45.8917 46.9718 50.0266 44.5525 51.5976 38.6893L56.8327 19.1518C58.3784 13.3832 56.0273 9.14496 50.185 7.57951Z"
                  fill="url(#paint0_linear_7532_46801)"
                />
                <path
                  d="M33.0562 11.3867H13.5238C5.88655 11.3867 0.75 16.7486 0.75 24.7237V43.1297C0.75 51.0824 5.88655 56.4442 13.5238 56.4442H33.0562C40.6935 56.4442 45.8075 51.0824 45.8075 43.1297V24.7237C45.8075 16.7486 40.6935 11.3867 33.0562 11.3867Z"
                  fill="url(#paint1_linear_7532_46801)"
                />
                <path
                  d="M20.6012 41.2413C20.0965 41.2413 19.5919 41.0498 19.2066 40.6645L13.8606 35.3185C13.0901 34.548 13.0901 33.2999 13.8606 32.5317C14.6311 31.7612 15.8769 31.7589 16.6474 32.5294L20.6012 36.4832L29.901 27.1833C30.6715 26.4128 31.9174 26.4128 32.6878 27.1833C33.4583 27.9538 33.4583 29.2019 32.6878 29.9724L21.9957 40.6645C21.6105 41.0498 21.1058 41.2413 20.6012 41.2413Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_7532_46801"
                    x1="59.2804"
                    y1="10.0166"
                    x2="24.7118"
                    y2="31.1736"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9BF763" />
                    <stop offset="1" stopColor="#25AB5B" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_7532_46801"
                    x1="45.8075"
                    y1="11.3867"
                    x2="14.0378"
                    y2="48.5429"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9BF763" />
                    <stop offset="1" stopColor="#25AB5B" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg width="59" height="57" viewBox="0 0 59 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.2"
                  d="M49.6357 7.55216L33.7844 3.30481C28.0347 1.76419 23.7849 4.23377 22.2392 10.0024L17.0042 29.5399C15.4332 35.4031 17.899 39.591 23.6487 41.1316L39.5 45.379C45.3424 46.9444 49.4773 44.5251 51.0483 38.662L56.2834 19.1245C57.8291 13.3559 55.478 9.11762 49.6357 7.55216Z"
                  fill="url(#paint0_linear_240_32226)"
                />
                <path
                  d="M32.5127 11.3438H12.9803C5.34309 11.3438 0.206543 16.7056 0.206543 24.6808V43.0867C0.206543 51.0394 5.34309 56.4012 12.9803 56.4012H32.5127C40.15 56.4012 45.264 51.0394 45.264 43.0867V24.6808C45.264 16.7056 40.15 11.3438 32.5127 11.3438Z"
                  fill="url(#paint1_linear_240_32226)"
                />
                <path
                  d="M20.0616 41.1905C19.557 41.1905 19.0523 40.999 18.6671 40.6137L13.321 35.2677C12.5505 34.4972 12.5505 33.2491 13.321 32.4809C14.0915 31.7104 15.3373 31.7081 16.1078 32.4786L20.0616 36.4324L29.3615 27.1325C30.132 26.3621 31.3778 26.3621 32.1483 27.1325C32.9188 27.903 32.9188 29.1511 32.1483 29.9216L21.4562 40.6137C21.0709 40.999 20.5663 41.1905 20.0616 41.1905Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_240_32226"
                    x1="26.135"
                    y1="-4.53664"
                    x2="15.034"
                    y2="36.8927"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCF00" />
                    <stop offset="1" stopColor="#FF8800" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_240_32226"
                    x1="0.206543"
                    y1="4.90697"
                    x2="0.206543"
                    y2="54.2556"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCF00" />
                    <stop offset="1" stopColor="#FF8800" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
          <div className={style.question_text}>
            <h3>
              {' '}
              {Permission?.edit || Permission?.review
                ? 'Question Published Successfully!'
                : 'Your question has been successfully sent for Review!'}
            </h3>
            <p style={{ marginTop: '0px!important' }}>
              {Permission?.edit || Permission?.review
                ? `Your Question ID ${id} has been published successfully.`
                : `Question ID ${id} has been sent for review`}
            </p>
          </div>
        </div>

        {/* bottom section */}
        {!editSection ? (
          <div className={style.create}>
            {/* line  */}
            <div className={style.line_div}>
              <div className={style.line}></div>
            </div>

            {/* button section  */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p style={{ width: '700px', fontWeight: 100, marginTop: '0px' }}>
                Select an option below to return to the question listing or add another question in the same question
                bank with the same settings.
              </p>
              <div style={{ display: 'flex' }} className={style.saveButton_questionCreation_Modal}>
                <NativeButton onClick={() => Cancel()}>
                  <div
                    style={{
                      textDecoration: 'underline',
                      lineHeight: '40px',
                      marginRight: '20px',
                      fontWeight: 'bold',
                      marginTop: '8px',
                    }}
                    className={` ${style.cancel} ${style.pointer}`}
                  >
                    Go Back to Question Bank
                  </div>
                </NativeButton>

                <div>
                  <button className={style.blue_button} onClick={() => SameSettingQuestion()}>
                    {' '}
                    Add New Question
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={style.create}>
            {/* line  */}
            <div className={style.line_div}>
              <div className={style.line}></div>
            </div>

            {/* button section  */}

            <div className={style.saveButton_questionCreation_Modal}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button style={{ width: '30%' }} className={style.blue_button} onClick={() => Cancel()}>
                  {' '}
                  Go Back to Question Listing
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SendForReview.propTypes = {
  id: PropTypes.any,
  initializeQuestion: PropTypes.func,
};

export default SendForReview;
