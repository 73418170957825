import React, { ReactNode } from 'react';
import style from '../../../assets/styles/rightPanelStyle.module.css';

// Interface for component props
interface RightPanelProps {
  children: ReactNode;
  rightbarshow: boolean;
}

const RightPanel: React.FC<RightPanelProps> = ({ children, rightbarshow }) => {
  return (
    <div id={style.right_panel} className={rightbarshow ? `${style.show}` : ''}>
      {children}
    </div>
  );
};

export default RightPanel;
