import React, { useEffect, useState } from 'react';
import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';
import addImg from '../../../../../../assets/images/add.svg';
import removeImg from '../../../../../../assets/images/remove.svg';

interface SelectionPerColumnProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any[];
  questionbutton: number;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  setbodyformultiquestion: (body: any[]) => void;
}
interface Row {
  id: number;
  questionStem: string;
  options: string[];
  correctAnswers: number[];
}
interface Option {
  value: string;
  is_correct: boolean;
}

interface ColumnOption {
  text: string;
  option: Option[];
}

interface Options {
  text: string;
  options: ColumnOption[];
}

interface TableRow {
  id: number;
  questionStem: string;
  options: string[];
  correctAnswers: number[];
}

interface Table {
  columns: string[];
  rows: TableRow[];
}
interface TableState {
  columns: string[];
  rows: Row[];
}
const SelectionPerColumn: React.FC<SelectionPerColumnProps> = ({ body, questionbutton, setbodyformultiquestion }) => {
  const [questionIdentifier, setQuestionIdentifier] = useState<string | undefined>(
    body[questionbutton]?.question_short_identifier
  );
  const [questionDirection, setQuestionDirection] = useState<string | undefined>(
    body[questionbutton]?.question_direction
  );
  // state of explanation_text and compose text
  const [explanation_text, setExplanation_text] = useState(body[questionbutton]?.explaination_text);
  const [compose_text, setCompose_text] = useState(body[questionbutton]?.compose_question);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [videoId, setVideoId] = useState(body[questionbutton]?.video_id);
  const [table, setTable] = useState<TableState>({
    columns: ['', '', '', '', ''], // Initially empty columns
    rows: [
      { id: 1, questionStem: '', options: ['', '', ''], correctAnswers: [] },
      { id: 2, questionStem: '', options: ['', '', ''], correctAnswers: [] },
      { id: 3, questionStem: '', options: ['', '', ''], correctAnswers: [] },
      { id: 4, questionStem: '', options: ['', '', ''], correctAnswers: [] },
    ],
  });
  function convertOptionsToTable(options: Options): Table {
    // Initialize the table object structure
    if (options?.options) {
      const table: Table = {
        columns: ['', options?.text, ...options?.options?.map((opt) => opt.text)],
        rows: [],
      };

      // Reconstruct the rows
      options?.options[0]?.option?.forEach((row, rowIndex) => {
        const reconstructedRow: TableRow = {
          id: rowIndex + 1,
          questionStem: row.value,
          options: [],
          correctAnswers: [],
        };

        // Extract options and correctAnswers for this row
        options?.options?.forEach((opt, colIndex) => {
          reconstructedRow.options.push(opt.option[rowIndex].value);
          if (opt.option[rowIndex].is_correct) {
            reconstructedRow.correctAnswers.push(colIndex);
          }
        });

        table.rows.push(reconstructedRow);
      });
      return table;
    } else {
      return table;
    }
  }
  useEffect(() => {
    if (body[questionbutton]?.options) {
      const data = convertOptionsToTable(body[questionbutton]?.options);
      setTable(data);
    }
  }, []);
  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionIdentifier,
      question_direction: questionDirection,
      options: {
        text: table.columns[1],
        options: table.columns.slice(2).map((column, colIndex) => {
          return {
            text: column,
            option: table.rows.map((row) => ({
              value: row.questionStem,
              is_correct: row.correctAnswers.includes(colIndex),
            })),
          };
        }),
      },
      compose_question: compose_text,
      explaination_text: explanation_text || null,
      video_id: videoId || null,
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    AddUpdateBody();
  }, [questionDirection, questionIdentifier, compose_text, explanation_text, table, videoId]);

  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
  const addColumn = () => {
    setTable((prev) => ({
      ...prev,
      columns: [...prev.columns, ''],
      rows: prev.rows.map((row) => ({
        ...row,
        options: [...row.options, ''],
      })),
    }));
  };

  const removeColumn = (index: number) => {
    if (table.columns.length > 4) {
      setTable((prev) => ({
        ...prev,
        columns: prev.columns.filter((_, colIndex) => colIndex !== index),
        rows: prev.rows.map((row) => ({
          ...row,
          options: row.options.filter((_, colIndex) => colIndex !== index - 2),
          correctAnswers: row.correctAnswers.filter((col) => col !== index - 2),
        })),
      }));
    }
  };

  const addRow = () => {
    setTable((prev) => ({
      ...prev,
      rows: [
        ...prev.rows,
        {
          id: prev.rows.length + 1,
          questionStem: '',
          options: Array(prev.columns.length - 2).fill(''),
          correctAnswers: [],
        },
      ],
    }));
  };

  const removeRow = (index: number) => {
    if (table.rows.length > 1) {
      setTable((prev) => ({
        ...prev,
        rows: prev.rows.filter((_, rowIndex) => rowIndex !== index),
      }));
    }
  };

  const handleQuestionStemChange = (rowIndex: number, value: string) => {
    const updatedRows = table.rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, questionStem: value };
      }
      return row;
    });
    setTable((prev) => ({ ...prev, rows: updatedRows }));
  };

  const handleColumnChange = (colIndex: number, value: string) => {
    const updatedColumns = table.columns.map((col, index) => {
      if (index === colIndex) {
        return value;
      }
      return col;
    });
    setTable((prev) => ({ ...prev, columns: updatedColumns }));
  };

  const setCorrectAnswer = (rowIndex: number, colIndex: number) => {
    const updatedRows = table.rows.map((row, index) => {
      if (index === rowIndex) {
        // Add or remove the selection for the specific column while keeping other selections intact
        const isAlreadySelected = row.correctAnswers.includes(colIndex);
        const correctAnswers = isAlreadySelected
          ? row.correctAnswers.filter((col) => col !== colIndex) // Deselect if already selected
          : [...row.correctAnswers, colIndex]; // Select the new column
        return { ...row, correctAnswers };
      } else {
        // Keep other rows the same, only updating the specific column
        const correctAnswers = row.correctAnswers.filter((col) => col !== colIndex);
        return { ...row, correctAnswers };
      }
    });
    setTable((prev) => ({ ...prev, rows: updatedRows }));
  };

  return (
    <>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
      <div className={style.tinymce_text}>
        {/* add explanation_text with tnymce*/}
        <div className={style.Question_Direction}>
          <p>
            Question Short Identifier <b className={style.red_mendantory}>*</b>
          </p>
          <textarea
            placeholder="Enter Question Short Identifier"
            className={`${style.question_identifier} ${style.Shortquestion} ${style.inlineShortquestion} ${style.nonResizable} ${style.nonResizable}`}
            value={questionIdentifier}
            onChange={(e) => setQuestionIdentifier(e.target.value)}
          />
        </div>

        {/* add Question Direction with tnymce*/}
        <div className={`${style.Question_Direction} ${style.QuestionDirection}`}>
          <p>Enter Question Direction</p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={questionDirection !== null ? questionDirection : ''}
              onChange={(text) => setQuestionDirection(text)}
            />
          </div>
        </div>

        {/* add compose_text with tnymce*/}
        <div className={style.compose_text}>
          <p className={style.compose_heading}>Compose Question And Mark The Correct Answer</p>
          <p>
            Compose Question <b className={style.red_mendantory}>*</b>
          </p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={compose_text !== null ? b64DecodeUnicode(compose_text) : ''}
              onChange={(text) => setCompose_text(btoa(text))}
            />
          </div>
        </div>
        <div>
          <div className={style.table_container}>
            <table>
              <thead>
                <tr>
                  {table.columns.map((col, colIndex) => (
                    <th key={colIndex + col}>
                      <div className={style.column_header}>
                        {colIndex > 3 && (
                          <button
                            className={` ${style.remove_column}  ${style.close_answer} ${style.pointer}`}
                            onClick={() => removeColumn(colIndex)}
                          >
                            <img className={style.remove_heading} src={removeImg} alt="remove" />
                            <span>Remove</span>
                          </button>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
                <tr>
                  {table.columns.map((col, colIndex) => (
                    <th className={colIndex > 0 ? 'tableHearder' : ''} key={colIndex + col}>
                      {colIndex > 0 && (
                        <input
                          type="text"
                          className={`${style.custom_input} tableHearder ${
                            colIndex === 1 ? style.first_column_heading : ''
                          }`}
                          placeholder="Type Heading"
                          value={col}
                          onChange={(e) => handleColumnChange(colIndex, e.target.value)}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table.rows.map((row, rowIndex) => (
                  <tr key={row.id}>
                    {/* Remove row button (appears as the rightmost due to RTL) */}
                    <td className={style.remove_row_container}>
                      {rowIndex > 1 && (
                        <button
                          className={` ${style.remove_row}  ${style.close_answer} ${style.pointer}`}
                          onClick={() => removeRow(rowIndex)}
                        >
                          <img className={style.remove_heading} src={removeImg} alt="remove" />
                          <span>Remove</span>
                        </button>
                      )}
                    </td>

                    {/* Question stem input */}
                    <td>
                      <input
                        type="text"
                        placeholder="Type Question Stem"
                        className={` ${style.custom_input} ${style.question_stem}`}
                        value={row.questionStem}
                        onChange={(e) => handleQuestionStemChange(rowIndex, e.target.value)}
                      />
                    </td>

                    {/* Option cells */}
                    {row.options.map((option, colIndex) => (
                      <td key={colIndex + option}>
                        <div className={style.custom_input_container}>
                          <input
                            type="radio"
                            className={style.custom_radio}
                            name={`correct-answer-column-${colIndex}`}
                            checked={row.correctAnswers.includes(colIndex)}
                            onChange={() => setCorrectAnswer(rowIndex, colIndex)}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={style.controls}>
            <button className={` ${style.Add_answer} ${style.pointer} ${style.inline_add_answer}`} onClick={addRow}>
              <img src={addImg} alt="add-image" />
              <span>Add New Row</span>
            </button>
            <button className={` ${style.Add_answer} ${style.pointer} ${style.inline_add_answer}`} onClick={addColumn}>
              <img src={addImg} alt="add-image" />
              <span>Add New Column</span>
            </button>
          </div>
        </div>
        <div className={style.explanation_text}>
          <p>Video ID</p>
          <div className={style.explanation_video}>
            <input
              type="text"
              value={videoId}
              placeholder="Enter Video ID"
              onChange={(e) => setVideoId(e.target.value)}
            />
          </div>
        </div>
        {/* add explanation_text with tnymce*/}
        <div className={style.explanation_text}>
          <p>Add Explanation Text</p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={explanation_text !== null ? b64DecodeUnicode(explanation_text) : ''}
              onChange={(text) => setExplanation_text(btoa(text))}
            />
          </div>
        </div>
      </div>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
    </>
  );
};
export default SelectionPerColumn;
