/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useState } from 'react';
import { CallWithAuth } from '../../../action/apiActions';
import {
  CREATE_CATEGORY,
  CREATE_IDENTIFIER,
  CREATE_LOD,
  CREATE_QUESTIONBANK,
  CREATE_SUBJECT_TAG,
  GET_FREE_TAG,
  GET_SUBJECT_TAG,
} from '../../../action/apiPath';

import style from '../../../assets/styles/modalStyle.module.css';
import {
  Get_All_Category,
  Get_All_Identifier,
  Get_All_LOD,
  Get_All_Question_bank,
  Get_All_Subject_pipeline,
} from '../../../redux/action';
import { useDispatch } from 'react-redux';
import { useToast } from '../../molecule/common/Toast';

const MastersAddModal = ({ modal, closeModal, type, limit, currentPage, categoryKey, setSort }) => {
  const dispatch = useDispatch();

  // ________________ Initialization of FREE TAG ________________
  const [newFreeTag, setnewFreeTag] = useState([]);
  const [multiselectedfreeTag, setmultiSelectedfreeTag] = useState([]);
  const [multifreeTaginput, setmultifreeTaginput] = useState('');
  const [multifreeTag, setmultifreeTag] = useState([]);
  const [multifreeTagStatus, setmultifreeTagStatus] = useState(false);
  const [newfreeTags, setnewfreeTags] = useState([]);
  const [newfreeTagStatus, setnewfreeTagStatus] = useState(false);

  // CALL GET API FOR getting TAGS
  const TagFreeMultiple = async (e) => {
    setmultifreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_FREE_TAG + '?keyword=' + e);
      if (data.status && data.res.status === 200) {
        if (data.res.data.length > 0) {
          setmultifreeTag(data.res.data);
          setnewfreeTagStatus(false);
          setmultifreeTagStatus(true);
        } else {
          setnewfreeTags([e]);
          setmultifreeTagStatus(false);
          setnewfreeTagStatus(true);
        }
      }
    } else {
      setmultifreeTagStatus(false);
    }
  };

  // select tags from options
  const selectFreeTag = (e) => {
    const data = [...multiselectedfreeTag, e].filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    setmultiSelectedfreeTag(data);
    setmultifreeTagStatus(false);
    setmultifreeTaginput('');
  };

  const selectnewFreeTag = (e) => {
    const data = [...newFreeTag, e].filter((value, index, self) => index === self.findIndex((t) => t === value));
    setnewFreeTag(data);
    setnewfreeTagStatus(false);
    setmultifreeTaginput('');
  };

  const CancelButton = () => {
    setnewFreeTag([]);
    setmultiSelectedfreeTag([]);
    setmultifreeTaginput('');
  };

  // _________ STATE INITIALIZATION subject tag_________
  const [subjectTag, setSubjectTag] = useState([]);
  const [selectedPrimaryTagStatus, setSelectedPrimaryTagStatus] = useState(false);
  const [subjectTagStatus, setSubjectTagStatus] = useState(false);
  const [selectedPrimaryTag, setSelectedPrimaryTag] = useState(null);

  const TagSubject = async (e) => {
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-subject/' + '?subject=' + e);
      if (data.status && data.res.status === 200) {
        setSubjectTag(data.res.data);
        if (data.res.data.length > 0) {
          setSubjectTagStatus(true);
        } else {
          setSubjectTagStatus(false);
        }
      }
    } else {
      setSubjectTagStatus(false);
    }
  };

  // select tags from options
  const selectPrimaryTag = (e) => {
    setSelectedPrimaryTag(e);
    setSelectedPrimaryTagStatus(true);
    setSubjectTagStatus(false);
  };

  // _________ STATE INITIALIZATION Topic tag_________
  const [TopicTag, setTopicTag] = useState([]);
  const [selectedTopicTagStatus, setSelectedTopicTagStatus] = useState(false);
  const [TopicTagStatus, setTopicTagStatus] = useState(false);
  const [selectedTopicTag, setSelectedTopicTag] = useState(null);

  const TagTopic = async (e) => {
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-topic/' + '?topic=' + e);
      if (data.status && data.res.status === 200) {
        setTopicTag(data.res.data);
        if (data.res.data.length > 0) {
          setTopicTagStatus(true);
        } else {
          setTopicTagStatus(false);
        }
      }
    } else {
      setTopicTagStatus(false);
    }
  };

  // select tags from options
  const selectTopicTag = (e) => {
    setSelectedTopicTag(e);
    setSelectedTopicTagStatus(true);
    setTopicTagStatus(false);
  };

  // _________ STATE INITIALIZATION area tag_________
  const [AreaTag, setAreaTag] = useState([]);
  const [selectedAreaTagStatus, setSelectedAreaTagStatus] = useState(false);
  const [AreaTagStatus, setAreaTagStatus] = useState(false);
  const [selectedAreaTag, setSelectedAreaTag] = useState(null);

  const TagArea = async (e) => {
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-area/' + '?area=' + e);
      if (data.status && data.res.status === 200) {
        setAreaTag(data.res.data);
        if (data.res.data.length > 0) {
          setAreaTagStatus(true);
        } else {
          setAreaTagStatus(false);
        }
      }
    } else {
      setAreaTagStatus(false);
    }
  };

  // select tags from options
  const selectAreaTag = (e) => {
    setSelectedAreaTag(e);
    setSelectedAreaTagStatus(true);
    setAreaTagStatus(false);
  };

  // ________________ Initialization of SUBTOIC TAG ________________
  // const [newSubTopicTag, setnewSubTopicTag] = useState([])
  const [multiselectedSubTopicTag, setmultiSelectedSubTopicTag] = useState([]);
  const [multiSubTopicTaginput, setmultiSubTopicTaginput] = useState('');
  const [multiSubTopicTag, setmultiSubTopicTag] = useState([]);
  const [multiSubTopicTagStatus, setmultiSubTopicTagStatus] = useState(false);
  // const [newSubTopicTags, setnewSubTopicTags] = useState([])
  // const [newSubTopicTagStatus, setnewSubTopicTagStatus] = useState(false)

  // CALL GET API FOR getting TAGS
  const TagSubTopicMultiple = async (e) => {
    setmultiSubTopicTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_SUBJECT_TAG + 'fetch-subtopic/' + '?subtopic=' + e);
      if (data.status && data.res.status === 200) {
        if (data.res.data.length > 0) {
          setmultiSubTopicTag(data.res.data);
          // setnewSubTopicTagStatus(false)
          setmultiSubTopicTagStatus(true);
        } else {
          // setnewSubTopicTags([e])
          setmultiSubTopicTagStatus(false);
          // setnewSubTopicTagStatus(true)
        }
      }
    } else {
      setmultiSubTopicTagStatus(false);
    }
  };

  // select tags from options
  const selectSubTopicTag = (e) => {
    setmultiSelectedSubTopicTag((current) => [...current, e]);
    setmultiSubTopicTagStatus(false);
    setmultiSubTopicTaginput('');
  };

  // const selectnewSubTopicTag = (e) => {
  //     setnewSubTopicTag(current => [...current, e])
  //     setnewSubTopicTagStatus(false)
  //     setmultiSubTopicTaginput("")
  // }

  const CancelButtonSubTopic = () => {
    // setnewSubTopicTag([])
    setmultiSelectedSubTopicTag([]);
  };
  const [descriptionSubTopic, setdescriptionSubTopic] = useState('');

  // ________________ Initialization of CATEGORY  ________________
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');

  // Error states for category name and discription //

  const [showValidationCategoryName, setShowValidationCategoryName] = useState('');
  const [showValidationDescription, setShowValidationDescription] = useState('');

  // ________________ Initialization of Identifier  ________________
  const [newIdentifierName, setNewIdentifierName] = useState('');
  const [newIdentifierDescription, setNewIdentifierDescription] = useState('');

  // ________________ Initialization of Question Bank  ________________
  const [newQuestionBankName, setNewQuestionBankName] = useState('');
  const [newQuestionBankDescription, setNewQuestionBankDescription] = useState('');

  // ________________ Initialization of LOD  ________________
  const toast = useToast();

  const [newLODName, setNewLODName] = useState('');

  // Function CALL for add
  const AddNew = async () => {
    if (type === 'Category') {
      const body = {
        name: newCategoryName,
        description: newCategoryDescription,
        free_tags: multiselectedfreeTag?.map((item) => item.id),
        new_free_tags: newFreeTag,
      };
      const data = await CallWithAuth('POST', CREATE_CATEGORY, body);
      if (data.status) {
        if (toast) {
          toast.remove('all');
          setNewCategoryName('');
          setNewCategoryDescription('');
          toast.add(String(data.res.data.message), 'success');
        }
        clearAllData();
        closeModal();
        dispatch(Get_All_Category(categoryKey, '', limit, currentPage));
        setSort('');
      } else {
        const keys = Object.keys(data.res.data);
        if (keys.length > 0) {
          const firstErrorKey = keys[0];
          if (toast) {
            toast.remove('all');
            toast.add(String(`${keys[0]} : ${data?.res?.data[firstErrorKey][0]}`), 'error');
          }
        }
      }
    }

    if (type === 'LOD') {
      const body = {
        level: newLODName,
        free_tags: multiselectedfreeTag?.map((item) => item.id),
        new_free_tags: newFreeTag,
      };
      const data = await CallWithAuth('POST', CREATE_LOD, body);
      if (data.status) {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.message), 'success');
        }
        clearAllData();
        closeModal();
        dispatch(Get_All_LOD(categoryKey, '', limit, currentPage));
        setSort('');
      } else {
        const keys = Object.keys(data.res.data);
        if (keys.length > 0) {
          const firstErrorKey = keys[0];
          if (toast) {
            toast.remove('all');
            toast.add(String(`${keys[0]} : ${data?.res?.data[firstErrorKey][0]}`), 'error');
          }
        }
      }
    }

    if (type === 'QuestionBank') {
      const body = {
        name: newQuestionBankName,
        discription: newQuestionBankDescription,
        free_tags: multiselectedfreeTag?.map((item) => item.id),
        new_free_tags: newFreeTag,
      };

      const data = await CallWithAuth('POST', CREATE_QUESTIONBANK, body);
      if (data.status) {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.message), 'success');
        }
        clearAllData();
        closeModal();
        dispatch(Get_All_Question_bank(categoryKey, '', limit, currentPage));
        setSort('');
      } else {
        const keys = Object.keys(data.res.data);
        if (keys.length > 0) {
          const firstErrorKey = keys[0];
          if (toast) {
            toast.remove('all');
            toast.add(String(`${keys[0]} : ${data?.res?.data[firstErrorKey][0]}`), 'error');
          }
        }
      }
    }

    if (type === 'Identifier') {
      const body = {
        name: newIdentifierName,
        description: newIdentifierDescription,
        free_tags: multiselectedfreeTag?.map((item) => item.id),
        new_free_tags: newFreeTag,
      };

      const data = await CallWithAuth('POST', CREATE_IDENTIFIER, body);
      if (data.status) {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.message), 'success');
        }
        clearAllData();
        closeModal();
        dispatch(Get_All_Identifier(categoryKey, '', limit, currentPage));
        setSort('');
      } else {
        const keys = Object.keys(data.res.data);
        if (keys.length > 0) {
          const firstErrorKey = keys[0];
          if (toast) {
            toast.remove('all');
            toast.add(String(`${keys[0]} : ${data?.res?.data[firstErrorKey][0]}`), 'error');
          }
        }
      }
    }

    if (type === 'SubjectPipeline') {
      const body = {
        subject: selectedPrimaryTag,
        area: selectedAreaTag,
        topic: selectedTopicTag,
        subtopic: multiselectedSubTopicTag?.map((item) => item).join(', '),
        subtopic_description: descriptionSubTopic,
      };

      const data = await CallWithAuth('POST', CREATE_SUBJECT_TAG, body);
      if (data.status) {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.message), 'success');
        }
        clearAllData();
        closeModal();
        dispatch(Get_All_Subject_pipeline('', '', limit, currentPage));
        setSort('');
      } else {
        const keys = Object.keys(data.res.data);
        if (keys.length > 0) {
          const firstErrorKey = keys[0];
          if (toast) {
            toast.remove('all');
            toast.add(String(`${keys[0]} : ${data?.res?.data[firstErrorKey][0]}`), 'error');
          }
        }
      }
    }
  };

  //  _____________ outside clickable close list  _____________
  const modalRef = useRef();
  // const handleOverlayClick = (event) => {
  //   if (modalRef.current && !modalRef.current.contains(event.target)) {
  //     closeModal();
  //     clearAllData()
  //   }
  // };

  // useEffect(() => {
  //   // Attach the event listener when the component mounts
  //   document.addEventListener('mousedown', handleOverlayClick);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('mousedown', handleOverlayClick);
  //   };
  // }, [handleOverlayClick]);

  //  _____________ outside clickable close modal  _____________
  const rowRef = useRef();

  // const handleOverlay = (event) => {
  //     if (rowRef.current && !rowRef.current.contains(event.target)) {
  //         setmultifreeTagStatus(false)
  //         setmultifreeTagStatus(false)
  //         setmultiSubTopicTagStatus(false)
  //         setTopicTagStatus(false)
  //         setAreaTagStatus(false)
  //         setSubjectTagStatus(false)
  //     }
  // };

  // useEffect(() => {
  //     // Attach the event listener when the component mounts
  //     document.addEventListener('mousedown', handleOverlay);

  //     // Clean up the event listener when the component unmounts
  //     return () => {
  //         document.removeEventListener('mousedown', handleOverlay);
  //     };
  // }, [handleOverlay]);
  const clearAllData = () => {
    setnewfreeTagStatus(false);
    setnewfreeTags([]);
    setmultifreeTagStatus(false);
    setmultifreeTag([]);
    setmultifreeTaginput('');
    setmultiSelectedfreeTag([]);
    setnewFreeTag([]);
    setSelectedPrimaryTag(null);
    setSubjectTagStatus(false);
    setSelectedPrimaryTagStatus(false);
    setSubjectTag([]);
    setSelectedTopicTag(null);
    setTopicTagStatus(false);
    setTopicTag([]);
    setSelectedTopicTagStatus(false);
    setAreaTag([]);
    setAreaTagStatus(false);
    setSelectedAreaTag(null);
    setSelectedAreaTagStatus(false);
    setmultiSubTopicTagStatus(false);
    setmultiSubTopicTaginput('');
    setmultiSelectedSubTopicTag([]);
    setmultiSubTopicTag([]);
    setdescriptionSubTopic('');
    setNewCategoryName('');
    setNewCategoryDescription('');
    setNewIdentifierName('');
    setNewIdentifierDescription('');
    setNewQuestionBankName('');
    setNewQuestionBankDescription('');
    setNewLODName('');
  };
  return (
    <>
      {modal ? (
        <>
          {type === 'Category' ? (
            <div className={style.filterModal}>
              <div className={style.center_div} ref={modalRef}>
                <div className={style.top_title}>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button
                      onClick={() => {
                        setnewFreeTag([]);
                        setmultiSelectedfreeTag([]);
                        setmultifreeTaginput('');
                        clearAllData();
                        closeModal();
                        setShowValidationCategoryName('');
                        setShowValidationDescription('');
                      }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* __________ title __________ */}
                  <div className={style.title} style={{ bottom: '8px' }}>
                    <div>
                      <span>Add New Category</span>
                    </div>
                  </div>
                </div>

                <div className={style.master_modal} style={{ position: 'relative', bottom: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }} className={style.tags}>
                    <div className={style.subject} style={{}}>
                      <p style={{ fontSize: '14px' }}>
                        Category Name <span className={style.required_field}>*</span>
                      </p>
                      <input
                        style={{ fontSize: '12px', marginBottom: '0' }}
                        className={style.input_search}
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        type="search"
                        id="searchInput"
                        placeholder="Type Category Name"
                      />
                    </div>
                    {showValidationCategoryName.length > 1 && (
                      <div>
                        <span style={{ fontSize: '14px', color: 'red' }}>{showValidationCategoryName}</span>
                      </div>
                    )}
                  </div>

                  <div style={{}} className={style.tags}>
                    <div className={style.subject} style={{}}>
                      <p style={{ fontSize: '14px' }}>Category Description</p>
                      <textarea
                        className={` ${style.input_search} ${style.description_input}`}
                        onChange={(e) => setNewCategoryDescription(e.target.value)}
                        value={newCategoryDescription}
                        type="textarea"
                        id="searchInput"
                        placeholder="Type Category Description"
                        style={{ width: '93.5%', fontSize: '12px' }}
                      />
                    </div>
                    {showValidationDescription && (
                      <div>
                        <span style={{ fontSize: '14px', color: 'red' }}>{showValidationDescription}</span>
                      </div>
                    )}
                  </div>
                  <div className={style.tags}>
                    <div className={style.subject} style={{ position: 'relative', bottom: '5px' }}>
                      <p style={{ fontSize: '14px' }}>Tags </p>
                    </div>
                  </div>
                  <div
                    className={style.master_tag_search}
                    style={{ position: 'relative', bottom: '12px', maxWidth: 'none' }}
                  >
                    <div className={style.tag_icon}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.2598 19.2592L16.1406 16.8594"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Tags</span>
                    </div>
                    {/* this is input field for searching */}
                    <div
                      className={style.input_search}
                      ref={rowRef}
                      style={{
                        overflowY: 'auto',
                        display: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'flex' : 'block',
                        flexWrap: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'wrap' : 'nowrap',
                      }}
                    >
                      {/* multifreeTag selected one  */}
                      {multiselectedfreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items.name}{' '}
                          </span>
                        </>
                      ))}

                      {/* new free tag selected one  */}
                      {newFreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items}{' '}
                          </span>
                        </>
                      ))}

                      {/* input field  */}
                      <input
                        type="search"
                        id="searchInput"
                        placeholder=""
                        value={multifreeTaginput}
                        onChange={(e) => TagFreeMultiple(e.target.value)}
                        style={{ padding: '15px', flex: '1', minWidth: '200px' }}
                      />
                    </div>
                    <>
                      {/* list of items of pre-defined multifree tag  */}
                      {multifreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {multifreeTag?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectFreeTag(items)}>
                                <span>{items.name} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}

                      {/* list of item of user-defined multifreetag  */}
                      {newfreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {newfreeTags?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectnewFreeTag(items)}>
                                <span>{items} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </>

                    {/* cancel button  */}

                    <div className={style.cancel_button}>
                      <button className={style.pointer} onClick={() => CancelButton()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div
                    className={` ${style.saveButton_questionBank} ${style.margin_top_20} ${style.masterButtonWrap}`}
                    style={{ position: 'relative', top: '15px' }}
                  >
                    <div
                      className={` ${style.cancel} ${style.pointer}`}
                      onClick={() => {
                        clearAllData();
                      }}
                    >
                      Cancel
                    </div>
                    <div>
                      <button className={style.blue_button} onClick={() => AddNew()}>
                        Add Category
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {type === 'Identifier' ? (
            <div className={style.filterModal}>
              <div className={style.center_div_large} ref={modalRef} style={{ overflow: 'hidden' }}>
                <div className={style.top_title}>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button
                      onClick={() => {
                        setnewFreeTag([]);
                        setmultiSelectedfreeTag([]);
                        setmultifreeTaginput('');
                        clearAllData();
                        closeModal();
                      }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* __________ title __________ */}
                  <div className={style.title} style={{ position: 'relative', bottom: '5px' }}>
                    <div>
                      <span>Add New Identifier</span>
                    </div>
                  </div>
                </div>

                <div className={`${style.master_modal} ${style.master_modalWrap}`} style={{ bottom: '30px' }}>
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>
                        Section Identifier Name <span className={style.required_field}>*</span>
                      </p>
                      <input
                        className={style.input_search}
                        onChange={(e) => setNewIdentifierName(e.target.value)}
                        type="search"
                        id="searchInput"
                        placeholder="Type Identifier Name"
                        value={newIdentifierName}
                      />
                    </div>
                  </div>

                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Section Identifier Description</p>
                      <textarea
                        className={` ${style.input_search} ${style.description_input}`}
                        onChange={(e) => setNewIdentifierDescription(e.target.value)}
                        type="textarea"
                        id="searchInput"
                        placeholder="Type Identifier Description"
                        style={{ width: '93.5%' }}
                        value={newIdentifierDescription}
                      />
                    </div>
                  </div>

                  {/* <div className='saveButton-questionBank margin-top-20'>
                                        <div className={` ${style.cancel} ${style.pointer}`} onClick={() => closeModal()} >
                                            Cancel
                                        </div>
                                        <div>
                                            <button className={style.blue_button} onClick={() => AddNew()}>Add Category</button>
                                        </div>
                                    </div> */}

                  {/* <div className={style.tags}>
                                        <div className={style.subject}>
                                            <p>Identifier Name </p>
                                            <input className={style.input_search} onChange={(e) => setNewCategoryName(e.target.value)} type='search' id='searchInput' placeholder='Type Category Name' />
                                        </div>

                                    </div> */}

                  <div className={style.tags}>
                    <div className={style.subject} style={{ position: 'relative', top: '7px' }}>
                      <p>Tags</p>
                    </div>
                  </div>
                  <div className={style.master_tag_search} style={{ maxWidth: 'none' }}>
                    <div className={style.tag_icon}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.2598 19.2592L16.1406 16.8594"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Tags</span>
                    </div>

                    {/* this is input field for searching */}
                    <div
                      className={style.input_search}
                      ref={rowRef}
                      style={{
                        overflowY: 'auto',
                        display: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'flex' : 'block',
                        flexWrap: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'wrap' : 'nowrap',
                      }}
                    >
                      {/* <div
                        className={style.tagsWrap}
                        style={{
                          width: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '270px' : 'none',
                        }}
                      > */}
                      {/* multifreeTag selected one  */}
                      {multiselectedfreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items.name}{' '}
                          </span>
                        </>
                      ))}

                      {/* new free tag selected one  */}
                      {newFreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items}{' '}
                          </span>
                        </>
                      ))}
                      {/* </div> */}
                      {/* input field  */}
                      <input
                        type="search"
                        id="searchInput"
                        placeholder=""
                        value={multifreeTaginput}
                        style={{ padding: '15px', flex: '1', minWidth: '200px' }}
                        onChange={(e) => TagFreeMultiple(e.target.value)}
                      />
                    </div>
                    <>
                      {/* list of items of pre-defined multifree tag  */}
                      {multifreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {multifreeTag?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectFreeTag(items)}>
                                <span>{items.name} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}

                      {/* list of item of user-defined multifreetag  */}
                      {newfreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {newfreeTags?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectnewFreeTag(items)}>
                                <span>{items} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </>

                    {/* cancel button  */}
                    <div className={style.cancel_button}>
                      <button className={style.pointer} onClick={() => CancelButton()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  {/* <div className={style.tags}>
                                        <div className={style.subject}>
                                            <p>Identifier Description </p>
                                            <textarea className={` ${style.input_search} ${style.description_input}`} onChange={(e) => setNewCategoryDescription(e.target.value)} type='textarea' id='searchInput' placeholder='Type Category Description' />
                                        </div>

                                    </div> */}

                  <div className={` ${style.saveButton_questionBank} ${style.margin_top_20} ${style.masterButtonWrap}`}>
                    <div
                      className={` ${style.cancel} ${style.pointer}`}
                      onClick={() => {
                        clearAllData();
                      }}
                    >
                      Cancel
                    </div>
                    <div>
                      <button className={style.blue_button} onClick={() => AddNew()}>
                        Add Identifier
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {type === 'LOD' ? (
            <div className={style.filterModal}>
              <div className={style.center_div} ref={modalRef} style={{ height: 'auto' }}>
                <div className={style.top_title} style={{ height: '14vh' }}>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button
                      onClick={() => {
                        setnewFreeTag([]);
                        setmultiSelectedfreeTag([]);
                        setmultifreeTaginput('');
                        clearAllData();
                        closeModal();
                      }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* __________ title __________ */}
                  <div className={style.title} style={{ bottom: '8px' }}>
                    <div>
                      <span>Add New Level of Difficulty</span>
                    </div>
                  </div>
                </div>

                <div className={`${style.master_modal}`} style={{ height: 'auto' }}>
                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>
                        Level of Difficulty Name <span className={style.required_field}>*</span>
                      </p>
                      <input
                        className={style.input_search}
                        onChange={(e) => setNewLODName(e.target.value)}
                        type="search"
                        id="searchInput"
                        value={newLODName}
                        placeholder="Type LOD Name"
                      />
                    </div>
                  </div>

                  <div className={style.tags}>
                    <div className={style.subject}>
                      <p>Tags </p>
                    </div>
                  </div>
                  <div className={style.master_tag_search} style={{ maxWidth: 'none' }}>
                    <div className={style.tag_icon}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.2598 19.2592L16.1406 16.8594"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Tags</span>
                    </div>

                    {/* this is input field for searching */}
                    <div
                      className={style.input_search}
                      ref={rowRef}
                      style={{
                        overflowY: 'auto',
                        display: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'flex' : 'block',
                        flexWrap: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'wrap' : 'nowrap',
                      }}
                    >
                      {/* multifreeTag selected one  */}
                      {multiselectedfreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items.name}{' '}
                          </span>
                        </>
                      ))}

                      {/* new free tag selected one  */}
                      {newFreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items}{' '}
                          </span>
                        </>
                      ))}

                      {/* input field  */}
                      <input
                        type="search"
                        id="searchInput"
                        placeholder=""
                        value={multifreeTaginput}
                        style={{ padding: '15px', flex: '1', minWidth: '200px' }}
                        onChange={(e) => TagFreeMultiple(e.target.value)}
                      />
                    </div>

                    <>
                      {' '}
                      {/* list of items of pre-defined multifree tag  */}
                      {multifreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {multifreeTag?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectFreeTag(items)}>
                                <span>{items.name} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* list of item of user-defined multifreetag  */}
                      {newfreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {newfreeTags?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectnewFreeTag(items)}>
                                <span>{items} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </>

                    {/* cancel button  */}
                    <div className={style.cancel_button}>
                      <button className={style.pointer} onClick={() => CancelButton()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div
                    className={` ${style.saveButton_questionBank} ${style.margin_top_20}`}
                    style={{ position: 'relative', left: '30px' }}
                  >
                    <div
                      className={` ${style.cancel} ${style.pointer}`}
                      onClick={() => {
                        clearAllData();
                      }}
                    >
                      Cancel
                    </div>
                    <div>
                      <button className={style.blue_button} onClick={() => AddNew()}>
                        Add LOD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {type === 'QuestionBank' ? (
            <div className={style.filterModal}>
              <div className={style.center_div} ref={modalRef}>
                <div className={style.top_title}>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button
                      onClick={() => {
                        setnewFreeTag([]);
                        setmultiSelectedfreeTag([]);
                        setmultifreeTaginput('');
                        clearAllData();
                        closeModal();
                      }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* __________ title __________ */}
                  <div className={style.title} style={{ bottom: '8px' }}>
                    <div>
                      <span>Add New Question Bank</span>
                    </div>
                  </div>
                </div>

                <div className={`${style.master_modal} ${style.master_modalWrap}`}>
                  <div className={style.tags}>
                    <div className={style.subject} style={{ position: 'relative', bottom: '15px' }}>
                      <p>
                        Question Bank Name <span className={style.required_field}>*</span>
                      </p>
                      <input
                        className={style.input_search}
                        onChange={(e) => setNewQuestionBankName(e.target.value)}
                        type="search"
                        id="searchInput"
                        value={newQuestionBankName}
                        placeholder="Type Question Bank Name"
                      />
                    </div>
                  </div>

                  <div className={style.tags}>
                    <div className={style.subject} style={{ position: 'relative', bottom: '15px' }}>
                      <p>Question Bank Description </p>
                      <textarea
                        className={` ${style.input_search} ${style.description_input}`}
                        onChange={(e) => setNewQuestionBankDescription(e.target.value)}
                        type="textarea"
                        id="searchInput"
                        value={newQuestionBankDescription}
                        placeholder="Type Question Bank  Description"
                        style={{ width: '93.5%' }}
                      />
                    </div>
                  </div>

                  <div className={style.tags}>
                    <div className={style.subject} style={{ position: 'relative', bottom: '12px' }}>
                      <p>Tags </p>
                    </div>
                  </div>
                  <div
                    className={style.master_tag_search}
                    style={{ position: 'relative', bottom: '20px', maxWidth: 'none' }}
                  >
                    <div className={style.tag_icon}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.2598 19.2592L16.1406 16.8594"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Tags</span>
                    </div>

                    {/* this is input field for searching */}
                    <div
                      className={style.input_search}
                      ref={rowRef}
                      style={{
                        overflowY: 'auto',
                        display: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'flex' : 'block',
                        flexWrap: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? 'wrap' : 'nowrap',
                      }}
                    >
                      {/* multifreeTag selected one  */}
                      {multiselectedfreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items.name}{' '}
                          </span>
                        </>
                      ))}

                      {/* new free tag selected one  */}
                      {newFreeTag?.map((items, i) => (
                        <>
                          <span
                            key={i}
                            className={`${style.selected_tags} ${style.masterSelectTags}`}
                            style={{
                              maxWidth: multiselectedfreeTag.length > 0 || newFreeTag.length > 0 ? '600px' : 'none',
                            }}
                          >
                            {items}{' '}
                          </span>
                        </>
                      ))}

                      {/* input field  */}
                      <input
                        type="search"
                        id="searchInput"
                        placeholder=""
                        style={{ padding: '15px', flex: '1', minWidth: '200px' }}
                        value={multifreeTaginput}
                        onChange={(e) => TagFreeMultiple(e.target.value)}
                      />
                    </div>

                    <>
                      {/* list of items of pre-defined multifree tag  */}
                      {multifreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {multifreeTag?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectFreeTag(items)}>
                                <span>{items.name} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}

                      {/* list of item of user-defined multifreetag  */}
                      {newfreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {newfreeTags?.map((items, i) => (
                            <>
                              <div key={i} className={style.pointer} onClick={() => selectnewFreeTag(items)}>
                                <span>{items} </span>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </>

                    {/* cancel button  */}
                    <div className={style.cancel_button}>
                      <button className={style.pointer} onClick={() => CancelButton()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className={` ${style.saveButton_questionBank} ${style.margin_top_20} ${style.masterButtonWrap}`}>
                    <div
                      className={` ${style.cancel} ${style.pointer}`}
                      onClick={() => {
                        clearAllData();
                      }}
                    >
                      Cancel
                    </div>
                    <div>
                      <button className={style.blue_button} onClick={() => AddNew()}>
                        Add Question Bank
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {type === 'SubjectPipeline' ? (
            <div className={style.filterModal}>
              <div className={style.center_div_large} ref={modalRef}>
                <div className={style.top_title}>
                  {/* __________ close __________  */}
                  <div className={style.close}>
                    <button
                      onClick={() => {
                        setnewFreeTag([]);
                        setmultiSelectedfreeTag([]);
                        setmultifreeTaginput('');
                        clearAllData();
                        closeModal();
                      }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* __________ title __________ */}
                  <div className={style.title}>
                    <div>
                      <span>Create New Tag</span>
                    </div>
                  </div>
                </div>

                <div className={style.identifier_master_modal}>
                  <div className={style.master_modal}>
                    {/* ___________________ subject ___________________ */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Subject </p>
                      </div>
                    </div>
                    <div className={style.master_tag_search}>
                      {/* this is input field for searching */}
                      <div className={style.input_search} ref={rowRef}>
                        {selectedPrimaryTagStatus ? (
                          <span style={{ paddingLeft: '0px' }}>{selectedPrimaryTag} </span>
                        ) : (
                          <input
                            type="search"
                            id="searchInput"
                            placeholder="Type Subject"
                            onChange={(e) => TagSubject(e.target.value)}
                          />
                        )}

                        {subjectTagStatus ? (
                          <div className={style.list_input_primary}>
                            {subjectTag?.map((items, i) => (
                              <>
                                <div key={i} className={style.pointer} onClick={() => selectPrimaryTag(items)}>
                                  <span>{items} </span>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {/* cancel button  */}
                      <div className={style.cancel_button}>
                        <button className={style.pointer} onClick={() => setSelectedPrimaryTagStatus(false)}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    {/* ___________________ Topic ___________________ */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Topic </p>
                      </div>
                    </div>
                    <div className={style.master_tag_search}>
                      {/* this is input field for searching */}
                      <div className={style.input_search} ref={rowRef}>
                        {selectedTopicTagStatus ? (
                          <span style={{ paddingLeft: '0px' }}> {selectedTopicTag} </span>
                        ) : (
                          <input
                            type="search"
                            id="searchInput"
                            placeholder="Type Topic"
                            onChange={(e) => TagTopic(e.target.value)}
                          />
                        )}

                        {TopicTagStatus ? (
                          <div className={style.list_input_primary}>
                            {TopicTag?.map((items, i) => (
                              <>
                                <div key={i} className={style.pointer} onClick={() => selectTopicTag(items)}>
                                  <span> {items} </span>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {/* cancel button  */}
                      <div className={style.cancel_button}>
                        <button className={style.pointer} onClick={() => setSelectedTopicTagStatus(false)}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className={style.master_modal}>
                    {/* ___________________ area ___________________ */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Area </p>
                      </div>
                    </div>
                    <div className={style.master_tag_search}>
                      {/* this is input field for searching */}
                      <div className={style.input_search} ref={rowRef}>
                        {selectedAreaTagStatus ? (
                          <span style={{ paddingLeft: '0px' }}> {selectedAreaTag} </span>
                        ) : (
                          <input
                            type="search"
                            id="searchInput"
                            placeholder="Type Area"
                            onChange={(e) => TagArea(e.target.value)}
                          />
                        )}

                        {AreaTagStatus ? (
                          <div className={style.list_input_primary}>
                            {AreaTag?.map((items, i) => (
                              <>
                                <div key={i} className={style.pointer} onClick={() => selectAreaTag(items)}>
                                  <span> {items} </span>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {/* cancel button  */}
                      <div className={style.cancel_button}>
                        <button className={style.pointer} onClick={() => setSelectedAreaTagStatus(false)}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    {/* ___________________ subtopic ___________________ */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Sub-Topic </p>
                      </div>
                    </div>
                    <div className={style.master_tag_search}>
                      {/* this is input field for searching */}
                      <div className={style.input_search} ref={rowRef}>
                        {/* multifreeTag selected one  */}
                        {multiselectedSubTopicTag?.map((items, i) => (
                          <>
                            <span key={i} className={style.selected_tags}>
                              {items}{' '}
                            </span>
                          </>
                        ))}

                        <input
                          type="search"
                          id="searchInput"
                          placeholder=""
                          value={multiSubTopicTaginput}
                          onChange={(e) => TagSubTopicMultiple(e.target.value)}
                        />

                        {/* list of items of pre-defined multiSubTopic tag  */}
                        {multiSubTopicTagStatus ? (
                          <div className={style.list_input_primary}>
                            {multiSubTopicTag?.map((items, i) => (
                              <>
                                <div key={i} className={style.pointer} onClick={() => selectSubTopicTag(items)}>
                                  <span>{items} </span>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {/* cancel button  */}
                      <div className={style.cancel_button}>
                        <button className={style.pointer} onClick={() => CancelButtonSubTopic()}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className={style.master_modal}>
                    {/* ___________________ Sub-top-description ___________________ */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Description of subtopic </p>
                      </div>
                    </div>
                    <div className={style.master_tag_search}>
                      {/* this is input field for searching */}
                      <div className={style.input_search} ref={rowRef}>
                        <input
                          type="text"
                          id="searchInput"
                          placeholder="Type Description Of Subtopic"
                          onChange={(e) => setdescriptionSubTopic(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className={style.master_modal}>
                    {/* ___________________ tags ___________________ */}
                    <div className={style.tags}>
                      <div className={style.subject}>
                        <p>Tags </p>
                      </div>
                    </div>

                    {multiselectedSubTopicTag.length === 0 ? (
                      <div>
                        <span className={style.AddpipelineWaterMark}>
                          No tags have been created yet. Enter the subject, area, topic and sub-topic to create a tag
                        </span>
                      </div>
                    ) : (
                      <div>
                        {multiselectedSubTopicTag?.map((items, i) => (
                          <>
                            <span key={i} className={style.slugs_Tag}>
                              {selectedPrimaryTag} | {selectedAreaTag} | {selectedTopicTag} | {items}
                            </span>{' '}
                            &nbsp;
                          </>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* <div className={style.master_modal}>
                                </div> */}
                </div>

                <div className={` ${style.saveButton_questionBank} ${style.margin_top_20} ${style.identifier_save}`}>
                  <div
                    className={` ${style.cancel} ${style.pointer}`}
                    onClick={() => {
                      clearAllData();
                    }}
                  >
                    Cancel
                  </div>
                  <div>
                    <button className={style.blue_button} onClick={() => AddNew()}>
                      Add Tag to List
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

MastersAddModal.propTypes = {
  categoryKey: PropTypes.any,
  closeModal: PropTypes.func,
  currentPage: PropTypes.any,
  limit: PropTypes.any,
  modal: PropTypes.any,
  setSort: PropTypes.func,
  type: PropTypes.string,
};

export default MastersAddModal;
