import PropTypes from 'prop-types';
import React from 'react';
import style from '../../../../../assets/styles/userManagement.module.css';
import NativeButton from '../../../../atoms/Button';

export default function ChipsSearchBar({
  placeholder,
  onChipsChange,
  onExistingChange,
  onNewChange,
  newDataStatus,
  newData,
  existingDataStatus,
  existingData,
  newCreation,
  label,
  onInputChange,
  searchTagLabel,
  value,
  chipsTags,
  setParamsTag,
  setQuestionBankTags,
  questionBankTagStatus,
  setQuestionBankTagStatus,
  disable = false,
}) {
  return (
    <div>
      {' '}
      <div style={{ width: '52%', marginTop: 14, padding: '0px' }} className={style.user_profile}>
        <div className={`${style.title_typography} ${style.title}`}>
          {label}
          <span className={style.required_field}> *</span>
        </div>
        <div className={style.tags}>
          <div style={{ marginTop: 0 }} className={style.search_listing}>
            <div className={style.tag_icon}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.2598 19.2592L16.1406 16.8594"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{searchTagLabel}</span>
            </div>
            <div className={style.input_search}>
              <svg
                style={{ position: 'relative', top: '14px', left: '14px' }}
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="20" height="20" rx="4" fill="#272727" />
                <path
                  d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                  fill="white"
                />
              </svg>
              <input
                disabled={disable}
                type="search"
                style={{ marginLeft: '15px', width: '90%' }}
                id="searchInput"
                placeholder={placeholder}
                value={value}
                autoComplete="off"
                list="autocompleteoff"
                className={`select-focused`}
                onChange={(e) => {
                  onInputChange(e.target.value);
                }}
              />

              {existingDataStatus && !disable ? (
                <div
                  style={{ height: existingData.length < 2 ? '30px' : '120px' }}
                  className={style.list_input_primary}
                >
                  {existingData?.map((items, i) => (
                    // <div key={i + items} className={style.pointer}>
                      <NativeButton
                        key={i + items}
                        //style={{display: 'content'}}
                        className={"freeTagsWidth"}
                        onClick={() => {
                          onExistingChange(items);
                        }}
                      >
                        <div className={style.pointer}>
                          <span>{items.name} </span>
                        </div>
                      </NativeButton>
                    // </div>
                  ))}
                </div>
              ) : (
                ''
              )}
              {newDataStatus && !disable ? (
                <div style={{ height: newData?.length < 2 ? '30px' : '120px' }} className={style.list_input_primary}>
                  {newData?.map((items, i) => (
                    <NativeButton
                    className={"freeTagsWidth"}
                      onClick={() => {
                        onNewChange(items);
                      }}
                      key={i + items}
                    >
                      <div className={style.pointer}>
                        <span>{items} </span>
                      </div>
                    </NativeButton>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style.selected_subtopic_tags}>
        {chipsTags?.length > 0 && (
          <div>
            <div style={{ marginLeft: 0 }} className={style.selected_tags_list}>
              {chipsTags?.map(
                (item, index) =>
                  item?.length > 0 && (
                    <span title={item} key={index + index} className={`${style.selected_tag_item} ${style.chips}`}>
                      {item.length > 8 ? `${item?.slice(0, 6)}...` : item}
                      {/* Cross icon to remove the selected tag */}
                      <svg
                        style={{ cursor: 'pointer' }}
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={style.remove_icon}
                        onClick={() => {
                          onChipsChange(item);
                        }} // Call removeTag function on click
                      >
                        <path
                          d="M18 6L6 18M6 6L18 18"
                          stroke="#272727"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ChipsSearchBar.propTypes = {
  chipsTags: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  disable: PropTypes.bool,
  existingData: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  existingDataStatus: PropTypes.any,
  label: PropTypes.any,
  newCreation: PropTypes.any,
  newData: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  newDataStatus: PropTypes.any,
  onChipsChange: PropTypes.func,
  onExistingChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onNewChange: PropTypes.func,
  placeholder: PropTypes.any,
  questionBankTagStatus: PropTypes.any,
  questionBankTags: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  searchTagLabel: PropTypes.any,
  setParamsTag: PropTypes.any,
  setQuestionBankTagStatus: PropTypes.any,
  setQuestionBankTags: PropTypes.any,
  style: PropTypes.shape({
    chips: PropTypes.any,
    input_search: PropTypes.any,
    list_input_primary: PropTypes.any,
    pointer: PropTypes.any,
    remove_icon: PropTypes.any,
    required_field: PropTypes.any,
    search_listing: PropTypes.any,
    selected_subtopic_tags: PropTypes.any,
    selected_tag_item: PropTypes.any,
    selected_tags_list: PropTypes.any,
    tag_icon: PropTypes.any,
    tags: PropTypes.any,
    title: PropTypes.any,
    title_typography: PropTypes.any,
    user_profile: PropTypes.any,
  }),
  value: PropTypes.any,
};
