/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LOGGED_IN_USER,
  BASIC_INFO,
  BASIC_INFORMATION,
  CLEAR_ALL_DATA,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_IRT_REPONSE_FAIL,
  GET_IRT_REPONSE_SUCCESS,
  GET_QDR_REPONSE_FAIL,
  GET_QDR_REPONSE_SUCCESS,
  QUESTION_BANK,
  // QUESTION_CREATION,
  QUESTION_CREATION_CANCEL,
  QUESTION_CREATION_CHOOSE_BACK,
  QUESTION_CREATION_ONE,
  QUESTION_CREATION_SCREEN_CANCEL,
  QUESTION_CREATION_TWO,
  SELECT_QUESTION_BANK,
  SELECT_QUESTION_BANK_RESPONSE,
  SEND_FOR_REVIEW,
  GET_TASKS_DETAIL_SUCCESS,
  GET_TASKS_DETAIL_FAIL,
  REVIEW_ALLDATA_SUCCESS,
  REVIEW_ALLDATA_FAIL,
  NEED_REVIEW_ALLDATA_SUCCESS,
  NEED_REVIEW_ALLDATA_FAIL,
  CHANGE_REVIEW_STATUS_SUCCESS,
  CHANGE_REVIEW_STATUS_FAIL,
  CHANGE_QUESTION_CATEGORY,
  TOGGLE_OPEN_STATUS,
  GET_USER_LIST_SUCESS,
  GET_USER_LIST_FAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_LIST_SUCCESS,
  GET_USER_ROLES_LIST_FAIL,
  GET_USER_ROLES_FAIL,
  GET_USER_QUESTION_BANK_SUCCESS,
  GET_ADD_USER_SUCCESS,
  GET_ADD_USER_FAIL,
  EDIT_USER_DETAIL_SUCCESS,
  EDIT_USER_DETAIL_FAIL,
  GET_ORGANISATION_LIST_SUCCESS,
  GET_ORGANISATION_LIST_FAIL,
  CHANGE_USER_SCREEN,
  CHANGE_QDR_SCREEN,
  CHANGE_IRT_SCREEN,
  CHANGE_ORGANISATION_SCREEN,
  CHANGE_MASTER_SCREEN,
  GET_CATEGORY_LIST_FAIL,
  GET_CATEGORY_LIST_SUCCESS,
  GET_IDENTIFIER_LIST_SUCCESS,
  GET_IDENTIFIER_LIST_FAIL,
  GET_LOD_LIST_SUCCESS,
  GET_LOD_LIST_FAIL,
  GET_SUBJECT_PIPELINE_LIST_SUCCESS,
  GET_SUBJECT_PIPELINE_LIST_FAIL,
  GET_QUESTION_BANK_LIST_SUCCESS,
  GET_QUESTION_BANK_LIST_FAIL,
  GET_TASK_SCREEN,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAIL,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_FAIL,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAIL,
  GET_TASK_SCREEN_WITH_ID,
  CHANGE_ADD_TEST_SCREEN,
  CREATE_TEST_BASIC_SETTING_SUCCESS,
  GET_TEST_LISTING_SUCCESS,
  GET_TEST_LISTING_FAIL,
  CHANGE_TEST_SCREEN,
  PRODUCT_TYPE_SUCCUSS,
  GET_TEST_DETAILS_SUCCESS,
  GET_TEST_DETAILS_FAIL,
  CREATE_TEST_SECTION_SUCCESS,
  GET_REPLACE_QUESTION_SUCCESS,
  GET_TETS_DETAILS_FOR_EDIT_SUCCESS,
  GET_TETS_DETAILS_FOR_EDIT_FAIL,
  CHANGE_EDIT_TEST_SCREEN,
  EDIT_CREATE_TEST_SECTION_SUCCESS,
  GET_BANK_LIST_WITH_PERMISSION_ROUTE,
  CREATE_TEST_BASIC_SETTING_SUCCESS_VIA_API,
  FLUSH_TESTPAPER_DATA,
} from './constant';
import Immutable from 'seamless-immutable';

// ____________ INITIAL DATA OF REDUCER for Creating Question Only ____________
export const initialData = {
  question_bank_id: 0,
};
export const BaiscInfoinitialData = {
  format: 0,
  difficulty: 0,
  primary_tag_id: 0,
  secondary_tags: [],
  free_tags: [],
  new_free_tags: [],
};
export const QuestionScreenData = {
  screen: 0,
  questiontype: {},
  questionDetail: {},
};
export const LoggedInUserData = {};

// ____________ REDUCERS for Creating Question Only ____________

// ****** REDUCER TO SAVE THE DATA OF SCREEN 0: QuestionBank Screen  ******
export const CreateQuestionReducer = (data = initialData, action: { type: any; data: any }) => {
  switch (action.type) {
    case SELECT_QUESTION_BANK:
      return { ...data, question_bank_id: action.data };
    case SELECT_QUESTION_BANK_RESPONSE:
      return data;
    case QUESTION_CREATION_SCREEN_CANCEL:
      return initialData; // Reset to initial state
    default:
      return data;
  }
};

// ****** REDUCER TO SAVE THE DATA OF SCREEN 1: BasicInformation Screen  ******
export const BasicInformationReducer = (data = BaiscInfoinitialData, action: { type: any; data: any }) => {
  switch (action.type) {
    case BASIC_INFORMATION:
      return action.data;
    case QUESTION_CREATION_SCREEN_CANCEL:
      return BaiscInfoinitialData; // Reset to initial state
    default:
      return data;
  }
};

// ****** REDUCER TO CHANGE THE SCREEN OF CREATE_QUESTION ******
export const QuestionScreenReducer = (
  data = QuestionScreenData,
  action: { type: any; screen: any; data: any; response: { data: any } }
) => {
  switch (action.type) {
    case QUESTION_BANK:
      return {
        screen: 0,
        // "questiontype": {}
      };
    case BASIC_INFO:
      return {
        screen: 1,
        // "questiontype": {}
      };
    case QUESTION_CREATION_ONE:
      return {
        screen: action.screen,
        questiontype: data.questiontype,
      };
    case QUESTION_CREATION_CHOOSE_BACK:
      return {
        screen: action.screen,
        // "questiontype": {}
      };
    case QUESTION_CREATION_CANCEL:
      return QuestionScreenData; // Reset to initial state
    case QUESTION_CREATION_TWO:
      return {
        screen: action.screen,
        questiontype: action.data,
      };
    case SEND_FOR_REVIEW:
      return {
        screen: 3,
        questiontype: data.questiontype,
        questionDetail: action.response.data,
      };
    case CLEAR_ALL_DATA:
      return QuestionScreenData;
    default:
      return data;
  }
};
// ****** REDUCER TO SAVE THE DATA FOR LOGGED IN USER ******
export const LoggedInUserReducer = (data = LoggedInUserData, action: { type: any; data: any }) => {
  switch (action.type) {
    case LOGGED_IN_USER:
      return { ...data, ...action.data };

    default:
      return data;
  }
};
// ____________ REDUCERS for rest of All ____________

// ____________ INITIAL DATA OF REDUCER for rest of ALL ____________

export const initialState = Immutable({
  qdrList: undefined,
  qdrFilterScreen: true,
  irtList: undefined,
  irtFilterScreen: true,
  taskManagementScreens: 0,
  taskList: undefined,
  taskDetail: undefined,
  reviewdata: undefined,
  reviewstatus: undefined,
  questionCategoryInReview: true,
  isOpenInReview: false,
  userData: undefined,
  userDataInfo: undefined,
  userScreen: 0,
  roles: undefined,
  questionBank: undefined,
  organisationList: undefined,
  organisationScreen: 0,
  masterScreen: 1,
  categoryList: undefined,
  identifierList: undefined,
  lodList: undefined,
  subjectPipelineList: undefined,
  questionBankList: undefined,
  questionBankListWithPermissions: undefined,
  productType: undefined,
  taskEdit: undefined,
  taskWithId: 0,
  testScreen: 0,
  testCreationId: null,
  addtestscreen: 0,
  getBasicSettingData: null,
  getTestCreationData: undefined,
  getProductType: null,
  testDetails: undefined,
  sectionBody: {},
  replacedQuestion: null,
  edittestscreen: 0,
});

export const GetAllData = (
  state = initialState,
  action: {
    type: any;
    response: { data: { id: any; data: { id: any } } | undefined,id:any|undefined };
    status: any;
    screen: any;
    taskId: any;
    body: any;
    id: any;
    data?: any;
  }
) => {
  switch (action.type) {
    case GET_QDR_REPONSE_SUCCESS:
      return state.setIn(['qdrList'], action.response.data);
    case GET_QDR_REPONSE_FAIL:
      return state.setIn(['qdrList'], action.response.data);
    case CHANGE_QDR_SCREEN:
      return state.setIn(['qdrFilterScreen'], action.status);
    case GET_IRT_REPONSE_SUCCESS:
      return state.setIn(['irtList'], action.response.data);
    case GET_IRT_REPONSE_FAIL:
      return state.setIn(['irtList'], action.response.data);
    case CHANGE_IRT_SCREEN:
      return state.setIn(['irtFilterScreen'], action.status);

    case GET_TASK_SCREEN:
      return state.setIn(['taskManagementScreens'], action.screen);

    case GET_TASK_SCREEN_WITH_ID:
      return state.setIn(['taskManagementScreens'], action.screen).setIn(['taskWithId'], action.taskId);

    case CREATE_TASK_SUCCESS:
      return state.setIn(['taskManagementScreens'], 0);

    case CREATE_TASK_FAIL:
      return state.setIn(['taskManagementScreens'], 0);

    case GET_TASKS_SUCCESS:
      return state.setIn(['taskList'], action.response.data).setIn(['taskManagementScreens'], 0);
    case GET_TASKS_FAIL:
      return state.setIn(['taskList'], action.response.data);
    case GET_TASKS_DETAIL_SUCCESS:
      return state.setIn(['taskDetail'], action.response.data).setIn(['taskManagementScreens'], 2);
    case GET_TASKS_DETAIL_FAIL:
      return state.setIn(['taskDetail'], action.response.data);
    case REVIEW_ALLDATA_SUCCESS:
      return state.setIn(['reviewdata'], action.response.data);
    case REVIEW_ALLDATA_FAIL:
      return state.setIn(['reviewdata'], action.response.data);
    case NEED_REVIEW_ALLDATA_SUCCESS:
      return state.setIn(['reviewdata'], action.response.data);
    case NEED_REVIEW_ALLDATA_FAIL:
      return state.setIn(['reviewdata'], action.response.data);
    case CHANGE_REVIEW_STATUS_SUCCESS:
      return state.setIn(['questionCategoryInReview'], true).setIn(['isOpenInReview'], false);
    case CHANGE_REVIEW_STATUS_FAIL:
      return state.setIn(['questionCategoryInReview'], false).setIn(['isOpenInReview'], true);
    case CHANGE_QUESTION_CATEGORY:
      return state.setIn(['questionCategoryInReview'], action.status);
    case TOGGLE_OPEN_STATUS:
      return state.setIn(['isOpenInReview'], action.status);
    case GET_USER_LIST_SUCESS:
      return state.setIn(['userData'], action.response.data);
    case GET_USER_LIST_FAIL:
      return state.setIn(['userData'], action.response.data);
    case GET_USER_DETAIL_SUCCESS:
      return state.setIn(['userDataInfo'], action.response.data).setIn(['userScreen'], 2);
    case CHANGE_USER_SCREEN:
      return state.setIn(['userScreen'], action.screen);
    case GET_USER_DETAIL_FAIL:
      return state.setIn(['userDataInfo'], action.response.data);
    case GET_USER_ROLES_SUCCESS:
      return state.setIn(['roles'], action.response.data);
    case GET_USER_ROLES_FAIL:
      return state.setIn(['roles'], action.response.data);

    case GET_USER_ROLES_LIST_SUCCESS:
      return state.setIn(['roles'], action.response.data);
    case GET_USER_ROLES_LIST_FAIL:
      return state.setIn(['roles'], action.response.data);

    case GET_USER_QUESTION_BANK_SUCCESS:
      return state.setIn(['questionBank'], action.response.data);
    case GET_BANK_LIST_WITH_PERMISSION_ROUTE:
      return state.setIn(['questionBankListWithPermissions'], action?.data);
    case GET_ADD_USER_SUCCESS:
      return state.setIn(['userScreen'], 0);
    case GET_ADD_USER_FAIL:
      return state.setIn(['userScreen'], 0);
    case EDIT_USER_DETAIL_SUCCESS:
      return state.setIn(['userDataInfo'], action.response.data).setIn(['userScreen'], 2);
    case EDIT_USER_DETAIL_FAIL:
      return state.setIn(['userDataInfo'], action.response.data);
    case GET_ORGANISATION_LIST_SUCCESS:
      return state.setIn(['organisationList'], action.response.data);
    case GET_ORGANISATION_LIST_FAIL:
      return state.setIn(['organisationList'], action.response.data);
    case CHANGE_ORGANISATION_SCREEN:
      return state.setIn(['organisationScreen'], action.status);
    case CHANGE_MASTER_SCREEN:
      return state.setIn(['masterScreen'], action.screen);
    case GET_CATEGORY_LIST_SUCCESS:
      return state.setIn(['categoryList'], action.response.data);
    case GET_CATEGORY_LIST_FAIL:
      return state.setIn(['categoryList'], action.response.data);
    case GET_IDENTIFIER_LIST_SUCCESS:
      return state.setIn(['identifierList'], action.response.data);
    case GET_IDENTIFIER_LIST_FAIL:
      return state.setIn(['identifierList'], action.response.data);
    case GET_LOD_LIST_SUCCESS:
      return state.setIn(['lodList'], action.response.data);
    case GET_LOD_LIST_FAIL:
      return state.setIn(['lodList'], action.response.data);
    case GET_SUBJECT_PIPELINE_LIST_SUCCESS:
      return state.setIn(['subjectPipelineList'], action.response.data);
    case GET_SUBJECT_PIPELINE_LIST_FAIL:
      return state.setIn(['subjectPipelineList'], action.response.data);
    case GET_QUESTION_BANK_LIST_SUCCESS:
      return state.setIn(['questionBankList'], action.response.data);
    case GET_QUESTION_BANK_LIST_FAIL:
      return state.setIn(['questionBankList'], action.response.data);

    case GET_PRODUCT_TYPE_SUCCESS:
      return state.setIn(['productType'], action.response.data);
    case GET_PRODUCT_TYPE_FAIL:
      return state.setIn(['productType'], action.response.data);

    case EDIT_TASK_SUCCESS:
      return state.setIn(['taskEdit'], action.response.data);
    case EDIT_TASK_FAIL:
      return state.setIn(['taskEdit'], action.response.data);
    case CHANGE_TEST_SCREEN:
      return state.setIn(['testScreen'], action.screen);
    case CHANGE_ADD_TEST_SCREEN:
      return state.setIn(['addtestscreen'], action.screen);
    case CHANGE_EDIT_TEST_SCREEN:
      return state.setIn(['edittestscreen'], action.screen);
    case CREATE_TEST_BASIC_SETTING_SUCCESS:
      return state
        .setIn(['testCreationId'], action.response?.data?.data?.id)
        .setIn(['addtestscreen'], action.screen)
        .setIn(['getBasicSettingData'], { ...action.body, ...action.response?.data });
        case CREATE_TEST_BASIC_SETTING_SUCCESS_VIA_API:
      return state
        .setIn(['testCreationId'], action?.response?.id)
        .setIn(['addtestscreen'], action.screen)
        .setIn(['getBasicSettingData'], { ...action.body, ...action?.response });
    case CREATE_TEST_SECTION_SUCCESS:
   
      return state.setIn(['sectionBody'], action.body).setIn(['addtestscreen'], action.screen);
    case EDIT_CREATE_TEST_SECTION_SUCCESS:
      return state.setIn(['sectionBody'], action.body).setIn(['edittestscreen'], action.screen);
    case GET_TEST_LISTING_SUCCESS:
      return state.setIn(['getTestCreationData'], action.response.data);
    case GET_TEST_LISTING_FAIL:
      return state.setIn(['getTestCreationData'], action.response.data);
    case PRODUCT_TYPE_SUCCUSS:
      return state.setIn(['getProductType'], action.response.data);

    case GET_TEST_DETAILS_SUCCESS:
      return state.setIn(['testDetails'], action.response.data);
    case GET_TEST_DETAILS_FAIL:
      return state.setIn(['testDetails'], action.response.data);
      case FLUSH_TESTPAPER_DATA:
        return state.setIn(['testDetails'], null).setIn(['getBasicSettingData'], null).setIn(['sectionBody'], {});
    case GET_REPLACE_QUESTION_SUCCESS:
      return state.setIn(['replacedQuestion'], action.response.data);

    case GET_TETS_DETAILS_FOR_EDIT_SUCCESS:
      return state.setIn(['testDetails'], {
        id: action.id,
        ...action.response.data,
      });
    case GET_TETS_DETAILS_FOR_EDIT_FAIL:
      return state.setIn(['testDetails'], {
        id: action.id,
        ...action.response.data,
      });
    default:
      return state;
  }
};
