/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  Duplicate_Test,
  Get_Test_Deatils,
  Get_Test_Detail_For_Edit,
  Get_Test_list,
  Published_Test,
  Unpublish_Test,
  changeAdd_test_screen,
  change_test_screen,
} from '../../../../../redux/action';

import styleModal from '../../../../../assets/styles/modalStyle.module.css';
// import TaskFilterModal from "../../../../atoms/Modal/TaskFilterModal";
import TestFilterModal from '../../../../atoms/Modal/TestFilterModal';
import moment from 'moment';
import { Pagination } from '../../../common/Pagination';
import NativeButton from '../../../../atoms/Button';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_TEST_LISTS } from '../../../../../action/apiPath';
import { useToast } from '../../../common/Toast';
import { GET_TEST_LISTING_FAIL, GET_TEST_LISTING_SUCCESS } from '../../../../../redux/constant';
import { useNavigate } from 'react-router-dom';

const TestList = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { getTestCreationData } = useSelector((state) => state.GetAllData);
  const navigate = useNavigate();
  //  FILTERS
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [selectedId, setSelectedId] = useState([]);
  const [selecteStatus, setSelecteStatus] = useState([]);
  const [academicYear, setAcademicYear] = useState('');
  const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([]);
  const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([]);
  const [selecetdPlayer, setSelectedPlayer] = useState([]);

  const handleFilterStateChange = (
    newMultiAuthorTag,
    newMultiFreeTag,
    newSearchId,
    newSelecteStatus,
    newAcademicYear,
    newSelecetdPlayer
  ) => {
    setmultiSelectedAuthorTag(newMultiAuthorTag);
    setmultiSelectedFreeTag(newMultiFreeTag);
    setSelectedId(newSearchId);
    setSelecteStatus(newSelecteStatus);
    setAcademicYear(newAcademicYear);
    setSelectedPlayer(newSelecetdPlayer);
  };

  /********  Sorting by below */
  const [sort, setSort] = useState('-created_at');

  const [sortId, setSortId] = useState(true);
  const [sortTestName, setSortTestName] = useState(true);
  const [sortProductType, setSortProductType] = useState(true);
  const [sortTestType, setSortTestType] = useState(true);
  const [sortQuestionbankName, setSortQuestionbankName] = useState(true);
  const [sortCreatedAt, setSortCreatedAt] = useState(true);
  const [sortCreatedBy, setSortCreatedBy] = useState(true);
  const [sortAcademicYear, setSortAcademicYear] = useState(true);
  const [sortStatus, setSortStatus] = useState(true);
  const [totalCount, setTotalCount] = useState(getTestCreationData?.count ?? 0);
  const [last, setLast] = useState(15);
  const [limit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(currentPage);
  const [nextUrl, setNextUrl] = useState(getTestCreationData?.next ?? null);
  const [prevUrl, setPrevUrl] = useState(getTestCreationData?.previous ?? null);

  useEffect(() => {
    setNextUrl(getTestCreationData?.next);
    setPrevUrl(getTestCreationData?.previous);
    setTotalCount(getTestCreationData?.count);
  }, [getTestCreationData]);

  const filterParams = {
    ids: Array.isArray(selectedId) ? selectedId.map(Number).join(',') : Number(selectedId) || '',
    status: Array.isArray(selecteStatus)
      ? selecteStatus
          .map((status) => {
            if (status === 'Draft') return 0;
            if (status === 'In-Review') return 1;
            if (status === 'Published') return 2;
            return '';
          })
          .join(',')
      : '',

    year: academicYear || '',
    free_tags: multiselectedFreeTag?.map((tag) => tag.id).join(','),
    author_tags: Array.isArray(multiselectedAuthorTag)
      ? multiselectedAuthorTag?.map((tag) => Number(tag.id)).join(',')
      : '',
    player_tags: Array.isArray(selecetdPlayer) ? selecetdPlayer?.map((tag) => Number(tag.id)).join(',') : '',
  };
  const getTestList = async (filterData) => {
    let url = `${GET_TEST_LISTS}?keyword=${key ?? ''}&sort=${sort}`;
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }

    // Check if filterParams is not empty
    if (Object.keys(filterData).length > 0) {
      url += `&player_types=${filterData.player_tags}&free_tags=${filterData.free_tags}&ids=${filterData.ids}&authors=${filterData.author_tags}&year=${filterData.year}&status=${filterData.status}`;
    }
    setPrevUrl(null);
    setNextUrl(null);
    const result = await CallWithAuth('GET', url);
    if (result?.res?.status == 200) {
      const response = result.res;
      dispatch({ type: GET_TEST_LISTING_SUCCESS, response });
    } else {
      dispatch({ type: GET_TEST_LISTING_FAIL, response });
    }
  };
  const key = '';
  useEffect(() => {
    if (filtersApplied) {
      getTestList(filterParams);
    } else {
      getTestList({});
    }
  }, [
    sort,
    filtersApplied,
    sortId,
    sortTestName,
    sortProductType,
    sortTestType,
    sortQuestionbankName,
    sortCreatedAt,
    sortCreatedBy,
    sortAcademicYear,
    sortStatus,
    selectedId,
    selecteStatus,
    academicYear,
    multiselectedFreeTag,
    multiselectedAuthorTag,
    selecetdPlayer,
    limit,
    key,
    currentPage,
    inputPage,
  ]);
  const [sortConfig, setSortConfig] = useState({
    field: 'created_at',
    direction: 'desc',
    sort: '-created_at',
  });

  const handleSort = (field) => {
    setSortConfig((prevConfig) => {
      let direction, sortValue;

      if (field === 'created_at') {
        // Toggle between ascending and descending for created_at
        if (prevConfig.field === field && prevConfig.direction === 'desc') {
          direction = 'desc';
          sortValue = field;
        } else {
          direction = 'asc';
          sortValue = `-${field}`;
        }
      } else {
        // General sorting logic for other fields
        direction = prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc';
        sortValue = direction === 'desc' ? `-${field}` : field;
      }

      setSort(sortValue);
      setSortConfig({ field, direction, sort: sortValue });

      // Reset pagination
      setCurrentPage(1);
      setInputPage(1);
      setOffset(0);
      setPrevUrl(null);
      setLast(15);

      return { field, direction, sort: sortValue };
    });
  };
  /***********  SORTING OVER *******************/

  // ________ filter modal open  ________
  const [modalFilter, setModalFilter] = useState(false);

  const openModalFilter = () => {
    setModalFilter(true);
  };

  const closeModalFilter = () => {
    setCurrentPage(1);
    setInputPage(1);
    setOffset(0);
    setPrevUrl(null);
    setLast(15);
    setModalFilter(false);
  };
  /*****Function to open edit / delete modal */
  const [showModal, setShowModal] = useState(false);

  const handleClick = (index) => {
    setShowModal((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const ModalComponent = ({ onClose, taskId, items }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [action, setAction] = useState('');

    const handleAction = (actionType) => {
      setAction(actionType);
      setShowConfirmation(true);
    };

    const handleConfirm = () => {
      if (action === 'unpublish') {
        dispatch(Unpublish_Test(taskId));
      } else if (action === 'publish') {
        dispatch(Published_Test(taskId));
      } else if (action === 'duplicate') {
        // dispatch(Duplicate_Test(taskId, editedName));
        duplicate_test({ name: editedName, id: taskId });
      }
      setShowConfirmation(false);
      onClose();
    };

    const duplicate_test = async (formData) => {
      const duplicateUrl = `${GET_TEST_LISTS}duplicate/`;
      const response = await CallWithAuth('POST', duplicateUrl, formData);
      if (response?.res?.status === 201) {
        const successMessage = response?.res?.data?.message;
        if (toast) {
          toast.add(successMessage, 'success');
        }
        getTestList({});
      } else {
        const errorMessage = response?.res?.data?.detail || response?.res?.data?.error;
        toast?.add(errorMessage, 'error');
      }
      return response;
    };

    const handleOutsideClick = (event) => {
      if (event.target.className === 'overlay') {
        onClose();
      }
    };

    const handleCancelDelete = () => {
      setShowConfirmation(false);
      onClose();
    };

    // __________ outside clickable close modal _______________

    const modalRef = useRef();

    const handleOverlayClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCancelDelete();
      }
    };

    useEffect(() => {
      // Attach the event listener when the component mounts
      document.addEventListener('mousedown', handleOverlayClick);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleOverlayClick);
      };
    }, [handleOverlayClick]);

    const [editedName, setEditedName] = useState('');

    const handleNameChange = (e) => {
      setEditedName(e.target.value);
    };

    return (
      <>
        {showConfirmation ? (
          <div className={styleModal.filterModal}>
            <div className={` ${styleModal.center_div} ${styleModal.delete}`} ref={modalRef}>
              {/* TABLE LISTING OF ALL AND MY QUESTION  */}
              <div className={styleModal.master_modal}>
                <div className={`${styleModal.title_delete} ${styleModal.center_text}`}>
                  {action === 'unpublish' && `Are you sure, Do you want to unpublish ( Task : ${taskId} ) ?`}
                  {action === 'duplicate' && (
                    <>
                      <p>Are you sure you want to duplicate this Test? (Test ID: {taskId})</p>
                      <div className={style.input_container}>
                        <label className="input_label">Enter test name</label>
                        <input
                          className={style.input_field}
                          type="text"
                          value={editedName}
                          onChange={handleNameChange}
                          placeholder="Enter name"
                        />
                      </div>
                    </>
                  )}
                  {action === 'publish' && `Are you sure, Do you want to publish ( Task : ${taskId} ) ?`}
                </div>

                <div className={` ${styleModal.saveButton_questionBank} ${styleModal.margin_top_20} ${style.center}`}>
                  <div className={` ${styleModal.cancel} ${styleModal.pointer}`} onClick={handleCancelDelete}>
                    Cancel
                  </div>
                  <div>
                    <button className={styleModal.blue_button} onClick={handleConfirm}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // <div className="overlay" style={{ position: 'relative' }} >
          <div
            className={style.threePin}
            ref={modalRef}
            onClick={handleOutsideClick}
            style={{ position: 'absolute', right: '-12px', top: '285px' }}
          >
            <div className={style.editPops}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 14.668H14"
                  stroke="#212529"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                  stroke="#212529"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                  stroke="#212529"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div
                className={style.action}
                onClick={() => {
                  navigate(`/test-papers-management/edit/${taskId}`);
                  //dispatch(Get_Test_Detail_For_Edit(taskId))
                }}
                // onClick={() =>
                //   dispatch(Task_Screen_Change_With_TaskId(taskId, 3))
                // }
              >
                Edit
              </div>
            </div>
            {items.status !== 'Draft' && items.status !== 'In-Review' ? (
              <div className={style.editPops}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_3757_42938)">
                    <path
                      d="M9.99967 0.667969H2.66634C1.93301 0.667969 1.33301 1.26797 1.33301 2.0013V10.668C1.33301 11.0346 1.63301 11.3346 1.99967 11.3346C2.36634 11.3346 2.66634 11.0346 2.66634 10.668V2.66797C2.66634 2.3013 2.96634 2.0013 3.33301 2.0013H9.99967C10.3663 2.0013 10.6663 1.7013 10.6663 1.33464C10.6663 0.967969 10.3663 0.667969 9.99967 0.667969ZM12.6663 3.33464H5.33301C4.59967 3.33464 3.99967 3.93464 3.99967 4.66797V14.0013C3.99967 14.7346 4.59967 15.3346 5.33301 15.3346H12.6663C13.3997 15.3346 13.9997 14.7346 13.9997 14.0013V4.66797C13.9997 3.93464 13.3997 3.33464 12.6663 3.33464ZM11.9997 14.0013H5.99967C5.63301 14.0013 5.33301 13.7013 5.33301 13.3346V5.33464C5.33301 4.96797 5.63301 4.66797 5.99967 4.66797H11.9997C12.3663 4.66797 12.6663 4.96797 12.6663 5.33464V13.3346C12.6663 13.7013 12.3663 14.0013 11.9997 14.0013Z"
                      fill="#212529"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3757_42938">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <div className={style.action} onClick={() => handleAction('duplicate')}>
                  Duplicate
                </div>
              </div>
            ) : null}
            <div className={style.editPops}>
              {items.status === 'In-Review' && (
                <>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.62661 7.54033C5.37277 7.28649 4.96121 7.28649 4.70737 7.54033C4.45353 7.79417 4.45353 8.20573 4.70737 8.45957L5.62661 7.54033ZM7.05366 9.88661L6.59404 10.3462C6.84788 10.6001 7.25944 10.6001 7.51328 10.3462L7.05366 9.88661ZM11.2933 6.5729C11.5471 6.31906 11.5471 5.9075 11.2933 5.65366C11.0394 5.39982 10.6279 5.39982 10.374 5.65366L11.2933 6.5729ZM8.73331 9.1262C8.98715 8.87236 8.98715 8.46081 8.73331 8.20697C8.47947 7.95312 8.06791 7.95312 7.81407 8.20697L8.73331 9.1262ZM9.37633 6.65137L8.91671 7.11099L9.83595 8.03023L10.2956 7.57061L9.37633 6.65137ZM4.70737 8.45957L6.59404 10.3462L7.51328 9.42699L5.62661 7.54033L4.70737 8.45957ZM7.51328 10.3462L8.73331 9.1262L7.81407 8.20697L6.59404 9.42699L7.51328 10.3462ZM10.2956 7.57061L11.2933 6.5729L10.374 5.65366L9.37633 6.65137L10.2956 7.57061Z"
                      fill="#292D32"
                    />
                    <path
                      d="M3.23872 3.33203C2.05989 4.53453 1.33301 6.18173 1.33301 7.9987C1.33301 11.6806 4.31778 14.6654 7.99967 14.6654C9.83012 14.6654 11.4883 13.9277 12.693 12.7334M4.83357 2.13036C5.77544 1.62113 6.8538 1.33203 7.99967 1.33203C11.6816 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 9.07777 14.41 10.097 13.9548 10.9987"
                      stroke="#292D32"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                    />
                  </svg>

                  <div className={style.action} onClick={() => handleAction('publish')}>
                    Publish
                  </div>
                </>
              )}

              {items.status === 'Published' && (
                <>
                  <>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.62661 7.54033C5.37277 7.28649 4.96121 7.28649 4.70737 7.54033C4.45353 7.79417 4.45353 8.20573 4.70737 8.45957L5.62661 7.54033ZM7.05366 9.88661L6.59404 10.3462C6.84788 10.6001 7.25944 10.6001 7.51328 10.3462L7.05366 9.88661ZM11.2933 6.5729C11.5471 6.31906 11.5471 5.9075 11.2933 5.65366C11.0394 5.39982 10.6279 5.39982 10.374 5.65366L11.2933 6.5729ZM8.73331 9.1262C8.98715 8.87236 8.98715 8.46081 8.73331 8.20697C8.47947 7.95312 8.06791 7.95312 7.81407 8.20697L8.73331 9.1262ZM9.37633 6.65137L8.91671 7.11099L9.83595 8.03023L10.2956 7.57061L9.37633 6.65137ZM4.70737 8.45957L6.59404 10.3462L7.51328 9.42699L5.62661 7.54033L4.70737 8.45957ZM7.51328 10.3462L8.73331 9.1262L7.81407 8.20697L6.59404 9.42699L7.51328 10.3462ZM10.2956 7.57061L11.2933 6.5729L10.374 5.65366L9.37633 6.65137L10.2956 7.57061Z"
                        fill="#292D32"
                      />
                      <path
                        d="M1.33366 1.33203L14.667 14.6654"
                        stroke="#292D32"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.23872 3.33203C2.05989 4.53453 1.33301 6.18173 1.33301 7.9987C1.33301 11.6806 4.31778 14.6654 7.99967 14.6654C9.83012 14.6654 11.4883 13.9277 12.693 12.7334M4.83357 2.13036C5.77544 1.62113 6.8538 1.33203 7.99967 1.33203C11.6816 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 9.07777 14.41 10.097 13.9548 10.9987"
                        stroke="#292D32"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                      />
                    </svg>

                    <div className={style.action} onClick={() => handleAction('unpublish')}>
                      Unpublish
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
          // </div>
        )}
      </>
    );
  };
  const getRotation = (field) => {
    if (sortConfig.field !== field) return 'rotate(0deg)';
    return sortConfig.direction === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)';
  };

  // Helper function to get icon color
  const getIconColor = (field) => {
    return sortConfig.field === field ? '#00ABFB' : '#999090';
  };

  return (
    <div className={style.test_listing_page}>
      <div style={{ display: 'flex' }} className={style.upperSection}>
        <div style={{ flex: 2 }} className={style.text}>
          <span>Test Papers</span>
        </div>
        <div className={style.midValues}>
          {/* <div className={style.search_listing}>
            <div className={style.tag_icon}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M18.2598 19.2592L16.1406 16.8594"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <span>Templates</span>
            </div>

            <div className={style.input_search}>
              <svg
                style={{ marginTop: "10px", marginLeft: "10px" }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="4" fill="#272727" />
                <path
                  d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                  fill="white"
                />
              </svg>
              <input
                style={{ borderRadius: "24px" }}
                type="search"
                id="searchInput"
                placeholder="Search by product types and templates"
              />
            </div>
          </div> */}

          <div className={style.filter}>
            <div className={style.pointer} onClick={() => openModalFilter()}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.39844 2.10156H18.5984C19.6984 2.10156 20.5984 3.00156 20.5984 4.10156V6.30156C20.5984 7.10156 20.0984 8.10156 19.5984 8.60156L15.2984 12.4016C14.6984 12.9016 14.2984 13.9016 14.2984 14.7016V19.0016C14.2984 19.6016 13.8984 20.4016 13.3984 20.7016L11.9984 21.6016C10.6984 22.4016 8.89844 21.5016 8.89844 19.9016V14.6016C8.89844 13.9016 8.49844 13.0016 8.09844 12.5016L4.29844 8.50156C3.79844 8.00156 3.39844 7.10156 3.39844 6.50156V4.20156C3.39844 3.00156 4.29844 2.10156 5.39844 2.10156Z"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span>Filter</span>
            </div>
          </div>
        </div>
        <div className={style.rightButton}>
          <button
            onClick={() => {
              navigate('/test-papers-management/add');
              // dispatch(change_test_screen(1))
            }}
            className={` ${style.add_new_question} ${style.pointer}`}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" fill="white" />
              <path d="M7.5 12H16.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 16.5V7.5" stroke="#00ABFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <span>Add New Test Paper</span>
          </button>
        </div>
      </div>
      <div className={style.mainSection}>
        <div className={style.mainSectiontableWrap}>
          <table>
            <thead>
              <tr>
                <th className={` ${style.first} ${style.pointer}`}>
                  <span>Test ID</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('id');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('id'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('id')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('id')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('id')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('id')}
                    />
                  </svg>
                </th>

                <th className={` ${style.second} ${style.pointer}`}>
                  <span>Test Name</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('test_name');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('test_name'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('test_name')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('test_name')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('test_name')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('test_name')}
                    />
                  </svg>
                </th>
                <th className={` ${style.third} ${style.pointer}`}>
                  <span>Player Type</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('product_type');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('product_type'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('product_type')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('product_type')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('product_type')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('product_type')}
                    />
                  </svg>
                </th>
                <th className={` ${style.fourth} ${style.pointer}`}>
                  <span>Test Type</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('test_type');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('test_type'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('test_type')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('test_type')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('test_type')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('test_type')}
                    />
                  </svg>
                </th>
                <th className={` ${style.fifth} ${style.pointer}`}>
                  <span>Question Bank</span>
                </th>
                <th className={` ${style.sixth} ${style.pointer}`}>
                  <span>Created On</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('created_at');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('created_at'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('created_at')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('created_at')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('created_at')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('created_at')}
                    />
                  </svg>
                </th>
                <th className={` ${style.seventh} ${style.pointer}`}>
                  <span>Author</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('created_by');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('created_by'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('created_by')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('created_by')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('created_by')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('created_by')}
                    />
                  </svg>
                </th>
                <th className={` ${style.eighth} ${style.pointer}`}>
                  <span>Academic Year</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('academic_year');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('academic_year'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('academic_year')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('academic_year')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('academic_year')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('academic_year')}
                    />
                  </svg>
                </th>
                <th className={` ${style.ninth} ${style.pointer}`}>
                  <span>Status</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSort('status');
                    }}
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '6px',
                      transform: getRotation('status'),
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <path
                      d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                      fill={getIconColor('status')}
                    />
                    <path
                      d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                      fill={getIconColor('status')}
                    />
                    <path
                      d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                      fill={getIconColor('status')}
                    />
                    <path
                      d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                      fill={getIconColor('status')}
                    />
                  </svg>
                </th>
              </tr>
            </thead>

            <tbody>
              {getTestCreationData?.results?.map((items, i) => (
                <tr key={i + items}>
                  <td key={i + items}>
                    <div className={style.testId}>
                      <NativeButton onClick={() => dispatch(Get_Test_Deatils(items.id))}>
                        <u
                          style={{
                            fontWeight: 500,
                            color: '#00abfb',
                            cursor: 'pointer',
                          }}
                        >
                          {items.id}
                        </u>
                      </NativeButton>
                    </div>
                  </td>
                  <td>
                    <span>{items.test_name}</span>
                    <span
                      style={{
                        display: 'flex',
                        gap: '5px',
                        width: 'fit-content',
                      }}
                      className={style.description_detail}
                    >
                      {items?.free_tags.map((item, i) => (
                        <button key={i + item} className={style.general_button}>
                          {item.name}
                        </button>
                      ))}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        display: 'flex',
                        gap: '5px',
                        width: 'fit-content',
                        flexWrap: 'wrap',
                      }}
                      className={style.description_detail}
                    >
                      <button className={style.template_button}>{items.player_type}</button>
                    </span>
                  </td>
                  <td>{items.test_type}</td>
                  <td>
                    {items?.question_banks.slice(0, 3).map((item, i) => (
                      <button key={i + item} className={style.template_button}>
                        {item}
                      </button>
                    ))}
                    {items?.question_banks.length > 3 && (
                      <button className={style.template_button}>+{items.question_banks.length - 3}</button>
                    )}
                  </td>
                  <td>{moment(items?.created_on).format('DD MMM YYYY')}</td>
                  <td>{items.author}</td>
                  <td>{items.academic_year}</td>
                  <td>
                    <div className={style.user_management_table_body_content}>
                      {items.status === 'Draft' ? <button className={style.draft}>{items.status}</button> : ''}
                      {items.status === 'Published' ? <button className={style.published}>{items.status}</button> : ''}
                      {items.status === 'In-Review' ? <button className={style.in_review}>{items.status}</button> : ''}

                      <svg
                        style={{ cursor: 'pointer', padding: '0 12px' }}
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleClick(i)}
                      >
                        <path
                          d="M2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12ZM2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6ZM2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0Z"
                          fill="#272727"
                        />
                      </svg>

                      {showModal[i] && (
                        <ModalComponent onClose={() => handleClick(i)} index={i} items={items} taskId={items.id} />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className='paginationTableWrapper'> */}
        <div className={style.testPaperListingPagination}>
          <Pagination
            totalCount={totalCount}
            limit={limit}
            currentPage={currentPage}
            inputPage={inputPage}
            offset={offset}
            last={last}
            nextUrl={nextUrl}
            prevUrl={prevUrl}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            setInputPage={setInputPage}
            setLast={setLast}
            setNextUrl={setNextUrl}
          />
        </div>
        {/* </div> */}
        {modalFilter && (
          <TestFilterModal
            modalFilter={modalFilter}
            closeModalFilter={closeModalFilter}
            onFilterStateChange={handleFilterStateChange}
            setFiltersApplied={setFiltersApplied}
          />
        )}
      </div>
    </div>
  );
};

export default TestList;
