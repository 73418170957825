/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import ToastContext from './context';
import Toast from './Toast';


function getRandomNumber() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array); // Generates cryptographically secure random values
  return array[0] % 46656;
}

function generateUEID() {
  let first: any = getRandomNumber();
  let second: any = getRandomNumber();
  first = ('000' + first.toString(36)).slice(-3);
  second = ('000' + second.toString(36)).slice(-3);

  return first + second;
}

function withToastProvider(Component: any) {
  function WithToastProvider(props: any) {
    const [toasts, setToasts] = useState<any[]>([]);
    const timeoutMap = useRef<Map<string, NodeJS.Timeout>>(new Map());
    const add = (content: any, type: string) => {
      const id = generateUEID();
      setToasts((prev) => [...prev, { id, content, type }]);
      // Add to timeout map
      const timeout = setTimeout(() => {
        remove(id);
      }, 5000);
      timeoutMap.current.set(id, timeout);
    };

    const remove = (id: any) => {
      if (id === 'all') {
        // Clear all timeouts
        timeoutMap.current.forEach((timeout) => clearTimeout(timeout));
        timeoutMap.current.clear();
        // Remove all toasts
        setToasts(() => []); // Reset state to an empty array
        return; // Exit early to prevent further execution
      }

      // Clear specific timeout
      const timeout = timeoutMap.current.get(id);
      if (timeout) {
        clearTimeout(timeout);
        timeoutMap.current.delete(id);
      }
      const removeToast = toasts.filter((toast) => toast.id !== id);
      // Remove the specific toast from state
      setToasts(removeToast);
    };

    const providerValue = useMemo(() => ({ add, remove }), []);

    return (
      <ToastContext.Provider value={providerValue}>
        <Component {...props} />
        {createPortal(
          <div className="toasts-wrapper">
            {toasts.map((toast) => (
              <Toast key={toast.id} remove={() => remove(toast.id)} type={toast.type}>
                {toast.content}
              </Toast>
            ))}
          </div>,
          document.body
        )}
      </ToastContext.Provider>
    );
  }
  return WithToastProvider;
}
export default withToastProvider;
