/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import style from '../../../../assets/styles/userManagement.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserLists } from '../../../../redux/action';
import { Pagination } from '../../common/Pagination';
import { useNavigate } from 'react-router-dom';
const UserList = () => {
  const [keyword, setKeyword] = useState(null);
  const [sort, setSort] = useState('');
  const [sortStatus, setSortStatus] = useState(true);
  const [sortOrganisation, setSortOrganisation] = useState(true);
  const [sortFirstName, setSortFirstName] = useState(true);
  const [sortLastName, setSortLastName] = useState(true);
  const [sortUserRole, setSortUserRole] = useState(true);
  const [sortStatusIcon, setSortStatusIcon] = useState(false);
  const [sortOrganisationIcon, setSortOrganisationIcon] = useState(false);
  const [sortFirstNameIcon, setSortFirstNameIcon] = useState(false);
  const [sortLastNameIcon, setSortLastNameIcon] = useState(false);
  const [sortUserRoleIcon, setSortUserRoleIcon] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [last, setLast] = useState(5);
  const limit = 15;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [inputPage, setInputPage] = useState(currentPage);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.GetAllData);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
  const Roles = userInfo.roles || [];
  const isOrganizationUser = Roles.some((role) => role.id === 10);

  useEffect(() => {
    if (isOrganizationUser) {
      alert('Access denied, only super admin or organization admin can access');
      navigate('/question-bank');
    }
  }, [isOrganizationUser]);
  useEffect(() => {
    setIsLoading(true);
    dispatch(GetUserLists(keyword, sort, limit, currentPage));
  }, [keyword, sortOrganisation, sort, currentPage]);

  useEffect(() => {
    setIsLoading(true);
    setCurrentPage(1);
    setInputPage(1);
    setOffset(0);
  }, []);

  useEffect(() => {
    if (userData) {
      setIsLoading(false);
      const totalResults = userData.count;
      // If the current offset is greater than the total results,
      // reset to first page
      if (offset >= totalResults && totalResults > 0) {
        setOffset(0);
        setCurrentPage(1);
        setInputPage(1);
        setLast(Math.min(15, userData?.count));
        setTotalCount(offset);
      } else {
        if (userData?.count) {
          setTotalCount(userData?.count);
          setLast(Math.min(offset + 15, userData?.count));
        }
        if (!userData?.count) {
          setTotalCount(0);
          setLast(1);
          setOffset(0);
        }
      }
    }
  }, [userData]);

  const sortOrganisationFunction = () => {
    setSortOrganisationIcon(true);
    setSortFirstNameIcon(false);
    setSortLastNameIcon(false);
    setSortUserRoleIcon(false);
    setSortStatusIcon(false);
    if (sortOrganisation) {
      setSort('organization__name');
      setSortOrganisation(!sortOrganisation);
    } else {
      setSort('-organization__name');
      setSortOrganisation(!sortOrganisation);
    }
  };

  const sortFirstNameFunction = () => {
    setSortFirstNameIcon(true);
    setSortOrganisationIcon(false);
    setSortLastNameIcon(false);
    setSortUserRoleIcon(false);
    setSortStatusIcon(false);
    if (sortFirstName) {
      setSort('first_name');
      setSortFirstName(!sortFirstName);
    } else {
      setSort('-first_name');
      setSortFirstName(!sortFirstName);
    }
  };
  const sortLastNameFunction = () => {
    setSortLastNameIcon(true);
    setSortOrganisationIcon(false);
    setSortFirstNameIcon(false);
    setSortUserRoleIcon(false);
    setSortStatusIcon(false);
    if (sortLastName) {
      setSort('last_name');
      setSortLastName(!sortLastName);
    } else {
      setSort('-last_name');
      setSortLastName(!sortLastName);
    }
  };
  const sortUserRoleFunction = () => {
    setSortUserRoleIcon(true);
    setSortOrganisationIcon(false);
    setSortFirstNameIcon(false);
    setSortLastNameIcon(false);
    setSortStatusIcon(false);
    if (sortUserRole) {
      setSort('user_roles');
      setSortUserRole(!sortUserRole);
    } else {
      setSort('-user_roles');
      setSortUserRole(!sortUserRole);
    }
  };

  const sortStatusFunction = () => {
    setSortStatusIcon(true);
    setSortOrganisationIcon(false);
    setSortFirstNameIcon(false);
    setSortLastNameIcon(false);
    setSortUserRoleIcon(false);
    if (sortStatus) {
      setSort('is_active');
      setSortStatus(!sortStatus);
    } else {
      setSort('-is_active');
      setSortStatus(!sortStatus);
    }
  };

  const handleSreach = (e) => {
    setKeyword(e.target.value);
    setCurrentPage(1);
    setInputPage(1);
    setSortOrganisation(false);
    setSortStatus(false);
    setSort('');
    setSortOrganisationIcon(false);
    setSortFirstNameIcon(false);
    setSortLastNameIcon(false);
    setSortUserRoleIcon(false);
    setSortStatusIcon(false);
  };
  const getRotation = (column) => {
    if (sort === column) {
      return 'rotate(180deg)';
    }
    return 'rotate(0deg)';
  };
  return (
    <div>
      <div className={style.question_bank}>
        {/* TABLE SECTION OF THIS PAGE  */}
        <div className={`${style.table_content_report} ${style.user_table_content}`}>
          {/* _______________________Top content of table_______________________  */}
          <div className={style.top_content_table}>
            {/*_______________________  Search bar  _______________________ */}
            <div className={` ${style.search_listing} ${style.user_management_search_listing}`}>
              <div className={style.tag_icon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.2598 19.2592L16.1406 16.8594"
                    stroke="#212529"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={style.input_search}>
                <input
                  type="search"
                  id="searchInput"
                  placeholder="Search for first name, last name, organization, role, question bank to refine list"
                  onChange={(e) => {
                    handleSreach(e);
                  }}
                />
              </div>
            </div>
            {/*_______________________  filter  _______________________ */}
            <div className={` ${style.filter}  ${style.user_management_filter}`}></div>
            {/*_______________________  Add Question Button  _______________________ */}
            <div className={style.add_question}>
              <button
                className={` ${style.add_new_question} ${style.pointer}`}
                onClick={() => navigate('/user-management/add-user')}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" fill="white" />
                  <path
                    d="M7.5 12H16.5"
                    stroke="#00ABFB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 16.5V7.5"
                    stroke="#00ABFB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Add User</span>
              </button>
            </div>
          </div>

          {/* TABLE LISTING OF USERS  */}
          {totalCount !== 0 ? (
            <div className={style.sub_head_content_table}>
              <table>
                <thead>
                  <tr>
                    <th className={` ${style.first} ${style.pointer}`} onClick={() => sortFirstNameFunction()}>
                      <span>First Name</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginLeft: '6px',
                          transform: getRotation('-first_name'),
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill={sortFirstNameIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill={sortFirstNameIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill={sortFirstNameIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill={sortFirstNameIcon ? '#00ABFB' : '#999090'}
                        />
                      </svg>
                    </th>
                    <th className={` ${style.first} ${style.pointer}`} onClick={() => sortLastNameFunction()}>
                      <span>Last Name</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginLeft: '6px',
                          transform: getRotation('-last_name'),
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill={sortLastNameIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill={sortLastNameIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill={sortLastNameIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill={sortLastNameIcon ? '#00ABFB' : '#999090'}
                        />
                      </svg>
                    </th>
                    <th className={` ${style.second} ${style.pointer}`} onClick={() => sortOrganisationFunction()}>
                      <span>Organization</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginLeft: '6px',
                          transform: getRotation('-organization__name'),
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill={sortOrganisationIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill={sortOrganisationIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill={sortOrganisationIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill={sortOrganisationIcon ? '#00ABFB' : '#999090'}
                        />
                      </svg>
                    </th>
                    <th className={` ${style.second} ${style.pointer}`} onClick={() => sortUserRoleFunction()}>
                      <span>User Role</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginLeft: '6px',
                          transform: getRotation('-user_roles'),
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill={sortUserRoleIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill={sortUserRoleIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill={sortUserRoleIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill={sortUserRoleIcon ? '#00ABFB' : '#999090'}
                        />
                      </svg>
                    </th>
                    <th className={` ${style.third} ${style.pointer}`}>
                      <span>Question Banks</span>
                    </th>
                    <th className={` ${style.fifth} ${style.pointer}`} onClick={() => sortStatusFunction()}>
                      <span>Status</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginLeft: '6px',
                          transform: getRotation('-is_active'),
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill={sortStatusIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill={sortStatusIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill={sortStatusIcon ? '#00ABFB' : '#999090'}
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill={sortStatusIcon ? '#00ABFB' : '#999090'}
                        />
                      </svg>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                {/* ____________ user Data Listing _____________ */}
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan="7"
                        style={{
                          textAlign: 'center',
                          padding: '20px',
                          fontSize: '15px',
                          fontFamily: 'IBM Plex Sans',
                        }}
                      >
                        Loading...
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {userData?.results?.map((item, i) => (
                      <tr key={i} className={style.table_body_content}>
                        <td>
                          <div className={`${style.user_management_table_body_content} ${style.userFirstname}`}>
                            {item.first_name}
                          </div>
                        </td>
                        <td>
                          <div className={`${style.user_management_table_body_content} ${style.userLasttname}`}>
                            {item.last_name}
                          </div>
                        </td>
                        <td>
                          <div className={`${style.user_management_table_body_content} ${style.userOrganization}`}>
                            {item.organization}
                          </div>
                        </td>
                        <td>
                          <div className={`${style.user_management_table_body_content} ${style.userRole}`}>
                            {item.user_roles}
                          </div>
                        </td>
                        <td>
                          <div className={`${style.user_management_table_body_content} ${style.userQuestionbanks}`}>
                            <span style={{ width: 'auto' }}>{item?.question_banks?.slice(0, 2).join(', ')}</span>
                            {item?.question_banks.length > 2 ? (
                              <span style={{ width: '16%' }}>
                                <span className={style.que_bank_count}>+{item?.question_banks.length - 1}</span>
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${item.is_active ? `${style.activate}` : `${style.inactive}`} ${style.userStatus}`}
                          >
                            {' '}
                            <span>{item.is_active ? 'Active' : 'Inactive'}</span>
                          </div>
                        </td>
                        <td className={style.user_detail_arrow}>
                          <div
                            className={` ${style.action} ${style.pointer}`}
                            onClick={() => {
                              navigate(`/user-management/user-details/${item.id}`);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977"
                                stroke="#212529"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          ) : (
            <>
              {isLoading ? (
                <div
                  colSpan="7"
                  style={{
                    textAlign: 'center',
                    padding: '20px',
                    fontSize: '15px',
                    fontFamily: 'IBM Plex Sans',
                  }}
                >
                  Loading...
                </div>
              ) : (
                <div className={style.noData}>
                  <div className={style.noDataText}>
                    No Results Found. We couldn't find anything that matches your search. Please try using different
                    keywords
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {/*______________________________ PAGINATION ______________________________  */}
        <div className={`${style.user_paginationWrap}`}>
          {totalCount !== 0 && (
            <Pagination
              totalCount={totalCount}
              limit={limit}
              currentPage={currentPage}
              inputPage={inputPage}
              offset={offset}
              last={last}
              nextUrl={userData.next}
              prevUrl={userData.previous}
              setOffset={setOffset}
              setCurrentPage={setCurrentPage}
              setInputPage={setInputPage}
              setLast={setLast}
            />
          )}
        </div>

        {/*______________________________ PAGINATION ______________________________  */}
      </div>
    </div>
  );
};

export default UserList;
