/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import style from '../../../../../assets/styles/testCreationStyle.module.css';
import { CallWithAuth } from '../../../../../action/apiActions';
import { CREATE_TESTS_BASIC_SETTING, GET_FREE_TAG } from '../../../../../action/apiPath';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add_Basic_Setting_Test,
  Edit_Test,
  Get_All_Category,
  Get_Product_Type,
  Task_of_User,
} from '../../../../../redux/action';
// * import { change_test_screen} from '../../../../../redux/action';
import QuillEditor from '../../../../atoms/QuillEditor';
import TextInputs from './InputField';
import ChipsSearchBar from './ChipsSearchBar';
import {
  CREATE_TEST_BASIC_SETTING_SUCCESS,
  CREATE_TEST_BASIC_SETTING_SUCCESS_VIA_API,
} from '../../../../../redux/constant';
import { useToast } from '../../../common/Toast';

const BasicSettings = ({ MasterData, isEdit, setSelectedType, onAdd }) => {
  const rowRef = useRef();

  const dispatch = useDispatch();
  const { categoryList, getProductType, testDetails, getBasicSettingData } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(Get_All_Category('', ''));
    dispatch(Task_of_User(0));
    dispatch(Get_Product_Type());
  }, []);

  //_______________________________________________ Creation of new test _________________________
  const [testName, setTestName] = useState('');
  const [noofQuestiontoPass, setNoofQuestiontoPass] = useState(null);
  const [rendering_template, setRendering_template] = useState(null);
  const [academicYear, setAcademicYear] = useState(null);
  const [category, setCategory] = useState(null);
  const [multiselectedfreeTag, setMultiselectedfreeTag] = useState([]);
  const [newFreeTag, setNewFreeTag] = useState([]);
  const [markingScheme, setMarkingScheme] = useState(0);
  const [correctAnswerMarks, setCorrectAnswerMarks] = useState(null);
  const [incorrectAnswerMarks, setIncorrectAnswerMarks] = useState(null);
  const [unattemptedPenalty, setUnattemptedPenalty] = useState(0);
  const [unattempted_penalty_starts_from, setUnattempted_penalty_starts_from] = useState(null);
  const [instruction, setInstruction] = useState([
    {
      has_timer: false,
      minutes: null,
      seconds: null,
      instruction_text: '',
    },
  ]);
  const [testType, setTestType] = useState(0);

  let body = {
    test_type: testType,
    rendering_template: rendering_template,
    test_name: testName,
    academic_year: academicYear,
    category: category,
    free_tags: multiselectedfreeTag?.map((item) => item.id),
    new_free_tags: newFreeTag,
    number_of_questions_to_pass: noofQuestiontoPass ?? 0,
    // task_label: testLabel,
    marking_scheme: markingScheme,
    correct_answer_marks: correctAnswerMarks,
    incorrect_answer_marks: incorrectAnswerMarks,
    unattempted_penalty: unattemptedPenalty ?? 0,
    unattempted_penalty_starts_from: unattempted_penalty_starts_from ?? 0,
    marking_logic_level: 0,
    instructions: instruction,
  };

  //_______________ Fn for handle test type
  const handleRenderingTemplate = (e) => {
    const selectedTemplate = Number(e.target.value);
    setRendering_template(selectedTemplate);
  };

  const handleTestType = (e) => {
    const selectedTemplate = Number(e.target.value);
    setTestType(selectedTemplate);
    setSelectedType(selectedTemplate);
  };

  //_______________ Fn for handle test name
  const handleTestName = (e) => {
    const selectedName = e.target.value;
    setTestName(selectedName);
  };
  const updateStates = (data) => {
    setTestType(data?.test_type);
    setSelectedType(data?.test_type);
    setTestName(data?.test_name);
    setNoofQuestiontoPass(data?.number_of_questions_to_pass);
    setRendering_template(data?.rendering_template);
    setAcademicYear(data?.academic_year);
    setCategory(data?.category);
    setMultiselectedfreeTag(data?.free_tags?.map((tag) => ({ id: tag.id, name: tag.name })));
    setMarkingScheme(data?.marking_scheme);
    setCorrectAnswerMarks(data?.correct_answer_marks);
    setIncorrectAnswerMarks(data?.incorrect_answer_marks);
    setUnattemptedPenalty(data?.unattempted_penalty);
    setUnattempted_penalty_starts_from(data?.unattempted_penalty_starts_from);
    data?.instructions &&
      setInstruction(
        data?.instructions?.map((instruction) => ({
          has_timer: instruction?.has_timer,
          minutes: instruction.minutes || 0,
          seconds: instruction.seconds || 0,
          instruction_text: instruction.instruction_text || '',
        }))
      );
  };
  useEffect(() => {
    if (testDetails && !onAdd) {
      updateStates(testDetails);
    } else {
      getBasicSettingData && updateStates(getBasicSettingData);
      const chips = getBasicSettingData?.free_tags.map((item) => item.name);
      getBasicSettingData && setChipsData([...chips, ...getBasicSettingData?.new_free_tags]);
    }
  }, [testDetails, getBasicSettingData]);
  const handleNumberOfQuestionToPass = (e) => {
    const selectedNumber = Number(e.target.value);
    setNoofQuestiontoPass(selectedNumber);
  };

  //_______________ Fn for handle academic year
  const handleAcademicYear = (e) => {
    const selectedYear = Number(e.target.value);
    setAcademicYear(selectedYear);
  };

  //_______________ Fn for handle category
  const handleCategory = (e) => {
    const selectedCategory = Number(e.target.value);
    setCategory(selectedCategory);
  };

  //_______________ Fn for handle free tags

  // ________________ Initialization of FREE TAG ________________//
  const [multifreeTaginput, setMultifreeTaginput] = useState('');
  const [multifreeTag, setMultifreeTag] = useState([]);
  const [multifreeTagStatus, setMultifreeTagStatus] = useState(false);
  const [newfreeTags, setNewfreeTags] = useState([]);
  const [newfreeTagStatus, setNewfreeTagStatus] = useState(false);
  const [chipsData, setChipsData] = useState([]);
  // CALL GET API FOR getting TAGS
  const TagFreeMultiple = async (e) => {
    setMultifreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth('GET', GET_FREE_TAG + '?keyword=' + e);
      if (data.status && data.res.status === 200) {
        if (data.res.data.length > 0) {
          setMultifreeTag(data.res.data);
          setNewfreeTagStatus(false);
          setMultifreeTagStatus(true);
        } else {
          setNewfreeTags([e]);
          setMultifreeTagStatus(false);
          setNewfreeTagStatus(true);
        }
      }
    } else {
      setMultifreeTagStatus(false);
    }
  };

  // select tags from options
  const selectFreeTag = (e) => {
    setMultiselectedfreeTag((current) => [...current, e]);
    setMultifreeTagStatus(false);
    setMultifreeTaginput('');
  };

  const selectnewFreeTag = (e) => {
    setNewFreeTag((current) => [...current, e]);
    setNewfreeTagStatus(false);
    setMultifreeTaginput('');
  };

  const CancelButton = (e) => {
    e.preventDefault();
    setNewFreeTag([]);
    setMultiselectedfreeTag([]);
  };
  //_______________ Fn for handle free tags over }

  //_______________ Fn for handle marking schemes
  const handleMarkingScheme = (e) => {
    const selectedMarking = Number(e.target.value);
    setMarkingScheme(selectedMarking);
  };

  //_______________ Fn for handle input markings
  const handleInputChange = (e, setter) => {
    const value = e.target.value;
    setter(Number(value));
  };

  //________________Fn for handle Intructions

  const handleTimerChange = (event, type, index) => {
    let value;
    if (type === 'has_timer') {
      value = event.target.checked;
    } else {
      value = parseInt(event.target.value, 10);
    }
    const newState = structuredClone(instruction);
    newState[index][type] = value;
    setInstruction(newState);
  };

  const handleInstructionTextChange = (content, index, event) => {
    const answer = btoa(unescape(encodeURIComponent(content)));

    setInstruction((prevState) => {
      const newState = [...structuredClone(prevState)];
      newState[index].instruction_text = answer;
      return newState;
    });
  };

  const addScreen = () => {
    setInstruction((prevState) => [
      ...prevState,
      {
        has_timer: true,
        minutes: null,
        seconds: null,
        instruction_text: '',
      },
    ]);
  };

  const removeScreen = (index) => {
    setInstruction((prevState) => prevState.filter((_, i) => i !== index));
  };

  //_______________________________________ Instructions exit ________________________________//

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2011;
    const endYear = currentYear + 5;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const decodeBase64 = (base64String) => {
    return b64DecodeUnicode(base64String);
  };

  function b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
  const testTypeArray = [
    { id: 0, name: 'Static' },
    { id: 1, name: ' Quasi-Adaptive' },
    { id: 2, name: 'LOFT' },
  ];
  useEffect(() => {
    const chips = multiselectedfreeTag.map((item) => item.name);
    setChipsData([...chips, ...newFreeTag]);
  }, [multiselectedfreeTag, newFreeTag]);
  const onChipsChange = (tag) => {
    const newMultiselectedfreeTag = multiselectedfreeTag?.filter((item) => item.name !== tag);
    setMultiselectedfreeTag(newMultiselectedfreeTag);
    const freeTag = newFreeTag?.filter((item) => item !== tag);
    setNewFreeTag(freeTag);
  };
  const getTesttypeStyle = () => {
    return {
      // eslint-disable-next-line eqeqeq
      color: testType || testType == 0 ? 'black' : '#808080',
    };
  };
  const getAcademicyearStyle = () => {
    return {
      color: academicYear ? 'black' : '#808080',
    };
  };
  const getCategoryStyle = () => {
    return {
      color: category ? 'black' : '#808080',
    };
  };
  const getTestPaperStyle = () => {
    return {
      color: rendering_template || rendering_template == 0 ? 'black' : '#808080',
    };
  };
  const toast = useToast();
  const handleSaveAndContinue = async () => {
    const body = {
      test_type: testType,
      rendering_template: rendering_template,
      test_name: testName,
      academic_year: academicYear,
      category: category,
      free_tags: multiselectedfreeTag?.map((item) => item.id),
      new_free_tags: newFreeTag,
      // task_label: testLabel,
      unattempted_penalty: unattemptedPenalty,
      instructions: instruction,
    };
    if (isEdit) {
      body.id = testDetails?.id;
    }
    if (onAdd && getBasicSettingData?.id) {
      body.id = getBasicSettingData?.id;
    }
    const url = isEdit
      ? `${CREATE_TESTS_BASIC_SETTING}${testDetails?.id}/update/`
      : onAdd && getBasicSettingData?.id
        ? `${CREATE_TESTS_BASIC_SETTING}${getBasicSettingData?.id}/update/`
        : CREATE_TESTS_BASIC_SETTING;
    const data = await CallWithAuth(isEdit || (onAdd && getBasicSettingData?.id) ? 'PATCH' : 'POST', url, body);
    if (data.status && (data.res.status === 201 || data.res.status === 200)) {
      toast?.add(data.res.data.message, 'success');
      const response = data?.res?.data?.data;
      const screen = 1;
      body.free_tags = multiselectedfreeTag;
      dispatch({ type: CREATE_TEST_BASIC_SETTING_SUCCESS_VIA_API, response, screen, body });
    } else {
      toast?.add(data.res.data.error, 'error');
      console.log('error', data);
    }
  };
  return (
    <div className={style.masterTestCreation}>
      <div className={style.testCreationArea}>
        <div className={style.bound}>
          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Test Type </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  tabIndex={0}
                  value={testType}
                  name="question"
                  id="question"
                  style={getTesttypeStyle()}
                  className={`${style.template_drop_filter} select-focused`}
                  onChange={handleTestType}
                  disabled={isEdit}
                >
                  {testTypeArray?.map((items, i) => (
                    <option key={i + items.name} value={items.id}>
                      {items.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Test Paper Rendering Template </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  tabIndex={0}
                  value={rendering_template}
                  name="question"
                  id="question"
                  style={getTestPaperStyle()}
                  className={`${style.template_drop_filter} select-focused`}
                  onChange={handleRenderingTemplate}
                >
                  {/* <option hidden>{rendering_template}</option> */}
                  <option>Select Rendering Template</option>
                  {getProductType?.map((items, i) => (
                    <option key={i + items.product_type} value={items.id}>
                      {items.product_type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.bound}>
          <TextInputs
            tabIndex={0}
            placeholder={'Enter Test Name'}
            onChange={handleTestName}
            style={style}
            label="Test Names"
            inputType="text"
            value={testName}
          />

          <div className={style.templateDrop}>
            <div className={style.templateDrop_item}>
              <div className={style.templateGroup}>
                <span>Academic Year </span>
                <b className={style.red_mendantory}>*</b>
                <select
                  tabIndex={0}
                  name="question"
                  value={academicYear}
                  id="question"
                  className={`${style.template_drop_filter} select-focused`}
                  style={getAcademicyearStyle()}
                  onChange={handleAcademicYear}
                >
                  <option>Select Academic Year</option>
                  {generateYears().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.templateDrop} style={{ marginBottom: '10px' }}>
          <div className={style.templateDrop_item}>
            <div className={style.templateGroup}>
              <span>Category </span>
              <b className={style.red_mendantory}>*</b>
              <select
                tabIndex={0}
                value={category}
                name="question"
                id="question"
                className={`${style.template_drop_filter} select-focused`}
                style={getCategoryStyle()}
                onChange={handleCategory}
              >
                <option value={null}>Select Category</option>
                {categoryList?.map((items, i) => (
                  <option key={i + items.name} value={items.id}>
                    {items.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* //-------------------Basic Static Start-------------------------// */}
        {testType !== 1 && (
          <>
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span>Free Form Tags </span>
                  <b className={style.red_mendantory}>*</b>
                  <div className={style.search_listing}>
                    <div className={style.tag_icon}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M18.2598 19.2592L16.1406 16.8594"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span>Tags</span>
                    </div>
                    {/* this is input field for searching */}
                    <div className={style.input_search} ref={rowRef}>
                      {/* multifreeTag selected one  */}
                      {multiselectedfreeTag?.map((items, i) => (
                        <span key={items.name + i} className={style.selected_tags}>
                          {items.name}{' '}
                        </span>
                      ))}

                      {/* new free tag selected one  */}
                      {newFreeTag?.map((items, i) => (
                        <span key={items + i} className={style.selected_tags}>
                          {items}{' '}
                        </span>
                      ))}

                      {/* input field  */}
                      <input
                        type="search"
                        id="searchInput"
                        placeholder=""
                        value={multifreeTaginput}
                        onChange={(e) => TagFreeMultiple(e.target.value)}
                      />

                      {/* list of items of pre-defined multifree tag  */}
                      {multifreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {multifreeTag?.map((items, i) => (
                            <div key={items.name + i} className={style.pointer} onClick={() => selectFreeTag(items)}>
                              <span>{items.name}a </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}

                      {/* list of item of user-defined multifreetag  */}
                      {newfreeTagStatus ? (
                        <div className={style.list_input_primary}>
                          {newfreeTags?.map((items, i) => (
                            <div key={items + i} className={style.pointer} onClick={() => selectnewFreeTag(items)}>
                              <span>{items} </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* cancel button  */}
                    <div className={style.cancel_button}>
                      <button className={style.pointer} onClick={(e) => CancelButton(e)} type="cancel">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75781 7.75781L16.2431 16.2431"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.75691 16.2431L16.2422 7.75781"
                            stroke="#272727"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {rendering_template == 2 && (
              <div className={style.templateDrop}>
                <div className={style.templateDrop_item}>
                  <div className={style.templateGroup}>
                    <span>Number of Question to Pass </span>
                    <b className={style.red_mendantory}>*</b>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      value={noofQuestiontoPass}
                      onChange={handleNumberOfQuestionToPass}
                      placeholder=""
                      className={style.template_drop_filter}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={style.templateDrop}>
              <hr className={style.hrLine} />
              <div className={style.pageTwoContainer}>
                <h3 className={style.testMarklabel}>Group Marking Details</h3>
                <div className={style.templateDrop}>
                  <div className={style.templateDrop_item}>
                    <div className={style.templateGroup}>
                      <span>Marking Scheme </span>
                      <b className={style.red_mendantory}>*</b>
                      <select
                        name="question"
                        id="question"
                        className={style.template_drop_filter}
                        onClick={handleMarkingScheme}
                      >
                        <option value={0}>Regular -Per Question</option>
                        {/* <option value={1}>Progressive - Across Section</option>
                                        <option value={2}>Progressive - Section-wise</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <p style={{ paddingTop: '10px', paddingBottom: '10px' }} className={style.info}>
                  All questions will follow this marking logic. Marking logic can be overwritten at a question level on
                  next screen.
                </p>
                <div className={style.bound}>
                  <div className={style.templateDrop}>
                    <div className={style.templateDrop_item}>
                      <div className={style.templateGroup}>
                        <span>Correct Answer Marks </span>
                        <b className={style.red_mendantory}>*</b>
                        <input
                          type="number"
                          name="question"
                          id="question"
                          className={style.score_drop_filter}
                          placeholder="Input +/- score"
                          value={correctAnswerMarks}
                          onChange={(e) => handleInputChange(e, setCorrectAnswerMarks)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={style.templateDrop}>
                    <div className={style.templateDrop_item}>
                      <div className={style.templateGroup}>
                        <span>Incorrect Answer Marks </span>
                        <b className={style.red_mendantory}>*</b>
                        <input
                          type="number"
                          name="question"
                          id="question"
                          className={style.score_drop_filter}
                          placeholder="Input +/- score"
                          value={incorrectAnswerMarks}
                          onChange={(e) => handleInputChange(e, setIncorrectAnswerMarks)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Test type */}
                  {rendering_template === 5 && (
                    <>
                      <div className={style.templateDrop}>
                        <div className={style.templateDrop_item}>
                          <div className={style.templateGroup}>
                            <span>Unattempted Penalty </span>
                            <b className={style.red_mendantory}>*</b>
                            <input
                              type="number"
                              name="question"
                              id="question"
                              className={style.score_drop_filter}
                              placeholder="Input +/- score"
                              value={unattemptedPenalty}
                              onChange={(e) => handleInputChange(e, setUnattemptedPenalty)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={style.templateDrop}>
                        <div className={style.templateDrop_item}>
                          <div className={style.templateGroup}>
                            <span>Unattempted Penalty Start From </span>
                            <b className={style.red_mendantory}>*</b>
                            <input
                              type="number"
                              name="question"
                              id="question"
                              className={style.score_drop_filter}
                              placeholder="Input +/- score"
                              value={unattempted_penalty_starts_from}
                              onChange={(e) => handleInputChange(e, setUnattempted_penalty_starts_from)}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <hr className={style.hrLine} />
                <h3 className={style.testMarklabel}>Test Instructions</h3>
                <p className={style.info}>Add instructions to appear before the test commences</p>
                {instruction?.map((instruction, index) => (
                  <div key={index + instruction} className={style.screenNum}>
                    <p style={{ fontWeight: 500 }}>Screen {index + 1}</p>
                    <div className={style.icon_text}>
                      <label className="custom-checkbox" aria-label="Has Timer">
                        <input
                          type="checkbox"
                          checked={Boolean(instruction.has_timer)}
                          onChange={(e) => handleTimerChange(e, 'has_timer', index)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* <label>Has Timer</label> */}

                      <p className={style.info}>Instruction Screen has a timer</p>
                    </div>

                    {Boolean(instruction.has_timer) && (
                      <>
                        <div className={style.timer}>
                          <input
                            className={style.timer_input}
                            type="number"
                            name="minutes"
                            min="0"
                            max="100"
                            step="1"
                            autoComplete="off"
                            list="autocompleteoff"
                            value={instruction.minutes}
                            onChange={(e) => handleTimerChange(e, 'minutes', index)}
                          />
                          <label htmlFor="minutes">Minutes</label>

                          <input
                            className={style.timer_input}
                            type="number"
                            name="seconds"
                            min="0"
                            max="100"
                            step="1"
                            autoComplete="off"
                            list="autocompleteoff"
                            value={instruction.seconds}
                            onChange={(e) => handleTimerChange(e, 'seconds', index)}
                          />
                          <label htmlFor="seconds">Seconds</label>
                        </div>

                        <p className={style.marketLogic}>Test Instructions </p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2rem',
                          }}
                        >
                          {' '}
                          {/* <textarea
                                        type="textArea"
                                        placeholder="Type here"
                                        className={style.typeArea}
                                        value={instruction.instruction_text}
                                        onChange={(e) => handleInstructionTextChange(e, index)}
                                    /> */}
                          {/* <Editor
                                        apiKey='43r1aznok44zxafnhlerjdhremu6cizk6ropi635y4we48qz'
                                        init={{
                                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker markdown',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                        }}
                                        value={decodeBase64(instruction.instruction_text)}
                                        onEditorChange={(content) => handleInstructionTextChange(content, index)}
                                    /> */}
                          <div style={{ height: 'auto', flexGrow: '1' }}>
                            <QuillEditor
                              defaultValue={decodeBase64(instruction.instruction_text)}
                              onChange={(content) => handleInstructionTextChange(content, index)}
                            />
                          </div>
                          {index !== 0 && (
                            <button type="button" className={style.penaltyDelete} onClick={() => removeScreen(index)}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.8504 9.14062L18.2004 19.2106C18.0904 20.7806 18.0004 22.0006 15.2104 22.0006H8.79039C6.00039 22.0006 5.91039 20.7806 5.80039 19.2106L5.15039 9.14062"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
                <div onClick={() => addScreen()} className={style.threshold}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 12H16.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 16.5V7.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h5>Add Screen</h5>
                </div>

                <div className={style.performBT}>
                  {/* <button className={` ${style.btOne} ${style.common}`} onClick={() => dispatch(change_test_screen(0))}>
                    Back
                  </button> */}
                  <button
                    className={` ${style.btTwo} ${style.common}`}
                    onClick={() => {
                      isEdit ? dispatch(Edit_Test(testDetails?.id, body,toast)) : dispatch(Add_Basic_Setting_Test(body,toast));
                    }}
                  >
                    Save And Continue
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {/* //-------------------Basic Static End-------------------------// */}
        {testType == 1 && (
          <>
            <div className={style.templateDrop} style={{ marginBottom: '10px' }}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <ChipsSearchBar
                    onChipsChange={onChipsChange}
                    chipsTags={chipsData}
                    onNewChange={selectnewFreeTag}
                    onExistingChange={selectFreeTag}
                    newData={newfreeTags}
                    newDataStatus={newfreeTagStatus}
                    existingDataStatus={multifreeTagStatus}
                    existingData={[...multifreeTag]}
                    placeholder={'Search for tags'}
                    label="Free Form Tags"
                    searchTagLabel="Tags"
                    value={multifreeTaginput}
                    onInputChange={TagFreeMultiple}
                  />
                </div>
              </div>
            </div>
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <ChipsSearchBar
                    disable={true}
                    placeholder={'Choose task label'}
                    onChipsChange={onChipsChange}
                    chipsTags={[]}
                    onNewChange={selectnewFreeTag}
                    onExistingChange={selectFreeTag}
                    newData={newfreeTags}
                    newDataStatus={newfreeTagStatus}
                    existingDataStatus={multifreeTagStatus}
                    existingData={[...multifreeTag]}
                    label="Add Task Label - Choose from assigned tasks"
                    searchTagLabel="Label"
                    value=""
                    onInputChange={TagFreeMultiple}
                  />
                </div>
              </div>
            </div>

            <div className={style.templateDrop}>
              <hr className={style.hrLine} style={{ marginTop: '0.4em', marginBottom: '2.2em' }} />
              <div className={style.pageTwoContainer}>
                <h3 className={`${style.testMarklabel} ${style.mainHeadings}`}>Test Marking Details</h3>
                <p style={{ paddingTop: '10px', paddingBottom: '30px', margin: '0px' }} className={style.info}>
                  All questions will follow same marking logic unless new marking logics are created
                </p>
                <div className={style.templateDrop}>
                  <div className={style.templateDrop_item}>
                    <div className={style.templateGroup}>
                      <span>Unattempted Marks </span>
                      <b className={style.red_mendantory}>*</b>
                      <input
                        type="number"
                        name="question"
                        id="question"
                        className={style.score_drop_filter}
                        placeholder="Input +/- score"
                        value={unattemptedPenalty}
                        onChange={(e) => setUnattemptedPenalty(e.target.value)}
                        onBlur={(e) => setUnattemptedPenalty(Number(e.target.value))}
                        onKeyDown={(e) => {
                          if (['e', 'E'].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        list="autocompleteoff"
                      />
                    </div>
                  </div>
                </div>
                <hr className={style.hrLine} style={{ marginBottom: '2.2em' }} />
                <h3 className={`${style.testMarklabel} ${style.mainHeadings}`}>Test Instructions</h3>
                <p className={style.info}>Add instructions to appear before the test commences</p>
                {instruction?.map((instruction, index) => (
                  <div key={index + instruction} className={style.screenNum}>
                    <p style={{ fontWeight: 500, marginBottom: '5px' }}>Screen {index + 1}</p>
                    <div className={style.icon_text}>
                      <label className="custom-checkbox" aria-label="Has Timer">
                        <input
                          type="checkbox"
                          checked={Boolean(instruction.has_timer)}
                          onChange={(e) => handleTimerChange(e, 'has_timer', index)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* <label>Has Timer</label> */}

                      <p className={style.info}>Instruction Screen has a timer</p>
                    </div>
                    {Boolean(instruction.has_timer) && (
                      <>
                        <div className={style.timer}>
                          <input
                            className={style.timer_input}
                            type="number"
                            name="minutes"
                            min="0"
                            max="100"
                            step="1"
                            autoComplete="off"
                            list="autocompleteoff"
                            value={instruction.minutes}
                            onChange={(e) => handleTimerChange(e, 'minutes', index)}
                          />
                          <label htmlFor="minutes">Minutes</label>

                          <input
                            className={style.timer_input}
                            type="number"
                            name="seconds"
                            min="0"
                            max="100"
                            step="1"
                            autoComplete="off"
                            list="autocompleteoff"
                            value={instruction.seconds}
                            onChange={(e) => handleTimerChange(e, 'seconds', index)}
                          />
                          <label htmlFor="seconds">Seconds</label>
                        </div>

                        <p className={style.marketLogic}>Test Instructions </p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2rem',
                          }}
                        >
                          <div style={{ height: 'auto', flexGrow: '1' }}>
                            <QuillEditor
                              defaultValue={decodeBase64(instruction.instruction_text)}
                              onChange={(content) => handleInstructionTextChange(content, index)}
                            />
                          </div>
                          {index !== 0 && (
                            <button type="button" className={style.penaltyDelete} onClick={() => removeScreen(index)}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.8504 9.14062L18.2004 19.2106C18.0904 20.7806 18.0004 22.0006 15.2104 22.0006H8.79039C6.00039 22.0006 5.91039 20.7806 5.80039 19.2106L5.15039 9.14062"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#272727"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
                <div onClick={() => addScreen()} className={style.threshold}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 12H16.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 16.5V7.5"
                      stroke="#00ABFB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h5 style={{ cursor: 'pointer' }}>Add Screen</h5>
                </div>

                <div className={style.performBT}>
                  <button className={` ${style.btOne} ${style.common}`} onClick={() => dispatch(change_test_screen(0))}>
                    Back
                  </button>
                  <button className={` ${style.btTwo} ${style.common}`} onClick={() => handleSaveAndContinue()}>
                    Save And Continue
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

BasicSettings.propTypes = {
  MasterData: PropTypes.any,
  onAdd: PropTypes.any,
  isEdit: PropTypes.any,
  setSelectedType: PropTypes.func,
};

export default BasicSettings;
