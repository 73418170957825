import { useContext } from 'react';
import ToastContext from './context';

function useToast() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const context = useContext<any>(ToastContext);
  if (context) {
    return {
      add: (content: string, type: string) => context.add(content, type),
      remove: context.remove,
    };
  }
}

export default useToast;
