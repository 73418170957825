import React, { ReactNode } from 'react';
import logo from '../assets/images/logo.png';
import signin from '../assets/images/Frame.png';
import style from '../assets/styles/signin.module.css';

interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  return (
    <div className={style.signup_page}>
      <div className={style.section1}>
        <header className={style.ims_logo}>
          <img className={style.logo} src={logo} alt="ims-logo" width={100} height={100} />
        </header>
        <div className={style.main_area}>
          <div className={style.text_area}>
            <h2 className={style.title}>Welcome!</h2>
            <p className={style.title_text}>
              Enter your registered email address and password
              <br />
              and start creating with us
            </p>
          </div>
          <div className={`${style.title_img}`}>
            <img className={`${style.public_img1}`} src={signin} alt="sign-in" />
          </div>
        </div>
      </div>

      <div className={style.section2}>
        <div className={style.signup_form}>{children}</div>
      </div>
    </div>
  );
};

export default PublicLayout;
