// duplicateHelper.js
export const handleDuplicate = (items, openModal) => {
  const dataToPass = {
    questionId: items.question_id,
    questionBankId: items.question_bank,
  };
  openModal(dataToPass);
};

export const openModalHelper = (dataToPass, setModalData, setIsModalOpen) => {
  setModalData(dataToPass); // Set dataToPass to modalData
  setIsModalOpen(true); // Open the modal
};

export const closeModalHelper = (setIsModalOpen, setModalData, setIsDuplicatePreview) => {
  setIsModalOpen(false); // Close the modal
  setModalData(null); // Reset modalData
  setIsDuplicatePreview(false); // Reset duplicate preview
};
