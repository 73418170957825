import React, { useCallback, useEffect, useState } from 'react';
import style from '../../../../assets/styles/userManagement.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetOraganisationList,
  getUserQuestionBank,
  UserScreenChange,
  GetQuestionBankList,
  GetUserRoleList,
} from '../../../../redux/action';
import { debounce } from '../../../atoms/debounce';
import { CallWithAuth } from '../../../../action/apiActions';
import { CREATE_USERS, GET_QUESTIONS_AND_TAGS, GET_USERS } from '../../../../action/apiPath';
import { useToast } from '../../common/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import openEye from '../../../../assets/images/openEye.svg';
import closedEye from '../../../../assets/images/closeEye.svg';
import PageLayout from '../../../atoms/PageLayout';
const AddUser = () => {
  // _________ GET QUESTION BANK API CALL SAVED THE DATA IN STATE _________
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserQuestionBank());
    dispatch(GetOraganisationList());
  }, []);
  const intialUserDetails = {
    first_name: '',
    last_name: '',
    email_id: '',
    password: '',
    mobile_number: '',
    role_id: 0,
    organization_id: 0,
    is_active: true,
    question_bank_management: [],
  };
  //__________________  ADD USERS ___________________
  const [userRoles, setUserRoles] = useState([]);
  const [passwordSeen, setPasswordSeen] = useState('password');
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [userOrganisation, setUserOrganisation] = useState(null);
  const [userQuestionBank, setUserQuestionBank] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(currentPage);
  const [error, setError] = useState('');
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [limit] = useState(15);
  const [totalCount, setTotalCount] = useState(155);
  const [offset, setOffset] = useState(0);
  const [last, setLast] = useState(15);
  const [questionList, setQuestionList] = useState([]);
  const [questionBankSearchValue, setQuestionBankSearchValue] = useState('');
  const [questionBankTags, setQuestionBankTags] = useState([]);
  const [questionBankTagStatus, setQuestionBankTagStatus] = useState(false);
  const [paramsTag, setParamsTag] = useState([]);
  const [userDetails, setUserDetails] = useState(intialUserDetails);
  const toast = useToast();
  const { roles } = useSelector((state) => state.GetAllData);
  const { organisationList, questionBankListWithPermissions } = useSelector((state) => state.GetAllData);
  const [enableSelect, setEnableSelect] = useState(false);
  const [enableRoleSelect, setEnableRoleSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();
  const fetchUserDetails = async () => {
    let payload = { ...intialUserDetails };
    const response = await CallWithAuth('GET', GET_USERS + `${userId}/user-details/`);
    if (response.status) {
      const userDataInfo = response.res.data;
      payload.first_name = userDataInfo?.first_name ? userDataInfo?.first_name : '';
      payload.last_name = userDataInfo?.last_name ? userDataInfo?.last_name : '';
      payload.email_id = userDataInfo?.email_id ? userDataInfo?.email_id : '';
      payload.mobile_number = userDataInfo?.mobile_number ? userDataInfo?.mobile_number : '';
      payload.is_active = userDataInfo?.is_active ?? true;
      payload.organization_id = userDataInfo?.organizations?.organization_id
        ? userDataInfo?.organizations?.organization_id
        : 0;
      payload.role_id = userDataInfo?.roles?.role_id ? userDataInfo?.roles?.role_id : 0;
      payload.question_bank_management = userDataInfo?.question_bank_management
        ? userDataInfo?.question_bank_management
        : [];
      payload.password = process.env.REACT_APP_TESTCASE_FAKE_PASSWORD;
      setUserQuestionBank(userDataInfo?.question_bank_management ? userDataInfo?.question_bank_management : []);
      setUserDetails(payload);
      localStorage.setItem('editableData', JSON.stringify(payload));
      setIsLoading(false);
    } else if (toast) {
      toast.remove('all');
      toast.add(String(`User not found with id:${userId}`), 'error');
      navigate(`/user-management`);
    }
  };

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      fetchUserDetails();
    }
  }, [userId]);
  const handleChange = (inputValue) => {
    if (!isNaN(inputValue) && inputValue > 0) {
      setCurrentPage(inputValue);
      setOffset((inputValue - 1) * limit);
      setLast(offset);
      // Calculate the new last count dynamically based on the remaining records
      const newLastCount = Math.min(inputValue * limit, totalCount);
      setLast(newLastCount);
    }
  };

  // Use useCallback to memoize the debounced function
  const debouncedHandleChange = useCallback(
    debounce((inputValue) => {
      handleChange(inputValue);
    }, 1000),
    [totalCount, limit]
  );
  const isDifferentPayloads = (payload1, payload2) => {
    return JSON.stringify(payload1) !== JSON.stringify(payload2);
  };
  const handlePageInputChange = (e) => {
    const value = e.target.value;
    const inputValue = parseInt(value);
    if (isNaN(inputValue)) {
      setInputPage('');
      return;
    }
    if (inputValue > Math.ceil(totalCount / limit)) {
      setError('Page Limit exceed');
    } else {
      setError('');
    }
    // Valid page number
    setInputPage(inputValue);
  };

  const next = () => {
    if (offset + limit >= totalCount) {
      setOffset(offset);
      setCurrentPage(currentPage);
      setInputPage(currentPage);
    } else {
      setOffset(offset + limit);
      setCurrentPage(currentPage + 1);
      setInputPage(currentPage + 1);
    }
    if (last + limit > totalCount) {
      setLast(totalCount);
    } else {
      setLast(last + limit);
    }
  };

  const previous = () => {
    if (offset - limit < 0) {
      setOffset(offset);
      setCurrentPage(currentPage);
      setInputPage(currentPage);
    } else {
      setOffset(offset - limit);
      setCurrentPage(currentPage - 1);
      setInputPage(currentPage - 1);
    }
    if (last <= 15) {
      setLast(last);
    } else {
      setLast((currentPage - 1) * limit);
    }
  };
  useEffect(() => {
    if (totalCount < last) {
      setLast(totalCount);
    } else {
      const lastValue = currentPage > 1 ? (currentPage - 1) * limit : limit;
      setLast(lastValue);
    }
  }, [totalCount]);
  const QuestionBankAdd = (e, selection, event) => {
    const name = event.target.name.split(',')[0];
    const selectedQuestionBank = [...userQuestionBank];
    if (userQuestionBank.some((items) => items.id === e.id)) {
      let foundItemIndex = selectedQuestionBank.findIndex((item) => item.id === e.id);
      if (foundItemIndex !== -1) {
        let foundItem = { ...selectedQuestionBank[foundItemIndex] };
        let value = foundItem[name];
        foundItem.review = false;
        foundItem.edit = false;
        foundItem.view = false;
        foundItem.draft = false;
        foundItem[name] = !value;
        selectedQuestionBank[foundItemIndex] = foundItem;
      }
    } else {
      selectedQuestionBank[selectedQuestionBank.length] = {
        name: e.name,
        id: e.id,
        review: name === 'review' ? true : false,
        edit: name === 'edit' ? true : false,
        view: name === 'view' ? true : false,
        draft: name === 'draft' ? true : false,
      };
    }
    const updatedBanks = updateNewBanks(selectedQuestionBank, questionBankListWithPermissions);
    setQuestionList(updatedBanks);
    setUserQuestionBank(selectedQuestionBank);
  };

  function updateNewBanks(userBank, newBanks) {
    const allBanks = [...newBanks];
    userBank.forEach((user) => {
      const index = allBanks.findIndex((bank) => bank.id === user.id);
      if (index !== -1) {
        allBanks[index] = { ...user }; // Replace the object in newBanks
      }
    });
    return allBanks;
  }
  // ________ REMOVE ROLES ________
  const RomeveUserRoles = (e) => {
    const filteredUserroles = userRoles.filter((id) => id !== e.id);
    setUserRoles(filteredUserroles);
  };
  // ________ oganisation __________
  useEffect(() => {
    const keys = paramsTag.join(',');
    if (userId && keys === '') {
      dispatch(GetQuestionBankList(keys, '', '', '', userId));
    } else {
      dispatch(GetQuestionBankList(keys, '', '', ''));
    }
  }, [paramsTag]);
  useEffect(() => {
    if (questionBankListWithPermissions) {
      setTotalCount(questionBankListWithPermissions.length);
      setQuestionList(questionBankListWithPermissions);
    }
  }, [questionBankListWithPermissions]);
  useEffect(() => {
    if (userQuestionBank.length > 0 && questionBankListWithPermissions) {
      const updatedBanks = updateNewBanks(userQuestionBank, questionBankListWithPermissions);
      setQuestionList(updatedBanks);
    }
  }, [userQuestionBank, questionBankListWithPermissions]);
  const searchMultipleBank = async (e) => {
    setQuestionBankSearchValue(e);
    if (e.length > 2 && e.trim().length > 0) {
      const data = await CallWithAuth('GET', GET_QUESTIONS_AND_TAGS + '?keyword=' + e.trim());
      if (data?.status && data?.res && data?.res.status === 200) {
        if (data.res.data.length > 0) {
          setQuestionBankTags(data.res.data);
          setQuestionBankTagStatus(true);
        }
      }
      if (e.key === 'Enter') {
        setQuestionBankTagStatus(false);
      }
    } else {
      setQuestionBankTagStatus(false);
    }
  };
  const handleSaveUser = async (e) => {
    e.preventDefault();
    const paylaod = { ...userDetails };
    paylaod.question_bank_management = userQuestionBank.filter(
      (item) => item.review || item.edit || item.view || item.draft
    );
    if (!userId) {
      const data = await CallWithAuth('POST', CREATE_USERS, paylaod);
      if (data.status) {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.message), 'success');
          navigate(`/user-management`);
        }
      } else {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.error), 'error');
        }
      }
    } else {
      delete paylaod.password;
      const data = await CallWithAuth('PUT', GET_USERS + `${userId}/update-user/`, paylaod);
      if (data.status) {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.message), 'success');
          navigate(`/user-management/user-details/${userId}`);
        }
      } else {
        if (toast) {
          toast.remove('all');
          toast.add(String(data.res.data.error), 'error');
        }
      }
    }
  };
  useEffect(() => {
    dispatch(GetUserRoleList());
    return () => {
      setUserDetails(intialUserDetails);
      setUserQuestionBank([]);
      if (localStorage.getItem('editableData')) {
        localStorage.removeItem('editableData');
      }
    };
  }, []);
  useEffect(() => {
    if (userQuestionBank.length > 0 && questionBankListWithPermissions && userId) {
      const updatedBanks = updateNewBanks(userQuestionBank, questionBankListWithPermissions).sort((obj1, obj2) => {
        const hasTrue1 = obj1.review || obj1.edit || obj1.view || obj1.draft;
        const hasTrue2 = obj2.review || obj2.edit || obj2.view || obj2.draft;
        return hasTrue2 - hasTrue1;
      });
      setQuestionList(updatedBanks);
    }
  }, [questionBankListWithPermissions]);
  const navigate = useNavigate();
  return (
    <PageLayout title={'User Management'}>
      <div>
        <div className={style.top_section}>
          <span
            style={{ cursor: 'pointer' }}
            className={style.left}
            onClick={() => {
              if (userId) {
                dispatch(UserScreenChange(1));
                navigate(`/user-management/user-details/${userId}`);
              } else {
                dispatch(UserScreenChange(0));
                navigate(`/user-management`);
              }
            }}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5488 19.422L9.02883 12.902C8.25883 12.132 8.25883 10.872 9.02883 10.102L15.5488 3.58203"
                stroke="#212529"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </span>
        </div>
        <div className={`${style.table_content_report} ${style.table_content_adduser}`}>
          {isLoading ? (
            <p style={{ textAlign: 'center', marginTop: '20%' }}>Loading...</p>
          ) : (
            <form onSubmit={(e) => handleSaveUser(e)}>
              <div className={` ${style.top_content_table} ${style.profile_title_div}`}>
                {userId ? 'Edit User Profile' : 'Add User Profile'}
              </div>
              <div className={style.user_profile}>
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p className={style.title_typography}>
                      First Name <span className={style.required_field}>*</span>
                    </p>
                    <input
                      className={style.input_search}
                      type="search"
                      id="searchInput"
                      value={userDetails.first_name}
                      onChange={(e) => setUserDetails({ ...userDetails, first_name: e.target.value })}
                      placeholder="Enter your first name"
                      autoComplete="off"
                      list="autocompleteoff"
                    />
                  </div>
                  <div className={style.subject}>
                    <p className={style.title_typography}>
                      Last Name <span className={style.required_field}>*</span>
                    </p>
                    <input
                      className={style.input_search}
                      type="search"
                      id="searchInput"
                      value={userDetails.last_name}
                      onChange={(e) => setUserDetails({ ...userDetails, last_name: e.target.value })}
                      placeholder="Enter your last name"
                      autoComplete="off"
                      list="autocompleteoff"
                    />
                  </div>
                  <div className={style.subject}>
                    <p className={style.title_typography}>
                      Email ID <span className={style.required_field}>*</span>
                    </p>
                    <input
                      type="search"
                      className={style.input_search}
                      id="searchInput"
                      value={userDetails.email_id}
                      onChange={(e) => setUserDetails({ ...userDetails, email_id: e.target.value })}
                      placeholder="Enter your email"
                      autoComplete="off"
                      list="autocompleteoff"
                      disabled={userId}
                    />
                  </div>
                </div>
              </div>
              <div className={style.user_profile}>
                <div className={style.tags}>
                  <div className={style.subject}>
                    <p className={style.title_typography}>
                      Mobile Number <span className={style.required_field}>*</span>
                    </p>
                    <input
                      className={style.input_search}
                      type="search"
                      id="searchInput"
                      autoComplete="off"
                      list="autocompleteoff"
                      value={userDetails.mobile_number}
                      maxLength={10}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setUserDetails({ ...userDetails, mobile_number: +e.target.value });
                        }
                      }}
                      placeholder="Enter your mobile number"
                    />
                  </div>
                  <div className={style.subject}>
                    <p className={style.title_typography}>
                      Organization <span className={style.required_field}>*</span>
                    </p>
                    <select
                      className={`${style.dropdown_css} ${enableSelect ? style.selected : ''}`}
                      id="searchInput"
                      placeholder="Choose Organization"
                      name="cars"
                      style={{ color: userDetails.organization_id === 0 ? '#8E9EAB;' : 'black' }}
                      value={userDetails.organization_id}
                      onChange={(e) => {
                        setUserDetails({ ...userDetails, organization_id: +e.target.value });
                        setEnableSelect(true);
                      }}
                    >
                      <option value="0" disabled hidden>
                        Choose Organization
                      </option>
                      {organisationList?.map((items, i) => (
                        <option key={i} value={items.id}>
                          {items.name}
                        </option>
                      ))}
                    </select>
                    {organisationList
                      ?.filter((item) => item.id === userOrganisation)
                      .map((items, i) => (
                        <span key={i} className={style.roleBox}>
                          {items.name}
                        </span>
                      ))}
                  </div>
                  <div className={style.subject}>
                    <p className={style.title_typography}>
                      Roles <span className={style.required_field}>*</span>
                    </p>
                    <select
                      className={`${style.dropdown_css} ${enableRoleSelect ? style.selected : ''}`}
                      id="searchInput"
                      placeholder="Choose Role"
                      name="cars"
                      style={{ color: userDetails.role_id === 0 ? '#8E9EAB;' : 'black' }}
                      value={userDetails.role_id}
                      onChange={(e) => {
                        setUserDetails({ ...userDetails, role_id: +e.target.value });
                        setEnableRoleSelect(true);
                      }}
                    >
                      <option hidden>Choose Role</option>
                      {roles?.data
                        ?.filter((item) => !userRoles.includes(item.id))
                        ?.map((items, i) => (
                          <option key={i} value={items.id}>
                            {items.name}
                          </option>
                        ))}
                    </select>
                    {roles?.data
                      ?.filter((item) => userRoles.includes(item.id))
                      ?.map((items, i) => (
                        <span key={i} className={style.roleBox}>
                          {items.name}
                          <span onClick={() => RomeveUserRoles(items)}>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.34375 6.34375L17.6575 17.6575"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.34254 17.6575L17.6562 6.34375"
                                stroke="#272727"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
              <div style={{ padding: '0px 27px' }} className={` ${style.select_question} ${style.profile_status}`}>
                <div style={{ alignItems: 'center', padding: 0 }} className={style.info}>
                  <div style={{ padding: 0 }} className={`${style.subject} ${style.sub_label}`}>
                    <p className={style.title_typography}>
                      Temporary Password <span className={style.required_field}>*</span>
                    </p>
                    <input
                      className={`${style.input_search} ${style.subject_inp}`}
                      type={passwordSeen}
                      id="searchInput"
                      value={userDetails.password}
                      onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
                      placeholder="Enter temporary password"
                      disabled={userId}
                      autoComplete="off"
                      list="autocompleteoff"
                    />
                    {!userId && (
                      <div onClick={() => setPasswordSeen(passwordSeen === 'password' ? 'text' : 'password')}>
                        <img
                          src={passwordSeen === 'password' ? closedEye : openEye}
                          alt="openEye"
                          className="showPassword"
                        />
                      </div>
                    )}
                  </div>
                  {/* format  */}
                  <div style={{ marginLeft: '-23px' }} className={style.format}>
                    <div className={`${style.title_typography} ${style.format_title}`}>
                      Status <span className={style.required_field}>*</span>
                    </div>
                    <div className={style.format_choice}>
                      <div>
                        <input
                          type="radio"
                          className={style.pointer}
                          id="question1"
                          checked={userDetails.is_active}
                          onClick={() => setUserDetails({ ...userDetails, is_active: true })}
                          name="choose_question"
                        />
                        <label htmlFor="html">Active</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className={style.pointer}
                          id="question2"
                          checked={!userDetails.is_active}
                          onClick={() => setUserDetails({ ...userDetails, is_active: false })}
                          name="choose_question2"
                        />
                        <label htmlFor="html">Inactive</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '52%', marginTop: 14 }} className={style.user_profile}>
                <div className={`${style.title_typography} ${style.title}`}>
                  Question Bank Management <span className={style.required_field}>*</span>
                </div>
                <div className={style.tags}>
                  <div style={{ marginTop: 0 }} className={style.search_listing}>
                    <div className={style.tag_icon} style={{ maxWidth: '165px' }}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.2598 19.2592L16.1406 16.8594"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Question Bank Name</span>
                    </div>
                    <div className={style.input_search}>
                      <svg
                        style={{ position: 'relative', top: '14px', left: '14px' }}
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect y="0.5" width="20" height="20" rx="4" fill="#272727" />
                        <path
                          d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                          fill="white"
                        />
                      </svg>
                      <input
                        type="search"
                        style={{ marginLeft: '15px', width: '90%' }}
                        id="searchInput"
                        placeholder="Search subjects to set permission"
                        value={questionBankSearchValue}
                        autoComplete="off"
                        list="autocompleteoff"
                        onChange={(e) => {
                          searchMultipleBank(e.target.value);
                          setCurrentPage(1);
                          setOffset(0);
                          setInputPage(1);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && e.target.value.trim().length > 0) {
                            if (!paramsTag.includes(e.target.value)) {
                              setParamsTag([...paramsTag, e.target.value.trim()]);
                            }
                            setQuestionBankSearchValue('');
                            setQuestionBankTags([]);
                            setQuestionBankTagStatus(false);
                            event.target.blur();
                          }
                        }}
                      />

                      {questionBankTagStatus ? (
                        <div
                          style={{ height: questionBankTags.length < 2 ? '30px' : '120px' }}
                          className={style.list_input_primary}
                        >
                          {questionBankTags?.map((items, i) => (
                            <div
                              key={i}
                              className={style.pointer}
                              onClick={() => {
                                if (!paramsTag.includes(items)) {
                                  setParamsTag([...paramsTag, items]);
                                }
                                setQuestionBankSearchValue('');
                                setQuestionBankTags([]);
                                setQuestionBankTagStatus(false);
                              }}
                            >
                              <span>{items} </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.selected_subtopic_tags}>
                {paramsTag?.length > 0 && (
                  <div>
                    <div className={style.selected_tags_list}>
                      {paramsTag?.map(
                        (item, index) =>
                          item.trim().length > 0 && (
                            <span title={item} key={index} className={`${style.selected_tag_item} ${style.chips}`}>
                              {item}
                              {/* Cross icon to remove the selected tag */}
                              <svg
                                style={{ cursor: 'pointer' }}
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={style.remove_icon}
                                onClick={() => {
                                  const newTags = paramsTag.filter((items) => items !== item);
                                  setParamsTag(newTags);
                                  setCurrentPage(1);
                                  setInputPage(1);
                                  setOffset(0);
                                }} // Call removeTag function on click
                              >
                                <path
                                  d="M18 6L6 18M6 6L18 18"
                                  stroke="#272727"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          )
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className={` ${style.select_question} ${style.profile_status}`}>
                <div
                  id="clickOuter"
                  onClick={() => {
                    // eslint-disable-next-line no-console
                    console.log('outsideClicked');
                  }}
                  className={`${style.title_typography} ${style.title}`}
                >
                  Role Permission
                </div>
                <div className={style.table_container}>
                  <table className={style.responsive_table}>
                    <thead>
                      <tr>
                        <th className={`${style.first} ${style.headerTitle}`}>Question Bank</th>
                        <th className={`${style.second} ${style.headerTitle}`}>Review</th>
                        <th className={`${style.third} ${style.headerTitle}`}>Edit</th>
                        <th className={`${style.fourth} ${style.headerTitle}`}>View</th>
                        <th className={`${style.last} ${style.headerTitle}`}>Draft</th>
                      </tr>
                    </thead>
                    <tbody>
                      {questionList?.slice(offset, last).map((item, index) => (
                        <tr key={index}>
                          <td className={`${style.first} ${style.table_content_typography}`}>{item.name}</td>
                          <td className={style.second}>
                            <label style={{ cursor: 'pointer' }} className={style.custom_radio}>
                              <input
                                type="radio"
                                name={`review,${item.id}`}
                                checked={item['review']}
                                className={style.radio_checkmark}
                                onClick={(e) => QuestionBankAdd(item, 'review', e)}
                              />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                          <td className={style.third}>
                            <label style={{ cursor: 'pointer' }} className={style.custom_radio}>
                              <input
                                type="radio"
                                name={`edit,${item.id}`}
                                checked={item['edit']}
                                onClick={(e) => QuestionBankAdd(item, 'edit', e)}
                              />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                          <td className={style.fourth}>
                            <label style={{ cursor: 'pointer' }} className={style.custom_radio}>
                              <input
                                type="radio"
                                name={`view,${item.id}`}
                                checked={item['view']}
                                onClick={(e) => QuestionBankAdd(item, 'view', e)}
                              />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                          <td className={style.last}>
                            <label style={{ cursor: 'pointer' }} className={style.custom_radio}>
                              <input
                                type="radio"
                                name={`draft,${item.id}`}
                                checked={item['draft'] === true}
                                onClick={(e) => QuestionBankAdd(item, 'draft', e)}
                              />
                              <span className={style.radio_checkmark}></span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <button style={{ display: 'none' }} type="submit"></button>
            </form>
          )}{' '}
          {isLoading ? (
            <></>
          ) : (
            <>
              <div style={{ position: 'relative', width: '93%' }} className={style.paginationWrap}>
                <div style={{ width: '100%' }} className={style.pagination}>
                  <div className={style.number}>
                    {error ? <span style={{ color: 'red' }}>{error}</span> : 'The page you’re on'}
                    &nbsp; &nbsp;
                    <input
                      type="number"
                      value={inputPage}
                      onChange={handlePageInputChange}
                      onBlur={(e) => {
                        // Reset to current page if invalid input
                        if (inputPage > Math.ceil(totalCount / limit)) {
                          setError('Page Limit exceed');
                        } else {
                          if (e.target.value === '' || e.target.value < 1) {
                            setInputPage(1);
                            setCurrentPage(1);
                            return;
                          }
                          setInputPage(inputPage);
                          setCurrentPage(inputPage);
                          debouncedHandleChange(inputPage);
                          setError('');
                        }
                      }}
                      min="1"
                      max={Math.ceil(totalCount / limit)}
                      className={style.currentpage}
                    />
                  </div>
                  <div className={style.line}>
                    <svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line
                        opacity="0.1"
                        x1="0.695312"
                        y1="23.5"
                        x2="0.695311"
                        y2="0.5"
                        stroke="#764FDB"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <div className={style.change}>
                    <button style={{ cursor: offset > 0 ? 'pointer' : 'not-allowed' }} onClick={(e) => previous(e)}>
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.5622 12.7803C10.2635 13.0732 9.7793 13.0732 9.48064 12.7803L5.14685 8.53033C4.84819 8.23744 4.84819 7.76256 5.14685 7.46967L9.48064 3.21967C9.7793 2.92678 10.2635 2.92678 10.5622 3.21967C10.8608 3.51256 10.8608 3.98744 10.5622 4.28033L6.76921 8L10.5622 11.7197C10.8608 12.0126 10.8608 12.4874 10.5622 12.7803Z"
                          fill={offset === 0 ? '#a1a1a1' : '#00ABFB'} // Dynamic fill color
                        />
                      </svg>
                    </button>
                    <div className={style.current}>
                      {offset + 1}-{last} of {totalCount}
                    </div>
                    <button onClick={(e) => next(e)} disabled={last === totalCount}>
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.63122 3.21967C6.92989 2.92678 7.41413 2.92678 7.7128 3.21967L12.0466 7.46967C12.3452 7.76256 12.3452 8.23744 12.0466 8.53033L7.7128 12.7803C7.41413 13.0732 6.92989 13.0732 6.63122 12.7803C6.33256 12.4874 6.33256 12.0126 6.63122 11.7197L10.4242 8L6.63122 4.28033C6.33256 3.98744 6.33256 3.51256 6.63122 3.21967Z"
                          fill={last === totalCount ? '#a1a1a1' : '#00ABFB'} // Dynamic fill color
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className={` ${style.saveButton_questionBank} ${style.margin_to_save}`}>
                <div
                  className={` ${style.cancel} ${style.pointer}`}
                  onClick={() => {
                    if (userId) {
                      const data = JSON.parse(localStorage?.getItem('editableData'));
                      setUserQuestionBank(data.question_bank_management);
                      setUserDetails(data);
                      const newUserData = { ...userDetails, question_bank_management: userQuestionBank };
                      const showToast = isDifferentPayloads(data, newUserData);
                      if (showToast) {
                        toast.remove('all');
                        toast.add(String('Details reset successfully'), 'success');
                      }
                    } else {
                      setUserDetails(intialUserDetails);
                      setUserQuestionBank([]);
                      setQuestionList(questionBankListWithPermissions?.results);
                      const newUserData = { ...userDetails, question_bank_management: userQuestionBank };
                      const showToast = isDifferentPayloads(intialUserDetails, newUserData);
                      if (showToast) {
                        toast.remove('all');
                        toast.add(String('Details reset successfully'), 'success');
                      }
                    }
                    setParamsTag([]);
                  }}
                >
                  Cancel
                </div>
                <div>
                  <button className={style.blue_button} type="submit" onClick={handleSaveUser}>
                    {userId ? 'Save Changes' : 'Add User'}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default AddUser;
