/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import style from '../../../../../../assets/styles/createQuestion.module.css';
import QuillEditor from '../../../../../atoms/QuillEditor';
import addImg from '../../../../../../assets/images/add.svg';
import removeImg from '../../../../../../assets/images/remove.svg';

interface SelectionPerRowLabelProps {
  body: any[];
  questionbutton: number;
  // eslint-disable-next-line no-unused-vars
  setbodyformultiquestion: (body: any[]) => void;
}
interface Row {
  label: string;
  id: number;
  text: string;
  option: any[];
}

interface TableState {
  columns: string[];
  rows: Row[];
}

const SelectionPerRowLabel: React.FC<SelectionPerRowLabelProps> = ({
  body,
  questionbutton,
  setbodyformultiquestion,
}) => {
  const intialTableState = {
    columns: ['', '', '', '', ''], // 5 columns in total
    rows: [
      {
        id: 1,
        text: '',
        label: '',
        option: [
          { value: '', is_correct: false },
          { value: '', is_correct: false },
          { value: '', is_correct: false },
        ],
      }, // 4 option to match the 4 answer columns
      {
        id: 2,
        text: '',
        label: '',
        option: [
          { value: '', is_correct: false },
          { value: '', is_correct: false },
          { value: '', is_correct: false },
        ],
      },
      {
        id: 3,
        text: '',
        label: '',
        option: [
          { value: '', is_correct: false },
          { value: '', is_correct: false },
          { value: '', is_correct: false },
        ],
      },
      {
        id: 4,
        text: '',
        label: '',
        option: [
          { value: '', is_correct: false },
          { value: '', is_correct: false },
          { value: '', is_correct: false },
        ],
      },
    ],
  };
  //_______ redux selector _______
  const [questionIdentifier, setQuestionIdentifier] = useState<string | undefined>(
    body[questionbutton]?.question_short_identifier
  );
  const [questionDirection, setQuestionDirection] = useState<string | undefined>(
    body[questionbutton]?.question_direction
  );
  // state of explanation_text and compose text
  const [explanation_text, setExplanation_text] = useState(body[questionbutton]?.explaination_text);
  const [compose_text, setCompose_text] = useState(body[questionbutton]?.compose_question);
  const [videoId, setVideoId] = useState(body[questionbutton]?.video_id);
  const [table, setTable] = useState<TableState>(
    Array.isArray(body[questionbutton]?.options)
      ? {
          columns: [
            '',
            body[questionbutton]?.options[0]?.label,
            ...body[questionbutton]?.options[0]?.option?.map((item: any) => item.value),
          ],
          rows: [...body[questionbutton]?.options],
        }
      : intialTableState
  );
  useEffect(() => {
    if (Array.isArray(body[questionbutton]?.options)) {
      const newCol = [...table.columns];
      setTable({ columns: newCol, rows: [...body[questionbutton]?.options] });
    }
  }, []);
  useEffect(() => {
    return () => {
      setTable(intialTableState);
    };
  }, []);

  const AddUpdateBody = () => {
    const updatebody = [...body];
    updatebody[questionbutton] = {
      idx: questionbutton,
      question_type_id: body[questionbutton]?.question_type_id,
      question_short_identifier: questionIdentifier,
      question_direction: questionDirection,
      options: [...table.rows],
      compose_question: compose_text,
      explaination_text: explanation_text || null,
      video_id: videoId || null,
    };
    setbodyformultiquestion(updatebody);
  };

  useEffect(() => {
    AddUpdateBody();
  }, [questionDirection, questionIdentifier, compose_text, explanation_text, videoId, table]);

  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
  const addColumn = () => {
    setTable((prev) => ({
      ...prev,
      columns: [...prev.columns, ''],
      rows: prev.rows.map((row) => ({
        ...row,
        option: [...row.option, { value: '', is_correct: false }],
      })),
    }));
  };

  const removeColumn = (index: number) => {
    if (table.columns.length > 4) {
      setTable((prev) => {
        // Filter out the column
        const updatedColumns = prev.columns.filter((_, colIndex) => colIndex !== index);
        // Update rows to remove the corresponding option and adjust correctAnswer
        const updatedRows = prev.rows.map((row) => {
          const updatedOptions = row.option.filter((_, colIndex) => colIndex !== index - 2);

          return {
            ...row,
            option: updatedOptions,
          };
        });
        return {
          ...prev,
          columns: updatedColumns,
          rows: updatedRows,
        };
      });
    }
  };

  const addRow = () => {
    setTable((prev) => ({
      ...prev,
      rows: [
        ...prev.rows,
        {
          id: prev.rows.length + 1,
          text: '',
          label: '',
          option: prev.rows[0].option.map((item: any) => ({ value: item.value, is_correct: false })),
        },
      ],
    }));
  };

  const removeRow = (index: number) => {
    if (table.rows.length > 1) {
      setTable((prev) => ({
        ...prev,
        rows: prev.rows.filter((_, rowIndex) => rowIndex !== index),
      }));
    }
  };

  const handleQuestionStemChange = (rowIndex: number, value: string) => {
    const updatedRows = table.rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, text: value };
      }
      return row;
    });
    setTable((prev) => ({ ...prev, rows: updatedRows }));
  };
  const setCorrectAnswer = (rowIndex: number, colIndex: number) => {
    const newRows = table.rows;
    const data = newRows[rowIndex].option.map((item, i) => {
      if (i === colIndex) {
        item.is_correct = true;
      } else {
        item.is_correct = false;
      }
      return item;
    });
    newRows[rowIndex].option = data;
    setTable((prev) => ({ ...prev, rows: newRows }));
  };

  return (
    <>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
      <div className={style.tinymce_text}>
        {/* add explanation_text with tnymce*/}
        <div className={style.Question_Direction}>
          <p>
            Question Short Identifier <b className={style.red_mendantory}>*</b>
          </p>
          <textarea
            placeholder="Enter Question Short Identifier"
            className={`${style.question_identifier} ${style.Shortquestion} ${style.inlineShortquestion}`}
            value={questionIdentifier}
            onChange={(e) => setQuestionIdentifier(e.target.value)}
          />
        </div>

        {/* add Question Direction with tnymce*/}
        <div className={`${style.Question_Direction} ${style.QuestionDirection}`}>
          <p>Enter Question Direction</p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={questionDirection !== null ? questionDirection : ''}
              onChange={(text) => setQuestionDirection(text)}
            />
          </div>
        </div>

        {/* add compose_text with tnymce*/}
        <div className={style.compose_text}>
          <p className={style.compose_heading}>Compose Question And Mark The Correct Answer</p>
          <p>
            Compose Question <b className={style.red_mendantory}>*</b>
          </p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={compose_text !== null ? b64DecodeUnicode(compose_text) : ''}
              onChange={(text) => setCompose_text(btoa(text))}
            />
          </div>
        </div>
        <div>
          <div className={style.table_container}>
            <table>
              <thead>
                <tr>
                  {table.columns.map((col, colIndex) => (
                    <th key={colIndex + col}>
                      <div className={style.column_header}>
                        {colIndex > 3 && (
                          <button
                            className={` ${style.remove_column}  ${style.close_answer} ${style.pointer}`}
                            onClick={() => removeColumn(colIndex)}
                          >
                            <img className={style.remove_heading} src={removeImg} alt="remove" />
                            <span>Remove</span>
                          </button>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>

                <tr>
                  {table.columns.map((col, colIndex) => (
                    <th className={colIndex > 0 ? 'tableHearder' : ''} key={colIndex + col}>
                      {colIndex > 0 && (
                        <input
                          type="text"
                          className={`${style.custom_input} tableHearder`}
                          placeholder="Type Heading"
                          value={col}
                          onChange={(e) => {
                            const newCol = [...table.columns];
                            newCol[colIndex] = e.target.value;
                            let rows: any = [];
                            if (colIndex < 2) {
                              rows = table.rows.map((item) => {
                                item.label = e.target.value;
                                return item;
                              });
                            } else {
                              rows = table.rows.map((item) => {
                                item.option[colIndex - 2].value = e.target.value;
                                return item;
                              });
                            }
                            setTable({ columns: newCol, rows: rows });
                          }}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table.rows.map((row, rowIndex) => (
                  <tr key={row.id}>
                    {/* Remove row button (appears as the rightmost due to RTL) */}
                    <td className={style.remove_row_container}>
                      {rowIndex > 0 && (
                        <button
                          className={` ${style.remove_row}  ${style.close_answer} ${style.pointer}`}
                          onClick={() => removeRow(rowIndex)}
                        >
                          <img className={style.remove_heading} src={removeImg} alt="remove" />
                          <span>Remove</span>
                        </button>
                      )}
                    </td>
                    {/* Question stem input */}
                    <td>
                      <input
                        type="text"
                        placeholder="Type Question Stem"
                        className={style.custom_input}
                        value={row.text}
                        onChange={(e) => handleQuestionStemChange(rowIndex, e.target.value)}
                      />
                    </td>
                    {/* Option cells */}
                    {row.option.map((item, colIndex) => (
                      <td key={colIndex + item}>
                        <div className={style.custom_input_container}>
                          <input
                            type="radio"
                            className={style.custom_radio}
                            name={`correct-answer-${rowIndex}`}
                            checked={item.is_correct}
                            onChange={() => setCorrectAnswer(rowIndex, colIndex)}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={style.controls}>
            <button className={` ${style.Add_answer} ${style.pointer} ${style.inline_add_answer}`} onClick={addRow}>
              <img src={addImg} alt="add-image" />
              <span>Add New Row</span>
            </button>
            <button className={` ${style.Add_answer} ${style.pointer} ${style.inline_add_answer}`} onClick={addColumn}>
              <img src={addImg} alt="add-image" />
              <span>Add New Column</span>
            </button>
          </div>
        </div>
        <div className={style.explanation_text}>
          <p>Video ID</p>
          <div className={style.explanation_video}>
            <input
              type="text"
              value={videoId}
              placeholder="Enter Video ID"
              onChange={(e) => setVideoId(e.target.value)}
            />
          </div>
        </div>
        {/* add explanation_text with tnymce*/}
        <div className={style.explanation_text}>
          <p>Add Explanation Text</p>
          <div style={{ height: 'auto', flexGrow: '1' }}>
            <QuillEditor
              defaultValue={explanation_text !== null ? b64DecodeUnicode(explanation_text) : ''}
              onChange={(text) => setExplanation_text(btoa(text))}
            />
          </div>
        </div>
      </div>
      {/* QUESTION_CREATING_FINAL_SCREEN  */}
    </>
  );
};
export default SelectionPerRowLabel;
