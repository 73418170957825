import React, { useEffect, useState } from 'react';
import { CallWithAuth } from '../action/apiActions';
import { GET_QUESTION_DRAFT, POST_INITIALIZING_QUESTION_CREATION } from '../action/apiPath';
import AuthLayout from '../layout/AuthLayout';
import QuestionBank from '../component/molecule/specific/CreateQuestionScreens/QuestionBank';
import { useDispatch, useSelector } from 'react-redux';
import BasicInfo from '../component/molecule/specific/CreateQuestionScreens/BasicInfo/BasicInfo';
import QuestionCreation from '../component/molecule/specific/CreateQuestionScreens/QuestionCreation/index';
import MediumModal from '../component/atoms/Modal/MediumModal';
import {
  BasicInformation,
  CreateQuestionAction,
  QuestionCreationScreen,
  QuestionCreationScreenBack,
} from '../redux/action';
import SendForReview from '../component/molecule/specific/CreateQuestionScreens/SendForReview';
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu';
import { useParams } from 'react-router-dom';
import style from '../assets/styles/createQuestion.module.css';

const CreateQuestionSame = () => {
  // _____________ initialization _____________
  const dispatch = useDispatch();
  const param = useParams();

  // _____________ initialization _____________

  // START : initializeQuestion for getting the ID so that backend will know that we are start creating question
  const [id, setId] = useState(0);

  const initializeQuestion = async () => {
    const initializeID = await CallWithAuth('POST', POST_INITIALIZING_QUESTION_CREATION);
    if (initializeID.status && typeof initializeID.res.data.id === 'number') {
      setId(initializeID.res.data.id);
    }
  };

  useEffect(() => {
    initializeQuestion();
  }, [param]);
  // END : initializeQuestion for getting the ID so that backend will know that we are start creating question

  // _____________ REDUX SELECTOR _____________
  const selector = useSelector((state) => state.QuestionScreenReducer);

  // _____________ MODAL OPEN AND CLOSE _____________
  const [modalMedium, setModalMedium] = useState(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [modalData] = useState({
    title: 'Are you sure, Do you want to change your Question Type 1?',
    description: 'Your Current Changes Will Not Be Saved If You Are Changing Your Question Type',
    cancelButton: 'Continue Editing',
    saveButton: 'Yes, Change',
  });

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  // __________ get detail of the same question through his id __________
  const [getData, setGetData] = useState(null);
  const GetQuetsion = async () => {
    const data = await CallWithAuth('GET', GET_QUESTION_DRAFT + param.id + '/get-details/');
    if (data.status && data.res.status === 200) {
      setGetData(data.res.data);
    }
  };

  useEffect(() => {
    GetQuetsion();
  }, [param]);

  useEffect(() => {
    if (getData !== null && id !== 0) {
      if (selector.screen === 0) {
        dispatch(CreateQuestionAction(getData[0].question_bank, id));
      } else if (selector.screen === 1) {
        if (getData[0].format === 0) {
          dispatch(
            BasicInformation(
              {
                format: getData[0].format,
                difficulty: getData[0].difficulty,
                category: 2,
                academic_year: getData[0].academic_year,
                primary_tag_id: getData[0].primary_tag.id,
                secondary_tags: getData[0]?.secondary_tags?.map((item) => item.id),
                free_tags: getData[0]?.free_tags?.map((item) => item.id),
                new_free_tags: getData[0]?.new_free_tags?.map((item) => item.id),
              },
              id,
              0
            )
          );
        } else if (getData[0].format === 1) {
          const generateBodyForMultiQuestion = () => {
            return getData.map((question, index) => ({
              idx: index,
              question_type_id: question?.question_type_id?.id,
              difficulty: question?.difficulty,
              category: 2,
              academic_year: question?.academic_year,
              primary_tag_id: question?.primary_tag?.id,
              secondary_tags: question?.secondary_tags?.map((item) => item?.id),
              free_tags: question?.free_tags?.map((item) => item?.id),
              new_free_tags: [],
            }));
          };
          dispatch(
            BasicInformation(
              {
                question_group: generateBodyForMultiQuestion(),
                group_name: 'NAME 1',
                format: 1,
              },
              id,
              1
            )
          );
        }
      } else if (selector.screen === 2.1) {
        dispatch(
          QuestionCreationScreen({
            question_type_id: getData[0].question_type_id,
          })
        );
      }
    }
  }, [id, getData, selector]);

  return (
    <>
      <AuthLayout>
        {/* rightpannel top left menu according to different screen  */}
        <RightPanelTopMenu setQuestionBankId={null} />

        {/* CREATE QUESTION SCREEN with name of screen  */}
        <div className={style.create_question}>
          <div className={style.create_title}>Create Question</div>
        </div>

        {/* QUESTION_CREATION : DIV  */}
        <div className={style.question_ID}>
          <div className={style.title}>
            {/* START : question ID div  */}
            <span>
              Question ID
              <b> &nbsp; {id}</b>
            </span>
            {/* END : question ID div  */}
          </div>

          {/* START : SCREEN - CREATE QUESTION  */}
          {selector.screen === 0 ? <QuestionBank id={id} /> : ''}
          {selector.screen === 1 ? <BasicInfo id={id} /> : ''}
          {selector.screen === 2.1 || selector.screen === 2.2 ? <QuestionCreation id={id} /> : ''}
          {selector.screen === 3 ? <SendForReview id={id} /> : ''}
          {/* END : SCREEN - CREATE QUESTION  */}
        </div>
        {/* QUESTION_CREATION : DIV  */}
      </AuthLayout>
      {/* MEDIUM MODAL CALL with dispatch function  */}
      <MediumModal
        modalMedium={modalMedium}
        forwardButton={() => dispatch(QuestionCreationScreenBack())}
        openModalMedium={openModalMedium}
        closeModalMedium={closeModalMedium}
        modalData={modalData}
      />
    </>
  );
};

export default CreateQuestionSame;
