import PropTypes from 'prop-types';
import React from 'react';

export default function InputField({ onChange, style, label, value, placeholder, inputType }) {
  return (
    <div>
      <div className={style.templateDrop}>
        <div className={style.templateDrop_item}>
          <div className={style.templateGroup}>
            <span>{label} </span>
            <b className={style.red_mendantory}>*</b>
            <input
              autoComplete="off"
              list="autocompleteoff"
              style={{ width: '362px' }}
              type={inputType}
              value={value}
              onChange={onChange}
              placeholder={placeholder ?? ''}
              className={`${style.template_drop_filter} select-focused`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

InputField.propTypes = {
  inputType: PropTypes.any,
  label: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.string,
  style: PropTypes.shape({
    red_mendantory: PropTypes.any,
    templateDrop: PropTypes.any,
    templateDrop_item: PropTypes.any,
    templateGroup: PropTypes.any,
    template_drop_filter: PropTypes.any,
  }),
  value: PropTypes.string,
};
