/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediumModal from '../../../../atoms/Modal/MediumModal';
import { QuestionCreationFinalCall, QuestionCreationScreenCancel } from '../../../../../redux/action';
import McqSingleAnswer from './QuestionType.js/MCQ-singleAnswer';
import McqMultiAnswer from './QuestionType.js/MCQ-multiAnswer';
import DescriptveText from './QuestionType.js/Descriptive';
import TitaQuestions from './QuestionType.js/TITA';
import TitaSpecificRange from './QuestionType.js/TITA-specificRange';
import TitaSpecificFormat from './QuestionType.js/TITA-specificFormat';
import GraphAnalysis from './QuestionType.js/GraphAnalysis';
import FractionInput from './QuestionType.js/FractionInput';
import SelectionPerRowInline from './QuestionType.js/SelectionPerRowInline';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_QUESTIOB_TYPE } from '../../../../../action/apiPath';
import { useNavigate } from 'react-router-dom';
import style from '../../../../../assets/styles/createQuestion.module.css';
import SelectionPerRowLabel from './QuestionType.js/SelectionPerRowLabel';
import SelectionPerColumn from './QuestionType.js/SelectionPerColumn';

interface CreatingQuestionSingleProps {
  id: string | number;
  getData: any; // Replace 'any' with the appropriate type if known
}

const CreatingQuestionSingle: React.FC<CreatingQuestionSingleProps> = ({ id, getData }) => {
  // _______ initialization:start _______
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // _______ initialization: end _______

  //_______ redux selector _______
  const selector = useSelector((state: any) => state.QuestionScreenReducer);

  // MODAL OPEN AND CLOSE
  const [modalMedium, setModalMedium] = useState(false);
  const [modalData] = useState({
    title: 'Requires a Review',
    description: 'The question needs to be reviewed by our team before adding it to the Question Bank',
    cancelButton: 'Continue Editing',
    saveButton: 'Send for Review',
  });

  const openModalMedium = () => {
    setModalMedium(true);
  };

  const closeModalMedium = () => {
    setModalMedium(false);
  };

  function b64DecodeUnicode(str: string) {
    const sentence = decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sentence;

    // Function to check if a string contains only spaces, `&nbsp;`, or is empty
    const isEmptyText = (text: any) => {
      const trimmedText = text.replace(/&nbsp;/g, '').trim();
      return trimmedText.length === 0;
    };

    // Check if there are <p> tags and process them
    const pTags = tempDiv.querySelectorAll('p');
    if (pTags.length > 0) {
      const hasNonEmptyPTag = Array.from(pTags).some((p) => !isEmptyText(p.innerHTML));
      return hasNonEmptyPTag ? str : '';
    } else {
      // If no <p> tags, check if the entire input has any meaningful content
      const textContent = tempDiv?.textContent?.trim();
      return textContent && textContent.length > 0 ? str : '';
    }
  }
  const saveAndContinue = () => {
    const payload = { ...finalbody };
    payload.compose_question = payload.compose_question && b64DecodeUnicode(finalbody?.compose_question);
    payload.explaination_text = payload.explaination_text && b64DecodeUnicode(finalbody?.explaination_text);

    dispatch(QuestionCreationFinalCall(payload, id));
  };

  const initializeBodyForMultiQuestion = (getData: any, selector: any) => ({
    question_type_id: selector.questiontype.question_type_id.id,
    question_short_identifier: getData?.question_short_identifier || null,
    compose_question: getData?.compose_question || null,
    options: getData?.options || null,
    correct_answer: getData?.correct_answer || null,
    last_option_idx: null,
    randomise_answers: getData?.randomise_answers || false,
    explaination_video: getData?.explaination_video || null,
    explaination_text: getData?.explaination_text || null,
    answer_format: getData?.answer_format >= 0 ? getData?.answer_format : null,
    task_label: null,
    video_id: getData?.video_id || null,
    question_direction: getData?.question_direction || null,
  });

  const [bodyformultiquestion, setBodyformultiquestion] = useState([initializeBodyForMultiQuestion(getData, selector)]);

  interface FinalBody {
    question_type_id: any;
    question_short_identifier: any;
    compose_question: any;
    options: any;
    correct_answer: any;
    last_option_idx: null;
    randomise_answers: any;
    explaination_video: any;
    explaination_text: any;
    answer_format: any;
    task_label: null;
    video_id: any;
    question_direction: any;
  }

  const [finalbody, setFinalbody] = useState<FinalBody | null>(null);
  const [questionbutton] = useState(0);

  // ________ get category from API and grouped in sequence ________
  const [questionCategory, setQuestionCategory] = useState([]);

  const GetQuestionTypeCategory = async () => {
    // eslint-disable-next-line no-console
    console.log(questionCategory);
    const category = await CallWithAuth('GET', GET_QUESTIOB_TYPE);
    if (category.status && category.res && category.res.status === 200) {
      setQuestionCategory(category.res.data);
    }
  };

  useEffect(() => {
    GetQuestionTypeCategory();
  }, []);

  useEffect(() => {
    setFinalbody(bodyformultiquestion[0]);
  }, [bodyformultiquestion]);

  // _____________ MODAL OPEN AND CLOSE _____________
  const [modalMediumCancel, setModalMediumCancel] = useState(false);
  const [modalDataCancel] = useState({
    title: 'Are you sure, Do you want to cancel Question Creation?',
    description: 'Your Current Changes Will Not Be Saved And You Will Be Redirected To Question Listing ',
    cancelButton: 'Continue Editing',
    saveButton: 'Yes, Cancel',
  });

  const openModalMediumCancel = () => {
    setModalMediumCancel(true);
  };

  const closeModalMediumCancel = () => {
    setModalMediumCancel(false);
  };
  const Permission: any = JSON.parse(sessionStorage.getItem('questionAccess') ?? '{}');

  // cancel button to send the user to question-bank
  const Cancel = () => {
    navigate('/question-bank');
    dispatch(QuestionCreationScreenCancel());
  };

  return (
    <>
      {/* question type getting from screen 2.1 */}
      <div className={style.question_creating}>
        <div>
          <div className={style.question_creating_title}>
            <span>{selector.questiontype.question_type_id.name}</span>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922"
                stroke="#212529"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      {/*_______  QUESTION TYPE SCREEN ACCORDING TO SELECTOR _______  */}
      {bodyformultiquestion[questionbutton]?.question_type_id === 1 ? (
        <McqSingleAnswer
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 2 ? (
        <McqMultiAnswer
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 3 ? (
        <TitaQuestions
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 4 ? (
        <TitaSpecificRange
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 5 ? (
        <TitaSpecificFormat
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 6 ? (
        <DescriptveText
          key={questionbutton}
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 14 ||
      bodyformultiquestion[questionbutton]?.question_type_id === 15 ? (
        <GraphAnalysis
          key={questionbutton}
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 17 ? (
        <SelectionPerRowInline
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 18 ? (
        <SelectionPerRowLabel
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 19 ? (
        <SelectionPerColumn
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}
      {bodyformultiquestion[questionbutton]?.question_type_id === 20 ? (
        <FractionInput
          key={questionbutton}
          body={bodyformultiquestion}
          questionbutton={questionbutton}
          setbodyformultiquestion={setBodyformultiquestion}
        />
      ) : (
        ''
      )}

      {/* SAVE AND CONTINUE  */}
      <div className={style.saveButton_questionCreation}>
        <button
          className={` ${style.cancel} ${style.pointer} ${style.cancelBtnWrap}`}
          onClick={() => openModalMediumCancel()}
        >
          Cancel
        </button>
        <div>
          <button
            className={style.blue_button}
            onClick={() => (Permission.edit || Permission.review ? saveAndContinue() : openModalMedium())}
          >
            {Permission.edit || Permission.review ? 'Publish' : 'Save & Continue'}
          </button>
        </div>
      </div>
      {/* SAVE AND CONTINUE  */}

      {/* MEDIUM MODAL CALL with dispatch function  */}
      <MediumModal
        modalData={modalData}
        forwardButton={() => saveAndContinue()}
        modalMedium={modalMedium}
        closeModalMedium={closeModalMedium}
      />
      <MediumModal
        modalMedium={modalMediumCancel}
        forwardButton={() => Cancel()}
        closeModalMedium={closeModalMediumCancel}
        modalData={modalDataCancel}
      />
    </>
  );
};

export default CreatingQuestionSingle;
