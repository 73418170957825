import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { CallForAuth } from '../action/apiActions';
import { GET_AUTHENTICATION } from '../action/apiPath';

const Home = () => {
  // ________ initialization :start ____________
  const url = useLocation();
  const navigate = useNavigate();
  // ________ initialization :end ____________

  // Start _______ getting the authtoken from the url and storing it in Cookies

  const login = async () => {
    const token = sessionStorage.getItem('refreshToken')?.split(',')[0];

    const checkAuth = await CallForAuth(`jwt ${token}`, 'GET', GET_AUTHENTICATION);
    if (checkAuth.status && checkAuth.res.data.jwt !== null) {
      navigate('/question-bank');
      Cookies.set('accessToken', checkAuth.res.data.jwt);
      Cookies.set('refreshToken', checkAuth.res.data.jwt_refresh);
      Cookies.set('firstName', checkAuth.res.data.first_name);
      Cookies.set('lastName', checkAuth.res.data.last_name);
    } else {
      Cookies.set('accessToken', null);
      navigate('/sign-in');
    }
  };

  useEffect(() => {
    login();
  }, [url]);
  //_______ getting the authtoken from the url and storing it in Cookies_______ END:

  return <></>;
};

export default Home;
